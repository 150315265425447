import { AdapterFunction } from 'src/interfaces';
import { IAdaptedOrdersList, IOrdersListItem, IUserOrdersListResponse } from '../widgets/user-orders-widget/providers';
import { geoProvider } from '../orders/pages/order-view/providers/GeoProvider';
import { BatchGeoInput } from 'src/services/GraphQL';
import { logger } from '@qlean/front-logger';
import { DEFAULT_TIME_ZONE } from 'src/services/DateTimeService';

export const ordersListAdapter: AdapterFunction<IUserOrdersListResponse, Promise<IAdaptedOrdersList>> = async (
  ordersList,
) => {
  const tzMap = await getTimeZones(ordersList.data);
  // На случай старых заказов с битыми адресами
  return {
    ...ordersList,
    data: ordersList.data.map((item) => ({
      ...item,
      order: {
        ...item.order,
        timeZone: tzMap.get(item.order?.orderId!) || DEFAULT_TIME_ZONE,
      },
      statusCategory: item.order?.statusCategory!,
    })),
  };
};

// Временное решение. Нужно убрать дозапрос, когда начнем получать заказы по gql
async function getTimeZones(orders: IOrdersListItem[]): Promise<Map<string, string>> {
  const tzMap = new Map<string, string>();
  const geoInputs: BatchGeoInput[] = [];

  orders.forEach((order) => {
    const orderId = order.order?.orderId!;
    const coordinates = order.address?.coordinates;
    if (coordinates) {
      geoInputs.push({
        id: orderId,
        geoInput: {
          lat: coordinates.lat,
          long: coordinates.lng,
        },
      });
    } else {
      logger.error('No coordinates in address for order', orderId);
    }
  });

  const res = await geoProvider.getTimezoneByGeoBatch({ batchGeoInput: geoInputs });
  res.geoBatch.forEach((entry) => {
    const { timeZone } = entry.geo.dateTime;
    if (timeZone !== null) {
      tzMap.set(entry.id, timeZone);
    }
  });

  return tzMap;
}
