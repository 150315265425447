import { AppSubModule } from 'src/interfaces';
import { CATALOGS_PROVIDER_TOKENS } from '../constants';
import { managerServiceProvider } from './service-providers/ManagerService.provider';

class ManagerListModule extends AppSubModule {
  public providers = [
    {
      token: CATALOGS_PROVIDER_TOKENS.MANAGER_SERVICE,
      provider: managerServiceProvider,
    },
  ];
}

export const managerListModule = new ManagerListModule();
