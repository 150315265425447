import { TServiceProvider } from './ServiceLocator.interface';

export default class ServiceLocator {
  private serviceProviders = new Map<string, TServiceProvider>();

  public register(token: string, serviceProvider: TServiceProvider): void {
    if (!this.serviceProviders.has(token)) {
      this.serviceProviders.set(token, serviceProvider);
    }
  }

  public get<T>(token: string): T | never {
    const instance = this.serviceProviders.get(token);

    if (instance) return instance as T;

    throw new Error('Service provider with the given token has not been registered');
  }
}
