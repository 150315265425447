import cn from 'classnames';
import React, { ComponentProps, memo } from 'react';

export type IconName =
  | 'cart-shopping'
  | 'check-double'
  | 'chevron-down'
  | 'chevron-left'
  | 'chevron-right'
  | 'chevron-up'
  | 'circle-check'
  | 'circle-info'
  | 'circle-xmark'
  | 'clock-rotate-left'
  | 'copy'
  | 'dollar-sign'
  | 'edit'
  | 'ellipsis'
  | 'hashtag'
  | 'lock-open'
  | 'lock'
  | 'minus'
  | 'pen'
  | 'phone-slash'
  | 'plus'
  | 'share'
  | 'star'
  | 'stopwatch'
  | 'trash-arrow-up'
  | 'trash'
  | 'turn-up'
  | 'turn-right'
  | 'user-xmark'
  | 'user'
  | 'xmark'
  | 'list-check'
  | 'comment'
  | 'check';

export interface IconProps extends Omit<ComponentProps<'i'>, 'children'> {
  name: IconName;
}

export const Icon = memo<IconProps>(({ className, name, ...props }) => (
  <i className={cn(className, 'fa', `fa-${name}`)} {...props} />
));
