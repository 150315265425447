import { PlatformAdminApi } from 'src/services/GrpcService';
import { ADDRESS_TYPE, IAddressDetail, ICoordinates, IMetro } from '../address.interfaces';

export class AddressRelModel implements PlatformAdminApi.IAddressRel {
  // eslint-disable-next-line max-params
  constructor(
    public readonly id: string,
    public readonly label: string,
    public readonly value: string,
    public readonly unrestrictedValue: string,
    public readonly object: string,
    public readonly detail: IAddressDetail | null,
    public readonly coordinates: ICoordinates | null,
    public readonly metro: IMetro[],
    public readonly type: ADDRESS_TYPE,
    public readonly isReadonly: boolean = false,
  ) {}
}
