import { action, makeObservable, observable } from 'mobx';
import { Menu, IBreadcrumb, MENU_TYPES } from 'src/interfaces';

export default class MenuStore {
  @observable menu: Menu[] = [];

  constructor() {
    makeObservable(this);
  }

  breadcrumbs: Map<string, IBreadcrumb[]> = new Map<string, IBreadcrumb[]>();

  @action setMenu(menu: Menu[]): void {
    this.fillBreadcrumbs(menu);
    this.menu = menu;
  }

  private fillBreadcrumbs(menu: Menu[], parent: IBreadcrumb[] = []) {
    menu.forEach((item: Menu) => {
      switch (item.type) {
        case MENU_TYPES.LINK:
        case MENU_TYPES.ROUTER:
        case MENU_TYPES.ITEMS: {
          this.breadcrumbs.set(item.link, [...parent, { link: item.link, text: item.text }]);
          if (item.children) {
            this.fillBreadcrumbs(item.children, [...parent, { link: item.link, text: item.text }]);
          }
        }
      }
    });
  }

  @action getBreadcrumbs(key: string): IBreadcrumb[] {
    return [...(this.breadcrumbs.get(key) || [])];
  }
}
