export interface LocalStorageHook {
  tokensInfo: TokensInfo;
  managerSsoId: string | null;
  managerEmail: string | null;
}

export interface TokensInfo {
  accessToken: string | null;
  refreshToken: string | null;
}

export const useLocalStorage = (): LocalStorageHook => {
  const accessToken: string | null = localStorage.getItem('accessToken');
  const refreshToken: string | null = localStorage.getItem('refreshToken');
  const managerSsoId: string | null = localStorage.getItem('user_id');
  const managerEmail: string | null = localStorage.getItem('user_email');
  const tokensInfo: TokensInfo = { accessToken, refreshToken };

  return {
    tokensInfo,
    managerSsoId,
    managerEmail,
  };
};
