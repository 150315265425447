import React from 'react';
import { Dropdown } from 'react-bootstrap';

import { IIconButtonProps } from '../buttons/IconButton/IconButton';

import { TabsMenuItem } from './TabsMenuItem';
import { TabsMenuToggle } from './TabsMenuToggle';

interface TabsMenuProps<T> {
  tabs: readonly T[];
  value?: null | T;
  icon: IIconButtonProps['icon'];
  onTabClick?(tab: T): void;
  getTabLink?(tab: T): string;
  getId(value: T): string;
  getLabel(value: T): string;
  disabledPlus: boolean;
}

export function TabsMenu<T>({
  tabs,
  value,
  icon,
  getTabLink,
  onTabClick,
  getId,
  getLabel,
  disabledPlus,
}: TabsMenuProps<T>) {
  const active = value ? tabs.some((tab) => getId(tab) === getId(value)) : false;

  return (
    <Dropdown>
      <Dropdown.Toggle as={TabsMenuToggle} active={active} icon={icon} id="dropdown-menu" disabled={disabledPlus} />

      <Dropdown.Menu>
        {tabs.map((tab, i) => {
          const id = getId(tab);

          return (
            <TabsMenuItem
              key={id}
              tab={tab}
              active={getLabel(tab) === (value != null && getLabel(value))}
              getTabLink={getTabLink}
              onTabClick={onTabClick}
              getLabel={getLabel}
            />
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
}
