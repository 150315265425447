import GrpcService, { PlatformAdminApi } from 'src/services/GrpcService';

import {
  ICreate,
  ISearch,
  IUpdate,
  IRestore,
  IFindById,
  IRemove,
} from 'src/services/ServiceLocator/ServiceLocator.interface';
import { RequestController } from 'src/utils/decorators/request-controller';

class PresencePolygonServiceProvider implements ISearch, ICreate, IUpdate, IRestore, IFindById, IRemove {
  @RequestController({ logger: true, retry: true, debounce: true })
  search(params: PlatformAdminApi.ISearchPresencePolygonRequest): Promise<PlatformAdminApi.PresencePolygonsList> {
    return GrpcService.PresencePolygonService.Search(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  create(params: PlatformAdminApi.ICreatePresencePolygonRequest): Promise<PlatformAdminApi.PresencePolygon> {
    return GrpcService.PresencePolygonService.Create(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  update(params: PlatformAdminApi.IUpdatePresencePolygonRequest): Promise<PlatformAdminApi.PresencePolygon> {
    return GrpcService.PresencePolygonService.Update(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  restore(params: PlatformAdminApi.IUuidRequest): Promise<PlatformAdminApi.PresencePolygon> {
    return GrpcService.PresencePolygonService.Restore(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  remove(params: PlatformAdminApi.IUuidRequest): Promise<PlatformAdminApi.PresencePolygon> {
    return GrpcService.PresencePolygonService.Remove(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  findById(params: PlatformAdminApi.IUuidRequest): Promise<PlatformAdminApi.PresencePolygon> {
    return GrpcService.PresencePolygonService.FindById(params);
  }
}

export const presencePolygonServiceProvider = new PresencePolygonServiceProvider();
export type TPresencePolygonServiceProvider = typeof presencePolygonServiceProvider;
