import * as retryFn from 'promise-retry';

import { IRetryOptions, TDecorator } from '../interfaces';

const DEFAULT_RETRY_OPTIONS: Partial<IRetryOptions> = { retries: 2 };

export const Retry =
  (
    options: Partial<IRetryOptions> | undefined = DEFAULT_RETRY_OPTIONS,
    changeParams?: (args: unknown[], numberAttempt: number) => unknown[] | void,
  ): TDecorator =>
  // eslint-disable-next-line max-params
  (target: unknown, propertyKey: string, descriptor: PropertyDescriptor) => {
    const originalMethod = descriptor.value;

    descriptor.value = function (...args: unknown[]) {
      return retryFn(async (retry, number) => {
        try {
          if (changeParams) {
            // eslint-disable-next-line no-param-reassign
            args = changeParams.call(this, args, number) || args;
          }

          return await originalMethod.apply(this, args);

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          if (e.code >= 500 || e.code >= 400) {
            throw e;
          }

          retry(e);
        }
      }, options);
    };

    return descriptor;
  };
