import { Listener, ObserverFn, RightSidebarEventMeta, RightSidebarBaseEvent } from './interfaces';
import { RIGHT_SIDEBAR_EVENTS_TYPES } from './constants';
import { logger } from '@qlean/front-logger';

class EventBus {
  private listeners: Listener[] = [];

  constructor(private readonly eventType: RIGHT_SIDEBAR_EVENTS_TYPES) {}

  public trigger(event: RightSidebarBaseEvent) {
    this.listeners.forEach((listener) => {
      listener.cb({ ...event, type: this.eventType });

      logger.info(
        `TRIGGERED FROM: SCOPE: ${event.scope}/METHOD: ${event.method}, EVENT: ${event.event}, TYPE: ${this.eventType}`,
      );
      logger.info(`CAUGHT IN: SCOPE: ${listener.scope}, METHOD: ${listener.cb.name}`);
    });
  }
  public subscribe(cb: ObserverFn, { scope, method }: RightSidebarEventMeta) {
    const cbExists: boolean = this.listeners.some((listener) => listener.cb.name === cb.name);
    const cbHasName: boolean = Boolean(cb.name);

    if (!cbHasName) {
      logger.error('Передаваемый коллбэк обязательно доожен быть именованной функцией.');
      logger.error('Не правильно: observable.subscribe(() => ....)');
      logger.error('Правильно: const cb = () => ...; observable.subscribe(cb)');

      return;
    }

    if (cbExists) {
      return;
    }

    const listener: Listener = {
      cb,
      scope,
      method,
      type: this.eventType,
    };

    this.listeners.push(listener);
  }

  unsubscribe(cb: ObserverFn) {
    const cbHasName: boolean = Boolean(cb.name);

    if (!cbHasName) {
      logger.error('Передаваемый коллбэк обязательно доожен быть именованной функцией.');
      logger.error('Не правильно: observable.subscribe(() => ....)');
      logger.error('Правильно: const cb = () => ...; observable.subscribe(cb)');

      return;
    }

    this.listeners = this.listeners.filter((listener) => listener.cb.name !== cb.name);
  }
}
export default EventBus;
