import React, { FC, useCallback } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Icon, IconButton } from '../../../../../libs/ui-kit';
import TaskStyles from '../task.module.scss';
import cn from 'classnames';
import { TaskStateEnum } from 'src/services/GraphQL';

interface IInProgressToolbarProps {
  actionsHandler: (event: TaskStateEnum) => void;
  isDisabled: boolean;
}

const wrongAssignmentText: string = 'Задача назначена на другого исполнителя';

export const InProgressToolbar: FC<IInProgressToolbarProps> = ({ actionsHandler, isDisabled }) => {
  const postponeHandler = useCallback(() => actionsHandler(TaskStateEnum.POSTPONED), [actionsHandler]);
  const rescheduleHandler = useCallback(() => actionsHandler(TaskStateEnum.RESCHEDULED), [actionsHandler]);
  const cancelTaskHandler = useCallback(() => actionsHandler(TaskStateEnum.CANCELLED), [actionsHandler]);
  const proceedTaskHandler = useCallback(() => actionsHandler(TaskStateEnum.PROCEED), [actionsHandler]);
  const cancelText: string = isDisabled ? wrongAssignmentText : 'Отменить задачу';

  return (
    <>
      <div className="mb-2 d-flex">
        {isDisabled ? (
          <OverlayTrigger placement="top" overlay={<Tooltip id="proceed-task">{wrongAssignmentText}</Tooltip>}>
            <div className="flex-grow-1">
              <Button
                disabled={isDisabled}
                variant="success"
                className={cn('w-100', TaskStyles.applyButton)}
                onClick={proceedTaskHandler}>
                <Icon className="ml-1" name="circle-check" /> Выполнить
              </Button>
            </div>
          </OverlayTrigger>
        ) : (
          <Button
            disabled={isDisabled}
            variant="success"
            className={cn(TaskStyles.applyButton)}
            onClick={proceedTaskHandler}>
            <Icon className="ml-1" name="circle-check" /> Выполнить
          </Button>
        )}

        <OverlayTrigger placement="top" overlay={<Tooltip id="cancel-task">{cancelText}</Tooltip>}>
          <div className="ml-1">
            <IconButton disabled={isDisabled} icon="user-xmark" onClick={cancelTaskHandler} />
          </div>
        </OverlayTrigger>

        {/* <OverlayTrigger placement="top" overlay={<Tooltip id="cancel-task">Отложить задачу</Tooltip>}>
          <div>
            <IconButton icon="stopwatch" className="mr-1" />
          </div>
        </OverlayTrigger> */}
        {/* <IconButton icon="user-xmark" className="mr-1" onClick={cancelTaskHandler} /> */}
        {/* <IconButton icon="stopwatch" className="mr-1" onClick={postponeHandler} /> */}
        {/* <IconButton icon="share" className="mr-1" disabled />
        <IconButton icon="circle-xmark" disabled /> */}
      </div>

      <div className={TaskStyles.callActions}>
        {isDisabled ? (
          <OverlayTrigger placement="top" overlay={<Tooltip id="reshedule-task">{wrongAssignmentText}</Tooltip>}>
            <Button disabled={isDisabled} variant="secondary" className="mr-1" onClick={rescheduleHandler}>
              <Icon name="clock-rotate-left" /> Перезвон
            </Button>
          </OverlayTrigger>
        ) : (
          <Button disabled={isDisabled} variant="secondary" className="mr-1" onClick={rescheduleHandler}>
            <Icon name="clock-rotate-left" /> Перезвон
          </Button>
        )}

        {isDisabled ? (
          <OverlayTrigger placement="top" overlay={<Tooltip id="postpone-task">{wrongAssignmentText}</Tooltip>}>
            <Button disabled={isDisabled} variant="secondary" onClick={postponeHandler}>
              <Icon name="phone-slash" /> Недозвон
            </Button>
          </OverlayTrigger>
        ) : (
          <Button disabled={isDisabled} variant="secondary" onClick={postponeHandler}>
            <Icon name="phone-slash" /> Недозвон
          </Button>
        )}
      </div>
    </>
  );
};
