import { makeAutoObservable } from 'mobx';
import { RIGHT_SIDEBAR_BUTTON_STATES, RIGHT_SIDEBAR_BUTTONS_CONFIG, SidebarButtonsIcons } from '../constants';
import { NewStackModal, RightSidebarButtonsConfig } from '../interfaces';
import { logger } from '@qlean/front-logger';
import EventBus from '../EventBus/EventBus';
import { RIGHT_SIDEBAR_EVENTS_TYPES } from '../EventBus';
import RightSidebarEvent from '../EventBus/RightSidebarEvent';

export class LayoutRightSidebarStore {
  modalsStack: NewStackModal[] = [];
  buttonsList: RightSidebarButtonsConfig[] = [...RIGHT_SIDEBAR_BUTTONS_CONFIG];
  showModal: boolean = false;
  selectedButton: RightSidebarButtonsConfig | undefined;
  closeModalObservable: EventBus = new EventBus(RIGHT_SIDEBAR_EVENTS_TYPES.CLOSE);

  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  setSelected = (button: RightSidebarButtonsConfig) => {
    if (button.id === this.selectedButton?.id) {
      this.selectedButton = undefined;
      this.showModal = false;
      this.changeSelectedButtonState(this.selectedButton);
      return;
    }

    this.selectedButton = button;
    this.showModal = true;
    this.changeSelectedButtonState(this.selectedButton.id);
  };

  addModal = (modal: NewStackModal, event: RightSidebarEvent | undefined) => {
    if (event) {
      logger.info(`OPENED IN: SCOPE: ${event.scope}, METHOD: ${event.method}`);
    }

    this.modalsStack = [...this.modalsStack, modal];
  };

  dismissModal = (event?: RightSidebarEvent | undefined): NewStackModal | undefined => {
    if (event) {
      logger.info(`CLOSED IN: SCOPE: ${event.scope}, METHOD: ${event.method}`);
    }

    return this.modalsStack.pop();
  };

  openDoubleCheckSidebar(): void {
    const chosenButton: RightSidebarButtonsConfig | undefined = this.buttonsList.find(
      (button) => button.icon === SidebarButtonsIcons.CHECK_DOUBLE,
    );

    if (!chosenButton) {
      logger.error(`Кнопка с иконкой ${event} не существует`);
      return;
    }

    this.setSelected(chosenButton);
  }

  updateButtonsState = (buttons: RightSidebarButtonsConfig[]): void => {
    this.buttonsList = buttons;
  };

  private changeSelectedButtonState = (event?: number): void => {
    this.resetModalsStack();
    this.buttonsList.forEach((button) => {
      if (button.id === event) {
        button.state = RIGHT_SIDEBAR_BUTTON_STATES.PRIMARY;
      } else {
        button.state = RIGHT_SIDEBAR_BUTTON_STATES.OUTLINE;
      }
    });
  };

  private resetModalsStack = (): void => {
    this.modalsStack = [];
  };
}
