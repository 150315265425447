import { IUserOrdersServiceProvider } from 'src/modules/users/widgets/user-orders-widget';
import { IAdaptedOrdersList, IRetrieveOrdersRequest } from 'src/modules/users/widgets/user-orders-widget/providers';
import GrpcService from 'src/services/GrpcService';
import { RequestController } from 'src/utils/decorators/request-controller';
import { ordersListAdapter } from '../../adapters/ordersListAdapter';

export class ContractorExternalOrdersProvider implements IUserOrdersServiceProvider {
  @RequestController({ logger: true, debounce: true, retry: true })
  async retrieveOrders(request: IRetrieveOrdersRequest): Promise<IAdaptedOrdersList> {
    const res = await GrpcService.ContractorsService.RetrieveCrmOrders({
      limit: request.limit,
      page: request.page,
      where: [{ contractorId: request.userId }],
    });
    return ordersListAdapter(res);
  }
}
