import { AppModule, IRoute, Menu } from 'src/interfaces';
import routes from './plt-core.routes';
import menu from './plt-core.menu';
import { PLT_CORE_PROVIDER_TOKENS } from './constants';
import { IProviderRecord } from 'src/services/ServiceLocator/ServiceLocator.interface';
import {
  elementServiceProvider,
  priceElementServiceProvider,
  regionServiceProvider,
  tagsServiceProvider,
  unitServiceProvider,
} from './service-providers';

class PltCoreModule extends AppModule {
  public routes = (fallback: JSX.Element): IRoute[] => routes(fallback);
  public menu = (): Menu[] => menu();
  public providers: IProviderRecord[] = [
    {
      token: PLT_CORE_PROVIDER_TOKENS.TAGS_SERVICE,
      provider: tagsServiceProvider,
    },
    {
      token: PLT_CORE_PROVIDER_TOKENS.ELEMENT_SERVICE,
      provider: elementServiceProvider,
    },
    {
      token: PLT_CORE_PROVIDER_TOKENS.PRICE_ELEMENT_SERVICE,
      provider: priceElementServiceProvider,
    },
    {
      token: PLT_CORE_PROVIDER_TOKENS.REGION_SERVICE,
      provider: regionServiceProvider,
    },
    {
      token: PLT_CORE_PROVIDER_TOKENS.UNIT_SERVICE,
      provider: unitServiceProvider,
    },
  ];
}

export const pltCoreModule = new PltCoreModule();
