import { parse, stringify } from 'query-string';
import { get, pick } from 'lodash';
import AwesomePhoneNumberService from 'src/services/AwesomePhonenumberService';

/**
 * парсинг нужен для фильтрации props.location
 * парсим только `page`, `limit`, `searchText`, `dependence` и `sort`
 * @param search тело запроса после `?`
 */
export const parceSearchQueryToObject = (search: string): Record<string, string | number> => {
  const fullObj = parse(search, { parseNumbers: true });

  const pickedObj = pick(fullObj, ['page', 'limit', 'searchText', 'dependence']);

  // `sort` выделяем отдельно, т.к. это сложновложенный объект
  const pickedSort = getValueByKeyInSearchQuery('sort', search);

  if (pickedSort) {
    const sort = JSON.parse(pickedSort);

    return { ...pickedObj, sort } as Record<string, string | number>;
  }

  return pickedObj as Record<string, string | number>;
};

export const getValueByKeyInSearchQuery = (key: string, search?: string): string | undefined => {
  if (!search) {
    return;
  }

  const isString = (str: unknown): str is string => typeof str === 'string';
  const value = get(parse(search), key);

  return isString(value) ? value : undefined;
};

/**
 * в url записываем только `page`, `limit`, `searchText`, `dependence` и `sort`
 * @param args объект запроса
 */
export const stringifyObjectToSearchQuery = (args: Record<string, never>): string => {
  const pickedObj = pick(args, ['page', 'limit', 'searchText', 'dependence']);
  const pickedSort = pick(args, ['sort']);

  const searchQuerry = stringify(pickedObj, { skipEmptyString: true });

  /**
   * `stringify` из `query-string` не умеет работать со сложновложенными объектами
   */
  const sortQuery = pickedSort['sort'] ? `sort=${JSON.stringify(pickedSort['sort'])}` : '';

  const needAmpersand = !!(searchQuerry && sortQuery);

  return `?${searchQuerry}${needAmpersand ? '&' : ''}${sortQuery}`;
};

export const formatMobilePhone = (phone: string = '') => {
  const apnPhone = new AwesomePhoneNumberService(phone.replace(/[^\d]/gu, ''), 'RU');

  if (!apnPhone.isValid()) {
    return '';
  }

  const parsedPhone = apnPhone.getNumber();

  // eslint-disable-next-line prefer-named-capture-group
  return parsedPhone.replace(/(\d)(\d{3})(\d{3})(\d{2})(\d{2})$/u, '$1\xA0($2)\xA0$3-$4-$5');
};

export function formatDuration(minutesDuration: number | null | undefined): string {
  if (!minutesDuration) return '';

  const timeArray: string[] = [];

  const hours: number = Math.floor(minutesDuration / 60);
  const minutes: number = minutesDuration % 60;

  if (hours) {
    timeArray.push(`${hours} ч.`);
  }

  if (minutes) {
    timeArray.push(`${minutes} мин.`);
  }

  return timeArray.join(' ');
}
