import { RightSidebarBaseEvent } from './interfaces';

class RightSidebarEvent implements RightSidebarBaseEvent {
  readonly event: string;
  readonly scope: string;
  readonly method: string | undefined;

  constructor({ scope, method, event }: RightSidebarBaseEvent) {
    this.event = event;
    this.scope = scope;
    this.method = method;
  }
}

export default RightSidebarEvent;
