import { AppSubModule } from 'src/interfaces';
import { CATALOGS_PROVIDER_TOKENS } from '../constants';
import { apiServiceProvider } from './service-providers/ApiService.provider';

class ApiListModule extends AppSubModule {
  public providers = [
    {
      token: CATALOGS_PROVIDER_TOKENS.API_SERVICE,
      provider: apiServiceProvider,
    },
  ];
}

export const apiListModule = new ApiListModule();
