import { makeObservable, observable } from 'mobx';

export default class ThemeStore {
  // Layout navbar color
  @observable navbarBg = 'lighter';

  // Layout sidenav color
  @observable sidenavBg = 'sidenav-theme';

  // Layout footer color
  @observable footerBg = 'footer-theme';

  constructor() {
    makeObservable(this);
  }
}
