import React, { FC } from 'react';
import { Form } from 'react-bootstrap';
import { observer } from 'mobx-react';
import { FieldLabel } from '../common';
import { IField } from '../interface';

interface ISimpleFieldProps extends IField {
  type: string;
}

export const SimpleInput: FC<ISimpleFieldProps> = observer(
  ({ label = '', name = '', type = 'text', ...other }: ISimpleFieldProps) => (
    <Form.Group id={name} className="field field-simple">
      <FieldLabel label={label} />
      <Form.Control type={type} {...other} />
    </Form.Group>
  ),
);
