import { makeAutoObservable } from 'mobx';

export enum ConfirmActionsTypes {
  DEFAULT = 'primary',
  DANGER = 'danger',
  SUCCESS = 'success',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface IConfirmManagerOptions<CONFIRM_DATA = any> {
  title?: string;
  message: string | React.ComponentType<CONFIRM_DATA>;
  confirmButtonLabel?: string;
  onConfirm: () => Promise<void>;
  variant?: ConfirmActionsTypes;
  data?: CONFIRM_DATA;
  size?: 'sm' | 'lg' | 'xl';
}

class ConfirmManager {
  public isShown = false;
  public confirmOptions!: IConfirmManagerOptions;
  public resolveConfirm!: () => void;

  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  show<T>(options: IConfirmManagerOptions<T>) {
    this.isShown = true;
    this.confirmOptions = options;

    return new Promise<void>((res) => {
      this.resolveConfirm = res;
    });
  }

  hide() {
    this.isShown = false;
    this.resolveConfirm();
  }
}

export default new ConfirmManager();
