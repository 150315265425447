import GrpcService, { PlatformAdminApi } from 'src/services/GrpcService';

import {
  ICreate,
  ISearch,
  IUpdate,
  IRestore,
  IFindById,
  IRemove,
} from 'src/services/ServiceLocator/ServiceLocator.interface';
import { RequestController } from 'src/utils/decorators/request-controller';

class PresenceAreaServiceProvider implements ISearch, ICreate, IUpdate, IRestore, IFindById, IRemove {
  @RequestController({ logger: true, retry: true, debounce: true })
  search(params: PlatformAdminApi.ISearchPresenceAreaRequest): Promise<PlatformAdminApi.PresenceAreasList> {
    return GrpcService.PresenceAreaService.Search(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  create(params: PlatformAdminApi.ICreatePresenceAreaRequest): Promise<PlatformAdminApi.PresenceArea> {
    return GrpcService.PresenceAreaService.Create(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  update(params: PlatformAdminApi.IUpdatePresenceAreaRequest): Promise<PlatformAdminApi.PresenceArea> {
    return GrpcService.PresenceAreaService.Update(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  restore(params: PlatformAdminApi.IUuidRequest): Promise<PlatformAdminApi.PresenceArea> {
    return GrpcService.PresenceAreaService.Restore(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  remove(params: PlatformAdminApi.IUuidRequest): Promise<PlatformAdminApi.PresenceArea> {
    return GrpcService.PresenceAreaService.Remove(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  findById(params: PlatformAdminApi.IUuidRequest): Promise<PlatformAdminApi.PresenceArea> {
    return GrpcService.PresenceAreaService.FindById(params);
  }
}

export const presenceAreaServiceProvider = new PresenceAreaServiceProvider();
export type TPresenceAreaServiceProvider = typeof presenceAreaServiceProvider;
