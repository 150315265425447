import { SortOrder } from 'react-bootstrap-table-next';

import { TPagination } from '@qlean/admin-web-list-view/dist/components';

import { PlatformAdminApi } from './services/GrpcService';
import { IProviderRecord } from './services/ServiceLocator/ServiceLocator.interface';
import React from 'react';

export interface AnyObject {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  [key: string]: any;
}

export type TFactoryItem<K, P, I = void> = I extends void ? { type: K; props: P } : { type: K; props?: P; items: I };

export type TStorePending = {
  state: 'pending';
};

export type TStoreDone<Data> = {
  state: 'done';
  data: Data;
};

export type TStoreError = {
  state: 'error';
  error: Error | string;
};

export type TStore<Data> = TStorePending | TStoreDone<Data> | TStoreError;

export type TUuidRequest = PlatformAdminApi.UuidRequest;

export enum BADGE_VARIANT {
  DEFAULT = 'default',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  SUCCESS = 'success',
  INFO = 'info',
  WARNING = 'warning',
  DANGER = 'danger',
  DARK = 'dark',
}

export enum MENU_TYPES {
  ITEMS = 'items',
  LINK = 'link',
  ROUTER = 'router',
  HEADER = 'header',
  DIVIDER = 'divider',
}

export enum EHandlerListType {
  pagination = 'pagination',
  sort = 'sort',
  search = 'search',
}

export interface MenuDivider {
  type: MENU_TYPES.DIVIDER;
}

export interface MenuHeader {
  type: MENU_TYPES.HEADER;
  text: string;
}

export interface MenuLink {
  type: MENU_TYPES.LINK;
  link: string;
  text: string;
  target?: string;
  linkClass?: string;
  icon?: string;
  badgeText?: string;
  badgeVariant?: BADGE_VARIANT;
  disabled?: boolean;
  active?: boolean;
  children?: Menu[];
}

export interface MenuRouter {
  type: MENU_TYPES.ROUTER;
  link: string;
  text: string;
  linkClass?: string;
  icon?: string;
  badgeText?: string;
  badgeVariant?: BADGE_VARIANT;
  disabled?: boolean;
  active?: boolean;
  children?: Menu[];
}

export interface MenuItems {
  type: MENU_TYPES.ITEMS;
  link: string;
  text: string;
  icon?: string;
  badgeText?: string;
  badgeVariant?: BADGE_VARIANT;
  children: Menu[];
  open?: boolean;
  disabled?: boolean;
  active?: boolean;
}

export type Menu = (MenuLink | MenuRouter | MenuDivider | MenuHeader | MenuItems) & {
  visible?: boolean;
};

export interface IBreadcrumb {
  link: string;
  text: string;
}
export interface IBaseFetchStore<Request = Record<string, unknown>> {
  fetch(): Promise<void | void[]>;
  fetch(force: boolean): Promise<void | void[]>;
  fetch(req: Request): Promise<void | void[]>;
  fetch(req: Request, force: boolean): Promise<void | void[]>;
  fetch(req?: Request, force?: boolean): Promise<void | void[]>;
}

export interface IRoute {
  path: string;
  pureComponent?: React.ComponentType & any;
  layout?: React.ComponentType & any;
  exact?: boolean;
}

export interface ISortOption {
  field: string;
  order: SortOrder;
}

export interface IFetchState {
  limit?: number;
  page?: number;
  sort?: ISortOption[] | ISortOption | undefined;
  searchText?: string;
}

export interface IHandlerList {
  type: EHandlerListType;
  value?: IFetchOptions;
}

export type TOptions = {
  pagination?: TPagination;
  sort?: {
    field: string;
    order: SortOrder;
  };
  searchText?: string;
};

export interface IFetchOptions {
  pagination?: TPagination;
  sort?: ISortOption | undefined;
  searchText?: string;
}

export abstract class AppModule {
  public abstract providers: IProviderRecord[];
  public abstract routes(fallback: JSX.Element): IRoute[];
  public abstract menu(): Menu[];
}

export abstract class AppSubModule {
  public abstract providers: IProviderRecord[];
}

export type ICommonSearch<T = Record<string, unknown>> = PlatformAdminApi.CommonSearch & { where: T[] };

export interface IComponentKey {
  componentKey: React.Key;
}

export interface IColProps {
  col?: number;
}

type ValueOf<Obj> = Obj[keyof Obj];
type OneOnly<Obj, Key extends keyof Obj> = { [key in Exclude<keyof Obj, Key>]+?: undefined } & Pick<Obj, Key>;
type OneOfByKey<Obj> = { [key in keyof Obj]: OneOnly<Obj, key> };
export type OneOf<Obj> = ValueOf<OneOfByKey<Obj>>;

export type PickEnum<T, K extends T> = {
  [P in keyof K]: P extends K ? P : never;
};

export interface UrlParams {
  id: string;
}

export type AdapterFunction<RequestedEntity, PresenterEntity, TOptions = void> = (
  entity: RequestedEntity,
  options?: TOptions,
) => PresenterEntity;
