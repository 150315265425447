import { AdapterFunction } from '../interfaces';
import {
  PresenterCancellationReason,
  PresenterCancellationReasonsListItem,
  RequestedCancellationReasonsListItem,
} from '../../../interfaces';

export const cancellationReasonAdapter: AdapterFunction<
  RequestedCancellationReasonsListItem,
  PresenterCancellationReason
> = (reason: RequestedCancellationReasonsListItem) =>
  ({
    id: reason.id,
    title: reason.title,
  } as const);

export const cancellationReasonsListAdapter: AdapterFunction<
  RequestedCancellationReasonsListItem[],
  PresenterCancellationReasonsListItem[]
> = (requeatedReasons: RequestedCancellationReasonsListItem[]) => {
  const reasonsMap: Record<string, PresenterCancellationReason[]> = requeatedReasons.reduce(
    (reasonsMap, currReason) => {
      const reason: PresenterCancellationReason = cancellationReasonAdapter(currReason);

      if (reasonsMap[currReason.category]) {
        reasonsMap[currReason.category].push(reason);
      } else {
        reasonsMap[currReason.category] = [reason];
      }

      return reasonsMap;
    },
    {},
  );

  return Object.keys(reasonsMap).map((label) => ({ label, options: reasonsMap[label] }));
};
