import { IDadataObject } from '../address.interfaces';
import { CoordinatesModel } from '../models';

export const getCoordinates = (dadataObject: IDadataObject | undefined | null): CoordinatesModel | null => {
  if (!dadataObject) return null;

  // eslint-disable-next-line camelcase
  const { geo_lat, geo_lon } = dadataObject;

  // eslint-disable-next-line camelcase
  if (typeof geo_lat === 'undefined' || typeof geo_lon === 'undefined') {
    return null;
  }

  // eslint-disable-next-line camelcase
  if (geo_lat === null || geo_lon === null) {
    return null;
  }

  if (isNaN(Number(geo_lat)) || isNaN(Number(geo_lon))) {
    return null;
  }

  const lat = Number(geo_lat);
  const lng = Number(geo_lon);

  return new CoordinatesModel(lat, lng);
};
