import { action, computed, makeObservable, observable, toJS } from 'mobx';
import { logger } from '@qlean/front-logger';

import { IBaseFetchStore, TStore, TStoreDone } from 'src/interfaces';
import ServiceLocator from 'src/services/ServiceLocator/ServiceLocator';

export abstract class BaseFetchStore<TData, Request = Record<string, unknown>> implements IBaseFetchStore<Request> {
  protected MAX_ERROR_ATTEMPT = 3;
  protected LOGGER_PREFIX = '--BaseFetchStore';

  promise?: Promise<void>;
  errorAttempt = this.MAX_ERROR_ATTEMPT;
  public static serviceLocator: ServiceLocator;

  protected constructor() {
    makeObservable(this);
  }

  @observable public result: TStore<TData> = {
    state: 'pending',
  };

  protected abstract request(req?: Request, forced?: boolean): Promise<TData>;

  @computed get state(): TStore<TData>['state'] {
    return this.result.state;
  }

  @computed get data(): TStoreDone<TData>['data'] | undefined {
    return this.result.state === 'done' ? toJS(this.result.data) : undefined;
  }

  @action
  public fetch(req?: Request | boolean, force?: boolean): Promise<void> {
    if (typeof req === 'boolean') {
      // eslint-disable-next-line no-param-reassign
      force = req;
      // eslint-disable-next-line no-param-reassign
      req = undefined;
    }

    logger.info(`${this.LOGGER_PREFIX}--fetch--start-`);

    // Если уже запрашиваем - вернем сохраненный промис
    if (this.result.state === 'pending' && this.promise) {
      logger.info(`${this.LOGGER_PREFIX}--fetch--skip-pending-promise-`);

      return this.promise;
    }

    if ((!force && this.result.state === 'done') || (this.result.state === 'error' && this.errorAttempt === 0)) {
      logger.info(`${this.LOGGER_PREFIX}--fetch--skip-empty-promise-`);

      return Promise.resolve();
    }

    logger.info(`${this.LOGGER_PREFIX}--fetch--execute-`);

    this.result = { state: 'pending' };
    this.promise = this.request(req).then(this.fetchSuccess, this.fetchError);

    return this.promise;
  }

  @action.bound
  private fetchSuccess = (data: TData): void => {
    logger.info(`${this.LOGGER_PREFIX}--fetchSuccess--data-`, data);
    this.errorAttempt = this.MAX_ERROR_ATTEMPT;
    this.result = data ? { state: 'done', data } : { state: 'error', error: new TypeError('Not found') };
  };

  @action.bound
  private fetchError = (error: Error | string): void => {
    logger.error(`${this.LOGGER_PREFIX}--fetchError--error-`, error);
    this.result = { state: 'error', error };
    this.errorAttempt -= 1;
  };
}
