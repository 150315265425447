import './polyfills';
import React from 'react';
import * as Sentry from '@sentry/react';
import { HttpClient } from '@sentry/integrations';
import * as ReactDOM from 'react-dom';
import { configure } from 'mobx';
import Popper from 'popper.js';
import { BrowserRouter } from 'react-router-dom';

import App from './App';

declare const SENTRY_DSN: string;
declare const NAMESPACE: string;
declare const NODE_ENV: string;

configure({
  enforceActions: 'never',
});

// Required to enable animations on dropdowns/tooltips/popovers
// @ts-expect-error TODO
Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false;

Sentry.init({
  dsn: SENTRY_DSN,
  environment: NAMESPACE,
  enabled: NODE_ENV === 'production',
  enableTracing: NODE_ENV === 'production',
  integrations: [new Sentry.BrowserTracing(), new HttpClient()],
  tracesSampleRate: 1.0,
});

const rootElement = document.getElementById('root');
ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  rootElement,
);
