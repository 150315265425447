import { AppSubModule } from 'src/interfaces';
import { PLT_CORE_PROVIDER_TOKENS } from 'src/modules/plt-core/constants';
import { regionServiceProvider } from 'src/modules/plt-core/service-providers';

class RegionsListModule extends AppSubModule {
  public providers = [
    {
      token: PLT_CORE_PROVIDER_TOKENS.REGION_SERVICE,
      provider: regionServiceProvider,
    },
  ];
}

export const regionsListModule = new RegionsListModule();
