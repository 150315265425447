import React, { FC } from 'react';
import { PLACEHOLDERS, TITLES } from '../address-constants';
import { TAddress } from '../address.interfaces';
import { StringField } from '../../../../String';
import { AddressTypeField } from './AddressTypeField';

interface IAdditionalDataProps {
  address: TAddress | undefined | null;
  name: string;
}

export const AdditionalDataField: FC<IAdditionalDataProps> = ({ address, name }) => (
  <>
    <p style={{ fontWeight: '600', fontSize: '14px' }}>Дополнительно</p>
    <div className="mb-2">
      <StringField label={TITLES.entrance} name={`${name}.detail.entrance`} value={address?.detail?.entrance || ''} />
    </div>
    <div className="mb-2">
      <StringField label={TITLES.floor} name={`${name}.detail.floor`} value={address?.detail?.floor || ''} />
    </div>
    <div className="mb-2">
      <StringField label={TITLES.intercom} name={`${name}.detail.intercom`} value={address?.detail?.intercom || ''} />
    </div>
    <div className="mb-2">
      <StringField
        label={TITLES.label}
        name={`${name}.label`}
        value={address?.label || ''}
        placeholder={PLACEHOLDERS.label}
        disabled={address?.isReadonly}
      />
    </div>
    <StringField
      className="mb-2"
      label={TITLES.comment}
      name={`${name}.detail.comment`}
      value={address?.detail?.comment || ''}
    />
    <AddressTypeField name="type" />
  </>
);
