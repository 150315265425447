import GrpcService, { PlatformAdminApi } from 'src/services/GrpcService';

import {
  ICreate,
  ISearch,
  IUpdate,
  IRestore,
  IFindById,
  IRemove,
} from 'src/services/ServiceLocator/ServiceLocator.interface';
import { RequestController } from 'src/utils/decorators/request-controller';

class OptionServiceProvider implements ISearch, ICreate, IUpdate, IRestore, IFindById, IRemove {
  @RequestController({ logger: true, retry: true, debounce: true })
  search(params: PlatformAdminApi.ISearchOptionRequest): Promise<PlatformAdminApi.SearchOptionResponse> {
    return GrpcService.OptionService.Search(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  create(params: PlatformAdminApi.ICreateOptionRequest): Promise<PlatformAdminApi.OptionResponse> {
    return GrpcService.OptionService.Create(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  update(params: PlatformAdminApi.IUpdateOptionRequest): Promise<PlatformAdminApi.OptionResponse> {
    return GrpcService.OptionService.Update(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  restore(params: PlatformAdminApi.IUuidRequest): Promise<PlatformAdminApi.OptionResponse> {
    return GrpcService.OptionService.Restore(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  remove(params: PlatformAdminApi.IUuidRequest): Promise<PlatformAdminApi.OptionResponse> {
    return GrpcService.OptionService.Remove(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  findById(params: PlatformAdminApi.IUuidRequest): Promise<PlatformAdminApi.OptionResponse> {
    return GrpcService.OptionService.FindById(params);
  }
}

export const optionServiceProvider = new OptionServiceProvider();
export type TOptionServiceProvider = typeof optionServiceProvider;
