import React from 'react';
import { Link } from 'react-router-dom';
import { RouteChildrenProps } from 'react-router';

import Sidenav from '@qlean/admin-web-appwork/vendor/react/libs/sidenav-compiled';
import AppStore from 'src/store/AppStore';
import MenuStore from 'src/store/MenuStore';

import layoutHelpers from './helpers';
import { Menu, MENU_TYPES } from 'src/interfaces';

interface IProps {
  sidenavBg: string;
  orientation?: 'vertical' | 'horizontal';
}

export default class LayoutSidenav extends React.Component<IProps & RouteChildrenProps> {
  private layoutSidenavClasses() {
    let bg = this.props.sidenavBg;

    if (
      this.props.orientation === 'horizontal' &&
      (bg.indexOf(' sidenav-dark') !== -1 || bg.indexOf(' sidenav-light') !== -1)
    ) {
      bg = bg.replace(' sidenav-dark', '').replace(' sidenav-light', '').replace('-darker', '').replace('-dark', '');
    }

    return `bg-${bg} ${
      this.props.orientation === 'horizontal' ? 'layout-sidenav-horizontal container-p-x flex-grow-0' : 'layout-sidenav'
    }`;
  }

  toggleSidenav(e) {
    e.preventDefault();
    layoutHelpers.toggleCollapsed();
  }

  private isMenuActive(url) {
    return this.props.location.pathname.indexOf(url) === 0;
  }

  private isMenuOpen(url) {
    return this.props.location.pathname.indexOf(url) === 0;
  }

  render() {
    const { brandLogo, brandName, defaultRoute } = AppStore;
    const { menu } = MenuStore;

    // @ts-ignore
    return (
      <Sidenav orientation={this.props.orientation} className={this.layoutSidenavClasses()}>
        {this.props.orientation !== 'horizontal' && (
          <>
            <div className="app-brand">
              <img src={brandLogo} alt="brand" className="app-brand-logo bg-primary" />
              <Link to={defaultRoute} className="app-brand-text sidenav-text font-weight-normal ml-2">
                {brandName}
              </Link>
              <a
                href="#toggle"
                className="layout-sidenav-toggle sidenav-link text-large ml-auto"
                onClick={this.toggleSidenav}>
                <i className="ion ion-md-menu align-middle" />
              </a>
            </div>
            <Sidenav.Divider className="mt-0" />
          </>
        )}

        {/* Inner */}
        <div className={`sidenav-inner ${this.props.orientation === 'horizontal' ? '' : 'py-1'}`}>
          {this.renderMenu(menu)}
        </div>
      </Sidenav>
    );
  }

  renderMenu(menu: Menu[], num: number = 1) {
    return menu.map((item, idx) => {
      // eslint-disable-next-line no-plusplus, no-param-reassign
      const key = `menu-item-${item.type}-${num++}`;

      switch (item.type) {
        case MENU_TYPES.DIVIDER:
          return <Sidenav.Divider key={key} />;
        case MENU_TYPES.HEADER:
          return <Sidenav.Header key={key}>{item.text}</Sidenav.Header>;
        case MENU_TYPES.LINK: {
          const { link, text, type, ...props } = item;
          return (
            <Sidenav.Link key={key} href={link} {...props}>
              {text}
            </Sidenav.Link>
          );
        }
        case MENU_TYPES.ROUTER: {
          const { link, text, type, ...props } = item;
          return (
            <Sidenav.RouterLink key={key} to={link} {...props}>
              {text}
            </Sidenav.RouterLink>
          );
        }
        case MENU_TYPES.ITEMS: {
          const { type, text, link, children, ...props } = item;
          return (
            <Sidenav.Menu
              key={key}
              linkText={text}
              active={this.isMenuActive(link)}
              open={this.isMenuOpen(link)}
              {...props}>
              {this.renderMenu(children, num)}
            </Sidenav.Menu>
          );
        }
        default:
          return null;
      }
    });
  }
}
