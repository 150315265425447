import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';

import { FieldError, FieldLabel, FieldSelect, IFieldLabelProps } from '../../common';

import { TSelectFieldProps } from './SelectField.interface';
import { useField, useFormikContext } from 'formik';

export const SelectField = <T,>(props: TSelectFieldProps<T>): JSX.Element => {
  const { mode = 'vertical', name = '', label, options = [], disabled, initialValue, onChange } = props;
  const isHorizontal = mode === 'horizontal';
  const fieldProps = isHorizontal ? { as: Row } : {};
  const labelProps: IFieldLabelProps = isHorizontal ? { column: { mode: 'sm', size: 3 } } : {};
  // eslint-disable-next-line react/jsx-no-useless-fragment
  const wrapper = (children) => (isHorizontal ? <Col sm={9}>{children}</Col> : <>{children}</>);

  const { setFieldValue, setFieldTouched } = useFormikContext();
  const [inputProps, { error, touched }] = useField({ name });

  const onSelectChange = (chosenValue: unknown | null) => {
    setFieldTouched(name);
    setFieldValue(name, chosenValue);

    if (onChange) {
      onChange(chosenValue as T);
    }
  };

  return (
    <Form.Group id={name} style={props.style} {...fieldProps}>
      <FieldLabel label={label} {...labelProps} />
      {wrapper(
        <>
          <FieldSelect {...props} {...inputProps} options={options} disabled={disabled} onChange={onSelectChange} />
          {touched && error && <FieldError error={error} />}
        </>,
      )}
    </Form.Group>
  );
};
