import { useField } from 'formik';
import React, { FC } from 'react';
import { Form } from 'react-bootstrap';
import { ADDRESS_TYPE_OPTIONS, ADDRESS_TYPE_TRANSLATION } from '../address-constants';

interface IAddressTypeFieldProps {
  name: string;
}

export const AddressTypeField: FC<IAddressTypeFieldProps> = ({ name }) => {
  const [{ value }, , { setValue }] = useField(name);

  const handleAddressTypeChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    // Т.к. енам численный, то его нужно из строки сконвертировать обратно в число
    setValue(+event.currentTarget.id);
  };

  return (
    <Form.Group>
      {ADDRESS_TYPE_OPTIONS.map((addressType) => (
        <Form.Check
          as="input"
          checked={addressType === value}
          onChange={handleAddressTypeChange}
          // Т.к. енам численный, то его нужно сконвертировать в строку
          id={addressType.toString()}
          key={addressType}
          type="radio"
          name="type"
          label={ADDRESS_TYPE_TRANSLATION[addressType]}
        />
      ))}
    </Form.Group>
  );
};
