import { AppModule, IRoute, Menu, MENU_TYPES } from 'src/interfaces';
import { IProviderRecord } from 'src/services/ServiceLocator/ServiceLocator.interface';
import { CUSTOMERS_PROVIDER_TOKENS } from './constants';
import routes from './customers.routes';
import { customersProvider } from './service-providers';

class CustomersModule extends AppModule {
  public routes = (fallback: JSX.Element): IRoute[] => routes(fallback);

  public menu = (): Menu[] => [
    {
      type: MENU_TYPES.ROUTER,
      text: 'Клиенты',
      link: '/users/customers/',
    },
  ];
  public providers: IProviderRecord[] = [
    {
      token: CUSTOMERS_PROVIDER_TOKENS.CUSTOMERS_SERVICE,
      provider: customersProvider,
    },
  ];
}

export const customersModule = new CustomersModule();
