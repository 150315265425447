import { toast } from 'react-toastify';
import { ToastOptions } from 'react-toastify/dist/types';

import { SimpleDebounce } from '../../utils/decorators/simple-debounce';

export class ToastifyService {
  @SimpleDebounce()
  toast(message: string, options?: ToastOptions): void {
    toast(message, options);
  }
}
