import cn from 'classnames';
import React, { FC } from 'react';
import styles from './Spinner.styles.module.scss';

interface ISpinnerProps {
  size: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'default';
}

export const Spinner: FC<ISpinnerProps> = ({ size }) => (
  <i className={cn('fa-solid fa-spinner', styles.spinner, styles[size])} />
);
