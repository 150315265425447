import { Logger } from '../../../services/LoggerService';

import { ILoggerOptions, TDecorator } from '../interfaces';

export function Log(loggerOptions?: ILoggerOptions): TDecorator {
  const { loggerPrefix } = loggerOptions || {};

  // eslint-disable-next-line max-params
  return (target: unknown, propertyKey: string, descriptor: PropertyDescriptor): void => {
    const originalMethod = descriptor.value;
    const serviceName: string = (target as Record<string, unknown>).constructor?.name;
    const defaultLoggerPrefix = `${serviceName}-${propertyKey}`;
    const prefix = loggerPrefix || defaultLoggerPrefix;

    descriptor.value = function (...args: unknown[]) {
      try {
        const result = originalMethod.apply(this, args);

        if (result && typeof result.then === 'function' && typeof result.catch === 'function') {
          Logger.info(`${prefix || ''} fetch start`, ...args);

          result
            .then((response) => {
              Logger.info(`${prefix || ''} fetch success`, response);
            })
            .catch((error: unknown) => {
              Logger.error(`${prefix || ''} fetch error`, error);
            });

          return result;
        }

        return result;
      } catch (error) {
        Logger.error(`${prefix || ''} error`, error);
        throw error;
      }
    };
  };
}
