import { AppSubModule } from 'src/interfaces';
import { CATALOGS_PROVIDER_TOKENS } from '../constants';
import { blockingReasonsServiceProvider } from './service-providers/BlockingReasonsService.provider';

class BlockingReasonsListModule extends AppSubModule {
  public providers = [
    {
      token: CATALOGS_PROVIDER_TOKENS.BLOCKING_REASONS_SERVICE,
      provider: blockingReasonsServiceProvider,
    },
  ];
}

export const blockingReasonsListModule = new BlockingReasonsListModule();
