import React, { memo, useCallback, useEffect, useState } from 'react';
import { DateTimeService } from 'src/services/DateTimeService';
import { logger } from '@qlean/front-logger';
import { Form, Formik } from 'formik';
import { FieldLabel, StringField } from 'src/libs/ui-kit';
import { TimeShortcutFields } from '../../TimeShortcutFields';
import { Button } from 'react-bootstrap';

export interface TicketTaskPutOffFormProps {
  taskId: string;
  onClose(): void;
}

interface PostponeFormValues {
  readonly date: null | Date;
  readonly comment: string;
}

const FORM_VALUES: PostponeFormValues = {
  date: null,
  comment: '',
};

/**
 * @deprecated
 * Комионент более не актуален
 */
export const TaskPostponeForm = memo<TicketTaskPutOffFormProps>(({ taskId, onClose }) => {
  const [minDate, setMinDate] = useState<string | number | undefined>(undefined);

  const onSubmit = useCallback(
    (values: PostponeFormValues) => {
      logger.info(values);
    },
    [onClose],
  );

  useEffect(() => {
    const today: string = DateTimeService.today();
    const date: string = DateTimeService.format(today, 'YYYY-MM-DD');

    setMinDate(date);
  }, []);

  return (
    <Formik initialValues={FORM_VALUES} onSubmit={onSubmit}>
      <Form className="p-3">
        <FieldLabel label="Отложить" />
        <TimeShortcutFields name="date" shortcuts={SHORTCUTS} minDate={minDate} />
        <StringField name="comment" variant="textarea" label="Комментарий" />

        <div>
          <Button type="submit" className="mr-2">
            Подтвердить
          </Button>

          <Button type="button" variant="default" onClick={onClose}>
            Отменить
          </Button>
        </div>
      </Form>
    </Formik>
  );
});

const SHORTCUTS = [
  {
    label: '15 мин.',
    value: 15,
    unit: DateTimeService.Unit.Minutes,
  },
  {
    label: '30 мин.',
    value: 30,
    unit: DateTimeService.Unit.Minutes,
  },
  {
    label: '45 мин.',
    value: 45,
    unit: DateTimeService.Unit.Minutes,
  },
  {
    label: '1 ч.',
    value: 1,
    unit: DateTimeService.Unit.Hours,
  },
] as const;
