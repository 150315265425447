import { useEffect, useRef, useState } from 'react';

import { Observable } from './interfaces';

export function useStore<T>(store: Observable<T>) {
  const [state, setState] = useState<T>(null!);

  const initialized = useRef<boolean>(false);

  if (!initialized.current) {
    initialized.current = true;
    store.subscribe(setState).unsubscribe();
  }

  useEffect(() => {
    const subscription = store.subscribe(setState);
    return () => subscription.unsubscribe();
  }, [store]);

  return state;
}
