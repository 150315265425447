import React, { memo } from 'react';
import { Alert as BSAlert } from 'react-bootstrap';
import cn from 'classnames';

import './styles.scss';

export const Alert = memo<
  {
    header: React.ReactNode;
  } & React.ComponentProps<typeof BSAlert>
  // eslint-disable-next-line react/prop-types
>(({ header, children, className = '', variant, ...props }) => {
  const showIcon = !!variant && variant in ICON_MAP;

  const alertClassName = cn(className, 'alert-qlean', {
    'alert-qlean-with-icon': showIcon,
  });

  const iconClassName = cn('d-block', 'fas', 'alert-qlean-icon', ICON_MAP[variant as keyof typeof ICON_MAP]);

  return (
    <BSAlert className={alertClassName} variant={variant} {...props}>
      {showIcon && (
        <div className="alert-qlean-icon-wrapper">
          <i className={iconClassName} />
        </div>
      )}

      <div className="alert-qlean-header-wrapper">
        <BSAlert.Heading>{header}</BSAlert.Heading>
      </div>

      {children && <div className="alert-qlean-description">{children}</div>}
    </BSAlert>
  );
});

Alert.displayName = 'Alert';

const ICON_MAP = {
  success: 'fa-check-circle',
  info: 'fa-info-circle',
  warning: 'fa-exclamation-triangle',
  danger: 'fa-exclamation-circle',
} as const;
