export enum CATALOGS_PROVIDER_TOKENS {
  NOTIFICATION_SERVICE = 'notificationService',
  TOKEN_SERVICE = 'tokenService',
  REGULATIONS_SERVICE = 'regulationsService',
  OPTION_SERVICE = 'optionService',
  MANAGER_SERVICE = 'managerService',
  PROFILE_SERVICE = 'profileService',
  MANAGER_SKILLS_SERVICE = 'managerSkillsService',
  BLOCKING_REASONS_SERVICE = 'blockingReasonsService',
  BLOCKING_REASON_GROUP_SERVICE = 'blockingReasonGroupService',
  PERSON_SERVICE = 'personService',
  OFFER_SERVICE = 'offerService',
  SKILL_SERVICE = 'skillService',
  POSITION_SERVICE = 'positionService',
  MARKET_SERVICE = 'marketService',
  SPECIALIZATIONS_SERVICE = 'specializationsService',
  PRESENCE_AREA_SERVICE = 'presenceAreaService',
  PRESENCE_POLYGON_SERVICE = 'presencePolygonService',
  API_SERVICE = 'apiService',
  TENANT_SERVICE = 'tenantService',
  APP_SERVICE = 'appService',
  WMS_TEMPLATES_SERVICE = 'wmsTemplatesServiceProvider',
  WMS_SKUS_SERVICE = 'wmsSkusServiceProvider',
  WMS_WAREHOUSES_SERVICE = 'WmsWarehousesServicePrvider',
  WMS_PLACES_SERVICE = 'WmsPlacesServiceProvider',
}
