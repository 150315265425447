import React, { FC, useCallback, useEffect, useState } from 'react';
import { TAddress } from '../address.interfaces';
import { TITLES } from '../address-constants';
import ManualAddressAdapter from '../adapters/ManualAddress.adapter';
import { StringField } from '../../../../String';
import { useField, useFormikContext } from 'formik';
import { FieldError, ValidationErrors } from '../../../../../common';

interface IManualFieldProps {
  address: TAddress | undefined | null;
  name: string;
  blockedFields: Set<string>;
  handleChosenAddress: (addressRel: TAddress, isDadata: boolean) => void;
  isReadonly: boolean;
}

export const ManualField: FC<IManualFieldProps> = ({
  name,
  address,
  blockedFields,
  handleChosenAddress,
  isReadonly,
}: IManualFieldProps): JSX.Element => {
  const [currentAddress, setCurrentAddress] = useState<TAddress | null>(address || null);
  const [field] = useField(name);
  const [errorsMap, setErrorsMap] = useState<{ [key: string]: string | undefined }>({});

  const { touched } = useFormikContext();

  const handleManualAddress = useCallback(
    (event) => {
      const manualAddress = ManualAddressAdapter(field.value, event.target.name, event.target.value);

      if (touched) {
        handleChosenAddress(manualAddress, false);
      }
    },
    [field.value, handleChosenAddress, touched],
  );

  const validate = useCallback((value: string): string | undefined => {
    if (value?.trim()) return undefined;

    return ValidationErrors.required();
  }, []);

  useEffect(() => {
    if (!address) {
      return;
    }

    if (address.object) {
      setCurrentAddress(address);
    } else {
      const manualAddress = ManualAddressAdapter(address, '', '');

      setCurrentAddress(manualAddress);
    }

    setErrorsMap({ street: validate(address?.detail?.street || ''), house: validate(address?.detail?.house || '') });
  }, [address, validate]);

  return (
    <>
      <div className="mb-2">
        <StringField
          label={TITLES.region}
          name={`${name}.detail.region`}
          value={currentAddress?.detail?.region || ''}
          onChange={handleManualAddress}
          disabled={blockedFields.has('region') || isReadonly}
        />
      </div>
      <div className="mb-2">
        <StringField
          label={TITLES.city}
          name={`${name}.detail.city`}
          value={currentAddress?.detail?.city || ''}
          onChange={handleManualAddress}
          disabled={blockedFields.has('city') || isReadonly}
        />
      </div>
      <div className="mb-2">
        <StringField
          label={TITLES.settlement}
          name={`${name}.detail.settlement`}
          value={currentAddress?.detail?.settlement}
          onChange={handleManualAddress}
          disabled={blockedFields.has('settlement') || isReadonly}
        />
      </div>
      <div className="mb-2">
        <StringField
          name={`${name}.detail.area`}
          label={TITLES.area}
          value={currentAddress?.detail?.area || ''}
          onChange={handleManualAddress}
          disabled={blockedFields.has('area') || isReadonly}
        />
      </div>
      <div className="mb-2">
        <StringField
          name={`${name}.detail.street`}
          label={TITLES.street}
          value={currentAddress?.detail?.street || ''}
          onChange={handleManualAddress}
          validate={validate}
          disabled={blockedFields.has('street') || isReadonly}
        />
        <FieldError error={errorsMap['street'] || ''} />
      </div>
      <div className="mb-2">
        <StringField
          name={`${name}.detail.house`}
          label={TITLES.house}
          value={currentAddress?.detail?.house || ''}
          onChange={handleManualAddress}
          validate={validate}
          disabled={blockedFields.has('house') || isReadonly}
        />
        <FieldError error={errorsMap['house'] || ''} />
      </div>
      <div className="mb-2">
        <StringField
          name={`${name}.detail.block`}
          label={TITLES.block}
          value={currentAddress?.detail?.block || ''}
          onChange={handleManualAddress}
          disabled={blockedFields.has('block') || isReadonly}
        />
      </div>
      <div>
        <StringField
          name={`${name}.detail.flat`}
          label={TITLES.flat}
          value={currentAddress?.detail?.flat || ''}
          onChange={handleManualAddress}
          disabled={blockedFields.has('flat' || isReadonly)}
        />
      </div>
    </>
  );
};
