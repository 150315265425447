import React, { forwardRef } from 'react';

import { IconButton, IIconButtonProps } from '../buttons/IconButton/IconButton';

interface TabsMenuToggleProps {
  icon: IIconButtonProps['icon'];
  active: boolean;
  onClick(): void;
  disabled: boolean;
}

export const TabsMenuToggle = forwardRef<HTMLButtonElement, TabsMenuToggleProps>(
  ({ icon, active, onClick, disabled }, ref) => (
    <IconButton
      ref={ref}
      icon={icon}
      variant={active ? 'primary' : 'default'}
      size="sm"
      clickHandler={onClick}
      disabled={disabled}
    />
  ),
);
