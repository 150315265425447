import { OmsStore } from '../OMS/OmsStore';
import { SsoStore } from '../SSO/Sso.store';
import { TicketsStore } from '../ticket-system/Tickets.store';

export class TicketsRootStore {
  private static rootTicketsStoreInstance: TicketsRootStore | undefined;

  public readonly ticketsSystemStore: TicketsStore = new TicketsStore();
  public readonly ssoStore: SsoStore = new SsoStore();
  public readonly omsStore: OmsStore = new OmsStore();

  /**
   * @summary Конструктор нужен только для того, чтобы сделать его приватным.
   * @description Это позволит не допустить возможность создания нового экземпляра через new TicketsRootStore снаружи класса.
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  public static getTicketsRootStore(): TicketsRootStore {
    if (!this.rootTicketsStoreInstance) {
      this.rootTicketsStoreInstance = new TicketsRootStore();

      return this.rootTicketsStoreInstance;
    }

    return this.rootTicketsStoreInstance;
  }
}

export const ticketsRootStore: TicketsRootStore = TicketsRootStore.getTicketsRootStore();
export type TTicketsRootStore = typeof ticketsRootStore;
