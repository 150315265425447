import { makeAutoObservable } from 'mobx';
import { BreadcrumbsLink } from './interfaces';

class BreadcrumbsStore {
  links: readonly BreadcrumbsLink[] = [];

  get useFallback(): boolean {
    return !this.links.length;
  }

  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  registerLinks(links: readonly BreadcrumbsLink[]) {
    this.links = links;
  }

  unregisterLinks() {
    this.links = [];
  }
}

export const breadcrumbsStore = new BreadcrumbsStore();
