import { AppSubModule } from 'src/interfaces';
import { CATALOGS_PROVIDER_TOKENS } from '../constants';
import { skillServiceProvider } from './service-providers/SkillService.provider';

class SkillsListModule extends AppSubModule {
  public providers = [
    {
      token: CATALOGS_PROVIDER_TOKENS.SKILL_SERVICE,
      provider: skillServiceProvider,
    },
  ];
}

export const skillsListModule = new SkillsListModule();
