import { wmsTemplatesServiceProvider } from './service-providers/WmsTemplatesService.provider';
import { AppSubModule } from 'src/interfaces';
import { CATALOGS_PROVIDER_TOKENS } from '../constants';

class WmsTemplatesModule extends AppSubModule {
  public providers = [
    {
      token: CATALOGS_PROVIDER_TOKENS.WMS_TEMPLATES_SERVICE,
      provider: wmsTemplatesServiceProvider,
    },
  ];
}

export const wmsTemplatesModule = new WmsTemplatesModule();
