import GrpcService from 'src/services/GrpcService';

import { RequestController } from '../../../../utils/decorators/request-controller';
import { TCustomerAddressesList, TCustomerSearchRequest, TCustomersList } from '../Customers.interfaces';
import { PlatformAdminApi } from 'src/services/GrpcService/codegen/app_pb';
import { TUuidRequest } from 'src/interfaces';

class CustomersProvider {
  @RequestController({ logger: true, debounce: true, retry: true })
  public search(params: TCustomerSearchRequest): Promise<TCustomersList> {
    return GrpcService.CustomerService.Search(params);
  }

  @RequestController({ logger: true, debounce: true, retry: true })
  public remove(params: TUuidRequest): Promise<PlatformAdminApi.ICustomer> {
    return GrpcService.CustomerService.Remove(params);
  }

  @RequestController({ logger: true, debounce: true, retry: true })
  public searchTenants(
    params: PlatformAdminApi.ITenantSearchRequestFrontend,
  ): Promise<PlatformAdminApi.TenantSearchResponse> {
    return GrpcService.CustomerService.SearchTenant(params);
  }
}

export const customersProvider = new CustomersProvider();
export type TCustomersProvider = typeof customersProvider;
