import { lazy } from 'src/utils';
import { IRoute } from '../../routes';

export default (fallback: JSX.Element): IRoute[] => [
  // {
  //   path: '/catalogs/core/tags/',
  //   pureComponent: lazy(() => import('./tags-list/pages/tags-list/TagsList.page'), fallback),
  //   breadcrumb: 'Список тегов',
  //   exact: true,
  // },
  // {
  //   path: '/catalogs/regions/',
  //   pureComponent: lazy(() => import('./regions-list/pages/regions-list/RegionsList.page'), fallback),
  //   breadcrumb: 'Список регионов',
  //   exact: true,
  // },
  // {
  //   path: '/catalogs/core/units/',
  //   pureComponent: lazy(() => import('./units-list/pages/units-list/UnitsList.page'), fallback),
  //   breadcrumb: 'Список единиц измерения',
  //   exact: true,
  // },
  {
    path: '/catalogs/presence/',
    pureComponent: lazy(() => import('./presence-area-list/pages/presence-area-list/PresenceAreaList.page'), fallback),
    breadcrumb: 'Регионы',
    exact: true,
  },
  {
    path: '/catalogs/presence/area/',
    pureComponent: lazy(() => import('./presence-area-list/pages/presence-area-list/PresenceAreaList.page'), fallback),
    breadcrumb: 'Регионы присутствия',
    exact: true,
  },
  {
    path: '/catalogs/presence/polygon/',
    pureComponent: lazy(
      () => import('./presence-polygon-list/pages/presence-polygon-list/PresencePolygonList.page'),
      fallback,
    ),
    breadcrumb: 'Полигоны',
    exact: true,
  },

  // Управление уведомленииями
  {
    path: '/catalogs/communications-gateway',
    pureComponent: lazy(() => import('./tokens-list/pages/tokens-list/TokensList.page'), fallback),
    breadcrumb: 'Управление уведомлениями',
    exact: true,
  },
  {
    path: '/catalogs/communications-gateway/notifications/',
    pureComponent: lazy(() => import('./notifications-list/pages/notifications-list/NotificationsList.page'), fallback),
    breadcrumb: 'Уведомления',
    exact: true,
  },
  {
    path: '/catalogs/communications-gateway/tokens/',
    pureComponent: lazy(() => import('./tokens-list/pages/tokens-list/TokensList.page'), fallback),
    breadcrumb: 'Токены',
    exact: true,
  },

  // CRM
  {
    path: '/catalogs/crm/',
    pureComponent: lazy(() => import('./offer-list/pages/offer-list/OfferList.page'), fallback),
    breadcrumb: 'Сервисы CRM',
    exact: true,
  },
  {
    path: '/catalogs/crm/offers/',
    pureComponent: lazy(() => import('./offer-list/pages/offer-list/OfferList.page'), fallback),
    breadcrumb: 'Офферы',
    exact: true,
  },
  {
    path: '/catalogs/crm/persons/',
    pureComponent: lazy(() => import('./person-list/pages/person-list/PersonList.page'), fallback),
    breadcrumb: 'Клиенты',
    exact: true,
  },

  // HRM
  {
    path: '/catalogs/hrm',
    pureComponent: lazy(
      () => import('./blocking-reason-group-list/pages/blocking-reason-group-list/BlockingReasonGroupList.page'),
      fallback,
    ),
    breadcrumb: 'HRM',
    exact: true,
  },
  {
    path: '/catalogs/hrm/blockingReasonsGroups/',
    pureComponent: lazy(
      () => import('./blocking-reason-group-list/pages/blocking-reason-group-list/BlockingReasonGroupList.page'),
      fallback,
    ),
    breadcrumb: 'Группы причин отключения',
    exact: true,
  },
  {
    path: '/catalogs/hrm/blockingReasons/',
    pureComponent: lazy(
      () => import('./blocking-reasons-list/pages/blocking-reasons-list/BlockingReasonsList.page'),
      fallback,
    ),
    breadcrumb: 'Причины отключения',
    exact: true,
  },
  {
    path: '/catalogs/hrm/specializations/',
    pureComponent: lazy(
      () => import('./specializations-list/pages/specializations-list/SpecializationsList.page'),
      fallback,
    ),
    breadcrumb: 'Специализации',
    exact: true,
  },
  {
    path: '/catalogs/hrm/skills/',
    pureComponent: lazy(() => import('./skills-list/pages/skills-list/SkillsList.page'), fallback),
    breadcrumb: 'Навыки',
    exact: true,
  },
  {
    path: '/catalogs/hrm/positions/',
    pureComponent: lazy(() => import('./position-list/pages/position-list/PositionList.page'), fallback),
    breadcrumb: 'Рабочие позиции',
    exact: true,
  },

  // SSO
  {
    path: '/catalogs/sso',
    pureComponent: lazy(() => import('./api-list/pages/api-list/ApiList.page'), fallback),
    breadcrumb: 'SSO',
    exact: true,
  },
  {
    path: '/catalogs/sso/api/',
    pureComponent: lazy(() => import('./api-list/pages/api-list/ApiList.page'), fallback),
    breadcrumb: 'Список Api',
    exact: true,
  },
  {
    path: '/catalogs/sso/market/',
    pureComponent: lazy(() => import('./market-list/pages/market-list/MarketList.page'), fallback),
    breadcrumb: 'Сервисы',
    exact: true,
  },
  {
    path: '/catalogs/sso/tenants/',
    pureComponent: lazy(() => import('./tenant-list/pages/tenant-list/TenantList.page'), fallback),
    breadcrumb: 'Список арендаторов',
    exact: true,
  },
  {
    path: '/catalogs/sso/apps/',
    pureComponent: lazy(() => import('./app-list/pages/app-list/AppList.page'), fallback),
    breadcrumb: 'Приложения',
    exact: true,
  },

  // WMS

  {
    path: '/catalogs/wms',
    pureComponent: lazy(() => import('./wms-skus-list/pages/WmsSkusList.page'), fallback),
    breadcrumb: 'wms',
    exact: true,
  },
  {
    path: '/catalogs/wms/templates',
    pureComponent: lazy(() => import('./wms-templates/pages/WmsTemplatesList.page'), fallback),
    breadcrumb: 'Шаблоны',
    exact: true,
  },
  {
    path: '/catalogs/wms/skus',
    pureComponent: lazy(() => import('./wms-skus-list/pages/WmsSkusList.page'), fallback),
    breadcrumb: 'Номенклатура',
    exact: true,
  },
  {
    path: '/catalogs/wms/warehouses',
    pureComponent: lazy(() => import('./wms-warehouses/pages/WmsWarehousesList.page'), fallback),
    breadcrumb: 'Склады',
    exact: true,
  },
  {
    path: '/catalogs/wms/places',
    pureComponent: lazy(() => import('./wms-places/pages/WmsPlacesList.page'), fallback),
    breadcrumb: 'Места',
    exact: true,
  },
];
