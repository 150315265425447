import GrpcService, { PlatformAdminApi } from 'src/services/GrpcService';

import {
  ICreate,
  ISearch,
  IUpdate,
  IRestore,
  IFindById,
  IRemove,
} from 'src/services/ServiceLocator/ServiceLocator.interface';
import { RequestController } from 'src/utils/decorators/request-controller';

class SpecializationsServiceProvider implements ISearch, ICreate, IUpdate, IRestore, IFindById, IRemove {
  @RequestController({ logger: true, retry: true, debounce: true })
  search(
    params: PlatformAdminApi.ISpecializationsSearchRequest,
  ): Promise<PlatformAdminApi.SpecializationsSearchResponse> {
    return GrpcService.SpecializationsService.Search(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  create(params: PlatformAdminApi.ISpecializationCreateRequest): Promise<PlatformAdminApi.SpecializationResponse> {
    return GrpcService.SpecializationsService.Create(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  update(params: PlatformAdminApi.ISpecializationUpdateRequest): Promise<PlatformAdminApi.SpecializationResponse> {
    return GrpcService.SpecializationsService.Update(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  restore(params: PlatformAdminApi.ISpecializationRestoreRequest): Promise<PlatformAdminApi.SpecializationResponse> {
    return GrpcService.SpecializationsService.Restore(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  remove(params: PlatformAdminApi.ISpecializationRemoveRequest): Promise<PlatformAdminApi.SpecializationResponse> {
    return GrpcService.SpecializationsService.Remove(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  findById(params: PlatformAdminApi.IUuidRequest): Promise<PlatformAdminApi.SpecializationResponse> {
    return GrpcService.SpecializationsService.FindById(params);
  }
}

export const specializationsServiceProvider = new SpecializationsServiceProvider();
export type TSpecializationsServiceProvider = typeof specializationsServiceProvider;
