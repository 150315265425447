import React, { ChangeEventHandler, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useField } from 'formik';

import { DateTimeService } from 'src/services/DateTimeService';
import dayjs from 'dayjs';

export interface TicketTaskTimeFormProps {
  name: string;
  shortcuts: readonly ShortcutConfig[];
  minDate?: string | number;
}

export interface ShortcutConfig {
  readonly value: number;
  readonly unit: DateTimeService.Unit;
  readonly label: string;
}

export const TimeShortcutFields = memo<TicketTaskTimeFormProps>(({ name, shortcuts, minDate }) => {
  const [, , { setValue }] = useField<string | null | Date | undefined>({ name });
  const [directDate, setDirectDate] = useInputValue('');
  const [directTime, setDirectTime] = useInputValue('');
  const [shortcutValue, setShortcutValue] = useState<null | number>(null);

  const isDirect = shortcutValue === shortcuts.length;
  const setValueRef = useRef<typeof setValue>(null!);
  setValueRef.current = setValue;

  const value = useMemo(() => {
    if (shortcutValue == null) return null;

    const chosenShortcut: ShortcutConfig | null | undefined = shortcuts[shortcutValue];

    const time: string | null | Date = chosenShortcut
      ? DateTimeService.addTime(chosenShortcut.value, chosenShortcut.unit)
      : DateTimeService.getJsDate(`${directDate} ${directTime}`);

    return time;
  }, [directDate, directTime, isDirect, shortcutValue, shortcuts]);

  useEffect(() => {
    setValueRef.current(value);
  }, [value, setValueRef]);

  const shortcutsOptions = useMemo(
    () =>
      shortcuts.map(({ label }, index) => (
        <ShortcutsOption
          key={label}
          isActive={index === shortcutValue}
          label={label}
          onSelect={setShortcutValue}
          value={index}
        />
      )),
    [setShortcutValue, shortcutValue, shortcuts],
  );

  return (
    <>
      <div className="mb-3">
        {shortcutsOptions}

        <ShortcutsOption
          value={shortcutsOptions.length}
          label="Другое время"
          isActive={isDirect}
          onSelect={setShortcutValue}
        />
      </div>

      {isDirect && (
        <div className="d-flex">
          <Form.Group className="mr-2 flex-grow-1">
            <Form.Label>Дата</Form.Label>
            <Form.Control type="date" value={directDate} onChange={setDirectDate} min={minDate} />
          </Form.Group>

          <Form.Group>
            <Form.Label>Время</Form.Label>
            <Form.Control type="time" value={directTime} onChange={setDirectTime} />
          </Form.Group>
        </div>
      )}
    </>
  );
});

interface ShortcutsOptionProps {
  value: number;
  label: string;
  isActive: boolean;
  onSelect(value: number): void;
}

const ShortcutsOption = memo<ShortcutsOptionProps>(({ isActive, label, onSelect, value }) => {
  const onClick = useCallback(() => onSelect(value), [onSelect, value]);

  return (
    <Button type="button" variant="secondary" size="sm" onClick={onClick} active={isActive} className="mb-1 mr-1">
      {label}
    </Button>
  );
});

function useInputValue(initialValue?: null | string) {
  const [value, setValue] = useState(initialValue || '');

  const onChange = useCallback<ChangeEventHandler<HTMLInputElement>>((e) => setValue(e.target.value), []);

  return [value, onChange] as const;
}
