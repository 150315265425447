import React, { FC } from 'react';
import { Button, ButtonProps } from 'react-bootstrap';
import { Spinner } from '../spinner/Spinner';

export interface ILoaderButtonProps extends ButtonProps {
  isLoading?: boolean;
}

export const LoaderButton: FC<ILoaderButtonProps> = ({ isLoading, size, children, ...props }): JSX.Element => (
  <Button
    // Этот стиль нужен для того, чтобы работал тултип на disabled кнопке
    // https://react-bootstrap-v4.netlify.app/components/overlays/#overlays-disabled
    style={props.disabled ? { pointerEvents: 'none' } : { pointerEvents: 'auto' }}
    className="w-100 d-flex justify-content-center align-items-center"
    {...props}>
    {isLoading ? <Spinner size="default" /> : children}
  </Button>
);
