import GrpcService, { PlatformAdminApi } from 'src/services/GrpcService';

import {
  ICreate,
  ISearch,
  IUpdate,
  IRestore,
  IFindById,
  IRemove,
} from 'src/services/ServiceLocator/ServiceLocator.interface';
import { RequestController } from 'src/utils/decorators/request-controller';

class OfferServiceProvider implements ISearch, ICreate, IUpdate, IRestore, IFindById, IRemove {
  @RequestController({ logger: true, retry: true, debounce: true })
  search(params: PlatformAdminApi.IOfferSearchRequest): Promise<PlatformAdminApi.IOfferSearchResponse> {
    return GrpcService.OfferManagementService.Search(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  create(params: PlatformAdminApi.IOfferCreateRequest): Promise<PlatformAdminApi.OfferResponse> {
    return GrpcService.OfferManagementService.Create(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  update(params: PlatformAdminApi.IOfferUpdateRequest): Promise<PlatformAdminApi.OfferResponse> {
    return GrpcService.OfferManagementService.Update(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  restore(params: PlatformAdminApi.UuidRequest): Promise<PlatformAdminApi.OfferResponse> {
    return GrpcService.OfferManagementService.Restore(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  remove(params: PlatformAdminApi.UuidRequest): Promise<PlatformAdminApi.OfferResponse> {
    return GrpcService.OfferManagementService.Remove(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  findById(params: PlatformAdminApi.UuidRequest): Promise<PlatformAdminApi.OfferResponse> {
    return GrpcService.OfferManagementService.FindById(params);
  }
}

export const offerServiceProvider = new OfferServiceProvider();
export type TOfferServiceProvider = typeof offerServiceProvider;
