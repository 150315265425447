import { Observable } from 'zen-observable-ts';
import {
  IApplyTaskPresenter,
  PresenterCancellationReasonsListItem,
  PresenterClientHistory,
  PresenterSalesHistoryItem,
  PresenterTaskItem,
  PresenterTicketConnection,
  PresenterTicketItem,
  PresenterTicketsHistoryItem,
  RequestedCancellationReasonsListItem,
  RequestedCategoryItem,
  RequestedTicketItem,
  TicketsListPaginationOptions,
} from '../../interfaces';
import {
  ApplyTask,
  ApplyTaskInTicket,
  ApplyTaskInTicketVariables,
  ApplyTaskVariables,
  CancellationReasons,
  CancellationReasonsVariables,
  CancelTask,
  CancelTaskVariables,
  CompleteWorkWithUser,
  CompleteWorkWithUserVariables,
  CreateTaskInTicketMutation,
  CreateTaskInTicketMutationVariables,
  EntityTypeEnum,
  GetActiveTickets,
  GetActiveTicketsVariables,
  GetAllTasks,
  GetAllTasksVariables,
  GetAllTickets,
  GetAllTicketsVariables,
  GetDashboardTickets,
  GetDashboardTicketsVariables,
  GetManagerCommunicationChannel,
  GetManagerCommunicationChannelVariables,
  GetOrderBySerialNumber,
  GetOrderBySerialNumberVariables,
  GetProblemsCategories,
  GetProblemsCategoriesVariables,
  ManagerCommunicationChannelEnum,
  PostponeTask,
  PostponeTaskVariables,
  ProceedTask,
  ProceedTaskVariables,
  RescheduleTask,
  RescheduleTaskVariables,
  SagaFinishedSubscription,
  Task,
  TaskFilter,
  TasksConnection,
  TaskStateEnum,
  TaskSubscription,
  Ticket,
  TicketFilter,
  TicketsConnection,
  TicketsSubscription,
  UpdateTask,
  UpdateTaskVariables,
} from 'src/services/GraphQL';
import { apolloClient } from 'src/services/GraphQL/GraphQLClient';
import { RequestController } from 'src/utils/decorators/request-controller';
import { ApolloQueryResult, FetchResult } from '@apollo/client';
import {
  ApplyTaskAdapterHelper,
  cancellationReasonsListAdapter,
  categoryListIemAdapter,
  PresenterCategoryListItem,
  sagaResultAdapter,
  salesHistoryAdapter,
  taskAdapter,
  ticketAdapter,
  ticketConnectionAdapter,
  ticketsHistoryAdapter,
} from '../../helpers/adapters';
import { DEFAULT_TASKS_REQUEST_PARAMS, DEFAULT_TICKETS_REQUEST_PARAMS } from '../../constants';
import GrpcService, { PlatformAdminApi } from '../../../../services/GrpcService';

const defaultPaginationOptions: TicketsListPaginationOptions = {
  ticketsListCusor: '',
  tasksListCursor: '',
};

export class TicketsSystemProvider {
  /**
   * @deprecated
   */
  @RequestController({ logger: true })
  async getTickets(
    ticketFilter: TicketFilter = {},
    paginationOptions: TicketsListPaginationOptions = defaultPaginationOptions,
  ): Promise<PresenterTicketConnection> {
    const ticketOptions = {
      pagination: {
        first: paginationOptions.first ?? DEFAULT_TICKETS_REQUEST_PARAMS.amount,
        after: paginationOptions.ticketsListCusor,
      },
    };
    const taskOptions = {
      pagination: { first: DEFAULT_TASKS_REQUEST_PARAMS.amount, after: paginationOptions.tasksListCursor },
    };

    const response = await apolloClient.query<GetAllTickets, GetAllTicketsVariables>({
      query: GetAllTickets,
      variables: { ticketFilter, ticketOptions, taskOptions },
    });

    if (response.errors?.length) {
      await Promise.reject(response.errors);
    }

    return ticketConnectionAdapter(response.data.ticketsConnection as TicketsConnection);
  }

  @RequestController({ logger: true })
  async getActiveTickets(): Promise<PresenterTicketConnection> {
    const taskOptions = {
      pagination: { first: DEFAULT_TASKS_REQUEST_PARAMS.amount },
    };

    const response = await apolloClient.query<GetActiveTickets, GetActiveTicketsVariables>({
      query: GetActiveTickets,
      variables: { taskOptions },
    });

    if (response.errors?.length) {
      await Promise.reject(response.errors);
    }

    const ticketConnection: TicketsConnection = {
      __typename: 'TicketsConnection',
      edges: response.data.activeTickets.map((ticket) => ({
        __typename: 'TicketEdge',
        cursor: '',
        node: ticket as Ticket,
      })),
      pageInfo: {
        __typename: 'PageInfo',
        hasNextPage: false,
        hasPreviousPage: false,
      },
    };

    return ticketConnectionAdapter(ticketConnection);
  }

  @RequestController({ logger: true })
  async getDashboardTickets(): Promise<PresenterTicketConnection> {
    const taskOptions = {
      pagination: { first: DEFAULT_TASKS_REQUEST_PARAMS.amount },
    };

    const response = await apolloClient.query<GetDashboardTickets, GetDashboardTicketsVariables>({
      query: GetDashboardTickets,
      variables: { taskOptions },
    });

    if (response.errors?.length) {
      await Promise.reject(response.errors);
    }

    const ticketConnection: TicketsConnection = {
      __typename: 'TicketsConnection',
      edges: response.data.dashboardTickets.map((ticket) => ({
        __typename: 'TicketEdge',
        cursor: '',
        node: ticket as Ticket,
      })),
      pageInfo: {
        __typename: 'PageInfo',
        hasNextPage: false,
        hasPreviousPage: false,
      },
    };

    return ticketConnectionAdapter(ticketConnection);
  }

  @RequestController({ logger: true })
  async getTicketById(ticketFilter: TicketFilter = {}): Promise<PresenterTicketItem> {
    const ticketOptions = { pagination: { first: 1, after: '' } };
    const taskOptions = { pagination: { first: 10, after: '' } };

    const response = await apolloClient.query<GetAllTickets, GetAllTicketsVariables>({
      query: GetAllTickets,
      variables: { ticketFilter, ticketOptions, taskOptions },
    });

    if (response.errors?.length) {
      await Promise.reject(response.errors);
    }

    const { tickets } = ticketConnectionAdapter(response.data.ticketsConnection as TicketsConnection);

    if (!tickets.length) {
      await Promise.reject('Не найдено ни одного тикета');
    }

    return tickets[0];
  }

  @RequestController({ logger: true })
  async getCancellationReasons(
    variables: CancellationReasonsVariables,
  ): Promise<PresenterCancellationReasonsListItem[]> {
    const response: ApolloQueryResult<CancellationReasons> = await apolloClient.query<
      CancellationReasons,
      CancellationReasonsVariables
    >({
      query: CancellationReasons,
      variables,
    });

    if (response.errors?.length) {
      await Promise.reject(response.errors);
    }

    const filteredReasons: RequestedCancellationReasonsListItem[] = response.data.reasons.data.filter(
      (reason) => !!reason,
    ) as RequestedCancellationReasonsListItem[];

    const requestedReasons: PresenterCancellationReasonsListItem[] = cancellationReasonsListAdapter(filteredReasons);

    return requestedReasons;
  }

  @RequestController({ logger: true })
  async getProblemsCategories(variables: GetProblemsCategoriesVariables): Promise<PresenterCategoryListItem[]> {
    const response: ApolloQueryResult<GetProblemsCategories> = await apolloClient.query<
      GetProblemsCategories,
      GetProblemsCategoriesVariables
    >({
      query: GetProblemsCategories,
      variables,
    });

    if (response.errors?.length) {
      await Promise.reject(response.errors);
    }

    const requestedCategories: RequestedCategoryItem[] = [...(response?.data.categories ?? [])];
    const categoriesListItems: PresenterCategoryListItem[] = requestedCategories.map((category) =>
      categoryListIemAdapter(category),
    );

    return categoriesListItems;
  }

  @RequestController({ logger: true })
  async createTaskInTicket(variables: CreateTaskInTicketMutationVariables): Promise<string> {
    const response = await apolloClient.mutate<CreateTaskInTicketMutation, CreateTaskInTicketMutationVariables>({
      mutation: CreateTaskInTicketMutation,
      variables,
    });

    if (response.errors?.length) {
      await Promise.reject(response.errors);
    }

    return response.data?.createTaskInTicket.correlationId;
  }

  /**
   * @deprecated
   */
  @RequestController({ logger: true })
  async applyTask(variables: ApplyTaskVariables): Promise<IApplyTaskPresenter> {
    const response = await apolloClient.mutate<ApplyTask, ApplyTaskVariables>({
      mutation: ApplyTask,
      variables,
    });

    if (response.errors?.length) {
      await Promise.reject(response.errors);
    }

    return ApplyTaskAdapterHelper(response.data!.applyTask);
  }

  @RequestController({ logger: true })
  async postponeTask(variables: PostponeTaskVariables): Promise<PresenterTaskItem> {
    const response: FetchResult<PostponeTask> = await apolloClient.mutate<PostponeTask, PostponeTaskVariables>({
      mutation: PostponeTask,
      variables,
    });

    if (response.errors?.length) {
      await Promise.reject(response.errors);
    }

    return taskAdapter(response.data!.postponeTask);
  }

  @RequestController({ logger: true })
  async rescheduleTask(variables: RescheduleTaskVariables): Promise<PresenterTaskItem> {
    const response: FetchResult<RescheduleTask> = await apolloClient.mutate<RescheduleTask, RescheduleTaskVariables>({
      mutation: RescheduleTask,
      variables,
    });

    if (response.errors?.length) {
      await Promise.reject(response.errors);
    }

    return taskAdapter(response.data!.rescheduleTask);
  }

  @RequestController({ logger: true })
  async cancelTask(variables: CancelTaskVariables): Promise<PresenterTaskItem> {
    const response: FetchResult<CancelTask> = await apolloClient.mutate<CancelTask, CancelTaskVariables>({
      mutation: CancelTask,
      variables,
    });

    if (response.errors?.length) {
      await Promise.reject(response.errors);
    }

    return taskAdapter(response.data!.cancelTask);
  }

  @RequestController({ logger: true })
  async completeWorkWithUser(variables: CompleteWorkWithUserVariables): Promise<void> {
    const response: FetchResult<CompleteWorkWithUser> = await apolloClient.mutate<
      CompleteWorkWithUser,
      CompleteWorkWithUserVariables
    >({
      mutation: CompleteWorkWithUser,
      variables,
    });

    if (response.errors?.length) {
      await Promise.reject(response.errors);
    }

    return response.data?.completeWorkWithUser;
  }

  @RequestController({ logger: true })
  async applyTaskInTicket(variables: ApplyTaskInTicketVariables): Promise<string> {
    const response: FetchResult<ApplyTaskInTicket> = await apolloClient.mutate<
      ApplyTaskInTicket,
      ApplyTaskInTicketVariables
    >({
      mutation: ApplyTaskInTicket,
      variables,
    });

    if (response.errors?.length) {
      await Promise.reject(response.errors);
    }

    return response.data?.applyTaskInTicket.correlationId;
  }

  @RequestController({ logger: true })
  async getOrderBySerialNumber(
    params: GetOrderBySerialNumberVariables & {
      isExternal: boolean;
    },
  ): Promise<{
    orderId: string;
    cartId: string;
  }> {
    const { isExternal, orderSerialNumber } = params;

    if (isExternal) {
      const { id, cartId } = await GrpcService.OrderService.RetrieveOrderBySerialNumber({
        isExternal: true,
        serialNumber: String(orderSerialNumber),
      });

      return { orderId: id, cartId };
    }

    const response: ApolloQueryResult<GetOrderBySerialNumber> = await apolloClient.query<
      GetOrderBySerialNumber,
      GetOrderBySerialNumberVariables
    >({
      query: GetOrderBySerialNumber,
      variables: { orderSerialNumber },
    });

    if (response.errors?.length) {
      await Promise.reject(response.errors);
    }

    const { id: orderId, cartId } = response.data.ordersConnection.edges[0].node;

    return { orderId, cartId };
  }

  @RequestController({ logger: true })
  async proceedTask(taskId: string): Promise<PresenterTaskItem> {
    const response: ApolloQueryResult<ProceedTask> = await apolloClient.query<ProceedTask, ProceedTaskVariables>({
      query: ProceedTask,
      variables: { taskId },
    });

    if (response.errors?.length) {
      await Promise.reject(response.errors);
    }

    return taskAdapter(response.data.proceedTask);
  }

  @RequestController({ logger: true })
  async getClientHistory(clientId: string, orderSerialNumber?: number): Promise<PresenterClientHistory> {
    const tickets: PresenterTicketsHistoryItem[] = await this.getTicketsHistory(clientId, orderSerialNumber).catch(
      () => [],
    );
    let sales: PresenterSalesHistoryItem[] = [];

    // Если передан номер заказа, значит запрос пришел со страницы заказа.
    // Так как на странице заказа мы не показываем историю продаж, отправлять запрос не нужно.
    if (!orderSerialNumber) {
      sales = await this.getSalesHistory(clientId);
    }

    return { tickets, sales };
  }

  private async getTicketsHistory(
    clientSsoId: string,
    orderSerialNumber?: number,
  ): Promise<PresenterTicketsHistoryItem[]> {
    const ticketOptions = {
      pagination: {
        first: 1000,
      },
    };
    const taskOptions = {
      pagination: { first: 1000 },
    };

    let ticketFilter: TicketFilter = {
      clientSsoId: [clientSsoId],
      ticketLink: {},
    };

    if (orderSerialNumber) {
      ticketFilter = {
        ...ticketFilter,
        ticketLink: {
          serialNumber: [orderSerialNumber],
        },
      };
    }

    const response = await apolloClient.query<GetAllTickets, GetAllTicketsVariables>({
      query: GetAllTickets,
      variables: { ticketFilter, ticketOptions, taskOptions },
    });

    if (response.errors?.length) {
      await Promise.reject(response.errors);
    }

    return ticketsHistoryAdapter(response.data.ticketsConnection as TicketsConnection);
  }

  private async getSalesHistory(clientSsoId: string): Promise<PresenterSalesHistoryItem[]> {
    const taskOptions = {
      pagination: { first: 1000 },
    };

    const taskFilter: TaskFilter = {
      entityType: [EntityTypeEnum.PROSPECT],
      state: [
        TaskStateEnum.NEW,
        TaskStateEnum.IN_WORK,
        TaskStateEnum.PROCEED,
        TaskStateEnum.CANCELLED,
        TaskStateEnum.POSTPONED,
        TaskStateEnum.RESCHEDULED,
        TaskStateEnum.NO_COMMUNICATION,
      ],
      clientId: [clientSsoId],
    };

    const response = await apolloClient.query<GetAllTasks, GetAllTasksVariables>({
      query: GetAllTasks,
      variables: { taskFilter, taskOptions },
    });

    if (response.errors?.length) {
      await Promise.reject(response.errors);
    }

    const tasksConnection = response.data.tasksConnection as TasksConnection;

    const prospectIds = tasksConnection.edges.map(({ node }) => node.entity?.entityId!).filter(Boolean);
    let prospects: PlatformAdminApi.IProspect[] = [];

    if (prospectIds.length) {
      const prospectResponse = await GrpcService.ProspectsService.Search({
        limit: prospectIds.length,
        relations: ['price'],
        where: [
          {
            id: {
              in: prospectIds,
            },
          },
        ],
      });

      prospects = prospectResponse.data;
    } else {
      prospects = [];
    }

    return salesHistoryAdapter(tasksConnection, { prospects });
  }

  @RequestController({ logger: true })
  async getManagerCommunicationChannel(managerId: string): Promise<ManagerCommunicationChannelEnum> {
    const response = await apolloClient.query<GetManagerCommunicationChannel, GetManagerCommunicationChannelVariables>({
      query: GetManagerCommunicationChannel,
      variables: { managerId },
    });

    if (!response.data.manager.managerCommunicationChannel) {
      throw new Error(`Канал коммуникации менеджера с id ${managerId} не найден`);
    }

    return response.data.manager.managerCommunicationChannel.communicationChannel;
  }

  @RequestController({ logger: true })
  async updateTask(updateTaskRequest: UpdateTaskVariables): Promise<void> {
    const response = await apolloClient.query<UpdateTask, UpdateTaskVariables>({
      query: UpdateTask,
      variables: updateTaskRequest,
    });

    if (!response.data.updateTask) {
      await Promise.reject(`Не удалось обновить задачу с id ${updateTaskRequest.id}`);
    }
  }

  ticketsSubscription(assigneeSsoId: string): Observable<PresenterTicketItem> {
    return apolloClient
      .subscribe<TicketsSubscription>({
        query: TicketsSubscription,
        variables: {
          assigneeSsoId,
        },
      })
      .map((ticket) => ticketAdapter(ticket.data?.ticketChanged as RequestedTicketItem));
  }

  taskSubscription(ownerId: string): Observable<PresenterTaskItem> {
    return apolloClient
      .subscribe<TaskSubscription>({
        query: TaskSubscription,
        variables: {
          ownerId,
        },
      })
      .map((task) => taskAdapter(task.data?.taskChanged as Task));
  }

  sagaFinishedSubscription() {
    return apolloClient
      .subscribe<SagaFinishedSubscription>({
        query: SagaFinishedSubscription,
      })
      .map((result: FetchResult<SagaFinishedSubscription>) => sagaResultAdapter(result.data?.sagaFinished));
  }
}

export const ticketsSystemProvider = new TicketsSystemProvider();
export type TTicketsSystemProvider = typeof TicketsSystemProvider;
