import { wmsWarehousesServiceProvider } from './service-providers/WmsWarehousesService.provider';
import { AppSubModule } from 'src/interfaces';
import { CATALOGS_PROVIDER_TOKENS } from '../constants';

class WmsWarehousesModule extends AppSubModule {
  public providers = [
    {
      token: CATALOGS_PROVIDER_TOKENS.WMS_WAREHOUSES_SERVICE,
      provider: wmsWarehousesServiceProvider,
    },
  ];
}

export const wmsWarehousesModule = new WmsWarehousesModule();
