type resolvePromiseType = (value: string | PromiseLike<string>) => void;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type rejectPromiseType = (reason?: any) => void;

export const getDataUrl = (file: File): Promise<string> =>
  new Promise<string>((resolve: resolvePromiseType, reject: rejectPromiseType) => {
    const reader: FileReader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = reject;
  });
