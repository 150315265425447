import React, { useCallback } from 'react';
import cn from 'classnames';
import { Dropdown } from 'react-bootstrap';

import styles from './styles.module.scss';

interface TabsMenuItemProps<T> {
  tab: T;
  active: boolean;
  onTabClick?(tab: T): void;
  getTabLink?(tab: T): string;
  getLabel(tab: T): string;
}

export function TabsMenuItem<T>({ tab, active, getTabLink, onTabClick, getLabel }: TabsMenuItemProps<T>) {
  const element = getTabLink ? 'a' : 'button';

  const href = getTabLink && getTabLink(tab);
  const onClick = useCallback(() => onTabClick && onTabClick(tab), [onTabClick, tab]);

  const className = cn({
    [styles.activeMenuItem]: active,
  });

  return (
    <Dropdown.Item as={element} className={className} active={active} href={href} onClick={onClick}>
      {getLabel(tab)}
    </Dropdown.Item>
  );
}
