import { catalogsModule } from './modules/catalogs/catalogs.module';
import { pltCoreModule } from 'src/modules/plt-core/plt-core.module';
import { wagesModule } from './modules/hrm/wages/wages.module';
import { IProviderRecord } from './services/ServiceLocator/ServiceLocator.interface';
import { contractsModule } from './modules/hrm/contracts/contracts.module';
import { contractorsModule } from './modules/hrm/contractors/contractors.module';
import { usersModule } from './modules/users/users.module';

export const serviceProviders: IProviderRecord[] = [
  ...pltCoreModule.providers,
  ...wagesModule.providers,
  ...catalogsModule.providers,
  ...contractsModule.providers,
  ...contractorsModule.providers,
  ...usersModule.providers,
];
