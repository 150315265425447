import React, { FC, useCallback, useEffect, useState } from 'react';
import { Loader } from '../../../../components/Loader';
import appStyles from 'src/app-styles.module.scss';
import TicketsListStyles from './TicketsList.module.scss';
import { TicketHeader } from '../TicketItem/components';
import { TicketContent } from '../TicketItem/components/TicketContent';
import { observer } from 'mobx-react';
import { useListIntersectionObserverRef } from '../../../../hooks';
import { TicketTaskCard } from '../Task';
import cn from 'classnames';
import styles from '../TicketItem/styles.module.scss';
import { useRightSidebarStore } from 'src/features/Layouts/LayoutRightSidebar';
import { useHistory, useParams } from 'react-router-dom';
import { NEW_TASK_MODAL } from '../../modals-configs';
import RightSidebarEvent from 'src/features/Layouts/LayoutRightSidebar/EventBus/RightSidebarEvent';
import { IconButton } from 'src/libs/ui-kit';
import { Button } from 'react-bootstrap';
import { SubHeaderPortal } from 'src/portals';
import { useTicketsRootStore } from '../../store';
import { ITaskInfo } from '../../store/ticket-system/ProcessingTasksStore';
import { LoaderButton } from 'src/libs/ui-kit/buttons/LoaderButton';

export const TicketsList: FC = observer(() => {
  const { ticketsSystemStore } = useTicketsRootStore();
  const [getTaskButtonLoading, setGetTaskButtonLoading] = useState<boolean>(false);
  const history = useHistory();
  const isSubheaderVisible = SubHeaderPortal.useBusy();
  const { userId: clientId } = useParams<{ userId: string }>();
  const { addModal } = useRightSidebarStore();
  const ticketsContainerStyles = cn(
    TicketsListStyles.ticketsContainer,
    isSubheaderVisible ? TicketsListStyles.withSubheader : TicketsListStyles.withoutSubheader,
  );
  const onScrollEnd = (entries) => {
    const [first] = entries;
    if (first.isIntersecting && ticketsSystemStore.shouldLoadMoreTickets) {
      ticketsSystemStore.addTickets(clientId);
    }
  };

  const { setLastElement } = useListIntersectionObserverRef(onScrollEnd);

  useEffect(() => {
    ticketsSystemStore.getTickets(clientId, { resetCursor: true, loader: true });
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    ticketsSystemStore.getManagerCommunicationChannel();

    return () => {
      ticketsSystemStore.resetTickets();
      ticketsSystemStore.managerCommunicationChannel = null;
    };
  }, [clientId]);

  const getTaskInWork = useCallback(async () => {
    const assigneeSsoId = ticketsSystemStore.getManagerSsoId();

    setGetTaskButtonLoading(true);
    await ticketsSystemStore.applyTaskInTicket({ assigneeSsoId });
    setGetTaskButtonLoading(false);
  }, []);

  const openNewTaskForm = useCallback(() => {
    const createProblemModal = NEW_TASK_MODAL();

    const event: RightSidebarEvent = new RightSidebarEvent({
      scope: 'TicketsList.tsx',
      event: createProblemModal.event,
      method: 'openNewTaskForm',
    });
    addModal(createProblemModal, event);
  }, [addModal]);

  const completeWorkWithUser = useCallback(async () => {
    await ticketsSystemStore.completeWorkWithUser();
    history.push('/');
  }, [ticketsSystemStore.completeWorkWithUser]);

  ticketsSystemStore.onSagaFinishedSubscription().subscribe(async (taskInfo: ITaskInfo | null) => {
    const queuedTaskInfo: ITaskInfo | undefined = await ticketsSystemStore.onQueueTaskSuccess();
    const link: string | null | undefined = taskInfo?.redirectionLink ?? queuedTaskInfo?.redirectionLink;
    const isTheSameUrlPath: boolean = history.location.pathname === link;

    if (link && !isTheSameUrlPath) {
      history.push(link);
    }
  });

  return (
    <div>
      {ticketsSystemStore.loadingTickets ? (
        <div className={appStyles.loader}>
          <Loader />
        </div>
      ) : (
        <div>
          <TicketHeader>
            <div className="flex-grow-1">Задачи</div>
            {ticketsSystemStore.hasActiveTickets || clientId ? (
              <IconButton icon="plus" size="sm" onClick={openNewTaskForm} />
            ) : (
              <LoaderButton
                variant="primary"
                isLoading={getTaskButtonLoading}
                onClick={getTaskInWork}
                className="w-50"
                disabled={getTaskButtonLoading}>
                Взять задачу
              </LoaderButton>
            )}
          </TicketHeader>

          {ticketsSystemStore.ticketsList.length ? (
            <div>
              <div className={ticketsContainerStyles}>
                {ticketsSystemStore.ticketsList.map((ticket) => (
                  <div key={ticket.id} ref={setLastElement} className="p-3">
                    <TicketContent ticket={ticket} />
                    <div>
                      <div>
                        {!!ticket.tasksList.length && (
                          <div className={cn('p-4', styles.cardContainer)}>
                            {ticket.tasksList.map((task) => (
                              <div className={styles.taskItem} key={task.id}>
                                <TicketTaskCard task={task} />
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              {ticketsSystemStore.hasActiveTickets && !ticketsSystemStore.hasActiveTasks && (
                <div className={cn('px-4 py-3 d-flex justify-content-center', TicketsListStyles.finishWorkButton)}>
                  <Button variant="default" onClick={completeWorkWithUser}>
                    Завершить работу с пользователем
                  </Button>
                </div>
              )}
            </div>
          ) : (
            <div className="px-4 py-3">Не найдено ни одного тикета</div>
          )}
        </div>
      )}
    </div>
  );
});
