import React, { memo } from 'react';
import Select from 'react-select';
import { getOptionLabel as TGetOptionLabel, getOptionValue as TGetOptionValue } from 'react-select/src/builtins';
import isEqual from 'lodash/isEqual';

/* eslint-disable @typescript-eslint/no-explicit-any */
type TFieldSelectValue = any;

export type TOnChangeActionMeta = {
  action: 'select-option' | 'remove-value';
  name: string;
  option: TFieldSelectValue;
};

export type TFieldSelect<T> = {
  isMulti?: boolean;
  isClearable?: boolean;
  optionLabel?: string;
  optionValue?: string;
  disabled?: boolean;
  getOptionLabel?: TGetOptionLabel;
  getOptionValue?: TGetOptionValue;
  /* eslint-disable @typescript-eslint/no-explicit-any */
  options?: T[];
  /* eslint-disable @typescript-eslint/no-explicit-any */
  value?: TFieldSelectValue | TFieldSelectValue[];
  onBeforeChange?: (
    value: TFieldSelectValue | TFieldSelectValue[],
    actionMeta: TOnChangeActionMeta,
  ) => TFieldSelectValue | TFieldSelectValue[];
  onChange?: (value: T | null) => void;
};

// eslint-disable-next-line react/display-name
export const FieldSelect = memo(
  <T,>(props: TFieldSelect<T>): JSX.Element => {
    const {
      value,
      options,
      optionLabel,
      optionValue,
      disabled,
      getOptionLabel,
      getOptionValue,
      onChange,
      isMulti = false,
      isClearable = true,
      ...other
    } = props;

    let nullishValue = value;

    if (value === undefined) {
      nullishValue = null;
    }

    const optionLabelCallback: TGetOptionLabel | undefined =
      // eslint-disable-next-line no-nested-ternary
      typeof getOptionLabel === 'function' ? getOptionLabel : optionLabel ? (option) => option[optionLabel] : undefined;
    const optionValueCallback: TGetOptionValue | undefined =
      // eslint-disable-next-line no-nested-ternary
      typeof getOptionValue === 'function' ? getOptionValue : optionValue ? (option) => option[optionValue] : undefined;

    return (
      <Select
        className="react-select"
        classNamePrefix="react-select"
        getOptionLabel={optionLabelCallback}
        getOptionValue={optionValueCallback}
        options={options}
        isMulti={isMulti}
        isClearable={isClearable}
        isDisabled={disabled}
        onChange={onChange}
        value={nullishValue}
        defaultValue={nullishValue}
        {...other}
      />
    );
  },
  (prevProps, nextProps) => isEqual(prevProps, nextProps),
);
