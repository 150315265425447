import { IRoute } from 'src/routes';
import { lazy } from 'src/utils';

export default (fallback: JSX.Element): IRoute[] => [
  {
    path: '/hrm/contracts/',
    pureComponent: lazy(() => import('./pages/contract-list/ContractList.page'), fallback),
    exact: true,
    breadcrumb: 'Контракты',
    title: 'Контракты',
  },
  {
    path: '/hrm/contractors/:id/contracts/',
    exact: true,
    breadcrumb: 'Контракты',
    title: 'Контракты',
  },
  {
    path: '/hrm/contractors/:id/contracts/new/',
    pureComponent: lazy(() => import('./pages/сontract-сreate/ContractCreate.page'), fallback),
    exact: true,
    breadcrumb: 'Новый контракт',
    title: 'Новый контракт',
  },
  {
    path: '/hrm/contractors/:id/contracts/:contractId/',
    pureComponent: lazy(() => import('./pages/contract-view/ContractView.page'), fallback),
    exact: true,
    breadcrumb: 'Карточка контракта',
    title: 'Карточка контракта',
  },
  {
    path: '/hrm/contractors/:id/contracts/:contractId/edit/',
    pureComponent: lazy(() => import('./pages/сontract-edit/ContractEdit.page'), fallback),
    exact: true,
    breadcrumb: 'Редактирование контракта',
    title: 'Редактирование контракта',
  },
];
