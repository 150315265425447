import GrpcService, { PlatformAdminApi } from 'src/services/GrpcService';
import { IFindById, ISearch } from 'src/services/ServiceLocator/ServiceLocator.interface';
import { RequestController } from 'src/utils/decorators/request-controller';

class ElementServiceProvider implements IFindById, ISearch {
  @RequestController({ logger: true, retry: true, debounce: true })
  findById(params: PlatformAdminApi.IUuidRequest): Promise<PlatformAdminApi.ElementResponse> {
    return GrpcService.ElementService.FindById(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  search(params: PlatformAdminApi.ISearchElementRequest): Promise<PlatformAdminApi.SearchElementResponse> {
    return GrpcService.ElementService.Search(params);
  }
}

export const elementServiceProvider = new ElementServiceProvider();
export type TElementServiceProvider = typeof elementServiceProvider;
