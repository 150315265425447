import { RequestController } from 'src/utils/decorators/request-controller';
import GrpcService, { PlatformAdminApi } from 'src/services/GrpcService';
import { logger } from '@qlean/front-logger';

class WmsWarehousesServiceProvider {
  @RequestController({ logger: true })
  search({ page, limit }: PlatformAdminApi.WarehousesRequest): Promise<PlatformAdminApi.IWarehousesCatalog> {
    return GrpcService.WarehousesService.RetrieveWarehouses({ page, limit });
  }

  @RequestController({ logger: true })
  findById(id: number): Promise<PlatformAdminApi.IWarehouse> {
    return GrpcService.WarehousesService.FindWarehouseById({ id });
  }

  // TODO: Реализовать, когда будет готов бэк
  @RequestController({ logger: true })
  update() {
    logger.info('Updating....');
    return undefined;
  }
}

export const wmsWarehousesServiceProvider = new WmsWarehousesServiceProvider();
export type TWmsWarehousesServiceProvider = typeof wmsWarehousesServiceProvider;
