import GrpcService, { PlatformAdminApi } from 'src/services/GrpcService';

import {
  ICreate,
  ISearch,
  IUpdate,
  IRestore,
  IFindById,
  IRemove,
} from 'src/services/ServiceLocator/ServiceLocator.interface';
import { RequestController } from 'src/utils/decorators/request-controller';

class SkillServiceProvider implements ISearch, ICreate, IUpdate, IRestore, IFindById, IRemove {
  @RequestController({ logger: true, retry: true, debounce: true })
  search(params: PlatformAdminApi.ISkillSearchRequest): Promise<PlatformAdminApi.SkillSearchResponse> {
    return GrpcService.SkillService.Search(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  create(params: PlatformAdminApi.ISkillCreateRequest): Promise<PlatformAdminApi.SkillResponse> {
    return GrpcService.SkillService.Create(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  update(params: PlatformAdminApi.ISkillUpdateRequest): Promise<PlatformAdminApi.SkillResponse> {
    return GrpcService.SkillService.Update(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  restore(params: PlatformAdminApi.IUuidRequest): Promise<PlatformAdminApi.SkillResponse> {
    return GrpcService.SkillService.Restore(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  remove(params: PlatformAdminApi.IUuidRequest): Promise<PlatformAdminApi.SkillResponse> {
    return GrpcService.SkillService.Remove(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  findById(params: PlatformAdminApi.IUuidRequest): Promise<PlatformAdminApi.SkillResponse> {
    return GrpcService.SkillService.FindById(params);
  }
}

export const skillServiceProvider = new SkillServiceProvider();
export type TSkillServiceProvider = typeof skillServiceProvider;
