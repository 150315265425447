import { PlatformAdminApi } from 'src/services/GrpcService';

export enum PLT_CORE_PROVIDER_TOKENS {
  TAGS_SERVICE = 'tagsService',
  ELEMENT_SERVICE = 'elementService',
  PRICE_ELEMENT_SERVICE = 'priceElementService',
  REGION_SERVICE = 'regionService',
  UNIT_SERVICE = 'unitService',
}

export const pickPriceElementProps: Array<keyof PlatformAdminApi.IPriceElement> = [
  'dateStart',
  'dateEnd',
  'cost',
  'duration',
];
export const pickPriceOptionProps: Array<keyof PlatformAdminApi.IPriceOption> = [
  'defaultValue',
  'minValue',
  'maxValue',
  'defaultFactor',
  'minFactor',
  'maxFactor',
  'unit',
  'type',
  'isFree',
  'display',
  'durationLinear',
  'durationMatrix',
];
export const pickUnitProps: Array<keyof PlatformAdminApi.IUnit> = ['title'];
