import { IRoute } from 'src/routes';
import { lazy } from 'src/utils';

export default (fallback: JSX.Element): IRoute[] => [
  {
    path: '/hrm/contractors',
    pureComponent: lazy(() => import('./../features/ContractorList/ContractorsList'), fallback),
    exact: true,
    breadcrumb: 'Исполнители',
    title: 'Исполнители',
  },
  {
    path: '/hrm/contractors/new',
    pureComponent: lazy(() => import('./../features/ContractorList/ContractorCreate'), fallback),
    exact: true,
    breadcrumb: 'Новый исполнитель',
    title: 'Новый исполнитель',
  },
  {
    path: '/hrm/contractors/:id',
    pureComponent: lazy(() => import('./../features/ContractorList/ContractorView'), fallback),
    exact: true,
    breadcrumb: 'Карточка исполнителя',
    title: 'Карточка исполнителя',
  },
  {
    path: '/hrm/contractors/:id/edit',
    pureComponent: lazy(() => import('./../features/ContractorList/ContractorEdit'), fallback),
    exact: true,
    breadcrumb: 'Редактирование исполнителя',
    title: 'Редактирование исполнителя',
  },
];
