import { Menu, MENU_TYPES } from 'src/interfaces';

export default (): Menu[] => [
  {
    type: MENU_TYPES.ITEMS,
    text: 'Общее',
    link: '/core',
    children: [
      {
        type: MENU_TYPES.ROUTER,
        text: 'Продукты',
        link: '/core/elements/',
        children: [
          {
            type: MENU_TYPES.ROUTER,
            text: 'Один продукт',
            link: '/core/elements/:id/',
          },
        ],
      },
    ],
  },
];
