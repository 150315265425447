import GrpcService, { PlatformAdminApi } from 'src/services/GrpcService';

import {
  ICreate,
  ISearch,
  IUpdate,
  IRestore,
  IFindById,
  IRemove,
} from 'src/services/ServiceLocator/ServiceLocator.interface';
import { RequestController } from 'src/utils/decorators/request-controller';

class TokenServiceProvider implements ISearch, ICreate, IUpdate, IRestore, IFindById, IRemove {
  @RequestController({ logger: true, retry: true, debounce: true })
  search(params: PlatformAdminApi.ISearchTokenRequest): Promise<PlatformAdminApi.ISearchTokenResponse> {
    return GrpcService.TokenService.Search(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  create(params: PlatformAdminApi.IUpsertTokenRequest): Promise<PlatformAdminApi.IToken> {
    return GrpcService.TokenService.Upsert(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  update(params: PlatformAdminApi.IUpsertTokenRequest): Promise<PlatformAdminApi.IToken> {
    return GrpcService.TokenService.Upsert(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  restore(params: PlatformAdminApi.IUuidRequest): Promise<PlatformAdminApi.IToken> {
    return GrpcService.TokenService.Restore(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  remove(params: PlatformAdminApi.IUuidRequest): Promise<PlatformAdminApi.IToken> {
    return GrpcService.TokenService.Remove(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  findById(params: PlatformAdminApi.IUuidRequest): Promise<PlatformAdminApi.IToken> {
    return GrpcService.TokenService.FindById(params);
  }
}

export const tokenServiceProvider = new TokenServiceProvider();
export type TTokenServiceProvider = typeof tokenServiceProvider;
