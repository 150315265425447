import { makeAutoObservable } from 'mobx';

export class EffectStore<Props, Result, Error = unknown> {
  private static readonly DEFAULT_KEY: symbol = Symbol('EffectStore default key');
  private static readonly DEFAULT_GET_KEY = () => EffectStore.DEFAULT_KEY;

  private pendingCount: Record<string | symbol, number | undefined> = {};

  private readonly getKey: (props: Props) => string | symbol;
  private readonly handle: (props: Props) => Promise<Result>;

  constructor(options: EffectStore.Options<Props, Result, Error>) {
    makeAutoObservable(this, undefined, { autoBind: true });

    this.getKey = options.getKey || EffectStore.DEFAULT_GET_KEY;
    this.handle = options.handle;
  }

  private inc(key: string | symbol) {
    const count = this.pendingCount[key] || 0;
    this.pendingCount[key] = count + 1;
  }

  private dec(key: string | symbol) {
    const count = this.pendingCount[key] || 0;

    if (count === 1) delete this.pendingCount[key];
    else this.pendingCount[key] = count + 1;
  }

  async call(props: Props): Promise<Result> {
    const key = this.getKey(props);

    this.inc(key);

    try {
      const result = await this.handle(props);

      this.dec(key);

      return result;
    } catch (error) {
      this.dec(key);
      throw error;
    }
  }

  get pending(): Readonly<Record<string | symbol, true | undefined>> {
    const result: Record<string | symbol, true> = {};

    for (const key in this.pendingCount) {
      if (Object.hasOwn(this.pendingCount, key)) result[key] = true;
    }

    return result;
  }

  get somePending(): boolean {
    for (const key in this.pendingCount) {
      if (Object.hasOwn(this.pendingCount, key)) return true;
    }

    return false;
  }
}

export namespace EffectStore {
  export interface Options<Props, Result, Error = unknown> {
    getKey?(props: Props): string;
    handle(props: Props): Promise<Result>;
  }
}
