import React, { FC, ReactNode } from 'react';
import { PresenterTaskItem } from 'src/features/Tickets/interfaces';
import { TaskStateEnum } from 'src/services/GraphQL';
import { InProgressToolbar } from './InProgressToolbar';
import { TakeToWorkToolbar } from './TakeToWorkToolbar';

interface ITaskToolbarProps {
  task: PresenterTaskItem;
  onAction: (action: TaskStateEnum) => void;
  isDisabled: boolean;
}

export const TaskToolbar: FC<ITaskToolbarProps> = ({ task, onAction, isDisabled }) => {
  const TOOLBARS_MAP: Record<TaskStateEnum, ReactNode> = {
    [TaskStateEnum.NEW]: <TakeToWorkToolbar task={task} />,
    [TaskStateEnum.PROCEED]: null,
    [TaskStateEnum.RESCHEDULED]: <TakeToWorkToolbar task={task} />,
    [TaskStateEnum.POSTPONED]: <TakeToWorkToolbar task={task} />,
    [TaskStateEnum.NO_COMMUNICATION]: null,
    [TaskStateEnum.IN_WORK]: <InProgressToolbar actionsHandler={onAction} isDisabled={isDisabled} />,
    [TaskStateEnum.CANCELLED]: null,
    [TaskStateEnum.DEDUPLICATED]: null,
  };

  return <div>{TOOLBARS_MAP[task.state]}</div>;
};
