import { logger } from '@qlean/front-logger';

export class Logger {
  public static debug<T>(...args: T[]): void {
    logger.debug(...args);
  }

  public static info<T>(...args: T[]): void {
    logger.info(...args);
  }

  public static warn<T>(...args: T[]): void {
    logger.warn(...args);
  }

  public static error<T>(...args: T[]): void {
    logger.error(...args);
  }
}
