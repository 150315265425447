import { AsyncDebounce } from '../async-debounce';
import { Retry } from '../request-retry';
import { Log } from '../logger';

import { IDebounceOptions, ILoggerOptions, IRequestControllerOptions, IRetryOptions, TDecorator } from '../interfaces';

export function RequestController(options: Partial<IRequestControllerOptions> = {}): TDecorator {
  const { logger, debounce, retry } = options;

  /**
   * ВАЖНО!!! Здесь имеет значение порядок декораторов.
   * @link https://www.typescriptlang.org/docs/handbook/decorators.html#decorator-composition
   * При композиции декораторов они выполняются в обратном порядке.
   */
  // eslint-disable-next-line max-params
  return (target: unknown, propertyKey: string, descriptor: PropertyDescriptor): void => {
    if (logger) {
      const options: ILoggerOptions | undefined = typeof logger === 'boolean' ? undefined : logger;
      Log(options)(target, propertyKey, descriptor);
    }

    if (debounce) {
      const options: IDebounceOptions | undefined = typeof debounce === 'boolean' ? undefined : debounce;
      AsyncDebounce(options)(target, propertyKey, descriptor);
    }

    if (retry) {
      const options: Partial<IRetryOptions> | undefined = typeof retry === 'boolean' ? undefined : retry;
      Retry(options)(target, propertyKey, descriptor);
    }
  };
}
