import GrpcService, { PlatformAdminApi } from 'src/services/GrpcService';
import { ISearch } from 'src/services/ServiceLocator/ServiceLocator.interface';
import { RequestController } from 'src/utils/decorators/request-controller';

class RegionServiceProvider implements ISearch {
  @RequestController({ logger: true, retry: true, debounce: true })
  search(params: PlatformAdminApi.ISearchRegionRequest): Promise<PlatformAdminApi.SearchRegionResponse> {
    return GrpcService.RegionService.Search(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  create(params: PlatformAdminApi.ICreateRegionRequest): Promise<PlatformAdminApi.IRegionResponse> {
    return GrpcService.RegionService.Create(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  update(params: PlatformAdminApi.IUpdateRegionRequest): Promise<PlatformAdminApi.IRegionResponse> {
    return GrpcService.RegionService.Update(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  restore(params: PlatformAdminApi.IUuidRequest): Promise<PlatformAdminApi.IRegionResponse> {
    return GrpcService.RegionService.Restore(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  remove(params: PlatformAdminApi.IUuidRequest): Promise<PlatformAdminApi.IRegionResponse> {
    return GrpcService.RegionService.Remove(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  findById(params: PlatformAdminApi.IUuidRequest): Promise<PlatformAdminApi.IRegionResponse> {
    return GrpcService.RegionService.FindById(params);
  }
}

export const regionServiceProvider = new RegionServiceProvider();
export type TRegionServiceProvider = typeof regionServiceProvider;
