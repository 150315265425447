import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';

// тип any обусловлен интерфейсом IntersectionObserver
/* eslint-disable  @typescript-eslint/no-explicit-any */
type callbackFunction = (entries: any) => void | Promise<void>;

interface ListIntersectionObserverObject {
  setLastElement: Dispatch<SetStateAction<HTMLElement | null>>;
}

export const useListIntersectionObserverRef = (callback: callbackFunction): ListIntersectionObserverObject => {
  const [lastElement, setLastElement] = useState<HTMLElement | null>(null);

  const observer = useRef(new IntersectionObserver(callback));

  useEffect(() => {
    const currentElement = lastElement;
    const currentObserver = observer.current;

    if (currentElement) {
      currentObserver.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [lastElement]);

  return { setLastElement };
};
