import { AppSubModule } from 'src/interfaces';

import { CATALOGS_PROVIDER_TOKENS } from '../constants';
import { optionServiceProvider } from './service-providers/OptionService.provider';

class OptionsListModule extends AppSubModule {
  public providers = [
    {
      token: CATALOGS_PROVIDER_TOKENS.OPTION_SERVICE,
      provider: optionServiceProvider,
    },
  ];
}

export const optionsListModule = new OptionsListModule();
