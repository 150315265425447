import React from 'react';

import AppLoader from 'src/components/AppLoader';
import LayoutMain from 'src/features/Layouts/LayoutMain';
import { BreadcrumbRedirect } from './components/Breadcrumbs';
import { lazy } from 'src/utils';

import { pltCoreModule } from 'src/modules/plt-core/plt-core.module';
import { wagesModule } from './modules/hrm/wages/wages.module';
import { catalogsModule } from './modules/catalogs/catalogs.module';
import { contractsModule } from './modules/hrm/contracts/contracts.module';
import { contractorsModule } from './modules/hrm/contractors/contractors.module';
import { usersModule } from './modules/users/users.module';

export interface IRoute {
  path: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pureComponent?: React.ComponentType & any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  layout?: React.ComponentType & any;
  exact?: boolean;
  breadcrumb?: React.ComponentType | React.ElementType | string | null;
  title?: string;
}

export interface INavLinks {
  title: string;
  dependence: string;
}

// ---
// Default application layout
export const DefaultLayout = LayoutMain;

export const routes = (): IRoute[] => [
  {
    path: '/',
    pureComponent: lazy(() => import('./pages/Home'), <AppLoader />),
    exact: true,
    breadcrumb: 'Главная',
    title: 'Главная',
  },

  {
    path: '/me/',
    pureComponent: lazy(() => import('./pages/Profile'), <AppLoader />),
    exact: true,
    breadcrumb: 'Профиль',
    title: 'Профиль',
  },
  {
    path: '/hrm',
    exact: true,
    breadcrumb: 'HRM',
    title: 'HRM',
  },
  ...wagesModule.routes(<AppLoader />),
  ...contractsModule.routes(<AppLoader />),
  ...contractorsModule.routes(<AppLoader />),
  ...usersModule.routes(<AppLoader />),
  {
    path: '/sso-management/',
    exact: true,
    // eslint-disable-next-line react/display-name
    breadcrumb: () => <BreadcrumbRedirect redirectLink="/sso-management/market/" text="SSO" />,
    title: 'SSO',
  },

  {
    path: '/crm/',
    exact: true,
    // eslint-disable-next-line react/display-name
    breadcrumb: () => <BreadcrumbRedirect redirectLink="/crm/prospects/" text="CRM" />,
    title: 'CRM',
  },
  {
    path: '/crm/feedback/',
    pureComponent: lazy(() => import('./modules/crm/features/FeedbackList/FeedbackList'), <AppLoader />),
    exact: true,
    breadcrumb: 'Отзывы',
    title: 'Отзывы',
  },
  {
    path: '/crm/feedback/new',
    pureComponent: lazy(() => import('./modules/crm/features/FeedbackList/FeedbackCreate'), <AppLoader />),
    exact: true,
    breadcrumb: 'Новый отзыв',
    title: 'Новый отзыв',
  },
  {
    path: '/crm/feedback/:id',
    pureComponent: lazy(() => import('./modules/crm/features/FeedbackList/FeedbackView'), <AppLoader />),
    exact: true,
    breadcrumb: 'Карточка отзыва',
    title: 'Карточка отзыва',
  },
  {
    path: '/crm/feedback/:id/edit',
    pureComponent: lazy(() => import('./modules/crm/features/FeedbackList/FeedbackEdit'), <AppLoader />),
    exact: true,
    breadcrumb: 'Редактирование отзыва',
    title: 'Редактирование отзыва',
  },
  {
    path: '/crm/tasks/',
    exact: true,
    // eslint-disable-next-line react/display-name
    breadcrumb: () => <BreadcrumbRedirect redirectLink="/crm/crm-tasks/managers/" text="Менеджеры" />,
    title: 'Менеджеры',
  },
  {
    path: '/communications-gateway/notifications/',
    pureComponent: lazy(() => import('./modules/catalogs/notifications-list/pages/notifications-list'), <AppLoader />),
    exact: true,
    breadcrumb: 'Уведомления',
    title: 'Уведомления',
  },
  {
    path: '/crm/prospects/',
    pureComponent: lazy(() => import('./modules/crm/features/Prospects/ProspectsList'), <AppLoader />),
    exact: true,
    breadcrumb: 'Проспекты',
    title: 'Проспекты',
  },
  {
    path: '/crm/surges/',
    pureComponent: lazy(() => import('./modules/crm/features/Surges/SurgesList'), <AppLoader />),
    exact: true,
    breadcrumb: 'Сурджи',
    title: 'Сурджи',
  },
  {
    path: '/crm/prospects/new/',
    pureComponent: lazy(() => import('./modules/crm/features/Prospects/ProspectCreate'), <AppLoader />),
    exact: true,
    breadcrumb: 'Новый проспект',
    title: 'Новый проспект',
  },

  {
    path: '/crm/prospects/:id/',
    pureComponent: lazy(() => import('./modules/crm/features/Prospects/ProspectView'), <AppLoader />),
    exact: true,
    breadcrumb: 'Карточка проспекта',
    title: 'Карточка проспекта',
  },
  {
    path: '/crm/prospects/:id/edit/',
    pureComponent: lazy(() => import('./modules/crm/features/Prospects/ProspectEdit'), <AppLoader />),
    exact: true,
    breadcrumb: 'Редактирование проспекта',
    title: 'Редактирование проспекта',
  },
  {
    path: '/communications-gateway',
    exact: true,
    breadcrumb: 'Управление уведомлениями',
    title: 'Управление уведомлениями',
  },

  ...pltCoreModule.routes(<AppLoader />),
  ...catalogsModule.routes(<AppLoader />),

  {
    path: '/loyalty-promocodes/',
    exact: true,
    // eslint-disable-next-line react/display-name
    breadcrumb: () => <BreadcrumbRedirect redirectLink="/loyalty-promocodes/promocodes/" text="Промокоды" />,
    title: 'Промокоды',
  },
  {
    path: '/loyalty-promocodes/promocodes/',
    pureComponent: lazy(() => import('./modules/loyalty-promocodes/features/Promocode/PromocodeList'), <AppLoader />),
    exact: true,
    breadcrumb: 'Промокоды',
    title: 'Промокоды',
  },
  {
    path: '/loyalty-promocodes/promocodes/new/',
    pureComponent: lazy(() => import('./modules/loyalty-promocodes/features/Promocode/PromocodeCreate'), <AppLoader />),
    exact: true,
    breadcrumb: 'Новый промокод',
    title: 'Новый промокод',
  },
  {
    path: '/loyalty-promocodes/promocodes/:id/',
    pureComponent: lazy(() => import('./modules/loyalty-promocodes/features/Promocode/PromocodeView'), <AppLoader />),
    exact: true,
    breadcrumb: 'Карточка промокода',
    title: 'Карточка промокода',
  },
  {
    path: '/loyalty-promocodes/promocodes/:id/edit/',
    pureComponent: lazy(() => import('./modules/loyalty-promocodes/features/Promocode/PromocodeEdit'), <AppLoader />),
    exact: true,
    breadcrumb: 'Редактирование промокода',
    title: 'Редактирование промокода',
  },
  {
    path: '/support/otp-codes/',
    pureComponent: lazy(() => import('./modules/support/otp-codes/OtpCodes'), <AppLoader />),
    exact: true,
    breadcrumb: 'OTP коды',
    title: 'OTP коды',
  },
];
