import { catalogsModule } from './modules/catalogs/catalogs.module';
// import { pltCoreModule } from 'src/modules/plt-core/plt-core.module';
import { Menu, MENU_TYPES } from './interfaces';
import { wagesModule } from './modules/hrm/wages/wages.module';
import { contractsModule } from './modules/hrm/contracts/contracts.module';
import { contractorsModule } from './modules/hrm/contractors/contractors.module';
import { usersModule } from './modules/users/users.module';

export const menu: Menu[] = [
  // { type: MENU_TYPES.ROUTER, link: '/', text: 'Главная', icon: 'ion ion-md-speedometer' },

  // ...pltCoreModule.menu(),
  {
    type: MENU_TYPES.ITEMS,
    text: 'HRM',
    link: '/hrm',
    children: [...wagesModule.menu(), ...contractsModule.menu(), ...contractorsModule.menu()],
  },
  ...usersModule.menu(),
  // {
  //   type: MENU_TYPES.ITEMS,
  //   text: 'Управление уведомлениями',
  //   link: '/communications-gateway',
  //   children: [
  //     {
  //       type: MENU_TYPES.ROUTER,
  //       text: 'Уведомления',
  //       link: '/communications-gateway/notifications',
  //     },
  //     {
  //       type: MENU_TYPES.ROUTER,
  //       text: 'Токены',
  //       link: '/communications-gateway/tokens',
  //     },
  //   ],
  // },
  // {
  //   type: MENU_TYPES.ITEMS,
  //   text: 'Регионы присутствия',
  //   link: '/presence-regions',
  //   children: [
  //     {
  //       type: MENU_TYPES.ROUTER,
  //       text: 'Регионы',
  //       link: '/presence-regions/presence-area/',
  //     },
  //     {
  //       type: MENU_TYPES.ROUTER,
  //       text: 'Полигоны',
  //       link: '/presence-regions/presence-polygons/',
  //     },
  //   ],
  // },
  {
    type: MENU_TYPES.ITEMS,
    text: 'CRM',
    link: '/crm',
    children: [
      {
        type: MENU_TYPES.ROUTER,
        text: 'Проспекты',
        link: '/crm/prospects/',
      },
      {
        type: MENU_TYPES.ROUTER,
        text: 'Отзывы',
        link: '/crm/feedback/',
      },
      {
        type: MENU_TYPES.ROUTER,
        text: 'Сурджи',
        link: '/crm/surges/',
      },
    ],
  },
  {
    type: MENU_TYPES.ITEMS,
    text: 'Промокоды',
    link: '/loyalty-promocodes',
    children: [
      {
        type: MENU_TYPES.ROUTER,
        text: 'Промокоды',
        link: '/loyalty-promocodes/promocodes/',
      },
    ],
  },
  {
    type: MENU_TYPES.ITEMS,
    text: 'Поддержка',
    link: '/support/otp-codes/',
    children: [
      {
        type: MENU_TYPES.ROUTER,
        text: 'OTP коды',
        link: '/support/otp-codes/',
      },
    ],
  },
  ...catalogsModule.menu(),
];
