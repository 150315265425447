import { User, UserEdge, UserTypeEnum, Contact, ContactTypeEnum } from 'src/services/GraphQL';
import { formatMobilePhone } from 'src/utils';

enum EUserGroups {
  CUSTOMERS = 'customers',
  CONTRACTORS = 'contractors',
}

// Профили патнера и сотрудника не реализованы, поэтому пока редиректим в профиль кастомера
const UserGroups: Record<UserTypeEnum, EUserGroups> = {
  [UserTypeEnum.CUSTOMER]: EUserGroups.CUSTOMERS,
  [UserTypeEnum.CONTRACTOR]: EUserGroups.CONTRACTORS,
  [UserTypeEnum.EMPLOYEE]: EUserGroups.CUSTOMERS,
  [UserTypeEnum.PARTNER]: EUserGroups.CUSTOMERS,
};

export interface UserForSearchInputOption {
  id: string;
  label: string;
  userType: EUserGroups;
}

export interface UserForSearchInput {
  label: UserTypeEnum;
  options: UserForSearchInputOption[];
}

export type UsersGroupsForSearchInput = UserForSearchInput[];

const usersTypes: Record<Extract<UserTypeEnum, UserTypeEnum.CUSTOMER | UserTypeEnum.CONTRACTOR>, string> = {
  [UserTypeEnum.CUSTOMER]: 'Клиенты',
  [UserTypeEnum.CONTRACTOR]: 'Исполнители',
};

export const usersListForSearchInputAdapter = (users: ReadonlyArray<UserEdge>): UserForSearchInput[] => {
  const usersMap: Record<string, UserForSearchInputOption[]> = users.reduce(
    (currUsersMap, currUser: UserEdge): Record<UserTypeEnum, UserForSearchInputOption[]> => {
      const userOption: UserForSearchInputOption = adaptUserForSearchInputAdapter(currUser);

      if (currUsersMap[currUser.node.userType]) {
        currUsersMap[currUser.node.userType].push(userOption);
      } else {
        currUsersMap[currUser.node.userType] = [userOption];
      }

      return currUsersMap as Record<UserTypeEnum, UserForSearchInputOption[]>;
    },
    {},
  );

  return Object.keys(usersMap).map((key: string) => ({ label: usersTypes[key], options: usersMap[key] }));
};

const adaptUserForSearchInputAdapter = (userEdge: UserEdge): UserForSearchInputOption => {
  const { node: user }: UserEdge = userEdge;
  const { firstName, lastName, contacts }: User = user;
  const [phone]: Contact[] = contacts.filter((contact) => contact.isMain && contact.type === ContactTypeEnum.MOBILE);
  const formattedPhone: string | undefined = formatMobilePhone(phone?.value);
  const fullName: string | undefined = [firstName?.trim(), lastName?.trim()].filter((name) => !!name).join(' ');
  const label: string = [formattedPhone?.trim(), fullName?.trim()].filter((item) => !!item).join(' · ');

  return {
    id: user.id,
    label,
    userType: UserGroups[user.userType],
  };
};
