import React, { memo, MutableRefObject, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import styles from './styles.module.scss';

interface TabProps<T> {
  tab: T;
  active: boolean;
  onTabClick?(tab: T): void;
  getTabLink?(tab: T): string;
  getLabel(value: T): string;
  index: number;
  onHide(index: number): void;
  onShow(index: number): void;
  icon?: ReactNode;
}

function TabRender<T>({ tab, active, onTabClick, getTabLink, index, onHide, onShow, getLabel, icon }: TabProps<T>) {
  const handleClick = useCallback(() => onTabClick && onTabClick(tab), [onTabClick, tab]);

  const ref = useRef<HTMLElement | null>(null);
  const parentRef = useRef<Element | null>(null);

  const [clientWidth, setClientWidth] = useState<number | null>(null);
  const [offsetLeft, setOffsetLeft] = useState<number | null>(null);
  const [offsetWidth, setOffsetWidth] = useState<number | null>(null);

  const [displayed, setDisplayed] = useState(false);

  const className = useMemo(
    () =>
      cn('font-medium-sm', styles.tab, {
        [styles.selectedTab]: active,
        [styles.displayedTab]: displayed,
      }),
    [active, displayed],
  );

  const handleHide = useCallback(() => {
    onHide(index);
    setDisplayed(false);
  }, [index, onHide]);

  const handleShow = useCallback(() => {
    onShow(index);
    setDisplayed(true);
  }, [index, onShow]);

  const takeSnapshot = useCallback(() => {
    const element = ref.current;

    if (element) {
      setOffsetLeft(element.offsetLeft);
      setOffsetWidth(element.offsetWidth);

      parentRef.current = element.offsetParent;
    }

    const parent = parentRef.current;

    if (parent) {
      setClientWidth(parent.clientWidth);
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(takeSnapshot, 100);
    return () => clearInterval(interval);
  }, [takeSnapshot]);

  useEffect(() => {
    const offsetRight =
      typeof offsetLeft == 'number' && typeof offsetWidth == 'number' ? offsetLeft + offsetWidth : null;

    if (clientWidth && offsetRight && clientWidth + 1 >= offsetRight) {
      handleShow();
      return handleHide;
    }
  }, [clientWidth, handleHide, handleShow, offsetLeft, offsetWidth]);

  const label = getLabel(tab);

  if (getTabLink) {
    return (
      <Link ref={ref as MutableRefObject<HTMLAnchorElement | null>} to={getTabLink(tab)} className={className}>
        {icon}
        {label}
      </Link>
    );
  }

  return (
    <button
      ref={ref as MutableRefObject<HTMLButtonElement | null>}
      type="button"
      className={className}
      onClick={handleClick}>
      {icon}
      {label}
    </button>
  );
}

export const Tab = memo(TabRender);
