import React, { Component } from 'react';
import styles from './AppLoader.module.scss';

// TODO В будущем объединить с Loader
export default class AppLoader extends Component {
  async componentWillUnmount() {
    await new Promise((resolve) => {
      setTimeout(resolve, 5000);
    });
  }

  render() {
    return (
      <div className={styles.Container}>
        <div className={styles.Spinner}>
          <div className={`spinner-border text-primary ${styles.SpinnerEl}`} role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    );
  }
}
