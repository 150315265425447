import React, { FC, memo, useCallback, useMemo, useState } from 'react';
import Image from 'react-bootstrap/Image';
import styles from './QRCode.module.scss';
import classNames from 'classnames';

interface IQRCodeProps {
  img: string;
}

export const QRCode: FC<IQRCodeProps> = memo(({ img }) => {
  const [isOpened, setIsOpened] = useState(false);

  const toggleCode = useCallback(() => {
    setIsOpened(!isOpened);
  }, [isOpened]);

  const title = useMemo(() => {
    const closedTitle = 'Показать QR-код';
    const openedTitle = 'Скрыть QR-код';

    return isOpened ? openedTitle : closedTitle;
  }, [isOpened]);

  return (
    <div className="qrContainer">
      <div className={classNames('font-weight-bold mb-3', styles.title)} onClick={toggleCode}>
        {title}
      </div>
      <div className={classNames(isOpened ? styles.enter : styles.leave, styles.qrCode)}>
        <Image className="w-100" src={img} />
      </div>
    </div>
  );
});
