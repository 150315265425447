import { AppSubModule } from 'src/interfaces';

import { CATALOGS_PROVIDER_TOKENS } from '../constants';
import { offerServiceProvider } from './service-providers/OfferService.provider';

class OfferListModule extends AppSubModule {
  public providers = [
    {
      token: CATALOGS_PROVIDER_TOKENS.OFFER_SERVICE,
      provider: offerServiceProvider,
    },
  ];
}

export const offerListModule = new OfferListModule();
