import React, { FC } from 'react';
import { ITotalBlockInfo, TAddress } from '../address.interfaces';
import TotalInfoBlockAdapter from '../adapters/TotalInfoBlock.adapter';
import { Divider } from '../../../../../common/Divider';
import { Alert } from '../../../../../common/Alert';

interface ITotalInfoBlockProps {
  address: TAddress | undefined | null;
}

const AddressString: FC<{ addressValue: ITotalBlockInfo }> = ({ addressValue }): JSX.Element => {
  const { addressLink, address } = addressValue;

  return (
    <div>
      <div className="font-regular-sm text-muted">Адрес</div>
      <div>
        {addressLink ? (
          <a className="font-regular-base" href={addressLink} target="_blank" rel="noreferrer">
            {address}
          </a>
        ) : (
          <span>{address}</span>
        )}
      </div>
    </div>
  );
};

const MetroStationsString: FC<{ addressValue: ITotalBlockInfo }> = ({ addressValue }): JSX.Element | null => {
  const { metro } = addressValue;

  return metro.length ? (
    <>
      <div className="font-regular-sm text-muted">Ближайшие станции метро</div>
      {metro.map((metro) => (
        <span key={`metro-${metro.name}-${metro.color}`}>
          <div className="font-regular-base" style={{ color: `${metro.color}` }}>
            <span>{metro.name}</span>
            <Divider />
            <span>{`${metro.distance} км`}</span>
          </div>
        </span>
      ))}
    </>
  ) : null;
};

export const TotalInfoBlock: FC<ITotalInfoBlockProps> = ({ address }): JSX.Element => {
  const info: ITotalBlockInfo = TotalInfoBlockAdapter.adapt(address as TAddress);

  return (
    <>
      <Alert variant="info" header={<span>Проверьте итоговый адрес</span>} />
      <div className="mb-3">
        <AddressString addressValue={info} />
      </div>
      <div className="mb-3">
        <MetroStationsString addressValue={info} />
      </div>
      <div>
        <div className="font-regular-sm text-muted">Домофон</div>
        <div className="font-regular-base">{info.intercom}</div>
      </div>
    </>
  );
};
