import { skillsListModule } from './skills-list/skillsList.module';
import { presencePolygonListModule } from './presence-polygon-list/presencePolygonList.module';
import { presenceAreaListModule } from './presence-area-list/presenceAreaList.module';
import { positionListModule } from './position-list/positionList.module';
import { optionsListModule } from './options-list/optionsList.module';
import { offerListModule } from './offer-list/offerList.module';
import { notificationsListModule } from './notifications-list/notificationsList.module';
import { managerListModule } from './manager-list/managerList.module';
import { blockingReasonsListModule } from './blocking-reasons-list/blockingReasonsList.module';
import { profileServiceProvider } from './service-providers/ProfileService.provider';
import { CATALOGS_PROVIDER_TOKENS } from './constants';
import { AppModule, IRoute, Menu } from 'src/interfaces';
import routes from './catalogs.routes';
import menu from './catalogs.menu';
import { IProviderRecord } from 'src/services/ServiceLocator/ServiceLocator.interface';
import { blockingReasonGroupListModule } from './blocking-reason-group-list/blockingReasonGroupList.module';
import { managerSkillsListModule } from './manager-skills-list/managerSkillsList.module';
import { marketListModule } from './market-list/marketList.module';
import { personListModule } from './person-list/personList.module';
import { regionsListModule } from './regions-list/resionsList.module';
import { regulationsListModule } from './regulations-list/regulationsList.module';
import { specializationsListModule } from './specializations-list/specializationsList.module';
import { tagsListModule } from './tags-list/tagsList.module';
import { tenantListModule } from './tenant-list/tenantList.module';
import { tokensListModule } from './tokens-list/tokensList.module';
import { unitsListModule } from './units-list/unitsList.module';
import { appListModule } from './app-list/appList.module';
import { apiListModule } from './api-list/apiList.module';
import { wmsTemplatesModule } from './wms-templates/wmsTemplates.module';
import { wmsSkusModule } from './wms-skus-list/wmsSkus.module';
import { wmsWarehousesModule } from './wms-warehouses/wmsWarehouses.module';
import { wmsPlacesModule } from './wms-places/wmsPlaces.module';

class CatalogsModule extends AppModule {
  public routes = (fallback: JSX.Element): IRoute[] => routes(fallback);
  public menu = (): Menu[] => menu();
  public providers: IProviderRecord[] = [
    {
      token: CATALOGS_PROVIDER_TOKENS.PROFILE_SERVICE,
      provider: profileServiceProvider,
    },
    ...blockingReasonsListModule.providers,
    ...blockingReasonGroupListModule.providers,
    ...managerListModule.providers,
    ...managerSkillsListModule.providers,
    ...marketListModule.providers,
    ...notificationsListModule.providers,
    ...offerListModule.providers,
    ...optionsListModule.providers,
    ...personListModule.providers,
    ...positionListModule.providers,
    ...presenceAreaListModule.providers,
    ...presencePolygonListModule.providers,
    ...regionsListModule.providers,
    ...regulationsListModule.providers,
    ...skillsListModule.providers,
    ...specializationsListModule.providers,
    ...tagsListModule.providers,
    ...tenantListModule.providers,
    ...tokensListModule.providers,
    ...unitsListModule.providers,
    ...appListModule.providers,
    ...apiListModule.providers,
    ...wmsTemplatesModule.providers,
    ...wmsSkusModule.providers,
    ...wmsWarehousesModule.providers,
    ...wmsPlacesModule.providers,
  ];
}

export const catalogsModule = new CatalogsModule();
