import { Scalars, CategoryTypeEnum } from 'src/services/GraphQL';
import { AdapterFunction } from '../interfaces';
import { problemListItemAdapter } from './ProblemListIemAdapter.helper';
import { PresenterProblemListItem } from './ProblemListIemAdapter.helper';
import { RequestedCategoryItem } from '../../../interfaces';

interface PresenterCategoryItem {
  id: Scalars['ID'];
  title: Scalars['String'];
  type: CategoryTypeEnum;
  problems: PresenterProblemListItem[];
}

export interface PresenterCategoryListItem {
  label: string;
  value: Readonly<PresenterCategoryItem>;
  options: ReadonlyArray<PresenterProblemListItem>;
}

export const categoryListIemAdapter: AdapterFunction<RequestedCategoryItem, PresenterCategoryListItem> = (
  requestedCategory: RequestedCategoryItem,
) => {
  const category = {
    ...requestedCategory,
    problems: requestedCategory.subCategories.map((requestedProblem) =>
      problemListItemAdapter({ problem: requestedProblem }),
    ),
  };

  return {
    label: requestedCategory.title,
    value: category,
    options: requestedCategory.subCategories.map((requestedProblem) =>
      problemListItemAdapter({ problem: requestedProblem, categoryType: category.type }),
    ),
  };
};
