import React from 'react';
// @ts-expect-error TODO
import styles from './NotFound.scss';

export default class NotFound extends React.Component {
  render() {
    return (
      <div className={styles.Container}>
        <div className="text-center">
          <h1 className={styles.Status}>404</h1>
          <h2 className={styles.Message}>Not Found</h2>
        </div>
      </div>
    );
  }
}
