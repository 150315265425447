import APN, { PhoneNumberFormat } from 'awesome-phonenumber';

// Функционал дублируется в server
export class AwesomePhoneNumberService {
  private readonly _awesomeInstance: APN;

  constructor(phoneNumber: string, countryCode?: string) {
    this._awesomeInstance = new APN(phoneNumber, countryCode);
  }

  public isPossible(): boolean {
    return this._awesomeInstance.isPossible();
  }

  public isMobile(): boolean {
    return this._awesomeInstance.isMobile();
  }

  public isValid(): boolean {
    return this._awesomeInstance.isValid();
  }

  public isFixedLine(): boolean {
    return this._awesomeInstance.isFixedLine();
  }

  public getNumber(type: PhoneNumberFormat = 'e164'): string {
    return this._awesomeInstance.getNumber(type);
  }
}
