import { SweetAlertOptions } from 'sweetalert2';

export enum WAGES_PROVIDER_TOKENS {
  WAGES_SERVICE = 'wagesService',
}

export enum WAGE_TYPE {
  SELF_EMPLOYED = 'SELF_EMPLOYED',
  INDIVIDUAL = 'INDIVIDUAL',
}

export enum RATE_TYPE {
  SERVICE = 'SERVICE',
  HOUR = 'HOUR',
  DAY = 'DAY',
  MONTH = 'MONTH',
}

export enum AMOUNT_TYPE {
  FIXED = 'FIXED',
  PERCENT = 'PERCENT',
}

export const wageTypeOptions = [
  { name: WAGE_TYPE.SELF_EMPLOYED, label: 'Самозанятый' },
  { name: WAGE_TYPE.INDIVIDUAL, label: 'Физ.лицо' },
];

export const amountOptions = [
  { name: AMOUNT_TYPE.FIXED, label: 'p.' },
  { name: AMOUNT_TYPE.PERCENT, label: '%' },
];

export const rateOptions = [
  { name: RATE_TYPE.HOUR, label: ' за час' },
  { name: RATE_TYPE.DAY, label: ' за день' },
  { name: RATE_TYPE.MONTH, label: ' за месяц' },
  { name: RATE_TYPE.SERVICE, label: ' за услугу' },
];

export const BASE_MODAL_CONFIG: SweetAlertOptions = {
  showCancelButton: true,
  confirmButtonText: 'Да',
  cancelButtonText: 'Отмена',
  buttonsStyling: false,
  customClass: {
    confirmButton: 'btn btn-warning btn-lg',
    cancelButton: 'btn btn-default btn-lg',
    actions: 'text-center',
  },
};

export const MODAL_REMOVE_CONFIG: SweetAlertOptions = {
  ...BASE_MODAL_CONFIG,
  title: 'Удалить?',
  text: 'Вы планируете удалить выбранную сущность.',
  icon: 'error',
  customClass: {
    ...BASE_MODAL_CONFIG.customClass,
    confirmButton: 'btn btn-danger btn-lg',
  },
};

export const MODAL_RESTORE_CONFIG: SweetAlertOptions = {
  ...BASE_MODAL_CONFIG,
  title: 'Восстановить?',
  text: 'Вы планируете восстановить выбранную сущность.',
  icon: 'info',
  customClass: {
    ...BASE_MODAL_CONFIG.customClass,
    confirmButton: 'btn btn-info btn-lg',
  },
};
