import { IMetro, ITotalBlockInfo } from '../address.interfaces';

export class TotalInfoBlockModel implements ITotalBlockInfo {
  // eslint-disable-next-line max-params
  constructor(
    readonly address: string,
    readonly addressLink: string,
    readonly metro: IMetro[],
    readonly intercom: string = '',
  ) {
    if (!this.intercom) {
      this.intercom = 'Не указано';
    }
  }
}
