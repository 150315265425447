import { useFormikContext } from 'formik';
import React, { FC, useEffect, useRef, useState } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { FieldLabel } from '../../../../../common';
import { FIELD_FEEDBACK_TYPES, FieldFeedback } from '../../../../../common/FieldFeedback';
import DadataAddressAdapter from '../adapters/DadataAddress.adapter';
import LocationFilterAdapter from '../adapters/LocationFilter.adapter';
import {
  IAddressFeedbackObject,
  IDadataObject,
  IPresenceRegion,
  ISuggestion,
  TAddress,
  TLocationFilter,
} from '../address.interfaces';
import AddressService from '../services/AddressService';
import AddressesApiService from '../services/AddressesApiService';

interface IDadataFieldProps {
  address: TAddress | undefined | null;
  name: string;
  handleChosenAddress: (addressRel: TAddress, isDadata: boolean) => void;
  presenceRegions: IPresenceRegion[];
  isReadonly: boolean;
}

export const DadataField: FC<IDadataFieldProps> = ({
  address,
  name,
  handleChosenAddress,
  presenceRegions = [],
  isReadonly,
}: IDadataFieldProps): JSX.Element => {
  const dadataApiClient = new AddressesApiService();
  const [options, setOptions] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [addressFeedback, setAddressFeedback] = useState<IAddressFeedbackObject | null>(null);
  const [locationFilters, setLocationFilters] = useState<TLocationFilter[]>([]);
  const refAsyncTypeahead = useRef<AsyncTypeahead<string>>(null);
  const { setErrors } = useFormikContext<Record<string, TAddress | undefined>>();

  /**
   * Эффект нужен для того, чтобы на каждом рендере сетить ошибку, если она есть.
   * Так дизейблится кнопка "Сохранить" в модалке редактирования адреса.
   */
  useEffect(() => {
    const hasErrors: boolean =
      addressFeedback?.type === FIELD_FEEDBACK_TYPES.DANGER || addressFeedback?.type === FIELD_FEEDBACK_TYPES.WARNING;

    if (hasErrors) {
      setErrors({ dadata: addressFeedback?.text });
    } else {
      setErrors({});
    }
  });

  useEffect(() => {
    const dadata = AddressService.parseDadataObject(address?.object);
    const isManual = checkIfManualAddress(address, dadata);
    const feedback = AddressService.getLocationPrecision(dadata);

    if (isManual) {
      setErrors({});
      setAddressFeedback(null);

      return;
    }

    setAddressFeedback(feedback);
  }, [address, setErrors]);

  useEffect(() => {
    const filters = LocationFilterAdapter.adapt(presenceRegions);

    setLocationFilters(filters);
  }, [presenceRegions]);

  const loadAddressesList = (addressSearchString: string): void => {
    setIsLoading(true);

    dadataApiClient
      .getAddressesList(addressSearchString, locationFilters)
      .then((addresses: string[]) => setOptions(addresses))
      .finally(() => setIsLoading(false));
  };

  // Костыль для проверки, создан адрес в ручную или взят из дадаты.
  // Если данные об адресе есть в деталях адреса и нет в дадате, значит какие-то данные были заполнены в ручную
  const checkIfManualAddress = (address: TAddress | null | undefined, dadata: IDadataObject | undefined): boolean => {
    if (!address?.detail || !dadata) {
      return false;
    }

    // Здесь проверяем кейс, когда выбран город федерального знчения, - Москва либо СПб
    // Если так, то регион всегда совпадает с городом
    // И если есть данные о регионе в дадате и деталях адреса, при это регион и город совпадают,
    // Значит было зполнено ручное поле "Регион"
    if (!!address.detail.region && dadata.region && dadata.region === dadata.city) {
      return true;
    }

    const details = ['region', 'area', 'settlement', 'street', 'house', 'block', 'flat'];

    return details.reduce((acc, current) => {
      if (!acc) {
        return !!address.detail?.[current] && !dadata[current];
      }
      return acc;
    }, false);
  };

  const onAddressSelected = ([addressString]: string[] = []): Promise<void> =>
    dadataApiClient.getSingleAddress(addressString).then((addressSuggestion: ISuggestion) => {
      if (!addressSuggestion) return;

      const addressRel: TAddress = DadataAddressAdapter.adapt({
        value: address as TAddress,
        address: addressSuggestion,
      });
      const feedback = AddressService.getLocationPrecision(addressSuggestion.data);

      handleChosenAddress(addressRel, true);
      setAddressFeedback(feedback);
    });

  return (
    <div className="form-group">
      <FieldLabel label="Поиск по городу, улице, дому, квартире" />
      <AsyncTypeahead
        disabled={isReadonly}
        ref={refAsyncTypeahead}
        placeholder="Адрес не выбран"
        id={`${name}-field`}
        options={options}
        useCache={false}
        filterBy={(option) => !!option}
        isLoading={isLoading}
        onSearch={loadAddressesList}
        onChange={onAddressSelected}
        clearButton
        delay={300}
        defaultSelected={[address?.value || '']}
        promptText="Введите текст, чтобы начать поиск..."
        searchText="Осуществляется поиск..."
      />
      <div className="mt-1">
        <FieldFeedback type={addressFeedback?.type as FIELD_FEEDBACK_TYPES} text={addressFeedback?.text} />
      </div>
    </div>
  );
};
