import React, { FC } from 'react';
import { Form } from 'react-bootstrap';
import InputNumber from 'rc-input-number';

import { FieldLabel, FieldError } from 'src/libs/ui-kit/common';
import { INumberFieldProps } from './NumberField.interface';
import { useField, useFormikContext } from 'formik';

export const NumberField: FC<INumberFieldProps> = (props: INumberFieldProps): JSX.Element => {
  const { min, max, name = '', label, disabled, placeholder, type } = props;

  const [{ value }, { error, touched }] = useField(name);
  const { setFieldValue, setFieldTouched } = useFormikContext();

  const setValue = (event) => {
    setFieldValue(name, event || undefined);
  };

  return (
    <Form.Group id={name}>
      <FieldLabel label={label} />
      <>
        <InputNumber
          className="form-control"
          onChange={setValue}
          onBlur={() => setFieldTouched(name)}
          type={type}
          min={min}
          max={max}
          disabled={disabled}
          placeholder={placeholder}
          value={value}
        />
        {error && touched && <FieldError error={error} />}
      </>
    </Form.Group>
  );
};
