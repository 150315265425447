import { IAddressDetail, IDadataObject, TAddress } from '../address.interfaces';
import { ManualAddressModel } from '../models';
import { AddressDetailModel } from '../models';
import AddressService from '../services/AddressService';
import set from 'lodash/set';

// TODO: столкнулись с проблемой того, что в адаптер приходит старое значение модели
// решили пока патчить вручную
// eslint-disable-next-line max-params
function ManualAddressAdapter(address: TAddress, name: string, value: string): TAddress {
  set(address, name.split('.').slice(1).join('.'), value);

  return new ManualAddressModel(
    address?.id,
    address?.label,
    detailsAdapter(address),
    address?.type,
    address.object,
    address.isReadonly,
  );

  function detailsAdapter(address: TAddress): IAddressDetail | null {
    if (!address) return null;

    const dadataObject: IDadataObject | undefined = AddressService.parseDadataObject(address?.object);

    return new AddressDetailModel(
      address?.detail?.region || dadataObject?.region_with_type || '',
      address?.detail?.city || dadataObject?.city_with_type || '',
      address.detail?.settlement || dadataObject?.settlement_with_type || '',
      address?.detail?.area || dadataObject?.area_with_type || '',
      address?.detail?.street || dadataObject?.street_with_type || '',
      address?.detail?.block || `${dadataObject?.block_type || ''} ${dadataObject?.block || ''}`,
      address?.detail?.house || `${dadataObject?.house_type || ''} ${dadataObject?.house || ''}`,
      address?.detail?.floor,
      address?.detail?.flat || dadataObject?.flat,
      address?.detail?.comment,
      address?.detail?.intercom,
      address?.detail?.entrance,
    );
  }
}

export default ManualAddressAdapter;
