import React, { ComponentPropsWithoutRef, forwardRef, MouseEventHandler, useCallback } from 'react';
import { Button } from 'react-bootstrap';
import { Loader } from 'src/components/Loader';
import './IconButton.scss';
import cn from 'classnames';
import { Icon, IconName } from '../../common';

export interface IIconButtonProps extends Omit<ComponentPropsWithoutRef<'button'>, 'children'> {
  icon: IconButtonLabel;
  size?: 'sm';
  clickHandler?: MouseEventHandler<HTMLButtonElement>;
  isLoading?: boolean;
  disabled?: boolean;
  variant?: string;
}

export type IconButtonLabel = IconName;

export const IconButton = forwardRef<HTMLButtonElement, IIconButtonProps>(
  ({ clickHandler, onClick, className: classNameProp, isLoading, size, icon, variant, ...props }, ref): JSX.Element => {
    const className = cn(
      classNameProp,
      'icon-btn',
      'icon-button',
      'md-btn-flat',
      { 'icon-button--loading': isLoading },
      { 'icon-button_sm': size === 'sm' },
    );

    // Для обратной совместимости.
    const handleClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
      (e) => {
        if (clickHandler) clickHandler(e);
        if (onClick) onClick(e);
      },
      [clickHandler, onClick],
    );

    return (
      <Button
        ref={ref}
        onClick={handleClick}
        // Этот стиль нужен для того, чтобы работал тултип на disabled кнопке
        // https://react-bootstrap-v4.netlify.app/components/overlays/#overlays-disabled
        style={props.disabled ? { pointerEvents: 'none' } : { pointerEvents: 'auto' }}
        variant={variant || 'default'}
        className={className}
        {...props}>
        {isLoading ? <Loader size="sm" /> : <Icon name={icon} />}
      </Button>
    );
  },
);
