import { IAdapter, IPresenceRegion, TLocationFilter } from '../address.interfaces';
import { LocationFilterModel } from '../models';

export class LocationFilterAdapter implements IAdapter<IPresenceRegion[], TLocationFilter[]> {
  adapt(regions: IPresenceRegion[] = []): TLocationFilter[] {
    return regions.map((region) => new LocationFilterModel(region.title));
  }
}

export default new LocationFilterAdapter();
