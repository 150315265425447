import { useEffect } from 'react';
import { breadcrumbsStore } from './BreadcrumbsStore';

import { BreadcrumbsLink } from './interfaces';

export function useBreadcrumbs(links: readonly BreadcrumbsLink[]) {
  useEffect(() => {
    breadcrumbsStore.registerLinks(links);
    return breadcrumbsStore.unregisterLinks;
  }, [links]);
}
