import { SweetAlertOptions } from 'sweetalert2';

export enum CONTRACTORS_PROVIDER_TOKENS {
  CONTRACTORS_SERVICE = 'contractorsService',
}

export const BASE_MODAL_CONFIG: SweetAlertOptions = {
  showCancelButton: true,
  confirmButtonText: 'Да',
  cancelButtonText: 'Отмена',
  buttonsStyling: false,
  customClass: {
    confirmButton: 'btn btn-warning btn-lg',
    cancelButton: 'btn btn-default btn-lg',
    actions: 'text-center',
  },
};

export const MODAL_REMOVE_CONFIG: SweetAlertOptions = {
  ...BASE_MODAL_CONFIG,
  title: 'Удалить?',
  text: 'Вы планируете удалить выбранную сущность.',
  icon: 'error',
  customClass: {
    ...BASE_MODAL_CONFIG.customClass,
    confirmButton: 'btn btn-danger btn-lg',
  },
};

export const MODAL_RESTORE_CONFIG: SweetAlertOptions = {
  ...BASE_MODAL_CONFIG,
  title: 'Восстановить?',
  text: 'Вы планируете восстановить выбранную сущность.',
  icon: 'info',
  customClass: {
    ...BASE_MODAL_CONFIG.customClass,
    confirmButton: 'btn btn-info btn-lg',
  },
};

export const MODAL_PERMANENT_BLOCK_CONFIG: SweetAlertOptions = {
  ...BASE_MODAL_CONFIG,
  title: 'Заблокировать?',
  text: 'Вы планируете заблокировать выбранную сущность без возможности восстановления.',
  icon: 'warning',
  customClass: {
    ...BASE_MODAL_CONFIG.customClass,
    confirmButton: 'btn btn-info btn-lg',
  },
};
