import { FIELD_FEEDBACK_TYPES } from '../../../../common/FieldFeedback';

export enum ADDRESS_TYPE {
  UNDEFINED_ADDRESS_TYPE = 0,
  DEFAULT = 1,
  BIRTH_ADDRESS = 2,
  REG_ADDRESS = 3,
  RESIDENCE_ADDRESS = 4,
  PICKUP_POINT = 5,
}

export enum ADDRESS_STATUSES {
  DANGER = 'danger',
  NEED_HOUSE = 'need_house',
  NEED_FLAT = 'need_flat',
  SUCCESS = 'success',
}

export interface IAddressDetail {
  region: string;
  city: string;
  settlement: string;
  area: string;
  street: string;
  block: string;
  house: string;
  flat: string;
  floor: string;
  comment: string;
  intercom: string;
  entrance: string;
}

export interface ICoordinates {
  readonly lat: number | null;
  readonly lng: number | null;
}

export interface IMetroStation {
  label: string;
  color: string;
  line: string;
}

export interface IMetro {
  readonly distance: number;
  readonly line: string;
  readonly name: string;
  readonly color?: string;
}

interface IAddress {
  id: string;
  label: string;
  value: string;
  unrestrictedValue: string;
  object: string | undefined;
  detail: IAddressDetail;
  coordinates: ICoordinates;
  metro: IMetro[];
  type: ADDRESS_TYPE;
  createdAt: string;
  updatedAt: string;
  isDeleted: boolean;
  deletedAt: string;
  isReadonly: boolean;
}

interface IAddressRel {
  id: string;
  label: string;
  value: string;
  unrestrictedValue: string;
  object: string | undefined;
  detail: IAddressDetail | null;
  coordinates: ICoordinates | null;
  metro: IMetro[];
  type: ADDRESS_TYPE;
  isReadonly: boolean;
}

// https://dadata.ru/api/find-address/

export interface IDadataObject {
  postal_code: string; // '129366';
  country: string; // 'Россия';
  country_iso_code: string; // 'RU';
  federal_district?: string;
  region_fias_id: string; // '0c5b2444-70a0-4932-980c-b4dc0d3f02b5';
  region_kladr_id: string; // '7700000000000';
  region_iso_code: string; // 'RU-MOW';
  region_with_type: string; // 'г Москва';
  region_type: string; // 'г';
  region_type_full: string; // 'город';
  region: string; // 'Москва';
  area_fias_id?: string;
  area_kladr_id?: string;
  area_with_type?: string;
  area_type?: string;
  area_type_full?: string;
  area?: string; // Район в регионе
  city_fias_id: string; // '0c5b2444-70a0-4932-980c-b4dc0d3f02b5';
  city_kladr_id: string; // '7700000000000';
  city_with_type: string; // 'г Москва';
  city_type: string; // 'г';
  city_type_full: string; // 'город';
  city: string; // 'Москва';
  city_area?: string;
  city_district_fias_id?: string;
  city_district_kladr_id?: string;
  city_district_with_type?: string;
  city_district_type?: string;
  city_district_type_full?: string;
  city_district?: string;
  settlement_fias_id?: string;
  settlement_kladr_id?: string;
  settlement_with_type?: string;
  settlement_type?: string;
  settlement_type_full?: string;
  settlement?: string;
  street_fias_id: string; // '9c8dba99-bb48-459f-bc5f-a73e9e43e7d0';
  street_kladr_id: string; // '77000000000322000';
  street_with_type: string; // 'ул Ярославская';
  street_type: string; // 'ул';
  street_type_full: string; // 'улица';
  street: string; // 'Ярославская';
  house_fias_id: string; // '4dc675b3-8ea6-4fd1-8ebd-f4b62c5c6dd3';
  house_kladr_id: string; // '7700000000032200063';
  house_type: string; // 'д';
  house_type_full: string; // 'дом';
  house: string; // '21';
  block_type?: string;
  block_type_full?: string;
  block?: string;
  flat_type?: string;
  flat_type_full?: string;
  flat?: string;
  flat_area?: string;
  square_meter_price?: string;
  flat_price?: string;
  postal_box?: string;
  fias_id: string; // '4dc675b3-8ea6-4fd1-8ebd-f4b62c5c6dd3';
  fias_code: string; // '77000000000000032200063';
  fias_level: string;
  fias_actuality_state: string; // '0';
  kladr_id: string; // '7700000000032200063';
  geoname_id?: string;
  capital_marker: string; // '0';
  okato: string; // '45280552000';
  oktmo: string; // '45349000';
  tax_office: string; // '7717';
  tax_office_legal: string; // '7717';
  timezone?: string;
  geo_lat: string | null;
  geo_lon: string | null;
  beltway_hit?: string;
  beltway_distance?: string;
  metro?: IMetro[];
  qc_geo?: string;
  qc_complete?: string;
  qc_house?: string;
  history_values?: string[];
  unparsed_parts?: string;
  source?: string;
  qc?: string;
}

export interface ISuggestion {
  value: string;
  unrestricted_value: string;
  data: IDadataObject;
}

export interface IAddressesListResponse {
  suggestions: ISuggestion[];
}

export interface IAddressRequest {
  query: string;
  count?: number;
  locations?: TLocationFilter[];
  locations_boost?: TLocationFilter[];
}

export interface IAdapter<F, B> {
  adapt: (address: F) => B;
}

export interface IAddressFeedbackObject {
  text: string;
  type: FIELD_FEEDBACK_TYPES;
}

export interface ITotalBlockInfo {
  address: string;
  addressLink: string;
  metro: IMetro[];
  intercom: string;
}

export interface IPresenceRegion {
  id: string;
  title: string;
  name: string;
}

export type TAddress = IAddress | IAddressRel;
export type TAddressFeedback = { [key in ADDRESS_STATUSES]: IAddressFeedbackObject };
export type TLocationFilter = Partial<
  Pick<IDadataObject, 'region' | 'city' | 'street' | 'timezone' | 'country' | 'area'>
>;
