import { AppModule, IRoute, Menu, MENU_TYPES } from 'src/interfaces';
import { IProviderRecord } from 'src/services/ServiceLocator/ServiceLocator.interface';
import { CONTRACTS_PROVIDER_TOKENS } from './constants';
import routes from './contracts.routes';
import { contractsProvider } from './service-providers/Contracts.provider';

class ContractsModule extends AppModule {
  public routes = (fallback: JSX.Element): IRoute[] => routes(fallback);

  public menu = (): Menu[] => [
    {
      type: MENU_TYPES.ROUTER,
      text: 'Контракты',
      link: '/hrm/contracts/',
    },
  ];
  public providers: IProviderRecord[] = [
    {
      token: CONTRACTS_PROVIDER_TOKENS.CONTRACTS_SERVICE,
      provider: contractsProvider,
    },
  ];
}

export const contractsModule = new ContractsModule();
