import React, { createContext, memo, ReactNode, useContext } from 'react';

import { LayoutRightSidebarStore } from './LayoutRightSidebar.store';

export interface LayoutRightSidebarStoreProviderProps {
  children: ReactNode;
}

let store: LayoutRightSidebarStore | null = null;

const LayoutRightSidebarStoreContext: React.Context<LayoutRightSidebarStore | null> =
  createContext<LayoutRightSidebarStore | null>(null);

export const useRightSidebarStore: () => LayoutRightSidebarStore = () => useContext(LayoutRightSidebarStoreContext)!;

export const LayoutRightSidebarStoreProvider = memo<LayoutRightSidebarStoreProviderProps>(({ children }) => {
  if (!store) {
    store = new LayoutRightSidebarStore();
  }

  return <LayoutRightSidebarStoreContext.Provider value={store}>{children}</LayoutRightSidebarStoreContext.Provider>;
});
