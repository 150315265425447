import { CreateUserProfileFromSearchInput } from '../../../../services/GraphQL';
import {
  CreateUserProfileFromSearchInputVariables,
  GetUsersForSearchInput,
} from 'src/services/GraphQL/generated/operations';
import {
  GetUsersForSearchInputVariables,
  apolloClient,
  UserEdge,
  UserTypeEnum,
  ContactTypeEnum,
} from 'src/services/GraphQL';
import { RequestController } from 'src/utils/decorators/request-controller';
import { ApolloQueryResult, FetchResult } from '@apollo/client';
import { UserForSearchInput, usersListForSearchInputAdapter } from '../../helpers/adapters';

class SsoProvider {
  @RequestController({ logger: true, debounce: true })
  async usersListForSearchInput(variables: GetUsersForSearchInputVariables): Promise<UserForSearchInput[]> {
    const users: ApolloQueryResult<GetUsersForSearchInput> = await apolloClient.query<
      GetUsersForSearchInput,
      GetUsersForSearchInputVariables
    >({
      query: GetUsersForSearchInput,
      variables,
    });

    return usersListForSearchInputAdapter((users.data?.usersConnection?.edges || []) as ReadonlyArray<UserEdge>);
  }

  @RequestController({ logger: true })
  async createUserProfileFromSearchInput(phone: string): Promise<{ id: string }> {
    const variables: CreateUserProfileFromSearchInputVariables = {
      userType: UserTypeEnum.CUSTOMER,
      contacts: [{ value: phone, isMain: true, isVerified: true, type: ContactTypeEnum.MOBILE }],
    };

    const response: FetchResult<CreateUserProfileFromSearchInput> = await apolloClient.mutate<
      CreateUserProfileFromSearchInput,
      CreateUserProfileFromSearchInputVariables
    >({
      mutation: CreateUserProfileFromSearchInput,
      variables,
    });

    if (response.errors?.length) {
      await Promise.reject(response.errors);
    }

    return { id: response.data!.createUserProfile.id };
  }
}

export const ssoProvider = new SsoProvider();
export type TSsoProvider = typeof ssoProvider;
