import GrpcService from 'src/services/GrpcService';

import { RequestController } from '../../../../utils/decorators/request-controller';

import {
  ICreate,
  IFindById,
  IRemove,
  IRestore,
  ISearch,
  IUpdate,
} from '../../../../services/ServiceLocator/ServiceLocator.interface';
import {
  TContractorUpdateRequest,
  TContractorSearchRequest,
  TContractorCreateRequest,
  TContractorBlockRequest,
  TContractorActivateRequest,
  TContractorSearchResponse,
  TContractorResponse,
  TContractorAddTaskResponse,
  TContractorAddTaskRequest,
  // TContractorView,
  // TBlockingReasonList,
  // TContractorContractsListResponse,
  // TSearchBlockingReasonGroupRequest,
  // TBlockingReasonGroupList,
  // TContractorFreezeRequest,
  // TSearchBlockingReasonRequest,
  // TSearchContractorContractsRequest,
} from '../contractors.interfaces';
import { TUuidRequest } from '../../../../interfaces';

class ContractorsProvider implements ISearch, IFindById, ICreate, IUpdate, IRestore, IRemove {
  @RequestController({ logger: true, debounce: true, retry: true })
  public search(params: TContractorSearchRequest): Promise<TContractorSearchResponse> {
    return GrpcService.ContractorService.Search(params);
  }

  @RequestController({ logger: true, debounce: true, retry: true })
  public findById(params: TUuidRequest): Promise<TContractorResponse> {
    return GrpcService.ContractorService.FindById(params);
  }

  @RequestController({ logger: true, debounce: true, retry: true })
  public create(params: TContractorCreateRequest): Promise<TContractorResponse> {
    return GrpcService.ContractorService.Create(params);
  }

  @RequestController({ logger: true, debounce: true, retry: true })
  public update(params: TContractorUpdateRequest): Promise<TContractorResponse> {
    return GrpcService.ContractorService.Update(params);
  }

  @RequestController({ logger: true, debounce: true, retry: true })
  public remove(params: TUuidRequest): Promise<TContractorResponse> {
    return GrpcService.ContractorService.Remove(params);
  }

  @RequestController({ logger: true, debounce: true, retry: true })
  public restore(params: TUuidRequest): Promise<TContractorResponse> {
    return GrpcService.ContractorService.Restore(params);
  }

  @RequestController({ logger: true, debounce: true, retry: true })
  public block(params: TContractorBlockRequest): Promise<TContractorResponse> {
    return GrpcService.ContractorService.Block(params);
  }

  // @RequestController({ logger: true, debounce: true, retry: true })
  // public freeze(params: TContractorFreezeRequest): Promise<TContractorView> {
  //   return GrpcService.ContractorService.Freeze(params);
  // }

  @RequestController({ logger: true, debounce: true, retry: true })
  public activate(params: TContractorActivateRequest): Promise<TContractorResponse> {
    return GrpcService.ContractorService.Activate(params);
  }

  // @RequestController({ logger: true, debounce: true, retry: true })
  // public suggestBlockingReason(params: TSearchBlockingReasonRequest): Promise<TBlockingReasonList> {
  //   return GrpcService.ContractorService.SearchBlockingReason(params);
  // }

  // @RequestController({ logger: true, debounce: true, retry: true })
  // public suggestBlockingReasonGroups(params: TSearchBlockingReasonGroupRequest): Promise<TBlockingReasonGroupList> {
  //   return GrpcService.ContractorService.SearchBlockingReasonGroup(params);
  // }

  // @RequestController({ logger: true, debounce: true, retry: true })
  // public retrieveContracts(params: TSearchContractorContractsRequest): Promise<TContractorContractsListResponse> {
  //   return GrpcService.ContractorService.SearchContracts(params);
  // }

  @RequestController({ logger: true, debounce: true, retry: true })
  public addTask(params: TContractorAddTaskRequest): Promise<TContractorAddTaskResponse> {
    return GrpcService.ContractorService.AddTask(params);
  }
}

export const contractorsProvider = new ContractorsProvider();
export type TContractorsProvider = typeof contractorsProvider;
