import { wmsPlacesServiceProvider } from './service-providers/WmsPlacesService.provider';
import { AppSubModule } from 'src/interfaces';
import { CATALOGS_PROVIDER_TOKENS } from '../constants';

class WmsPlacesModule extends AppSubModule {
  public providers = [
    {
      token: CATALOGS_PROVIDER_TOKENS.WMS_PLACES_SERVICE,
      provider: wmsPlacesServiceProvider,
    },
  ];
}

export const wmsPlacesModule = new WmsPlacesModule();
