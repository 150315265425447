import { SagaResultPresenter } from '../../../interfaces';
import { AdapterFunction } from 'src/interfaces';
import { SagaResult, SagaResultEnum } from 'src/services/GraphQL';

export const sagaResultAdapter: AdapterFunction<SagaResult | undefined, SagaResultPresenter> = (
  sagaResponse?: SagaResult | undefined,
) => {
  const defaultPresenter: SagaResultPresenter = {
    correlationId: undefined,
    isSuccessful: false,
  };

  if (!sagaResponse) {
    return defaultPresenter;
  }

  return {
    correlationId: sagaResponse.correlationId,
    isSuccessful: sagaResponse.result === SagaResultEnum.SUCCESSFUL,
    error: sagaResponse.error,
  };
};
