import GrpcService, { PlatformAdminApi } from 'src/services/GrpcService';
import { ISearch } from 'src/services/ServiceLocator/ServiceLocator.interface';
import { RequestController } from 'src/utils/decorators/request-controller';

class UnitServiceProvider implements ISearch {
  @RequestController({ logger: true, retry: true, debounce: true })
  search(params: PlatformAdminApi.ISearchUnitRequest): Promise<PlatformAdminApi.SearchUnitResponse> {
    return GrpcService.UnitService.Search(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  create(params: PlatformAdminApi.ICreateUnitRequest): Promise<PlatformAdminApi.IUnitResponse> {
    return GrpcService.UnitService.Create(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  update(params: PlatformAdminApi.IUpdateRegionRequest): Promise<PlatformAdminApi.IUnitResponse> {
    return GrpcService.UnitService.Update(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  restore(params: PlatformAdminApi.IUuidRequest): Promise<PlatformAdminApi.IUnitResponse> {
    return GrpcService.UnitService.Restore(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  remove(params: PlatformAdminApi.IUuidRequest): Promise<PlatformAdminApi.IUnitResponse> {
    return GrpcService.UnitService.Remove(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  findById(params: PlatformAdminApi.IUuidRequest): Promise<PlatformAdminApi.IUnitResponse> {
    return GrpcService.UnitService.FindById(params);
  }
}

export const unitServiceProvider = new UnitServiceProvider();
export type TUnitServiceProvider = typeof unitServiceProvider;
