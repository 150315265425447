import type { Observable, Observer, Subscription } from './interfaces';

export class ObservableStore<T> implements Observer<T>, Observable<T> {
  private readonly listeners = new Set<(value: T) => void>();

  private current: T;

  constructor(initialState: T) {
    this.current = initialState;
  }

  next(value: T) {
    this.current = value;
    this.listeners.forEach((listener) => listener(value));
  }

  subscribe(next: (value: T) => void): Subscription;
  subscribe(observer: Observer<T>): Subscription;
  subscribe(observer: Observer<T> | ((value: T) => void)): Subscription {
    const next = typeof observer == 'function' ? observer : (value: T) => observer.next(value);

    next(this.current);

    this.listeners.add(next);

    return {
      unsubscribe: () => {
        this.listeners.delete(next);
      },
    };
  }
}
