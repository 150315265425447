import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { GetAuthTokenValues } from '../AuthorizeStore/AuthorizeStore';
import AuthorizeStore from '../AuthorizeStore';

import { TSubtitle } from 'src/features/PageHeader/PageHeader';
import ServiceLocator from 'src/services/ServiceLocator/ServiceLocator';

export default class AppStore {
  constructor() {
    makeObservable(this);
  }

  /**
   * Стартовый флаг загрузки приложения
   */
  @observable isLoading = true;
  @observable brandLogo = '/img/logo.png';
  @observable brandName = 'Platform Admin';
  @observable isAuthorized = false;

  /**
   * Routes
   *
   * Note: By default all routes use { "exact": true }. To change this
   * behaviour, pass "exact" option explicitly to the route object
   */
  @observable defaultRoute = '/';
  @observable useAuth = true;

  /**
   * Маршрут для страницы входа
   */
  @observable loginRoute = '/login';

  /**
   * Маршрут страницы профиля авторизованного пользователя
   */
  @observable profileRoute = '/me/';
  @observable pageTitle = '';
  @observable pageSubtitle: TSubtitle = [];

  /**
   * Document title template
   */
  @observable titleTemplate = '%s - %b';

  serviceLocator!: ServiceLocator;

  @action setPageMeta(title: string, subtitle: TSubtitle = []): this {
    runInAction(() => {
      this.pageTitle = title || '';
      this.pageSubtitle = subtitle;
    });

    return this;
  }

  @computed get documentTitle(): string {
    if (!this.pageTitle) {
      return this.brandName;
    }

    return this.titleTemplate.replace('%s', this.pageTitle).replace('%b', this.brandName);
  }

  @action setBrandName(brandName: string): this {
    this.brandName = brandName;

    return this;
  }

  @action onLoading = (): void => {
    this.isLoading = true;
  };

  @action offLoading = (): void => {
    this.isLoading = false;
  };

  @computed get isLogged(): boolean {
    return !!AuthorizeStore.userProfile;
  }

  @action signIn = (values: GetAuthTokenValues): Promise<void> =>
    AuthorizeStore.getAuthToken(values).then(() => {
      this.isAuthorized = true;
    });

  @action signOut = (): void => AuthorizeStore.removeAuthToken();
}
