import React, { FC, ReactNode } from 'react';
import { PickEnum } from 'src/interfaces';
import { TaskStateEnum } from 'src/services/GraphQL';
import { CancelTaskForm, TaskRescheduleForm } from '../forms';

interface ITaskFormsContainer {
  currentTaskState: TaskStateEnum | undefined;
  taskId: string;
  onAction: () => void;
}

type TTaskStateEnum = PickEnum<
  TaskStateEnum,
  TaskStateEnum.POSTPONED | TaskStateEnum.RESCHEDULED | TaskStateEnum.CANCELLED
>;

export const TaskFormsContainer: FC<ITaskFormsContainer> = ({ taskId, onAction, currentTaskState }) => {
  const FORMS_MAP: Record<TTaskStateEnum, ReactNode | null> = {
    [TaskStateEnum.RESCHEDULED]: <TaskRescheduleForm taskId={taskId} onAction={onAction} />,
    [TaskStateEnum.POSTPONED]: null,
    [TaskStateEnum.CANCELLED]: <CancelTaskForm taskId={taskId} onAction={onAction} />,
  };

  return <div>{currentTaskState && FORMS_MAP[currentTaskState]}</div>;
};
