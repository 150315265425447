import { AppSubModule } from 'src/interfaces';
import { CATALOGS_PROVIDER_TOKENS } from '../constants';
import { notificationServiceProvider } from './service-providers/NotificationService.provider';

class NotificationsListModule extends AppSubModule {
  public providers = [
    {
      token: CATALOGS_PROVIDER_TOKENS.NOTIFICATION_SERVICE,
      provider: notificationServiceProvider,
    },
  ];
}

export const notificationsListModule = new NotificationsListModule();
