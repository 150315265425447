import { useCallback, useMemo, useState } from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useToggle(initialValue?: null | boolean) {
  const [enabled, setValue] = useState(!!initialValue);

  const toggle = useCallback(() => setValue(not), []);
  const enable = useCallback(() => setValue(true), []);
  const disable = useCallback(() => setValue(false), []);

  return useMemo(
    () =>
      ({
        enabled,
        toggle,
        enable,
        disable,
      } as const),
    [disable, enable, enabled, toggle],
  );
}

const not = (value: boolean) => !value;
