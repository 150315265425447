import { apolloClient, GetOrderBySerialNumber, GetOrderBySerialNumberVariables } from '../../../../services/GraphQL';
import { RequestController } from '../../../../utils/decorators/request-controller';

export interface OmsOrderBySerialNumber {
  serialNumber: number;
  customerId: string;
}

export class OmsProvider {
  @RequestController({ logger: true, debounce: true })
  public async searchOmsOrderBySerialNumber(serialNumber: number): Promise<OmsOrderBySerialNumber[]> {
    const res = await apolloClient.query<GetOrderBySerialNumber, GetOrderBySerialNumberVariables>({
      query: GetOrderBySerialNumber,
      variables: {
        orderSerialNumber: serialNumber,
      },
    });

    return res.data.ordersConnection.edges.map((e) => ({
      customerId: e.node.customerId,
      serialNumber,
    }));
  }
}
