import { ContactTypeEnum } from 'src/services/GraphQL';
import { TSelectField } from 'src/libs/ui-kit';
import { PlatformAdminApi } from 'src/services/GrpcService/codegen/app_pb';

export enum CUSTOMERS_PROVIDER_TOKENS {
  CUSTOMERS_SERVICE = 'customersService',
}

export const addressTypeOptions = [
  { id: PlatformAdminApi.ADDRESS_TYPE.DEFAULT, title: 'Создан пользователем' },
  // { id: PlatformAdminApi.ADDRESS_TYPE.BIRTH_ADDRESS, title: 'Рождение' },
  // { id: PlatformAdminApi.ADDRESS_TYPE.REG_ADDRESS, title: 'Прописка' },
  { id: PlatformAdminApi.ADDRESS_TYPE.RESIDENCE_ADDRESS, title: 'Проживание' },
];

export const BLOCKING_REASON_OPTIONS: TSelectField<PlatformAdminApi.BLOCKING_REASON_SLUG>[] = [
  {
    label: 'Любой статус',
    name: PlatformAdminApi.BLOCKING_REASON_SLUG.UNDEFINED_BLOCKING_REASON_SLUG,
  },
  {
    label: 'Отзыв разрешения на обработку перс. данных',
    name: PlatformAdminApi.BLOCKING_REASON_SLUG.REVOCATION_PERS_DATA,
  },
  {
    label: 'Окончание сотрудничества',
    name: PlatformAdminApi.BLOCKING_REASON_SLUG.END_COOPERATION,
  },
];

export const CONTACT_NAMES_BY_TYPE_MAP = {
  [ContactTypeEnum.FACEBOOK]: 'Facebook',
  [ContactTypeEnum.INSTAGRAM]: 'Instagram',
  [ContactTypeEnum.LINKED_IN]: 'LinkedIn',
  [ContactTypeEnum.OK]: 'OK',
  [ContactTypeEnum.TELEGRAM]: 'Telegram',
  [ContactTypeEnum.TWITTER]: 'Twitter',
  [ContactTypeEnum.VK]: 'VK',
} as const;
