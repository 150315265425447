import cn from 'classnames';
import React, { memo } from 'react';

import { SubHeaderPortal } from 'src/portals';
import { TicketsContentBar } from './components/TicketsContentBar';

import styles from './Tickets.styles.module.scss';
import { LayoutRightSidebarStoreProvider } from '../Layouts/LayoutRightSidebar';

export const Tickets = memo(() => {
  const isSubheaderVisible = SubHeaderPortal.useBusy();

  return (
    <LayoutRightSidebarStoreProvider>
      <div
        className={cn({
          [styles.root]: true,
          [styles.rootUnderSubheader]: isSubheaderVisible,
        })}>
        <TicketsContentBar />
      </div>
    </LayoutRightSidebarStoreProvider>
  );
});
