import { CategoryTypeEnum } from 'src/services/GraphQL';
import { AdapterFunction } from '../interfaces';
import { RequestedProblemItem } from '../../../interfaces';

type PresenterProblemItem = Pick<RequestedProblemItem, 'id' | 'title'>;

export interface PresenterProblemListItem {
  label: string;
  value: PresenterProblemItem;
  isOrder: boolean;
}

export const problemListItemAdapter: AdapterFunction<
  { problem: RequestedProblemItem; categoryType?: CategoryTypeEnum },
  PresenterProblemListItem
> = ({ problem, categoryType }) => ({
  label: problem.title,
  value: problem,
  isOrder: categoryType === CategoryTypeEnum.ORDER,
});
