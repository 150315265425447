import React, { FC, useEffect, useState } from 'react';
import { useField } from 'formik';
import { AdditionalDataField, DadataField, ManualField, TotalInfoBlock } from './components';
import { TAddress } from './address.interfaces';
import './address-field-style.scss';
import { ADDRESS_DETAILS_KEYS, PRESENCE_REGIONS } from './address-constants';

interface IAddressProps {
  name: string;
  label: string;
}

export const AddressField: FC<IAddressProps> = ({ name }: IAddressProps): JSX.Element => {
  const [{ value }, _, { setValue, setTouched }] = useField<TAddress | null>(name);
  // храним поля, которые установлены через дадату с целью их дизейбла
  const [selectedDadataFields, setSelectedDadataFields] = useState<Set<string>>(new Set());

  useEffect(() => {
    // если у объекта поле установлено через дадату, то дизейблим только непустые поля
    // если нет, то дизейблим все поля, т.к. без дадаты мы не сможем вытащить координаты
    if (value?.unrestrictedValue) {
      setSelectedDadataFields(
        new Set(Object.keys(value?.detail ?? {}).filter((key) => !!value?.detail?.[key]?.trim())),
      );
    } else {
      setSelectedDadataFields(new Set(ADDRESS_DETAILS_KEYS));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChosenAddress = (addressRel: TAddress, isDadata: boolean): void => {
    setValue(addressRel);
    setTouched(true);

    if (isDadata) {
      setSelectedDadataFields(
        new Set(Object.keys(addressRel?.detail ?? {}).filter((key) => !!addressRel?.detail?.[key]?.trim())),
      );
    }
  };

  return (
    <div className="mb-4">
      <div className="mb-2">
        <DadataField
          isReadonly={value?.isReadonly || false}
          address={value}
          name={name}
          handleChosenAddress={handleChosenAddress}
          presenceRegions={PRESENCE_REGIONS}
        />
      </div>
      <ManualField
        address={value}
        name={name}
        blockedFields={selectedDadataFields}
        handleChosenAddress={handleChosenAddress}
        isReadonly={value?.isReadonly || false}
      />
      <hr className="mt-6 mb-5" />
      <AdditionalDataField name={name} address={value} />
      {value?.value ? <TotalInfoBlock address={value} /> : null}
    </div>
  );
};
