import { IRoute } from 'src/routes';
import { lazy } from 'src/utils';

export const contractorsRoutes = (fallback: JSX.Element): IRoute[] => [
  {
    path: '/users/contractors/',
    pureComponent: lazy(() => import('./pages/contractor-list/'), fallback),
    exact: true,
    breadcrumb: 'Исполнители',
    title: 'Исполнители',
  },
  {
    path: '/users/contractors/:userId/',
    pureComponent: lazy(() => import('./pages/contractor-view'), fallback),
    exact: true,
    breadcrumb: 'Карточка исполнителя',
    title: 'Карточка исполнителя',
  },
];
