import { AppSubModule } from 'src/interfaces';
import { CATALOGS_PROVIDER_TOKENS } from '../constants';
import { presencePolygonServiceProvider } from './service-providers/PresencePolygonService.provider';

class PresencePolygonListModule extends AppSubModule {
  public providers = [
    {
      token: CATALOGS_PROVIDER_TOKENS.PRESENCE_POLYGON_SERVICE,
      provider: presencePolygonServiceProvider,
    },
  ];
}

export const presencePolygonListModule = new PresencePolygonListModule();
