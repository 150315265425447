import { PresenterTicketItem } from '../../../interfaces';
import { PresenterTicketsHistoryItem } from 'src/features/Tickets/interfaces';
import { TicketEdge, TicketsConnection } from 'src/services/GraphQL';
import { AdapterFunction } from '../interfaces';
import { ticketAdapter } from './AdaptTicket.helper';
import { DateTimeService } from 'src/services/DateTimeService';

export const ticketsHistoryAdapter: AdapterFunction<TicketsConnection | null, PresenterTicketsHistoryItem[]> = (
  requestedTicketConnection,
) => {
  const ticketsEdges: ReadonlyArray<TicketEdge> = requestedTicketConnection?.edges ?? [];
  const ticketsMap: { [key: string]: PresenterTicketItem[] } = ticketsEdges.reduce((acc, currentTicket) => {
    const ticketDate = DateTimeService.format(currentTicket.node.createdAt, 'DD.MM.YYYY');

    if (acc[ticketDate]) {
      acc[ticketDate].push(ticketAdapter(currentTicket.node));
    } else {
      acc[ticketDate] = [ticketAdapter(currentTicket.node)];
    }

    return acc;
  }, {});

  return Object.keys(ticketsMap).map((date) => ({
    date,
    tickets: ticketsMap[date],
  }));
};
