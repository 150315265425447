import { TaskStateEnum } from 'src/services/GraphQL';
import { logger } from '@qlean/front-logger';
import { makeAutoObservable } from 'mobx';

export type TCorrelationId = string;
export type TProcessingTask = Record<TCorrelationId, ITaskInfo>;
export interface ITaskInfo {
  state: TaskStateEnum;
  ticketId?: string;
  taskId?: string;
  taskTitle?: string;
  redirectionLink?: string | null;
}

export class ProcessingTasksStore {
  private processingTasks: TProcessingTask = {};

  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public addProcessingTask(correlationId: string, taskInfo: ITaskInfo): void {
    if (this.processingTasks[correlationId]) {
      logger.error('Task with this correlation id is already being processing');
      return;
    }

    this.processingTasks[correlationId] = taskInfo;
  }

  public onTaskProcessed(correlationId: string): ITaskInfo | null {
    if (!this.processingTasks[correlationId]) {
      return null;
    }

    const { [correlationId]: processedTask, ...processingTasks } = this.processingTasks;
    this.processingTasks = processingTasks;

    return processedTask;
  }

  public hasTask(correlationId: string): boolean {
    return Boolean(this.processingTasks[correlationId]);
  }
}
