import { AppSubModule } from 'src/interfaces';

import { CATALOGS_PROVIDER_TOKENS } from '../constants';
import { marketServiceProvider } from './service-providers/MarketService.provider';

class MarketListModule extends AppSubModule {
  public providers = [
    {
      token: CATALOGS_PROVIDER_TOKENS.MARKET_SERVICE,
      provider: marketServiceProvider,
    },
  ];
}

export const marketListModule = new MarketListModule();
