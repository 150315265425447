import React, { ComponentProps, memo, ReactNode, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

import { ObservableStore } from './ObservableStore';
import { useStore } from './useStore';

export namespace PortalPair {
  export interface TargetProps extends ComponentProps<'div'> {}

  export interface RenderProps {
    readonly children: ReactNode;
  }
}

export class PortalPair {
  private readonly $element = new ObservableStore<null | HTMLDivElement>(null);
  private readonly $busy = new ObservableStore(false);

  constructor(private readonly name: string) {}

  private readonly useElement = (): null | HTMLDivElement => useStore(this.$element);
  readonly useBusy = (): boolean => useStore(this.$busy);

  readonly Target = memo<PortalPair.TargetProps>((props) => {
    const ref = useRef<null | HTMLDivElement>(null);

    useEffect(() => {
      let element: null | HTMLDivElement = null;

      this.$element
        .subscribe((e) => {
          element = e;
        })
        .unsubscribe();

      if (element) throw new Error(`Портал \`${this.name}\` уже существует`);
      if (!ref.current) return;

      this.$element.next(ref.current);

      return () => this.$element.next(null);
    }, []);

    return <div {...props} ref={ref} />;
  });

  readonly Render = memo<PortalPair.RenderProps>(({ children }) => {
    const element = this.useElement();

    useEffect(() => {
      let busy = false;

      this.$busy
        .subscribe((value) => {
          busy = value;
        })
        .unsubscribe();

      if (busy) throw new Error(`Портал \`${this.name}\` уже занят`);

      this.$busy.next(true);
      return () => this.$busy.next(false);
    }, []);

    return element && createPortal(children, element);
  });
}
