import React, { memo, useCallback, useEffect, useState } from 'react';
import { DateTimeService } from 'src/services/DateTimeService';
import { TimeShortcutFields } from '../../TimeShortcutFields';
import { Form, Formik } from 'formik';
import { FieldLabel, StringField } from 'src/libs/ui-kit';
import { Button } from 'react-bootstrap';
import { LoaderButton } from 'src/libs/ui-kit/buttons/LoaderButton';
import { useTicketsRootStore } from 'src/features/Tickets/store/tickets-root-store/TicketsStoreProvider';
export interface TicketTaskCallbackFormProps {
  taskId: string;
  onAction(): void;
}

interface RescheduleFormValues {
  readonly to: null | Date;
  readonly comment: string;
}

const FORM_VALUES: RescheduleFormValues = {
  to: null,
  comment: '',
};

export const TaskRescheduleForm = memo<TicketTaskCallbackFormProps>(({ taskId, onAction }) => {
  const {
    ticketsSystemStore: { rescheduleTask },
  } = useTicketsRootStore();
  const [minDate, setMinDate] = useState<string | number | undefined>(undefined);

  const onSubmit = useCallback(
    ({ to, comment }: RescheduleFormValues) => {
      onAction();
      rescheduleTask({ id: taskId, to, comment });
    },
    [onAction],
  );

  useEffect(() => {
    const today: string = DateTimeService.today();
    const date: string = DateTimeService.format(today, 'YYYY-MM-DD');

    setMinDate(date);
  }, []);

  return (
    <Formik initialValues={FORM_VALUES} onSubmit={onSubmit}>
      <Form className="p-3">
        <FieldLabel label="Перезвон" />
        <TimeShortcutFields name="to" shortcuts={SHORTCUTS} minDate={minDate} />
        <StringField name="comment" variant="textarea" label="Комментарий" />

        <div>
          <LoaderButton type="submit" className="mr-2">
            Подтвердить
          </LoaderButton>

          <Button type="button" variant="default" onClick={onAction}>
            Отменить
          </Button>
        </div>
      </Form>
    </Formik>
  );
});

const SHORTCUTS = [
  {
    label: '5 мин.',
    value: 5,
    unit: DateTimeService.Unit.Minutes,
  },
  {
    label: '10 мин.',
    value: 10,
    unit: DateTimeService.Unit.Minutes,
  },
  {
    label: '15 мин.',
    value: 15,
    unit: DateTimeService.Unit.Minutes,
  },
  {
    label: '30 мин.',
    value: 30,
    unit: DateTimeService.Unit.Minutes,
  },
  {
    label: '1 ч.',
    value: 1,
    unit: DateTimeService.Unit.Hours,
  },
] as const;
