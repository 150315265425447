import { AppSubModule } from 'src/interfaces';

import { CATALOGS_PROVIDER_TOKENS } from '../constants';
import { managerSkillsServiceProvider } from './service-providers/ManagerSkillsService.provider';

class ManagerSkillsListModule extends AppSubModule {
  public providers = [
    {
      token: CATALOGS_PROVIDER_TOKENS.MANAGER_SKILLS_SERVICE,
      provider: managerSkillsServiceProvider,
    },
  ];
}

export const managerSkillsListModule = new ManagerSkillsListModule();
