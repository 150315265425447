import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { Form, useField } from 'formik';
import { Button } from 'react-bootstrap';
import { FieldLabel, StringField } from 'src/libs/ui-kit';
import { useRightSidebarStore } from '../../../Layouts/LayoutRightSidebar';
import CreateProblemStyles from './CreateProblem.module.scss';
import Select, { ValueType } from 'react-select';
import cn from 'classnames';
import { PresenterCategoryListItem, PresenterProblemListItem } from '../../helpers/adapters';
import ToastifyService from 'src/services/ToastifyService';
import RightSidebarEvent from '../../../Layouts/LayoutRightSidebar/EventBus/RightSidebarEvent';
import { TICKET_MODALS_EVENTS } from '../../modals-configs';
import { useLocalStorage } from 'src/hooks';
import { useTicketsRootStore } from '../../store';
import { matchPath, useParams, useRouteMatch } from 'react-router-dom';
import { CategoryTypeEnum } from 'src/services/GraphQL';
import { CreateTaskInTicketRequest } from '../../interfaces';

export const CreateProblem: FC = memo(() => {
  const {
    ticketsSystemStore: {
      createTaskInTicket,
      getProblemsCategories,
      getOrderBySerialNumber,
      clientId,
      activeTicketCLientId,
    },
  } = useTicketsRootStore();
  const [chosenProblem, setChosenProblem] = useState<PresenterProblemListItem | undefined | null>(undefined);
  const [problemsCategories, setProblemsCategories] = useState<PresenterCategoryListItem[]>([]);
  const { dismissModal } = useRightSidebarStore();
  const { managerSsoId } = useLocalStorage();
  const { orderId: orderSerialNumber } = useParams<{ orderId?: string }>();
  const { path } = useRouteMatch();
  const [isOrderUrl, setIsOrderUrl] = useState<boolean>(false);
  const [isExternal, setIsExternal] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [{ value: comment }] = useField('comment');

  useEffect(() => {
    const externalOrdersPaths: string[] = ['/users/:userId/external-orders/:orderId/'];
    const isExternalOrderUrl = externalOrdersPaths.some((pathItem) => !!matchPath(pathItem, { path, exact: true }));
    setIsExternal(isExternalOrderUrl);
  }, []);

  useEffect(() => {
    const ordersPaths: string[] = [
      '/users/:userId/orders/:orderId/',
      '/users/:userId/orders/:orderId/:ticketId',
      '/users/:userId/external-orders/:orderId/',
    ];
    const isOrderUrl = ordersPaths.some((pathItem) => !!matchPath(pathItem, { path, exact: true }));

    setIsOrderUrl(isOrderUrl);
  }, []);

  useEffect(() => {
    getProblemsCategories({})
      .then((categories: PresenterCategoryListItem[]) => {
        // Если мы не находимся на странице редактирования заказа/драфта, то категорию "Заказ" нужно скрыть из списка категорий
        if (!isOrderUrl) {
          return categories.filter((category) => category.value.type !== CategoryTypeEnum.ORDER);
        }

        return categories;
      })
      .then((categories) => {
        setProblemsCategories(categories);
      })
      .catch(() => undefined);
  }, [getProblemsCategories, isOrderUrl]);

  const dismissModalCb = useCallback(() => {
    const event: RightSidebarEvent = new RightSidebarEvent({
      scope: 'CreateProblem.tsx',
      event: TICKET_MODALS_EVENTS.CREATE_PROBLEM_CANCEL,
      method: 'dismissModalCb',
    });

    dismissModal(event);
  }, [dismissModal]);

  const onCreateTask = async (): Promise<void> => {
    setIsSubmitted(true);
    if (!chosenProblem?.value) {
      ToastifyService.toast('Не удалось создать проблему', { type: 'error' });

      return;
    }

    if (!clientId && !activeTicketCLientId) {
      ToastifyService.toast('Не указан id клиента', { type: 'error' });

      return;
    }

    try {
      const requestBody: CreateTaskInTicketRequest = {
        subCategoryId: chosenProblem.value.id,
        creatorSsoId: managerSsoId!,
        assigneeSsoId: managerSsoId!,
        clientSsoId: activeTicketCLientId,
        taskTitle: chosenProblem.label,
        comment,
      };

      // Если мы находимся на странице заказа/драфта, и выбранная проблема относится к категории "Заказ",
      // то в тело запроса необходимо добавить id заказа и id корзины
      if (isOrderUrl && chosenProblem.isOrder) {
        const { orderId, cartId } = await getOrderBySerialNumber(Number(orderSerialNumber), isExternal);
        requestBody.orderId = orderId;
        requestBody.cartId = cartId;
        requestBody.isExternal = isExternal;
        requestBody.serialNumber = orderSerialNumber;
      }

      await createTaskInTicket(requestBody);
      dismissModal();
    } catch (e) {
      ToastifyService.toast('Не удалось создать проблему', { type: 'error' });
    } finally {
      setIsSubmitted(false);
    }
  };

  const onProblemChange: (problem: ValueType<PresenterCategoryListItem>) => void = useCallback((problem) => {
    setChosenProblem(problem as PresenterProblemListItem | undefined);
  }, []);

  const formatGroupLabel = (category) => (
    <div>
      <span className={cn('font-medium-base', CreateProblemStyles.categoryGroup)}>{category.label}</span>
    </div>
  );

  const formatOptionLabel = (problem) => (
    <div>
      <span className={cn('font-regular-base', CreateProblemStyles.problemItem)}>{problem.label}</span>
    </div>
  );

  return (
    <Form>
      <div>
        <div className="mb-4">
          <FieldLabel label="Категория запроса" />
          <Select
            options={problemsCategories}
            formatGroupLabel={formatGroupLabel}
            formatOptionLabel={formatOptionLabel}
            onChange={onProblemChange}
          />
        </div>

        {!!chosenProblem && (
          <div>
            <StringField name="comment" label="Описание" variant="textarea" />

            <div>
              <Button disabled={isSubmitted} type="submit" className="mr-2" onClick={onCreateTask}>
                Создать
              </Button>

              <Button type="button" variant="default" onClick={dismissModalCb}>
                Отменить
              </Button>
            </div>
          </div>
        )}
      </div>
    </Form>
  );
});
