import { AppModule, IRoute, Menu, MENU_TYPES } from 'src/interfaces';
import { IProviderRecord } from 'src/services/ServiceLocator/ServiceLocator.interface';
import { CONTRACTORS_PROVIDER_TOKENS } from './constants';
import routes from './contractors.routes';
import { contractorsProvider } from './service-providers/Contractors.provider';

class ContractorsModule extends AppModule {
  public routes = (fallback: JSX.Element): IRoute[] => routes(fallback);

  public menu = (): Menu[] => [
    {
      type: MENU_TYPES.ROUTER,
      text: 'Исполнители',
      link: '/hrm/contractors/',
    },
  ];
  public providers: IProviderRecord[] = [
    {
      token: CONTRACTORS_PROVIDER_TOKENS.CONTRACTORS_SERVICE,
      provider: contractorsProvider,
    },
  ];
}

export const contractorsModule = new ContractorsModule();
