import { RightSidebarButtonsConfig } from './interfaces';

export enum RIGHT_SIDEBAR_BUTTON_STATES {
  PRIMARY = 'primary',
  OUTLINE = 'outline',
}

export enum SidebarButtonsIcons {
  CHECK_DOUBLE = 'check-double',
  LIST_CHECK = 'list-check',
}

export const RIGHT_SIDEBAR_BUTTONS_CONFIG: ReadonlyArray<RightSidebarButtonsConfig> = [
  {
    id: 1,
    icon: SidebarButtonsIcons.CHECK_DOUBLE,
    state: RIGHT_SIDEBAR_BUTTON_STATES.OUTLINE,
    isVisible: true,
  },
  {
    id: 2,
    icon: SidebarButtonsIcons.LIST_CHECK,
    state: RIGHT_SIDEBAR_BUTTON_STATES.OUTLINE,
    isVisible: true,
    allowedPaths: {
      includedPaths: [
        '/users/customers/:id/',
        '/users/customers/:id/:ticketId',
        '/users/orders/:id/',
        '/users/orders/:id/:ticketId',
        '/users/:userId/orders/:orderId/',
        '/users/:userId/external-orders/:orderId/',
      ],
    },
  },
];
