import * as debounceFn from 'lodash.debounce';

export const SimpleDebounce =
  (milliseconds = 0, options = {}) =>
  // eslint-disable-next-line max-params
  (target: unknown, propertyKey: string, descriptor: PropertyDescriptor): PropertyDescriptor => {
    const map = new WeakMap();
    const originalMethod = descriptor.value;
    descriptor.value = function (...params) {
      let debounced = map.get(this);
      if (!debounced) {
        debounced = debounceFn(originalMethod, milliseconds, options).bind(this);
        map.set(this, debounced);
      }
      debounced(...params);
    };

    return descriptor;
  };
