import { PresenterTaskItem } from 'src/features/Tickets/interfaces';
import { TaskFields } from 'src/services/GraphQL';
import { AdapterFunction } from '../interfaces';
import { taskExecutionHistoryAdapter } from './TaskExecutionHistoryAdapter.helper';
import { COMMENTABLE_TASK_STATUSES } from 'src/features/Tickets/constants';

export const taskAdapter: AdapterFunction<TaskFields, PresenterTaskItem> = (task: TaskFields): PresenterTaskItem => ({
  id: task.id,
  title: task.title,
  state: task.state,
  createdAt: task.createdAt,
  entity: task.entity,
  executionHistory: taskExecutionHistoryAdapter(task.executionHistory),
  // Регулярка разбивает строку в массив по лайн брейкам ('/n')
  comment: task.comment?.match(/[^\r\n]+/gu) ?? [],
  canComment: COMMENTABLE_TASK_STATUSES.includes(task.state),
  hasComments: Boolean(task.comment),
});
