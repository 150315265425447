import { GetUsersForSearchInputVariables } from 'src/services/GraphQL';
import { makeAutoObservable } from 'mobx';
import { ssoProvider, TSsoProvider } from '../../services';
import ToastifyService from 'src/services/ToastifyService';
import { UserForSearchInput } from '../../helpers/adapters';

export class SsoStore {
  private readonly ssoSystemProvider: TSsoProvider = ssoProvider;

  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  async getUsersForSearchInput(variables: GetUsersForSearchInputVariables): Promise<UserForSearchInput[]> {
    try {
      return this.ssoSystemProvider.usersListForSearchInput(variables);
    } catch (error) {
      return [];
    }
  }

  async createUserProfile(phone: string): Promise<{ id: string | null }> {
    try {
      return this.ssoSystemProvider.createUserProfileFromSearchInput(phone);
    } catch (error) {
      ToastifyService.toast('Не удалось обновить тикет', { type: 'error' });

      return { id: null };
    }
  }
}
