// TODO: Необходимо декомпозировать стор!
// 1. Создать отдельные сторы для тасок и подписок
// 2. Перенести всю логику, связанную с подписками и тасками в соответствующие сторы
// TODO: Также некоторые методы можно перенести в утилитарные функции.

import {
  CreateTaskInTicketRequest,
  FetchTicketsOptions,
  IApplyTaskInTicketRequest,
  PresenterSalesHistoryItem,
  PresenterTicketsHistoryItem,
  SagaResultPresenter,
  UpdateTaskRequest,
} from '../../interfaces';
import { makeAutoObservable } from 'mobx';
import {
  IApplyTaskPresenter,
  PresenterCancellationReasonsListItem,
  PresenterTaskItem,
  PresenterTicketConnection,
  PresenterTicketItem,
  TicketsListPaginationOptions,
} from 'src/features/Tickets/interfaces';
import {
  ApplyTaskVariables,
  CancellationReasonsVariables,
  CancelTaskVariables,
  CreateTaskInTicketMutationVariables,
  GetProblemsCategoriesVariables,
  ManagerCommunicationChannelEnum,
  PostponeTaskVariables,
  RescheduleTaskVariables,
  TaskStateEnum,
  TicketFilter,
  TicketStateEnum,
} from 'src/services/GraphQL';
import ToastifyService from 'src/services/ToastifyService';
import { DEFAULT_TICKETS_REQUEST_PARAMS } from '../../constants';
import { PresenterCategoryListItem } from '../../helpers/adapters';
import { TicketsSystemProvider, ticketsSystemProvider } from '../../services';
import { ITaskInfo, ProcessingTasksStore } from './ProcessingTasksStore';
import { Observable } from '@apollo/client';
import { logger } from '@qlean/front-logger';

export class TicketsStore {
  private readonly ticketsSystemProvider: TicketsSystemProvider = ticketsSystemProvider;
  readonly processingTasksStore: ProcessingTasksStore = new ProcessingTasksStore();
  managerCommunicationChannel: ManagerCommunicationChannelEnum | null | undefined = null;
  activeTicketCLientId: string | undefined;
  ticketsList: PresenterTicketItem[] = [];
  ticketsListCursor: string = '';
  shouldLoadMoreTickets: boolean = false;
  loadingTickets: boolean = false;
  loadingHistory: boolean = false;
  ticketsHistory: PresenterTicketsHistoryItem[] = [];
  salesHistory: PresenterSalesHistoryItem[] = [];
  hasActiveTickets: boolean = false;
  clientId: string | undefined;

  private activeTicketsFilter = {
    state: [TicketStateEnum.OPENED, TicketStateEnum.NEW],
  };

  private holdTicketsFilter = {
    state: [TicketStateEnum.HOLD],
  };

  private openedTicketsFilter = {
    state: [TicketStateEnum.OPENED],
  };

  private get ticketsMap(): Record<string, boolean> {
    return this.ticketsList.reduce((ticketsMap, currTicket) => {
      ticketsMap[currTicket.id] = true;

      return ticketsMap;
    }, {});
  }

  public get hasActiveTasks(): boolean {
    const activeTaskStatuses: Set<TaskStateEnum> = new Set([TaskStateEnum.IN_WORK]);
    return this.ticketsList.some((ticket) => ticket.tasksList.some((task) => activeTaskStatuses.has(task.state)));
  }

  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });

    this.ticketsSubscriptions();
    this.taskSubscription();
    this.onSagaFinishedSubscription();
    this.onSagaErrorSubscription();
  }

  public setActiveTicketCLientId(clientId: string | undefined, options?: { shouldReset: boolean }): void {
    const activeStatusesMap = new Map([
      [TicketStateEnum.NEW, true],
      [TicketStateEnum.OPENED, true],
    ]);

    if (options?.shouldReset) {
      this.activeTicketCLientId = undefined;

      return;
    }

    // Если айдишник активного клиента уже имеется, то не нужно ничего делать.
    if (this.activeTicketCLientId) {
      return;
    }

    const ticket: PresenterTicketItem | undefined = this.ticketsList.find(
      (ticketItem) => activeStatusesMap.has(ticketItem.state) && ticketItem.assingee.id === this.getManagerSsoId(),
    );

    this.activeTicketCLientId = ticket?.clientSsoId || clientId;
  }

  // Существует определенная стратегия запроса тикетов:
  // 1. Если мы находимся не в профиле/заказе клиента, то, если есть активные тикеты, выводим их, если нет - выводим тикеты в холде
  // 2. Если мы находимся в профиле/клиента, то тикеты в холде запрашивать не нужно, возвращаем активные тикеты, даже если пустой массив
  getTickets = (clientId: string | undefined, options: FetchTicketsOptions = {}): void => {
    this.clientId = clientId;
    const managerSsoId: string = this.getManagerSsoId();
    let filters: TicketFilter = this.getBaseTicketsFilter(managerSsoId);
    const pagination: TicketsListPaginationOptions = {
      ticketsListCusor: options?.resetCursor ? '' : this.ticketsListCursor,
      first: options?.first,
    };

    if (options?.orderSerialNumber) {
      filters = {
        ...filters,
        ticketLink: {
          serialNumber: [Number(options.orderSerialNumber)],
        },
      };
    }

    this.shouldLoadMoreTickets = false;
    this.loadingTickets = options?.loader ?? true;

    this.ticketsSystemProvider
      .getActiveTickets()
      .then((ticketConnection: PresenterTicketConnection) => {
        this.hasActiveTickets = Boolean(ticketConnection.tickets.length);

        if (!this.hasActiveTickets) {
          let newFilters: TicketFilter = {};

          if (!clientId) {
            return this.ticketsSystemProvider.getDashboardTickets();
          }

          newFilters = { ...newFilters, clientSsoId: [clientId] };

          return this.fetchTicketsOnHold(newFilters, pagination);
        }

        return ticketConnection;
      })
      .then(({ tickets, currentCursor, hasNextPage }: PresenterTicketConnection) => {
        this.updatePaginationData(currentCursor, hasNextPage);
        this.ticketsList = tickets;
        this.setActiveTicketCLientId(clientId);
      })
      .catch(() => ToastifyService.toast('Не удалось загрузить список тикетов', { type: 'error' }))
      .finally(() => (this.loadingTickets = false));
  };

  // Существует определенная стратегия запроса тикетов:
  // 1. Если мы находимся не в профиле/заказе клиента, то, если есть активные тикеты, выволим их, если нет - выводим тикеты в холде
  // 2. Если мы находимся в профиле клиента, то тикеты в холде запрашивать не нужно, возвращаем активные тикеты, даже если пкстой массив
  addTickets = (clientId: string | undefined, options?: FetchTicketsOptions) => {
    const managerSsoId: string = this.getManagerSsoId();
    const filters: TicketFilter = this.getBaseTicketsFilter(managerSsoId);
    const pagination: TicketsListPaginationOptions = {
      ticketsListCusor: options?.resetCursor ? '' : this.ticketsListCursor,
    };

    this.shouldLoadMoreTickets = false;

    this.ticketsSystemProvider
      .getActiveTickets()
      .then((ticketConnection: PresenterTicketConnection) => {
        this.hasActiveTickets = Boolean(ticketConnection.tickets.length);

        if (!this.hasActiveTickets) {
          let newFilters: TicketFilter = {};

          if (!clientId) {
            return this.ticketsSystemProvider.getDashboardTickets();
          }

          newFilters = { ...newFilters, clientSsoId: [clientId] };

          return this.fetchTicketsOnHold(newFilters, pagination);
        }

        return ticketConnection;
      })
      .then(({ tickets, currentCursor, hasNextPage }: PresenterTicketConnection) => {
        this.updatePaginationData(currentCursor, hasNextPage);
        this.addMoreTickets(tickets);
      })
      .catch(() => ToastifyService.toast('Не удалось загрузить список тикетов', { type: 'error' }));
  };

  getCancellationReasons = (params: CancellationReasonsVariables): Promise<PresenterCancellationReasonsListItem[]> =>
    this.ticketsSystemProvider.getCancellationReasons(params);

  getProblemsCategories = (params: GetProblemsCategoriesVariables): Promise<PresenterCategoryListItem[]> =>
    this.ticketsSystemProvider.getProblemsCategories(params);

  createTaskInTicket = (params: CreateTaskInTicketRequest): Promise<void> => {
    if (!this.clientId && !this.activeTicketCLientId) {
      throw 'Не указан ID клиента';
    }

    const requestParams: CreateTaskInTicketMutationVariables = {
      ...params,
      communicationChannel: this.managerCommunicationChannel,
    };

    return this.ticketsSystemProvider
      .createTaskInTicket(requestParams)
      .then((correlationId: string) => {
        this.processingTasksStore.addProcessingTask(correlationId, {
          ticketId: requestParams.ticketId,
          state: TaskStateEnum.NEW,
          taskTitle: params.taskTitle,
        });
      })
      .catch(() => ToastifyService.toast('Не удалось создать задачу', { type: 'error' }));
  };

  updateTicket = (ticketId: string): void => {
    const filters: TicketFilter = {
      id: [ticketId],
    };

    this.ticketsSystemProvider
      .getTicketById(filters)
      .then((ticket: PresenterTicketItem | void) => {
        if (ticket) {
          this.updateTicketItemInList(ticket);
        }
      })
      .catch(() => ToastifyService.toast('Не удалось обновить тикет', { type: 'error' }));
  };

  /**
   * @deprecated
   */
  applyTask = (
    params: ApplyTaskVariables,
  ): Promise<{
    entityLink: string;
  }> =>
    this.ticketsSystemProvider
      .applyTask(params)
      .then((applyPresenter: IApplyTaskPresenter) => {
        this.updateTicket(applyPresenter.entityId);

        return { entityLink: applyPresenter.entityLink };
      })
      .catch(() => ({ entityLink: '' }));

  postponeTask = (params: PostponeTaskVariables): void => {
    this.ticketsSystemProvider
      .postponeTask(params)
      .then((task: PresenterTaskItem) => this.updateTicket(task.entity!.entityId))
      .catch(() => ToastifyService.toast('Не удалось отложить задачу', { type: 'error' }));
  };

  rescheduleTask = (params: RescheduleTaskVariables): void => {
    this.ticketsSystemProvider
      .rescheduleTask(params)
      .then((task: PresenterTaskItem) => this.updateTicket(task.entity!.entityId))
      .catch(() => ToastifyService.toast('Не удалось перенести задачу', { type: 'error' }));
  };

  cancelTask = (params: CancelTaskVariables): void => {
    this.ticketsSystemProvider
      .cancelTask(params)
      .then((task: PresenterTaskItem) => this.updateTicket(task.entity!.entityId))
      .catch(() => ToastifyService.toast('Не удалось отменить задачу', { type: 'error' }));
  };

  completeWorkWithUser = (): Promise<null> => {
    const managerSsoId: string = this.getManagerSsoId();

    return this.ticketsSystemProvider
      .completeWorkWithUser({ assigneeSsoId: managerSsoId })
      .then(() => {
        ToastifyService.toast('Работа с клиентом завершена', { type: 'success' });
        this.setActiveTicketCLientId(undefined, { shouldReset: true });

        return null;
      })
      .catch((errors) => {
        let message: string = 'Не удалось завершить работу с клиетом';

        if (errors?.some((error) => error.message === 'Not all tasks are processed')) {
          message = 'Чтобы завершить работу с клиентом, необходимо обработать все задачи';
        }

        ToastifyService.toast(message, { type: 'error' });

        return Promise.reject();
      });
  };

  applyTaskInTicket = ({ assigneeSsoId, task }: IApplyTaskInTicketRequest): Promise<void> =>
    this.ticketsSystemProvider.applyTaskInTicket({ assigneeSsoId, taskId: task?.id }).then((correlationId: string) => {
      this.processingTasksStore.addProcessingTask(correlationId, {
        ticketId: task?.entity!.entityId,
        state: TaskStateEnum.IN_WORK,
        taskTitle: task?.title,
        redirectionLink: task?.redirectionLink,
      });
    });

  getOrderBySerialNumber = async (orderSerialNumber: number, isExternal: boolean) =>
    this.ticketsSystemProvider.getOrderBySerialNumber({ orderSerialNumber, isExternal });

  proceedTask = (taskId: string): void => {
    this.ticketsSystemProvider
      .proceedTask(taskId)
      .then((task: PresenterTaskItem) => this.updateTicket(task.entity!.entityId))
      .catch(() => ToastifyService.toast('Не удалось выполнить задачу', { type: 'error' }));
  };

  public getClientHistory = (clientSsoId: string, orderSerialNumber?: string): void => {
    this.loadingHistory = true;
    const serialNumber: number | undefined = orderSerialNumber ? Number(orderSerialNumber) : undefined;

    this.ticketsSystemProvider
      .getClientHistory(clientSsoId, serialNumber)
      .then(({ tickets, sales }) => {
        this.ticketsHistory = tickets;
        this.salesHistory = sales;
      })
      .catch(() => ToastifyService.toast('Не удалось загрузить историю тикетов', { type: 'error' }))
      .finally(() => {
        this.loadingHistory = false;
      });
  };

  async getManagerCommunicationChannel(): Promise<void> {
    const managerSsoId: string = this.getManagerSsoId();

    try {
      this.managerCommunicationChannel = await this.ticketsSystemProvider.getManagerCommunicationChannel(managerSsoId);
    } catch (error) {
      if (this.managerCommunicationChannel) {
        this.managerCommunicationChannel = null;
      }
    }
  }

  updateTask(params: UpdateTaskRequest): void {
    try {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      this.ticketsSystemProvider.updateTask(params);
    } catch (error) {
      ToastifyService.toast(`Не удалось обновить задачу с id ${params.id}`);
    }
  }

  ticketsSubscriptions() {
    const managerId = this.getManagerSsoId();
    this.ticketsSystemProvider.ticketsSubscription(managerId).subscribe(async (ticket: PresenterTicketItem) => {
      logger.info(TicketsStore.name, 'ticketsSubscriptions', { ticket });

      const isExistingTicket: boolean = this.ticketsList.some((ticketItem) => ticketItem.id === ticket.id);
      const activeTickets = await this.ticketsSystemProvider.getActiveTickets();
      this.hasActiveTickets = !!activeTickets.tickets.length;

      if (isExistingTicket) {
        this.updateTicketItemInList(ticket);
        return;
      }

      /**
       * @description Когда создается новый тикет, в нем может быть либо одна таска (если сага с таской успела отработать)
       * либо тикет может быть без тасок, если сага с таской не успела отработать
       */
      const isNewlyCreatedTicket: boolean = ticket.state === TicketStateEnum.NEW && ticket.tasksList.length <= 1;

      if (isNewlyCreatedTicket) {
        this.handleNewTicket();
      }
    });
  }

  taskSubscription() {
    const managerId = this.getManagerSsoId();
    this.ticketsSystemProvider.taskSubscription(managerId).subscribe((task: PresenterTaskItem) => {
      logger.info(TicketsStore.name, 'taskSubscription', { task });

      const updatableStates: TaskStateEnum[] = [TaskStateEnum.NEW, TaskStateEnum.IN_WORK, TaskStateEnum.RESCHEDULED];

      if (updatableStates.includes(task.state)) {
        this.handleNewTask(task);
      }
    });
  }

  onSagaFinishedSubscription(): Observable<ITaskInfo | null> {
    return this.ticketsSystemProvider
      .sagaFinishedSubscription()
      .filter((result: SagaResultPresenter) => result.isSuccessful)
      .map((result: SagaResultPresenter) => this.processingTasksStore.onTaskProcessed(result.correlationId!))
      .filter((taskInfo: ITaskInfo | null) => taskInfo?.state === TaskStateEnum.IN_WORK);
  }

  onSagaErrorSubscription() {
    this.ticketsSystemProvider
      .sagaFinishedSubscription()
      .filter((result: SagaResultPresenter) => !result.isSuccessful)
      .subscribe((result: SagaResultPresenter) => {
        const isOurSaga = result.correlationId && this.processingTasksStore.hasTask(result.correlationId);
        if (!result.isSuccessful && isOurSaga) {
          let message = 'Не удалось выполнить действие';
          if (result?.error?.message) {
            message += `: ${result?.error?.message}`;
          }
          ToastifyService.toast(message, { type: 'error' });
        }

        logger.error('Saga failed', { result });
      });
  }

  public async onQueueTaskSuccess(): Promise<ITaskInfo | undefined> {
    const { tickets } = await this.fetchOpenedTickets();

    // Когда берут задачу из очереди, может быть только один тикет в статусе OPENED.
    if (tickets.length === 1) {
      const [ticket] = tickets;

      return {
        state: TaskStateEnum.NEW,
        redirectionLink: ticket.redirectionLink,
      };
    }
  }

  resetTickets() {
    this.ticketsList = [];
    this.ticketsListCursor = '';
    this.shouldLoadMoreTickets = false;
  }

  getManagerSsoId = (): string => localStorage.getItem('user_id') ?? '';

  /**
   * @deprecated Temporally unused method until pagination is implemented at the backend.
   */
  private fetchActiveTickets = (
    filters: TicketFilter,
    pagination: TicketsListPaginationOptions,
  ): Promise<PresenterTicketConnection> =>
    this.ticketsSystemProvider.getTickets(
      {
        ...filters,
        ...this.activeTicketsFilter,
      },
      pagination,
    );

  private fetchOpenedTickets = (): Promise<PresenterTicketConnection> => {
    const managerSsoId: string = this.getManagerSsoId();
    const filters: TicketFilter = this.getBaseTicketsFilter(managerSsoId);
    return this.ticketsSystemProvider.getTickets({ ...filters, ...this.openedTicketsFilter });
  };

  private fetchTicketsOnHold = (
    filters: TicketFilter,
    pagination: TicketsListPaginationOptions,
  ): Promise<PresenterTicketConnection> =>
    this.ticketsSystemProvider.getTickets(
      {
        ...filters,
        ...this.holdTicketsFilter,
      },
      pagination,
    );

  private updateTicketItemInList = (ticket: PresenterTicketItem): void => {
    this.ticketsList = this.ticketsList.map((ticketItem: PresenterTicketItem) => {
      if (ticketItem.id === ticket.id) {
        return ticket;
      }

      return ticketItem;
    });
  };

  private updatePaginationData(cursor: string, shouldLoadMore: boolean) {
    this.ticketsListCursor = cursor;
    this.shouldLoadMoreTickets = shouldLoadMore;
  }

  private addMoreTickets = (tickets: PresenterTicketItem[]): void => {
    const newTickets = tickets.reduce((acc: PresenterTicketItem[], curr: PresenterTicketItem) => {
      if (!this.ticketsMap[curr.id]) {
        acc.push(curr);
      }

      return acc;
    }, []);

    this.ticketsList = [...this.ticketsList, ...newTickets];
  };

  private getBaseTicketsFilter = (managerSsoId: string): TicketFilter => ({
    assigneeSsoId: [managerSsoId],
  });

  private newTicketWillBeVisible(hasMoreTickets: boolean): boolean {
    const ticketsLength: number = this.ticketsList.length;
    const canInsertBeforeCursor: boolean = ticketsLength
      ? ticketsLength % DEFAULT_TICKETS_REQUEST_PARAMS.amount > 0
      : true;

    return canInsertBeforeCursor && !hasMoreTickets;
  }

  private handleNewTicket(): void {
    const newTiketWillBeVisible: boolean = this.newTicketWillBeVisible(this.shouldLoadMoreTickets);
    const ticketsLengthWithNewTicket = this.ticketsList.length + 1;

    if (newTiketWillBeVisible) {
      this.getTickets(this.activeTicketCLientId, {
        first: ticketsLengthWithNewTicket,
        resetCursor: true,
        loader: false,
      });
    }
  }

  private handleNewTask(task: PresenterTaskItem): void {
    const ticketExists: boolean = this.ticketsList.some(
      (ticketItem: PresenterTicketItem) => ticketItem.id === task.entity?.entityId,
    );

    if (ticketExists) {
      this.updateTicket(task.entity!.entityId);
    }
  }
}
