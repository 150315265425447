import React, { ComponentType, Fragment, memo, ReactNode, useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import cn from 'classnames';

import styles from './styles.module.scss';
import { breadcrumbsStore } from './BreadcrumbsStore';

export interface BreadcrumbsProps {
  fallback: ComponentType;
}

export const Breadcrumbs = observer(({ fallback: Fallback }: BreadcrumbsProps) => {
  const { links, useFallback } = breadcrumbsStore;

  const items = useMemo(() => {
    let pathCache = '';

    return links.map<ReactNode>((link, i) => {
      const prevPath = pathCache === '/' ? '' : pathCache;
      const path = pathCache && link.pathSegment ? `${prevPath}/${link.pathSegment}` : link.pathSegment || '';
      pathCache = path;

      const showDelimiter = i > 0;
      const useLink = !!link.pathSegment;

      return (
        <Fragment key={path}>
          {showDelimiter && <i className={cn('fa-solid fa-angle-right', styles.delimiter)} />}

          {useLink ? (
            <Link to={path} className={cn('font-regular-14', styles.link)}>
              {link.label}
            </Link>
          ) : (
            <span className={cn('font-regular-14', styles.segment)}>{link.label}</span>
          )}
        </Fragment>
      );
    });
  }, [links]);

  return useFallback ? <Fallback /> : <nav className={styles.root}>{items}</nav>;
});
