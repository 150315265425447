import { AppSubModule } from 'src/interfaces';
import { CATALOGS_PROVIDER_TOKENS } from '../constants';
import { presenceAreaServiceProvider } from './service-providers/PresenceAreaService.provider';

class PresenceAreaListModule extends AppSubModule {
  public providers = [
    {
      token: CATALOGS_PROVIDER_TOKENS.PRESENCE_AREA_SERVICE,
      provider: presenceAreaServiceProvider,
    },
  ];
}

export const presenceAreaListModule = new PresenceAreaListModule();
