import React from 'react';
import { observer } from 'mobx-react';
import { RouteChildrenProps } from 'react-router';
import { NavLink } from 'react-router-dom';
import { Navbar, Nav } from 'react-bootstrap';
import cn from 'classnames';

import AppStore from 'src/store/AppStore';
import AuthorizeStore from 'src/store/AuthorizeStore';

import layoutHelpers from './helpers';
import { UserMenu } from '../../components/UserMenu';
import { Breadcrumbs } from 'src/libs/ui-kit/breadcrumbs';
import { Breadcrumbs as OldBreadcrumbs } from 'src/components/Breadcrumbs';

import styles from './LayoutHeader.styles.module.scss';

interface Props {
  navbarBg: string;
  sidenavToggle?: boolean;
}

@observer
export default class LayoutHeader extends React.Component<Props & RouteChildrenProps> {
  isRTL: boolean = document.documentElement.getAttribute('dir') === 'rtl';

  private toggleSidenav(e) {
    e.preventDefault();
    layoutHelpers.toggleCollapsed();
  }

  private onLogout = () => {
    const { signOut, loginRoute } = AppStore;
    signOut();
    this.props.history.push(loginRoute);
  };

  private renderUserInfo = () => {
    const { profileRoute, isLogged } = AppStore;
    const { userProfile } = AuthorizeStore;

    if (!isLogged || !userProfile) {
      return null;
    }

    const { email } = userProfile;

    return <UserMenu isRTL={this.isRTL} login={email} profileRoute={profileRoute} onLogout={this.onLogout} />;
  };

  render() {
    const { brandLogo, brandName } = AppStore;

    return (
      <div>
        <Navbar expand="lg" className={cn('layout-navbar', 'align-items-lg-center', 'container-p-x', styles.navBar)}>
          {/* Brand demo (see src/demo.css) */}
          <Navbar.Brand as={NavLink} to="/" className="app-brand d-lg-none py-0 mr-4">
            <img src={brandLogo} alt="brand" className="app-brand-logo bg-primary" />
            <span className="app-brand-text sidenav-text font-weight-normal ml-2">{brandName}</span>
          </Navbar.Brand>

          {/* Sidenav toggle (see src/demo.css) */}
          {layoutHelpers.isCollapsed() && (
            <Nav className="layout-sidenav-toggle d-lg-none align-items-lg-center mr-auto">
              <Nav.Item as="a" className="nav-link px-0 mr-lg-4" href="#toggle" onClick={this.toggleSidenav}>
                <i className="ion ion-md-menu text-large align-middle" />
              </Nav.Item>
            </Nav>
          )}

          {/* Navbar toggle */}
          <Navbar.Toggle />

          <Navbar.Collapse>
            <Breadcrumbs fallback={OldBreadcrumbs} />

            <Nav className="align-items-lg-center ml-auto">
              {/* Divider */}
              <div className="nav-item d-none d-lg-block text-big font-weight-light line-height-1 opacity-25 mr-3 ml-1">
                |
              </div>
              {this.renderUserInfo()}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    );
  }
}
