export class ValidationErrors {
  public static required(): string {
    return 'Поле обязательно для заполнения';
  }

  public static isPositive(): string {
    return 'Значение должно быть положительным числом';
  }

  public static isInteger(): string {
    return 'Значение должно быть целым числом';
  }

  public static min(minValue: number): string {
    return `Минимальное значение поля - ${minValue}`;
  }

  public static max(maxValue: number): string {
    return `Максимальное значение поля - ${maxValue}`;
  }
}
