import GrpcService, { PlatformAdminApi } from 'src/services/GrpcService';

import {
  ICreate,
  ISearch,
  IUpdate,
  IRestore,
  IFindById,
  IRemove,
} from 'src/services/ServiceLocator/ServiceLocator.interface';
import { RequestController } from 'src/utils/decorators/request-controller';

class BlockingReasonGroupServiceProvider implements ISearch, ICreate, IUpdate, IRestore, IFindById, IRemove {
  @RequestController({ logger: true, retry: true, debounce: true })
  search(
    params: PlatformAdminApi.IBlockingReasonGroupSearchRequest,
  ): Promise<PlatformAdminApi.BlockingReasonGroupSearchResponse> {
    return GrpcService.BlockingReasonGroupService.Search(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  create(
    params: PlatformAdminApi.IBlockingReasonGroupCreateRequest,
  ): Promise<PlatformAdminApi.BlockingReasonGroupResponse> {
    return GrpcService.BlockingReasonGroupService.Create(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  update(
    params: PlatformAdminApi.IBlockingReasonGroupUpdateRequest,
  ): Promise<PlatformAdminApi.BlockingReasonGroupResponse> {
    return GrpcService.BlockingReasonGroupService.Update(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  restore(params: PlatformAdminApi.IUuidRequest): Promise<PlatformAdminApi.BlockingReasonGroupResponse> {
    return GrpcService.BlockingReasonGroupService.Restore(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  remove(params: PlatformAdminApi.IUuidRequest): Promise<PlatformAdminApi.BlockingReasonGroupResponse> {
    return GrpcService.BlockingReasonGroupService.Remove(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  findById(params: PlatformAdminApi.IUuidRequest): Promise<PlatformAdminApi.BlockingReasonGroupResponse> {
    return GrpcService.BlockingReasonGroupService.FindById(params);
  }
}

export const blockingReasonGroupServiceProvider = new BlockingReasonGroupServiceProvider();
export type TBlockingReasonGroupServiceProvider = typeof blockingReasonGroupServiceProvider;
