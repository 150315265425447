import { ADDRESS_STATUSES, IAddressFeedbackObject, IDadataObject } from '../address.interfaces';
import { ADDRESS_FEEDBACKS } from '../address-constants';

class AddressService {
  static getLocationPrecision(dadata: IDadataObject | undefined): IAddressFeedbackObject {
    if (!dadata) {
      return ADDRESS_FEEDBACKS[ADDRESS_STATUSES.DANGER];
    }

    const hasStreet = Boolean(dadata.street);
    const hasHouse = Boolean(dadata.house);
    const hasFlat = Boolean(dadata.flat);

    if (!hasStreet) return ADDRESS_FEEDBACKS[ADDRESS_STATUSES.DANGER];
    if (!hasHouse) return ADDRESS_FEEDBACKS[ADDRESS_STATUSES.NEED_HOUSE];
    if (!hasFlat) return ADDRESS_FEEDBACKS[ADDRESS_STATUSES.NEED_FLAT];

    return ADDRESS_FEEDBACKS[ADDRESS_STATUSES.SUCCESS];
  }

  static parseDadataObject(stringObject: string | undefined): IDadataObject | undefined {
    if (!stringObject) return undefined;

    // eslint-disable-next-line @typescript-eslint/init-declarations
    let parsedObject: IDadataObject | undefined;

    try {
      parsedObject = JSON.parse(stringObject);
    } catch (e) {
      parsedObject = undefined;
    }

    return parsedObject;
  }
}

export default AddressService;
