import { PresenterSalesHistoryItem, PresenterSalesItem, PresenterSalesOrderInformation } from '../../../interfaces';
import { TaskEdge, TasksConnection } from 'src/services/GraphQL';
import { AdapterFunction } from '../interfaces';
import { DateTimeService } from 'src/services/DateTimeService';
import { PlatformAdminApi } from 'src/services/GrpcService';
import { keyBy } from 'lodash';
import { formatDuration } from '../../../../../utils';
import { taskExecutionHistoryAdapter } from './TaskExecutionHistoryAdapter.helper';

export const salesHistoryAdapter: AdapterFunction<
  TasksConnection | null,
  PresenterSalesHistoryItem[],
  {
    prospects: PlatformAdminApi.IProspect[];
  }
> = (requestedTaskConnection, options) => {
  if (!options?.prospects) {
    throw new Error('prospects is required');
  }

  const tasksEdges: ReadonlyArray<TaskEdge> = requestedTaskConnection?.edges ?? [];
  const prospectMap = keyBy(options.prospects, (prospect) => prospect.id);
  const tasksMap: {
    [key: string]: PresenterSalesItem[];
  } = tasksEdges
    .slice()
    .sort((a, b) => DateTimeService.difference(b.node.createdAt, a.node.createdAt))
    .reduce((acc, currentTicket) => {
      const taskDate = DateTimeService.format(currentTicket.node.createdAt, 'DD.MM.YYYY');

      const prospect = prospectMap[currentTicket.node.entity!.entityId] as PlatformAdminApi.IProspect;
      let orderInformation: PresenterSalesOrderInformation | undefined = undefined;

      if (prospect) {
        orderInformation = {
          totalDiscount: `${
            ((prospect.price?.subTotalPrice ?? 0) -
              (prospect.price?.totalPrice ?? 0) -
              (prospect.price?.bonusesUsed ?? 0)) /
            100
          } ₽`,
          usedBonuses: `${(prospect.price?.bonusesUsed ?? 0) / 100}`,
          subTotalPrice: `${(prospect.price?.subTotalPrice ?? 0) / 100} ₽`,
          totalDuration: formatDuration(prospect.price?.totalDuration),
          totalPrice: `${(prospect.price?.totalPrice ?? 0) / 100} ₽`,
        };
      }

      const { id, title, state, skill, executionHistory, createdAt } = currentTicket.node;

      const saleItem: PresenterSalesItem = {
        id,
        createdAt,
        title,
        state,
        orderInformation,
        skill: skill?.name,
        executionHistory: taskExecutionHistoryAdapter(executionHistory),
      };

      if (acc[taskDate]) {
        acc[taskDate].push(saleItem);
      } else {
        acc[taskDate] = [saleItem];
      }

      return acc;
    }, {});

  return Object.keys(tasksMap).map((date) => ({
    date,
    sales: tasksMap[date],
  }));
};
