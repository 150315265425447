import { AppSubModule } from 'src/interfaces';
import { CATALOGS_PROVIDER_TOKENS } from '../constants';
import { positionServiceProvider } from './service-providers/PositionService.provider';

class PositionListModule extends AppSubModule {
  public providers = [
    {
      token: CATALOGS_PROVIDER_TOKENS.POSITION_SERVICE,
      provider: positionServiceProvider,
    },
  ];
}

export const positionListModule = new PositionListModule();
