import { AppModule, IRoute, Menu, MENU_TYPES } from '../../../interfaces';
import routes from './wages.routes';
import { WAGES_PROVIDER_TOKENS } from './constants';
import { wagesProvider } from './service-providers';
import { IProviderRecord } from 'src/services/ServiceLocator/ServiceLocator.interface';

class WagesModule extends AppModule {
  public routes = (fallback: JSX.Element): IRoute[] => routes(fallback);

  public menu = (): Menu[] => [
    {
      type: MENU_TYPES.ROUTER,
      text: 'Тарифы',
      link: '/hrm/wages/',
    },
  ];
  public providers: IProviderRecord[] = [
    {
      token: WAGES_PROVIDER_TOKENS.WAGES_SERVICE,
      provider: wagesProvider,
    },
  ];
}

export const wagesModule = new WagesModule();
