import GrpcService, { PlatformAdminApi } from 'src/services/GrpcService';

import {
  ICreate,
  ISearch,
  IUpdate,
  IRestore,
  IFindById,
  IRemove,
} from 'src/services/ServiceLocator/ServiceLocator.interface';
import { RequestController } from 'src/utils/decorators/request-controller';

class TenantServiceProvider implements ISearch, ICreate, IUpdate, IRestore, IFindById, IRemove {
  @RequestController({ logger: true, retry: true, debounce: true })
  search(params: PlatformAdminApi.ITenantSearchRequest): Promise<PlatformAdminApi.TenantSearchResponse> {
    return GrpcService.TenantService.Search(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  create(params: PlatformAdminApi.ITenantCreateRequest): Promise<PlatformAdminApi.TenantResponse> {
    return GrpcService.TenantService.Create(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  update(params: PlatformAdminApi.ITenantUpdateRequest): Promise<PlatformAdminApi.TenantResponse> {
    return GrpcService.TenantService.Update(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  restore(params: PlatformAdminApi.IUuidRequest): Promise<PlatformAdminApi.TenantResponse> {
    return GrpcService.TenantService.Restore(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  remove(params: PlatformAdminApi.IUuidRequest): Promise<PlatformAdminApi.TenantResponse> {
    return GrpcService.TenantService.Remove(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  findById(params: PlatformAdminApi.IUuidRequest): Promise<PlatformAdminApi.TenantResponse> {
    return GrpcService.TenantService.FindById(params);
  }
}

export const tenantServiceProvider = new TenantServiceProvider();
export type TTenantServiceProvider = typeof tenantServiceProvider;
