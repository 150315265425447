import React, { FC, useCallback } from 'react';
import styles from '../styles.module.scss';
import { Badge, Card } from 'react-bootstrap';
import cn from 'classnames';
import { Icon, IconButton, Tabs } from 'src/libs/ui-kit';
import { Link } from 'react-router-dom';
import { PresenterTicketItem } from '../../../interfaces';
import { useToggle } from 'src/utils';

interface ITicketContentProps {
  ticket: PresenterTicketItem;
}

export const TicketContent: FC<ITicketContentProps> = ({ ticket }) => {
  const expand = useToggle();
  const getLabel = useCallback((ticket) => ticket.number, []);

  return (
    <Card>
      <div className={cn('pt-2', styles.section)}>
        <Tabs tabs={[ticket]} value={ticket} getLabel={getLabel} onTabClick={() => null} />
      </div>
      <div className={cn('pt-2', 'pb-2', styles.section)}>
        <div className={cn(styles.statusRow, 'mb-1')}>
          <div className={styles.statusCell}>
            {ticket.badgeType && ticket.badgeText && <Badge variant={ticket.badgeType}>{ticket.badgeText}</Badge>}
          </div>

          <div className={styles.createdAt}>{ticket.createdAt}</div>
        </div>

        <div className="mb-1">
          {/* {ticket.relatedEntity?.__typename === 'Cart' && (*/}
          {/*  <>*/}
          {/*    Корзина{' '}*/}
          {/*    <Link to={`/users/orders/${ticket.relatedEntity.orders[0].serialNumber}`}>*/}
          {/*      {ticket.relatedEntity.serialNumber}*/}
          {/*    </Link>*/}
          {/*  </>*/}
          {/* )}*/}
          {/* {ticket.relatedEntity?.__typename === 'Order' && (*/}
          {/*  <>*/}
          {/*    Заказ{' '}*/}
          {/*    <Link to={`/users/orders/${ticket.relatedEntity.serialNumber}`}>*/}
          {/*      {ticket.relatedEntity.serialNumber}*/}
          {/*    </Link>*/}
          {/*  </>*/}
          {/* )}{' '}*/}
          {/* · {ticket.description}*/}
        </div>

        {!expand.enabled && (
          <div>
            <button className={styles.infoButton} type="button" onClick={expand.enable}>
              <Icon name="chevron-down" className={styles.icon} /> Вся информация
            </button>
          </div>
        )}

        {expand.enabled && (
          <>
            {/* <div className="mb-1">
              <Icon name="star" className={styles.icon} /> Ответственный:
              <Link to={`/users/${ticket.executionOfficer.id}`}>
                {ticket.executionOfficer.department.name}, {ticket.executionOfficer.name}
              </Link>
            </div> */}

            <div className="mb-1">
              <Icon name="turn-up" className={cn(styles.icon, styles.icon90deg)} /> Исполнитель:&nbsp;
              {ticket.assingee.fullName ? <span>{ticket.assingee.fullName}</span> : 'ФИО исполнителя не указано'}
            </div>

            <div className="mb-1">
              <Icon name="user" className={styles.icon} /> Пользователь:&nbsp;
              {ticket.client.fullName ? (
                <Link to={`/users/customers/${ticket.client.id}`}>{ticket.client.fullName}</Link>
              ) : (
                <Link to={`/users/customers/${ticket.client.id}`}>ФИО клиента не указано</Link>
              )}
            </div>

            {/* <div className="mb-1">
              <Icon name="hashtag" className={styles.icon} /> Тикет <Link to="/???">{ticket.serialNumber}</Link>
            </div> */}

            <div>
              <button className={styles.infoButton} type="button" onClick={expand.disable}>
                <Icon name="chevron-up" className={styles.icon} /> Свернуть
              </button>
            </div>
          </>
        )}
      </div>

      <div className={cn('pt-2', 'pb-2', styles.section)}>
        <div className={styles.buttons}>
          <IconButton icon="xmark" disabled size="sm" />
        </div>

        {/* {forwardingForm.enabled && <ForwardTicketForm onClose={forwardingForm.disable} />}*/}
        {/* {newTaskForm.enabled && <NewTaskForm onClose={newTaskForm.disable} />}*/}
      </div>
    </Card>
  );
};
