import { AppSubModule } from 'src/interfaces';
import { CATALOGS_PROVIDER_TOKENS } from '../constants';
import { blockingReasonGroupServiceProvider } from './service-providers/BlockingReasonGroupService.provider';

class BlockingReasonGroupListModule extends AppSubModule {
  public providers = [
    { token: CATALOGS_PROVIDER_TOKENS.BLOCKING_REASON_GROUP_SERVICE, provider: blockingReasonGroupServiceProvider },
  ];
}

export const blockingReasonGroupListModule = new BlockingReasonGroupListModule();
