import GrpcService, { PlatformAdminApi } from 'src/services/GrpcService';

import {
  ICreate,
  ISearch,
  IUpdate,
  IRestore,
  IFindById,
  IRemove,
} from 'src/services/ServiceLocator/ServiceLocator.interface';
import { RequestController } from 'src/utils/decorators/request-controller';

class BlockingReasonsServiceProvider implements ISearch, ICreate, IUpdate, IRestore, IFindById, IRemove {
  @RequestController({ logger: true, retry: true, debounce: true })
  search(
    params: PlatformAdminApi.IBlockingReasonSearchRequest,
  ): Promise<PlatformAdminApi.BlockingReasonSearchResponse> {
    return GrpcService.BlockingReasonsService.Search(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  create(params: PlatformAdminApi.IBlockingReasonCreateRequest): Promise<PlatformAdminApi.BlockingReasonResponse> {
    return GrpcService.BlockingReasonsService.Create(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  update(params: PlatformAdminApi.IBlockingReasonUpdateRequest): Promise<PlatformAdminApi.BlockingReasonResponse> {
    return GrpcService.BlockingReasonsService.Update(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  restore(params: PlatformAdminApi.IUuidRequest): Promise<PlatformAdminApi.BlockingReasonResponse> {
    return GrpcService.BlockingReasonsService.Restore(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  remove(params: PlatformAdminApi.IUuidRequest): Promise<PlatformAdminApi.BlockingReasonResponse> {
    return GrpcService.BlockingReasonsService.Remove(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  findById(params: PlatformAdminApi.IUuidRequest): Promise<PlatformAdminApi.BlockingReasonResponse> {
    return GrpcService.BlockingReasonsService.FindById(params);
  }
}

export const blockingReasonsServiceProvider = new BlockingReasonsServiceProvider();
export type TBlockingReasonsServiceProvider = typeof blockingReasonsServiceProvider;
