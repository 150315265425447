import React, { FC } from 'react';
import { FIELD_FEEDBACK_TYPES, IFieldFeedbackProps } from './FieldFeedback.interfaces';
import './FieldFeedback.scss';

const ICONS_MAP = {
  [FIELD_FEEDBACK_TYPES.DANGER]: 'fa-solid fa-circle-exclamation',
  [FIELD_FEEDBACK_TYPES.SUCCESS]: 'fa-solid fa-circle-check',
  [FIELD_FEEDBACK_TYPES.WARNING]: 'fa-solid fa-triangle-exclamation',
  [FIELD_FEEDBACK_TYPES.DEFAULT]: 'fa-solid fa-circle-info',
};

export const FieldFeedback: FC<IFieldFeedbackProps> = ({ type, text }): JSX.Element => (
  <div className={`field-feedback-${type} d-flex align-items-center`}>
    <i className={`${ICONS_MAP[type]} mr-2`} /> <span>{text}</span>
  </div>
);
