import React, { ChangeEvent, FC, useState, useRef } from 'react';
import { observer } from 'mobx-react';
import { Dropdown, Form, Spinner } from 'react-bootstrap';

import { FieldLabel, FieldError } from '../../common';

import { ISearchFieldProps, TSearchFieldState } from './SearchField.interface';
import './SearchField.styles.scss';
import { useField } from 'formik';

export const SearchField: FC<ISearchFieldProps> = observer((props: ISearchFieldProps): JSX.Element => {
  const { className, onSearch, onChange, label = '', name = '', placeholder } = props;
  const [, { touched, error }, { setError }] = useField({ name });
  const [state, setState] = useState<TSearchFieldState>({
    status: 'done',
    data: [],
    menuShow: false,
  });
  const input = useRef<HTMLInputElement | null>(null);
  const executeSearch = (search?: string) => {
    if (state.status !== 'pending') {
      setState({ status: 'pending', data: [], menuShow: false });
    }

    onSearch(search)
      .then((result) => setState({ status: 'done', data: result, menuShow: result.length > 0 }))
      .catch((error) => setState({ status: 'error', error, menuShow: false }));
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    executeSearch(e.target && e.target.value);
  };

  const handleBlur = (e) => {
    if (!e.currentTarget.contains(e.relatedTarget) && state.menuShow) {
      setState({ ...state, menuShow: false });
    }
  };
  const handleClick = (el) => {
    if (typeof onChange === 'function') {
      input?.current?.focus();
      onChange(el);
    }
  };

  if (state.status === 'error' && state.error) {
    setError(typeof state.error === 'string' ? state.error : state.error.message);
  }
  const items = Array.isArray(state.data) ? state.data || [] : [];

  return (
    <Form.Group id={name} tabIndex={0} className={`field search-field ${className}`} onBlur={handleBlur}>
      <FieldLabel label={label} />
      <Form.Control
        ref={input}
        isInvalid={!!error}
        {...input}
        onFocus={() => !state.menuShow && state.data?.length && setState({ ...state, menuShow: true })}
        onChange={handleChange}
        placeholder={placeholder}
      />
      {state.status === 'pending' && (
        <Spinner animation="border" size="sm" variant="primary" className="search-field__spinner" />
      )}
      <Dropdown.Menu show={state.menuShow} className="search-field__menu" onBlur={handleBlur}>
        {items.map((item) => (
          <Dropdown.Item key={item[props.optionLabelKey]} onClick={() => handleClick(item)}>
            {item[props.optionValueKey]} <span className="text-muted">({item[props.optionLabelKey]})</span>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>

      {error && touched && <FieldError error={error} />}
    </Form.Group>
  );
});
