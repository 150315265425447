import { RequestController } from 'src/utils/decorators/request-controller';
import { GraphQLError } from 'graphql/error';
import { RefreshToken, RefreshTokenInput } from './generated';
import {
  ApolloClient,
  ApolloLink,
  FetchResult,
  from,
  HttpLink,
  NormalizedCacheObject,
  Operation,
  split,
} from '@apollo/client';
import { ErrorResponse, onError } from '@apollo/client/link/error';
import { logger } from '@qlean/front-logger';
import { graphQLErrorHandler } from '@qlean/front-sentry';
import { Observable, SubscriptionObserver } from 'zen-observable-ts';
import { cache } from './cache';
import AuthorizeStore from 'src/store/AuthorizeStore';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { getMainDefinition } from '@apollo/client/utilities';

declare const REACT_APP_GRAPHQL_TICKET_URL: string;
declare const REACT_APP_GRAPHQL_TICKET_SUBSCRIPTIONS: string;

export class GraphQLClient {
  private static apolloClientInstance: ApolloClient<NormalizedCacheObject>;

  static getApolloClient(): ApolloClient<NormalizedCacheObject> {
    if (!this.apolloClientInstance) {
      const httpLink: HttpLink = new HttpLink({ uri: REACT_APP_GRAPHQL_TICKET_URL });
      const authMiddleware: ApolloLink = new ApolloLink((operation, forward) => {
        this.setHeaders(operation);

        return forward(operation);
      });

      const errorLink: ApolloLink = onError(this.errorHandler);
      const sentryLink: ApolloLink = onError(graphQLErrorHandler);
      const wsLink = new GraphQLWsLink(
        createClient({
          url: REACT_APP_GRAPHQL_TICKET_SUBSCRIPTIONS,
        }),
      );

      const splitLink = split(
        ({ query }) => {
          const definition = getMainDefinition(query);
          return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
        },
        wsLink,
        httpLink,
      );

      this.apolloClientInstance = new ApolloClient({
        cache,
        link: from([authMiddleware, errorLink, sentryLink, splitLink]),
        defaultOptions: {
          query: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'all',
          },
          mutate: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'all',
          },
        },
      });

      return this.apolloClientInstance;
    }
    return this.apolloClientInstance;
  }

  /**
   * @summary Конструктор нужен только для того, чтобы сделать его приватным.
   * @description Это позволит не допустить возможность создания нового экземпляра через new GraphQLClient снаружи класса.
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  private static promiseToObservable = <T>(promise: Promise<T>) =>
    new Observable((subscriber: SubscriptionObserver<T>) => {
      promise.then(
        (value: T) => {
          if (subscriber.closed) return;
          subscriber.next(value);
          subscriber.complete();
        },
        (err) => subscriber.error(err),
      );
    });

  @RequestController({ logger: true })
  private static async refreshToken(): Promise<FetchResult<RefreshToken> | void> {
    const token = localStorage.getItem('refreshToken');

    try {
      const data: FetchResult<RefreshToken> = await this.apolloClientInstance.mutate<RefreshToken, RefreshTokenInput>({
        mutation: RefreshToken,
        variables: { refreshToken: token! },
      });

      return data;
    } catch (error) {
      AuthorizeStore.removeAuthToken();
      window.location.href = '/login';
    }
  }

  private static setHeaders = (operation: Operation) => {
    const { accessToken } = AuthorizeStore.getTokens();

    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        authorization: accessToken ? `JWT ${accessToken}` : '',
      },
    }));
  };

  private static errorHandler = (error: ErrorResponse) => {
    if (error?.graphQLErrors) {
      const hasExpired = error.graphQLErrors?.some((err) => err.message.includes('Provided JWT is expired'));
      const tokenNotVerified = error.graphQLErrors?.some((err) => err.message.includes('JWT was not verified'));

      if (hasExpired || tokenNotVerified) {
        return this.promiseToObservable<FetchResult<RefreshToken> | void>(this.refreshToken()).flatMap((response) => {
          if (!response?.data) {
            throw new GraphQLError('Refresh token error');
          }

          // В AuthorizeStore также нужно обновить токены, так как их использует GRPC
          AuthorizeStore.setTokens(response.data.refreshToken.accessToken, response.data.refreshToken.refreshToken);
          this.setHeaders(error.operation);

          return error.forward(error.operation);
        });
      }

      error.graphQLErrors.forEach((err) => logger.error(`[GraphQL error]: Message:`, JSON.stringify(err)));
    }

    if (error.networkError) logger.error(`[Network error]: ${error.networkError}`);
  };
}

export const apolloClient: ApolloClient<NormalizedCacheObject> = GraphQLClient.getApolloClient();
export type TApolloClient = typeof apolloClient;
