import GrpcService, { PlatformAdminApi } from 'src/services/GrpcService';

import {
  ICreate,
  ISearch,
  IUpdate,
  IRestore,
  IFindById,
  IRemove,
} from 'src/services/ServiceLocator/ServiceLocator.interface';
import { RequestController } from 'src/utils/decorators/request-controller';

class TagsServiceProvider implements ISearch, ICreate, IUpdate, IRestore, IFindById, IRemove {
  @RequestController({ logger: true, retry: true, debounce: true })
  search(params: PlatformAdminApi.ISearchTagRequest): Promise<PlatformAdminApi.SearchTagResponse> {
    return GrpcService.TagService.Search(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  create(params: PlatformAdminApi.ICreateTagRequest): Promise<PlatformAdminApi.ITagResponse> {
    return GrpcService.TagService.Create(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  update(params: PlatformAdminApi.IUpdateTagRequest): Promise<PlatformAdminApi.ITagResponse> {
    return GrpcService.TagService.Update(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  restore(params: PlatformAdminApi.IUuidRequest): Promise<PlatformAdminApi.ITagResponse> {
    return GrpcService.TagService.Restore(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  remove(params: PlatformAdminApi.IUuidRequest): Promise<PlatformAdminApi.ITagResponse> {
    return GrpcService.TagService.Remove(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  findById(params: PlatformAdminApi.IUuidRequest): Promise<PlatformAdminApi.ITagResponse> {
    return GrpcService.TagService.FindById(params);
  }
}

export const tagsServiceProvider = new TagsServiceProvider();
export type TTagsServiceProvider = typeof tagsServiceProvider;
