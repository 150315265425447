export class RequestError extends Error {
  public readonly causedBy: ReadonlyArray<Error>;
  public readonly humanReadableMessage: string;
  constructor({ humanReadableMessage, causedBy }: RequestErrorParams) {
    super();
    this.name = 'RequestError';
    this.message = humanReadableMessage;
    this.humanReadableMessage = humanReadableMessage;
    if (Array.isArray(causedBy)) {
      this.causedBy = [...causedBy];
    } else if (causedBy instanceof Error) {
      this.causedBy = [causedBy];
    } else {
      this.causedBy = [];
    }
  }

  toString(): string {
    const errorInfo = `${this.name}: ${this.message}`;

    if (this.causedBy.length > 0) {
      this.message += `\n${JSON.stringify(this.causedBy)}`;
    }

    return errorInfo;
  }
}

type RequestErrorParams = {
  humanReadableMessage: string;
  causedBy?: Error | ReadonlyArray<Error>;
};
