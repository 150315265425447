import GrpcService, { PlatformAdminApi } from 'src/services/GrpcService';

import {
  ICreate,
  ISearch,
  IUpdate,
  IRestore,
  IFindById,
  IRemove,
} from 'src/services/ServiceLocator/ServiceLocator.interface';
import { RequestController } from 'src/utils/decorators/request-controller';

class ProfileServiceProvider implements ISearch, ICreate, IUpdate, IRestore, IFindById, IRemove {
  @RequestController({ logger: true, retry: true, debounce: true })
  search(params: PlatformAdminApi.IProfileManagementSearchRequest): Promise<PlatformAdminApi.ProfileSearchResponse> {
    return GrpcService.ProfileManagementService.Search(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  create(params: PlatformAdminApi.IProfileManagementCreateRequest): Promise<PlatformAdminApi.ProfileResponse> {
    return GrpcService.ProfileManagementService.Create(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  update(params: PlatformAdminApi.IProfileManagementUpdateRequest): Promise<PlatformAdminApi.ProfileResponse> {
    return GrpcService.ProfileManagementService.Update(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  restore(params: PlatformAdminApi.IUuidRequest): Promise<PlatformAdminApi.ProfileResponse> {
    return GrpcService.ProfileManagementService.Restore(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  remove(params: PlatformAdminApi.IUuidRequest): Promise<PlatformAdminApi.ProfileResponse> {
    return GrpcService.ProfileManagementService.Remove(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  findById(params: PlatformAdminApi.IUuidRequest): Promise<PlatformAdminApi.ProfileResponse> {
    return GrpcService.ProfileManagementService.FindById(params);
  }
}

export const profileServiceProvider = new ProfileServiceProvider();
export type TProfileServiceProvider = typeof profileServiceProvider;
