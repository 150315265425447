import React from 'react';
import './Loader.scss';

interface ILoaderProps {
  size?: 'lg' | 'md' | 'sm';
}

export const Loader = ({ size = 'lg' }: React.PropsWithChildren<ILoaderProps>) => (
  <div className="loader__container">
    <div className="loader__spinner">
      <div
        className={`spinner-border text-primary loader__spinner-element loader__spinner-element--${size}`}
        role="status">
        <span className="sr-only">Загрузка...</span>
      </div>
    </div>
  </div>
);
