import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';

import { ButtonsList, SidebarModal } from './components';
import LayoutRightSidebarStyles from './LayoutRightSidebar.module.scss';
import { SubHeaderPortal } from '../../../portals';
import { useRightSidebarStore } from './store';
import { useSetButtonsVisibilityHook, useVisibilityPolicyHook } from './hooks';
import { useRouteMatch } from 'react-router-dom';

export const LayoutRightSidebar = observer(() => {
  const { showModal, selectedButton } = useRightSidebarStore();
  const { path } = useRouteMatch();
  const [allowedSidebar, setAllowedSidebar] = useState<boolean>(false);
  const isSubheaderVisible = SubHeaderPortal.useBusy();
  useSetButtonsVisibilityHook();
  const { checkVisibilityByPath } = useVisibilityPolicyHook();
  const isAllowedPath = checkVisibilityByPath(selectedButton!, path);

  useEffect(() => {
    setAllowedSidebar(isAllowedPath);
  }, [selectedButton, path]);

  return (
    <div
      className={cn({
        [LayoutRightSidebarStyles.sideBarContainer]: true,
        [LayoutRightSidebarStyles.sideBarContainerWithSubHeader]: isSubheaderVisible,
      })}>
      <div className={LayoutRightSidebarStyles.sideBarInnerContainer}>
        <ButtonsList />
        {allowedSidebar && showModal && <SidebarModal />}
      </div>
    </div>
  );
});
