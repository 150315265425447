import { PlatformAdminApi } from '../services/GrpcService';

export const dictionary = {
  formControl: {
    accountId: 'Идентификатор аккаунта',
    active: 'Активен',
    address: 'Адрес',
    adviceList: 'Советы',
    amount: 'Сумма',

    beginAtAvailableCoupon: 'Время начала доступности',
    beginAtCoupon: 'Время начала доступности',
    bgColor: 'Основной цвет заливки',
    bgImage: 'Изображение подложки',
    btnColor: 'Цвет заливки кнопки',
    btnTextColor: 'Цвет заливки текста кнопки',

    capacity: 'Слотов для мульти-записи',
    cashback: 'Кэшбэк',
    cashbackCount: 'Кэшбэк',
    category: 'Категория',
    clientCouponLifetimeDays: 'Длительность действия купона после покупки',
    clientId: 'Идентификатор Клиента',
    clientPhone: 'Телефон клиента',
    comment: 'Коментарии',
    coordinate: 'Координаты',
    createdAt: 'Дата создания',
    createdAtPurchasedCoupon: 'Время покупки',
    createdAtRecord: 'Дата записи',

    datetimeRecord: 'Дата визита',
    deletedAt: 'Дата удаления',
    description: 'Описание',
    descriptionColor: 'Цвет заливки описания',
    directionId: 'Направление',
    duration: 'Длительность',

    email: 'E-mail',
    executor: 'Исполнитель',
    executors: 'Исполнители',
    expiredAt: 'Срок истекает',
    expiredAtAvailableCoupon: 'Время окончания доступности',
    expiredAtCoupon: 'Время окончания доступности',
    expiredAtPurchasedCoupon: 'Время сгорания',

    fullDesc: 'Полное описание',
    fullName: 'Имя Фамилия',

    infoColor: 'Цвет текста информации',
    information: 'Об исполнителе',
    isActive: 'Онлайн запись',
    isDeleted: 'Удалён',
    isFromApp: 'Запись сделана',
    isMulti: 'Мульти-запись',

    links: 'Связи с другими сущностями',
    location: 'Локация',
    locationId: 'Локация',

    metro: 'Метро',
    metroList: 'Метро',

    name: 'Наименование',
    namePartner: 'Название партнёра',
    needFeedback: 'Отзыв',

    owner: 'Владелец',
    ownerId: 'Идентификатор парнёра',
    ownerType: 'Тип партнёра',
    ownerTypeCoupon: 'Тип купона',

    partnerId: 'ID партнера',
    partnerPhone: 'Телефон партнёра',
    partnersPhone: 'Телефоны администраторов',
    password: 'Пароль',
    passwordRetry: 'Подтверждение пароля',
    phone: 'Телефон',
    position: 'Позиция',
    price: 'Стоимость',
    priceCents: 'Стоимость',
    primaryAccount: 'Телефон главного администратора',
    promocodes: 'Промокоды',
    providerId: 'Идентификатор',
    providerToken: 'Токен',

    rating: 'Рейтинг',
    ratingFeedback: 'Оценка',
    ratingPercent: 'Оценка в процентах',
    ratingType: 'Тип оценки',
    recordProviderId: 'ID записи',
    relationId: 'Идентификатор сущности',
    relationType: 'Тип сущности',
    reviewerContact: 'Контакты автора',
    reviewerName: 'Имя автора',

    salon: 'Салон',
    salonId: 'Салон',
    service: 'Услуга',
    serviceExecutor: 'Имя исполнителя',
    serviceexecutorList: 'Список исполнителей',
    services: 'Услуги',
    shortDesc: 'Краткое описание',
    slug: 'Slug',
    source: 'Источник',
    specialization: 'Специализация',
    status: 'Статус',
    statusExecutor: 'Статус работника',
    statusRecord: 'Статус',

    tenantId: 'Идентификатор арендатора',
    title: 'Наименование',
    titleColor: 'Цвет заливки заголовка',
    type: 'Тип',

    updatedAt: 'Последнее обновление',
    updatedAtRecord: 'Дата обновления записи',
    user: 'ФИО',
    utilized: 'Статус использования',
    utilizedAt: 'Время утилизации',
    utilizerAccount: 'Администратор, который утилизировал',

    weight: 'Вес в списке',

    yaOrgId: 'Идентификатор Яндекса',
  },
  checkControl: {
    owner: {
      title: 'Владелец',
      label: 'YCLIENTS',
    },
    isBefore: {
      title: 'Показ совета',
      label: 'Показывать совет перед процедурой',
    },
    isIntegrated: {
      title: 'Интеграция салона',
      label: 'Салон интегрирован',
    },
    isHidden: {
      title: 'Онлайн запись',
      label: 'Невозможна онлайн запись',
    },
    isTrainer: {
      title: 'Тренер',
      label: 'Да',
    },
    isActive: {
      title: 'Онлайн запись',
      label: 'Да',
    },
    isMulti: {
      title: 'Мульти-запись',
      label: 'Да',
    },
    isRememberMe: {
      title: 'Запомнить меня',
      label: 'Запомнить меня',
    },
    isDeleted: {
      title: 'Удалён',
      label: 'Да',
    },
  },
  selectOptions: {
    owner: {
      '0': 'ADMIN',
      '1': 'YCLIENTS',
    },
    isBefore: {
      '0': 'После',
      '1': 'До',
    },
    isIntegrated: {
      '0': 'Нет',
      '1': 'Да',
    },
    isHidden: {
      '0': 'Возможна онлайн запись',
      '1': 'Невозможна онлайн запись',
    },
    needFeedback: {
      '0': 'Отзыв не оставлен',
      '1': 'Отзыв оставлен',
    },
    isFromApp: {
      '0': 'С помощью сторонних источников',
      '1': 'С помощью приложения',
    },
    isTrainer: {
      '0': 'Нет',
      '1': 'Да',
    },
    isActive: {
      '0': 'Нет',
      '1': 'Да',
    },
    isMulti: {
      '0': 'Нет',
      '1': 'Да',
    },
    statusExecutor: [
      { id: 1, status: 'Онлайн' },
      { id: 2, status: 'Оффлайн' },
      { id: 3, status: 'Уволен' },
    ],
    statusExecutorFilter: [
      { id: 0, status: 'Все' },
      { id: 1, status: 'Онлайн' },
      { id: 2, status: 'Оффлайн' },
      { id: 3, status: 'Уволен' },
    ],
    statusRecordFilter: [
      { id: 0, status: 'Все' },
      { id: 1, status: 'Ожидание клиента' },
      { id: 2, status: 'Клиент не пришёл' },
      { id: 3, status: 'Клиент пришёл' },
      { id: 4, status: 'Клиент отменил' },
      { id: 5, status: 'Клиент подтвердил' },
    ],
    getStatusExecutor: (isFired?: boolean, isHidden?: boolean): string => {
      if (isFired) {
        return 'Уволен';
      }
      if (isHidden) {
        return 'Оффлайн';
      }

      return 'Онлайн';
    },
    getStatusExecutorParam: (status?: 'Все' | 'Уволен' | 'Оффлайн' | 'Онлайн'): Record<string, boolean> => {
      if (status === 'Уволен') {
        return { isFired: true };
      }
      if (status === 'Оффлайн') {
        return { isHidden: true, isFired: false };
      }
      if (status === 'Онлайн') {
        return { isHidden: false, isFired: false };
      }
      if (status === 'Все') {
        return { isAll: true };
      }

      return {};
    },
    status: {
      ACTIVE: 'Активный',
      BLOCKED: 'Заблокирован',
      LICENSE_BLOCKED: 'Лицензия заблокирована',
    },
    statusRecord: {
      '1': 'Ожидание клиента',
      '2': 'Клиент не пришёл',
      '3': 'Клиент пришёл',
      '4': 'Клиент отменил',
      '5': 'Клиент подтвердил',
    },
    statusRecord2: [
      { value: '1', status: 'Ожидание клиента' },
      { value: '2', status: 'Клиент не пришёл' },
      { value: '3', status: 'Клиент пришёл' },
      { value: '4', status: 'Клиент отменил' },
      { value: '5', status: 'Клиент подтвердил' },
    ],
    isDeleted: {
      '0': 'Активен',
      '1': 'Удалён',
    },
    typeCashback: {
      '1': 'Начисление',
      '2': 'Списание',
    },
    typeCashbackFilter: [
      { type: 0, title: 'Все' },
      { type: 1, title: 'Начисление' },
      { type: 2, title: 'Списание' },
    ],
    isDeletedOptions: [
      { value: '0', label: 'Доступно' },
      { value: '1', label: 'Удалено' },
    ],
    statusOptions: [
      { id: 'ACTIVE', value: 'Активный' },
      { id: 'BLOCKED', value: 'Заблокирован' },
      { id: 'LICENSE_BLOCKED', value: 'Лицензия заблокирована' },
    ],
    ownerOptions: [
      { id: '0', value: 'ADMIN' },
      { id: '1', value: 'YCLIENTS' },
    ],
    utilized: {
      '0': 'Не использован',
      '1': 'Использован',
    },
    ownerType: {
      1: 'Партнер',
      2: 'Внешний',
    },
    statusCoupon: {
      '1': 'Активен',
      '2': 'Заблокирован',
    },
    statusCouponSelect: [
      { id: '1', status: 'Активен' },
      { id: '2', status: 'Заблокирован' },
    ],
    addressTypesSelect: [
      { type: PlatformAdminApi.ADDRESS_TYPE.DEFAULT, value: 'Создан пользователем' },
      { type: PlatformAdminApi.ADDRESS_TYPE.RESIDENCE_ADDRESS, value: 'Проживание' },
    ],
  },
  groupList: {
    executorid: 'Исполнитель',
    executorId: 'Исполнитель',
    serviceExecutor: 'Исполнитель',
    id: 'ID / Имя',
    seanceLength: 'Длительность сеанса (минут)',
    linkType: 'Тип связи',
    linkName: 'Имя связи',
    priceMin: 'Минимальная цена',
    priceMax: 'Максимальная цена',
    price: 'Цена',
    amountCents: 'Сумма кэшбэка',
    promocode: 'Промокод',
    status: 'Статус',
  },
  isRequired: 'Обязательное поле',
  filterForm: {
    isDeletedLabel: 'Состояние в Системе',
  },
  citizenshipOptions: [
    { id: 'РФ' },
    { id: 'Украина' },
    { id: 'Беларусь' },
    { id: 'Узбекистан' },
    { id: 'Киргизия' },
    { id: 'Казахстан' },
    { id: 'Таджикистан' },
    { id: 'Молдова' },
    { id: 'Албания' },
    { id: 'Босния и Герцеговина' },
    { id: 'Туркменистан' },
    { id: 'Абхазия' },
    { id: 'Австралия' },
    { id: 'Австрия' },
    { id: 'Азербайджан' },
    { id: 'Алжир' },
    { id: 'Ангола' },
    { id: 'Андорра' },
    { id: 'Антигуа и Барбуда' },
    { id: 'Аргентина' },
    { id: 'Армения' },
    { id: 'Афганистан' },
    { id: 'Багамские Острова' },
    { id: 'Бангладеш' },
    { id: 'Барбадос' },
    { id: 'Бахрейн' },
    { id: 'Белиз' },
    { id: 'Бельгия' },
    { id: 'Бенин' },
    { id: 'Болгария' },
    { id: 'Боливия' },
    { id: 'Ботсвана' },
    { id: 'Бразилия' },
    { id: 'Бруней' },
    { id: 'Буркина-Фасо' },
    { id: 'Бурунди' },
    { id: 'Бутан' },
    { id: 'Вануату' },
    { id: 'Ватикан' },
    { id: 'Великобритания' },
    { id: 'Венгрия' },
    { id: 'Венесуэла' },
    { id: 'Восточный Тимор' },
    { id: 'Вьетнам' },
    { id: 'Габон' },
    { id: 'Гаити' },
    { id: 'Гайана' },
    { id: 'Гамбия' },
    { id: 'Гана' },
    { id: 'Гватемала' },
    { id: 'Гвинея' },
    { id: 'Гвинея-Бисау' },
    { id: 'Германия' },
    { id: 'Гондурас' },
    { id: 'Государство Палестина' },
    { id: 'Гренада' },
    { id: 'Греция' },
    { id: 'Грузия' },
    { id: 'Дания' },
    { id: 'Джибути' },
    { id: 'Доминика' },
    { id: 'Доминиканская Республика' },
    { id: 'Конго' },
    { id: 'Египет' },
    { id: 'Замбия' },
    { id: 'Зимбабве' },
    { id: 'Израиль' },
    { id: 'Индия' },
    { id: 'Индонезия' },
    { id: 'Иордания' },
    { id: 'Ирак' },
    { id: 'Иран' },
    { id: 'Ирландия' },
    { id: 'Исландия' },
    { id: 'Испания' },
    { id: 'Италия' },
    { id: 'Йемен' },
    { id: 'Кабо-Верде' },
    { id: 'Камбоджа' },
    { id: 'Камерун' },
    { id: 'Канада' },
    { id: 'Катар' },
    { id: 'Кения' },
    { id: 'Кипр' },
    { id: 'Кирибати' },
    { id: 'Китай' },
    { id: 'КНДР' },
    { id: 'Колумбия' },
    { id: 'Коморские Острова' },
    { id: 'Коста-Рика' },
    { id: "Кот-д'Ивуар" }, // eslint-disable-line
    { id: 'Куба' },
    { id: 'Кувейт' },
    { id: 'Лаос' },
    { id: 'Латвия' },
    { id: 'Лесото' },
    { id: 'Либерия' },
    { id: 'Ливан' },
    { id: 'Ливия' },
    { id: 'Литва' },
    { id: 'Лихтенштейн' },
    { id: 'Люксембург' },
    { id: 'Маврикий' },
    { id: 'Мавритания' },
    { id: 'Мадагаскар' },
    { id: 'Малави' },
    { id: 'Малайзия' },
    { id: 'Мали' },
    { id: 'Мальдивские Острова' },
    { id: 'Мальта' },
    { id: 'Марокко' },
    { id: 'Маршалловы Острова' },
    { id: 'Мексика' },
    { id: 'Мозамбик' },
    { id: 'Монако' },
    { id: 'Монголия' },
    { id: 'Мьянма' },
    { id: 'Намибия' },
    { id: 'Науру' },
    { id: 'Непал' },
    { id: 'Нигер' },
    { id: 'Нигерия' },
    { id: 'Нидерланды' },
    { id: 'Никарагуа' },
    { id: 'Новая Зеландия' },
    { id: 'Норвегия' },
    { id: 'ОАЭ' },
    { id: 'Оман' },
    { id: 'Пакистан' },
    { id: 'Палау' },
    { id: 'Панама' },
    { id: 'Папуа - Новая Гвинея' },
    { id: 'Парагвай' },
    { id: 'Перу' },
    { id: 'Польша' },
    { id: 'Португалия' },
    { id: 'Республика Конго' },
    { id: 'Республика Корея' },
    { id: 'Руанда' },
    { id: 'Румыния' },
    { id: 'Сальвадор' },
    { id: 'Самоа' },
    { id: 'Сан-Марино' },
    { id: 'Сан-Томе и Принсипи' },
    { id: 'Саудовская Аравия' },
    { id: 'Северная Македония' },
    { id: 'Сейшельские Острова' },
    { id: 'Сенегал' },
    { id: 'Сент-Винсент и Гренадины' },
    { id: 'Сент-Китс и Невис' },
    { id: 'Сент-Люсия' },
    { id: 'Сербия' },
    { id: 'Сингапур' },
    { id: 'Сирия' },
    { id: 'Словакия' },
    { id: 'Словения' },
    { id: 'Соломоновы Острова' },
    { id: 'Сомали' },
    { id: 'Судан' },
    { id: 'Суринам' },
    { id: 'США' },
    { id: 'Сьерра-Леоне' },
    { id: 'Таиланд' },
    { id: 'Танзания' },
    { id: 'Того' },
    { id: 'Тонга' },
    { id: 'Тринидад и Тобаго' },
    { id: 'Тувалу' },
    { id: 'Тунис' },
    { id: 'Турция' },
    { id: 'Уганда' },
    { id: 'Уругвай' },
    { id: 'Федеративные Штаты Микронезии' },
    { id: 'Фиджи' },
    { id: 'Филиппины' },
    { id: 'Финляндия' },
    { id: 'Франция' },
    { id: 'Хорватия' },
    { id: 'ЦАР' },
    { id: 'Чад' },
    { id: 'Черногория' },
    { id: 'Чехия' },
    { id: 'Чили' },
    { id: 'Швейцария' },
    { id: 'Швеция' },
    { id: 'Шри-Ланка' },
    { id: 'Эквадор' },
    { id: 'Экваториальная Гвинея' },
    { id: 'Эритрея' },
    { id: 'Эсватини' },
    { id: 'Эстония' },
    { id: 'Эфиопия' },
    { id: 'ЮАР' },
    { id: 'Южная Осетия' },
    { id: 'Южный Судан' },
    { id: 'Ямайка' },
    { id: 'Япония' },
  ],
};

export type TFormControl = keyof typeof dictionary['formControl'];
export type TCheckControl = keyof typeof dictionary['checkControl'];
export type TSelectOptions = keyof typeof dictionary['selectOptions'];
export type TGroupList = keyof typeof dictionary['groupList'];
