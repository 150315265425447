export const reorder = <Props = Record<string, unknown>>(
  list: Array<Props>,
  startIndex: number,
  endIndex: number,
  // eslint-disable-next-line max-params
): Array<Props> => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
