import { observer } from 'mobx-react';
import React, { FC } from 'react';
import Button from 'react-bootstrap/esm/Button';
import ConfirmManager, { ConfirmActionsTypes } from './ConfirmManager';
import { Modal } from 'react-bootstrap';

interface IBodyProps {
  message: string | React.ComponentType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
}

export const ConfirmDialog = observer((): JSX.Element | null => {
  const { hide, confirmOptions, isShown, resolveConfirm } = ConfirmManager;
  const variant = confirmOptions?.variant || ConfirmActionsTypes.DEFAULT;
  const confirmButtonLabel = confirmOptions?.confirmButtonLabel ?? 'Подтвердить';

  const onClickConfirm = () => confirmOptions?.onConfirm().then(resolveConfirm);

  const ModalBody: FC<IBodyProps | undefined> = (props) => {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    const Body = typeof props?.message === 'string' ? () => <>{props?.message}</> : props?.message;

    return <Body {...props.data} />;
  };

  return (
    <div>
      <Modal show={isShown} onHide={hide} centered size={confirmOptions?.size}>
        <Modal.Header closeButton>
          <Modal.Title>{confirmOptions?.title || 'Подтвердите действие'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalBody message={confirmOptions?.message || ''} data={confirmOptions?.data} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant={variant} className="mx-1" onClick={onClickConfirm}>
            {confirmButtonLabel}
          </Button>
          <Button variant="secondary" className="mx-1" onClick={hide}>
            Отменить
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
});
