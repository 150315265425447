import BaseApiService from '../../../services/BaseApiService';
import { DADATA_BASE_ENDPOINT } from '../../../dadata-constants';
import { IAddressesListResponse, IAddressRequest, ISuggestion, TLocationFilter } from '../address.interfaces';

class AddressesApiService {
  private _httpClient = BaseApiService.createHttpClient(DADATA_BASE_ENDPOINT);

  public async getAddressesList(searchString: string, locationFilters: TLocationFilter[] = []): Promise<string[]> {
    const endpoint = '/address';
    const body: IAddressRequest = {
      query: searchString,
      // Dadata работает в camelcase
      // @link https://dadata.ru/api/suggest/address/
      // eslint-disable-next-line camelcase
      locations: locationFilters,
    };
    const {
      data: { suggestions },
    } = await this._httpClient.post<IAddressesListResponse>(endpoint, body);

    return suggestions.map((suggestion) => suggestion.value);
  }

  public async getSingleAddress(addressString: string): Promise<ISuggestion> {
    const endpoint = '/address';
    const body: IAddressRequest = {
      query: addressString,
      count: 1,
    };

    const {
      data: { suggestions },
    } = await this._httpClient.post<IAddressesListResponse>(endpoint, body);
    const [chosenAddress] = suggestions;

    return chosenAddress;
  }

  public async getMetroStations(searchString: string): Promise<ISuggestion[]> {
    const endpoint = '/metro';
    const body: IAddressRequest = {
      query: searchString,
    };
    const { data } = await this._httpClient.post(endpoint, body);

    return data;
  }
}

export default AddressesApiService;
