import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { RouteChildrenProps } from 'react-router';
import cn from 'classnames';

import { SubHeaderPortal } from 'src/portals';
import ThemeStore from 'src/store/ThemeStore';

import { Tickets } from '../Tickets';

import LayoutNavbar from './LayoutHeader';
import LayoutSidenav from './LayoutSidenav';
import layoutHelpers from './helpers';

import styles from './LayoutMain.styles.module.scss';
import { LayoutSubHeader } from './LayoutSubHeader';
import { LayoutRightSidebar } from './LayoutRightSidebar';
import { LayoutRightSidebarStoreProvider } from './LayoutRightSidebar/store/LayoutRightSidebarStoreProvider';

function closeSidenav(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
  e.preventDefault();
  layoutHelpers.setCollapsed(true);
}

export const LayoutMain = memo<RouteChildrenProps>(({ children, ...props }) => {
  useEffect(() => {
    layoutHelpers.init();
    layoutHelpers.update();
    layoutHelpers.setAutoUpdate(true);

    return () => {
      layoutHelpers.destroy();
    };
  }, []);

  const { sidenavBg, navbarBg } = ThemeStore;

  const isSubheaderVisible = SubHeaderPortal.useBusy();

  return (
    <div className="layout-wrapper layout-2">
      <div className="layout-inner">
        <LayoutSidenav sidenavBg={sidenavBg} {...props} />

        <div className="layout-container">
          <LayoutNavbar navbarBg={navbarBg} {...props} />
          <LayoutSubHeader />

          <div
            className={cn({
              'layout-content': true,
              [styles.containerUnderSubheader]: isSubheaderVisible,
            })}>
            <div className={cn('container-fluid', 'flex-grow-1', styles.page)}>{children}</div>
          </div>

          <Tickets />
        </div>
        <LayoutRightSidebarStoreProvider>
          <LayoutRightSidebar />
        </LayoutRightSidebarStoreProvider>
      </div>
      <div className="layout-overlay" onClick={closeSidenav} />
    </div>
  );
});

export default LayoutMain;
