import { useEffect } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { useRightSidebarStore } from '../store';
import { useVisibilityPolicyHook } from './useVisibilityPolicyHook';
export const useSetButtonsVisibilityHook = (): void => {
  const { pathname } = useLocation();
  const { path } = useRouteMatch();
  const { buttonsList, updateButtonsState } = useRightSidebarStore();
  const { checkVisibilityByPath } = useVisibilityPolicyHook();

  useEffect(() => {
    const updatedButtonsList = [...buttonsList].map((button) => {
      const isAllowedPath = checkVisibilityByPath(button, path);

      return {
        ...button,
        isVisible: isAllowedPath,
      };
    });

    updateButtonsState(updatedButtonsList);
  }, [pathname]);
};
