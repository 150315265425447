import { RequestController } from 'src/utils/decorators/request-controller';
import { ApolloQueryResult } from '@apollo/client';
import {
  apolloClient,
  GetTimezoneByGeo,
  GetTimezoneByGeoBatch,
  GetTimezoneByGeoBatchVariables,
  GetTimezoneByGeoVariables,
} from 'src/services/GraphQL';

class GeoProvider {
  @RequestController({ logger: true })
  public async getTimezoneByGeo(filterOptions: GetTimezoneByGeoVariables): Promise<GetTimezoneByGeo> {
    const response: ApolloQueryResult<GetTimezoneByGeo> = await apolloClient.query<
      GetTimezoneByGeo,
      GetTimezoneByGeoVariables
    >({
      query: GetTimezoneByGeo,
      variables: filterOptions,
    });

    return response.data;
  }

  @RequestController({ logger: true })
  public async getTimezoneByGeoBatch(filterOptions: GetTimezoneByGeoBatchVariables): Promise<GetTimezoneByGeoBatch> {
    const response: ApolloQueryResult<GetTimezoneByGeoBatch> = await apolloClient.query<
      GetTimezoneByGeoBatch,
      GetTimezoneByGeoBatchVariables
    >({
      query: GetTimezoneByGeoBatch,
      variables: filterOptions,
    });

    return response.data;
  }
}

export const geoProvider = new GeoProvider();
