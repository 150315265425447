import GrpcService from 'src/services/GrpcService';
import { PlatformAdminApi } from '../../../../services/GrpcService';
import { RequestController } from '../../../../utils/decorators/request-controller';

export class ContractorProvider {
  @RequestController({ logger: true, debounce: true, retry: true })
  public contractorsSearch(
    params: PlatformAdminApi.IContractorsSearchRequest,
  ): Promise<PlatformAdminApi.ContractorsList> {
    return GrpcService.ContractorsService.ContractorsSearch(params);
  }
}

export const contractorProvider = new ContractorProvider();
export type TContractorsProvider = typeof contractorProvider;
