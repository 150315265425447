import React from 'react';
import { CreateProblem } from './components/CreateProblem';
import { Formik } from 'formik';
import { NewStackModal } from '../Layouts/LayoutRightSidebar';
import { TicketsRootStoreProvider } from './store';

export enum TICKET_MODALS_EVENTS {
  CREATE_PROBLEM_CLOSE_MODAL = 'CLOSE_CREATE_PROBLEM_MODAL',
  CREATE_PROBLEM_CANCEL = 'CREATE_PROBLEM_CANCEL',
}

export const NEW_TASK_MODAL = (): NewStackModal => ({
  title: 'Создать задачу',
  event: TICKET_MODALS_EVENTS.CREATE_PROBLEM_CLOSE_MODAL,
  modal: (
    <Formik initialValues={{}} onSubmit={() => undefined}>
      <TicketsRootStoreProvider>
        <CreateProblem />
      </TicketsRootStoreProvider>
    </Formik>
  ),
});
