import { lazy } from 'src/utils';
import { IRoute } from '../../routes';

export default (fallback: JSX.Element): IRoute[] => [
  {
    path: '/core/elements/',
    pureComponent: lazy(() => import('./pages/element-list/ElementList.page'), fallback),
    breadcrumb: 'Список элементов',
    exact: true,
  },
  {
    path: '/core/elements/new/',
    pureComponent: lazy(() => import('./pages/element-edit/ElementEdit.page'), fallback),
    breadcrumb: 'Создание элемента',
    exact: true,
  },
  {
    path: '/core/elements/:id/',
    pureComponent: lazy(() => import('./pages/element-view/ElementView.page'), fallback),
    breadcrumb: 'Просмотр элемента',
    exact: true,
  },
  {
    path: '/core/elements/:id/edit/',
    pureComponent: lazy(() => import('./pages/element-edit/ElementEdit.page'), fallback),
    breadcrumb: 'Редактирование элемента',
    exact: true,
  },
];
