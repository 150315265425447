import { ExecutionHistoryItem } from 'src/features/Tickets/interfaces';
import { ExecutionHistoryItem as GqlExecutionHistoryItem, TaskStateEnum } from 'src/services/GraphQL';
import { AdapterFunction } from '../interfaces';
import { DateTimeService } from 'src/services/DateTimeService';
import { TICKET_DATE_TIME_FORMAT } from 'src/features/Tickets/constants';

const taskStateOrder = [
  TaskStateEnum.NEW,
  TaskStateEnum.IN_WORK,
  TaskStateEnum.DEDUPLICATED,
  TaskStateEnum.RESCHEDULED,
  TaskStateEnum.POSTPONED,
  TaskStateEnum.NO_COMMUNICATION,
  TaskStateEnum.CANCELLED,
  TaskStateEnum.PROCEED,
];

export const taskExecutionHistoryAdapter: AdapterFunction<
  ReadonlyArray<GqlExecutionHistoryItem> | null | undefined,
  ExecutionHistoryItem[]
> = (executionHistory) => {
  if (!executionHistory?.length) {
    return [];
  }

  return executionHistory
    .slice()
    .sort((a, b) => {
      const createDateDifference = DateTimeService.difference(a.createdAt, b.createdAt);

      if (createDateDifference) {
        return createDateDifference;
      }

      // если даты создания равны, то сортируем по статусу
      return taskStateOrder.indexOf(a.taskStateValue!) - taskStateOrder.indexOf(b.taskStateValue!);
    })
    .map((historyItem) => ({
      id: historyItem.id,
      createdAt: DateTimeService.format(historyItem.createdAt, TICKET_DATE_TIME_FORMAT),
      managerName: historyItem.manager?.name,
      taskStateValue: historyItem.taskStateValue,
      kind: historyItem.kind,
    }));
};
