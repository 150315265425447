import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { CheckDoublePortal, ListCheckPortal, useRightSidebarStore } from 'src/features/Layouts/LayoutRightSidebar';

import styles from './TicketsContentBar.styles.module.scss';
import { TicketsList } from '../TicketsList';
import { useParams } from 'react-router-dom';
import { Scalars } from 'src/services/GraphQL';
import { TicketsRootStoreProvider } from '../../store';
import { TicketsHistory } from '../TicketsHistory';

export const TicketsContentBar = observer(() => {
  const { ticketId } = useParams<{ ticketId: Scalars['ID'] }>();
  const { openDoubleCheckSidebar } = useRightSidebarStore();
  const [mouseOnTicket, setMouseOnTicket] = useState<boolean>(false);
  const [body] = document.getElementsByTagName('body');

  // Здесь отключаемм внешний скролл на body
  // при прокрутке содержимого тикета
  useEffect(() => {
    if (mouseOnTicket) {
      body.style.overflow = 'hidden';
    } else {
      body.style.overflow = 'unset';
    }
  }, [mouseOnTicket]);

  useEffect(() => {
    if (ticketId) {
      openDoubleCheckSidebar();
    }
  }, [ticketId]);

  return (
    <TicketsRootStoreProvider>
      <div
        className={styles.root}
        onMouseEnter={() => setMouseOnTicket(true)}
        onMouseLeave={() => setMouseOnTicket(false)}>
        <CheckDoublePortal.Render>
          <TicketsList />
        </CheckDoublePortal.Render>

        <ListCheckPortal.Render>
          <TicketsHistory />
        </ListCheckPortal.Render>
      </div>
    </TicketsRootStoreProvider>
  );
});
