import { Form, Formik } from 'formik';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { FieldError, FieldLabel, StringField } from 'src/libs/ui-kit';
import Select from 'react-select';
import TaskFormsStyles from './TaskFormsStyles.module.scss';
import cn from 'classnames';
import { PresenterCancellationReasonsListItem } from 'src/features/Tickets/interfaces';
import { CancellationReasonsVariables } from 'src/services/GraphQL';
import { useTicketsRootStore } from 'src/features/Tickets/store/tickets-root-store/TicketsStoreProvider';

export interface CancelFormProps {
  taskId: string;
  onAction(): void;
}

interface CancelFormValues {
  readonly cancelReasonId: string | undefined;
  readonly comment: string;
}

const FORM_VALUES: CancelFormValues = {
  cancelReasonId: undefined,
  comment: '',
};

export const CancelTaskForm: FC<CancelFormProps> = ({ taskId, onAction }) => {
  const {
    ticketsSystemStore: { getCancellationReasons, cancelTask },
  } = useTicketsRootStore();
  const [cancelReasonId, setCancelReasonId] = useState<string>('');
  const [noReasonError, setNoReasonError] = useState<boolean>(false);
  const [reasons, setReasons] = useState<PresenterCancellationReasonsListItem[]>([]);

  useEffect(() => {
    const defaultRequestBody: CancellationReasonsVariables = { categories: [] };

    getCancellationReasons(defaultRequestBody)
      .then((reasons: PresenterCancellationReasonsListItem[]) => setReasons(reasons))
      .catch(() => undefined);
  }, []);

  const onReasonChange = useCallback(
    (reason) => {
      if (reason) {
        setNoReasonError(false);
      }

      setCancelReasonId(reason.id);
    },
    [cancelReasonId],
  );

  const onSubmit = ({ comment }: CancelFormValues) => {
    if (!cancelReasonId) {
      setNoReasonError(true);

      return;
    }

    onAction();
    cancelTask({ taskId, cancelReasonId, comment });
  };

  const formatGroupLabel = (reasonCategory) => (
    <div>
      <span className={cn('font-medium-base', TaskFormsStyles.reasonCategoryGroup)}>{reasonCategory.label}</span>
    </div>
  );

  const formatOptionLabel = (reason) => (
    <div>
      <span className={cn('font-regular-base', TaskFormsStyles.reasonItem)} key={reason.id}>
        {reason.title}
      </span>
    </div>
  );

  return (
    <Formik initialValues={FORM_VALUES} onSubmit={onSubmit}>
      <Form className="p-3">
        <div className="mb-4">
          <FieldLabel label="Категория запроса" />
          <Select
            options={reasons}
            formatGroupLabel={formatGroupLabel}
            formatOptionLabel={formatOptionLabel}
            onChange={onReasonChange}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            getOptionValue={(option: any) => option.id}
          />
          {noReasonError && <FieldError error="Необходимо выбрать причину" />}
        </div>

        <StringField name="comment" variant="textarea" label="Комментарий" />

        <div>
          <Button type="submit" className="mr-2">
            Подтвердить
          </Button>

          <Button type="button" variant="default" onClick={onAction}>
            Отменить
          </Button>
        </div>
      </Form>
    </Formik>
  );
};
