import GrpcService from 'src/services/GrpcService';

import { RequestController } from '../../../../utils/decorators/request-controller';

import {
  ICreate,
  IFindById,
  IRemove,
  IRestore,
  ISearch,
  IUpdate,
} from '../../../../services/ServiceLocator/ServiceLocator.interface';
import {
  TContractEditPage,
  TContractSearchRequest,
  TContractCreatePage,
  TContractBlockRequest,
  TContractsList,
  TContractResponse,
  TContractView,
  TBlockingReasonGroupSuggestsList,
  TBlockingReasonSuggestsList,
  TSearchWageSuggestsRequest,
  TWageSuggestsList,
  TSearchSkillSuggestsRequest,
  TSkillSuggestsList,
  TSearchBlockingReasonGroupSuggestsRequest,
  TSearchBlockingReasonSuggestsRequest,
  TProductFilterOptionsList,
  TSpecializationFilterOptionsList,
  TContractSearchResponse,
} from '../Contracts.interfaces';
import { TUuidRequest } from '../../../../interfaces';

class ContractsProvider implements ISearch, IFindById, ICreate, IUpdate, IRestore, IRemove {
  @RequestController({ logger: true, debounce: true, retry: true })
  public search(params: TContractSearchRequest): Promise<TContractsList> {
    return GrpcService.ContractService.Search(params);
  }

  // нужно для совместимости старой версии исполнителей и новой версии контрактов
  @RequestController({ logger: true, debounce: true, retry: true })
  public SearchOldVersion(params: TContractSearchRequest): Promise<TContractSearchResponse> {
    return GrpcService.ContractService.SearchOldVersion(params);
  }

  @RequestController({ logger: true, debounce: true, retry: true })
  public findById(params: TUuidRequest): Promise<TContractView> {
    return GrpcService.ContractService.FindById(params);
  }

  @RequestController({ logger: true, debounce: true, retry: true })
  public create(params: TContractCreatePage): Promise<TContractResponse> {
    return GrpcService.ContractService.Create(params);
  }

  @RequestController({ logger: true, debounce: true, retry: true })
  public update(params: TContractEditPage): Promise<TContractResponse> {
    return GrpcService.ContractService.Update(params);
  }

  @RequestController({ logger: true, debounce: true, retry: true })
  public remove(params: TUuidRequest): Promise<TContractView> {
    return GrpcService.ContractService.Remove(params);
  }

  @RequestController({ logger: true, debounce: true, retry: true })
  public restore(params: TUuidRequest): Promise<TContractView> {
    return GrpcService.ContractService.Restore(params);
  }

  @RequestController({ logger: true, debounce: true, retry: true })
  public block(params: TContractBlockRequest): Promise<TContractView> {
    return GrpcService.ContractService.Block(params);
  }

  @RequestController({ logger: true, debounce: true, retry: true })
  public retrieveEditContract(params: TUuidRequest): Promise<TContractEditPage> {
    return GrpcService.ContractService.RetrieveEditContract(params);
  }

  @RequestController({ logger: true, debounce: true, retry: true })
  public suggestBlockingReasonGroups(
    params: TSearchBlockingReasonGroupSuggestsRequest,
  ): Promise<TBlockingReasonGroupSuggestsList> {
    return GrpcService.ContractService.SearchBlockingReasonGroupSuggests(params);
  }

  @RequestController({ logger: true, debounce: true, retry: true })
  public suggestBlockingReason(params: TSearchBlockingReasonSuggestsRequest): Promise<TBlockingReasonSuggestsList> {
    return GrpcService.ContractService.SearchBlockingReasonSuggests(params);
  }

  @RequestController({ logger: true, debounce: true, retry: true })
  public suggestWage(params: TSearchWageSuggestsRequest): Promise<TWageSuggestsList> {
    return GrpcService.ContractService.SearchWageSuggests(params);
  }

  @RequestController({ logger: true, debounce: true, retry: true })
  public suggestSkill(params: TSearchSkillSuggestsRequest): Promise<TSkillSuggestsList> {
    return GrpcService.ContractService.SearchSkillSuggests(params);
  }

  @RequestController({ logger: true, debounce: true, retry: true })
  public getProductFilterOptions(): Promise<TProductFilterOptionsList> {
    return GrpcService.ContractService.SearchProductFilterOptions({});
  }

  @RequestController({ logger: true, debounce: true, retry: true })
  public getSpecializationFilterOptions(): Promise<TSpecializationFilterOptionsList> {
    return GrpcService.ContractService.SearchSpecializationFilterOptions({});
  }
}

export const contractsProvider = new ContractsProvider();
export type TContractsProvider = typeof contractsProvider;
