import { RightSidebarButtonsConfig } from '../interfaces';
import { matchPath } from 'react-router-dom';
import { toJS } from 'mobx';

export const useVisibilityPolicyHook = () => {
  // 1. Если allowedPaths не указан, значит кнопку показываем на всех страницах
  // 2. Если allowedPaths содержит пути, значит есть варинты:
  //    а). in - массив путей, на которых показываем кнопку
  //    б). not - массив путей, на которых кнопку не показываем
  // 3. по п.2 - можно указать либо in, либо not. Оба варианта указать нельзя.
  const checkVisibilityByPath = (button: RightSidebarButtonsConfig, path: string): boolean => {
    if (!button?.allowedPaths) {
      return true;
    }

    const { includedPaths, excludedPaths } = button.allowedPaths;

    const inPathsArrayContainsPath: boolean | undefined = toJS(includedPaths)?.some(
      (pathItem) => !!matchPath(pathItem, { path, exact: true }),
    );
    const notPathsArrayContainsPath: boolean | undefined = toJS(excludedPaths)?.some(
      (pathItem) => !!matchPath(pathItem, { path, exact: true }),
    );

    const shouldShowIncluded: boolean =
      Boolean(inPathsArrayContainsPath) && typeof notPathsArrayContainsPath === 'undefined';
    const shouldShowExcluded: boolean = !notPathsArrayContainsPath && typeof inPathsArrayContainsPath === 'undefined';

    return shouldShowIncluded || shouldShowExcluded;
  };

  return {
    checkVisibilityByPath,
  };
};
