import React, { FC, useCallback } from 'react';
import { observer } from 'mobx-react';
import { IconButton } from '../../../../libs/ui-kit';
import { useRightSidebarStore } from '../store';

import LayoutRightSidebarStyles from '../LayoutRightSidebar.module.scss';
import { SidebarButtonsIcons } from '../constants';
import { CheckDoublePortal, ListCheckPortal } from '../portals';
import RightSidebarEvent from '../EventBus/RightSidebarEvent';

export const SidebarModal: FC = observer(() => {
  const { selectedButton, modalsStack, dismissModal } = useRightSidebarStore();
  const PORTALS_MAP = {
    [SidebarButtonsIcons.CHECK_DOUBLE]: <CheckDoublePortal.Target />,
    [SidebarButtonsIcons.LIST_CHECK]: <ListCheckPortal.Target />,
  };

  const dismissModalCb = useCallback(() => {
    const event: RightSidebarEvent = new RightSidebarEvent({
      event: 'root',
      scope: 'SidebarModal.tsx',
      method: 'dismissModalCb',
    });

    dismissModal(event);
  }, [dismissModal]);

  return (
    <div className={LayoutRightSidebarStyles.sideBarModal}>
      {selectedButton ? PORTALS_MAP[selectedButton.icon] : null}
      <div>
        {modalsStack.map((stackedModal, index) => (
          <div key={`modal-${index}`} className={LayoutRightSidebarStyles.stackedModalContainer}>
            <div className="font-semi-bold-base px-4 py-3 border-bottom">
              <IconButton icon="chevron-left" size="sm" className="mr-3" onClick={dismissModalCb} />
              <span className="font-semi-bold-14">{stackedModal.title}</span>
            </div>
            <div className="px-4 py-3">{stackedModal.modal}</div>
          </div>
        ))}
      </div>
    </div>
  );
});
