import React from 'react';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';

export interface IFieldLabelProps {
  readonly column?: {
    mode: 'sm' | 'lg';
    size: number;
  };
  readonly label?: string;
  readonly isRequired?: boolean;
  readonly hidden?: boolean;
  // @deprecated
  readonly labelVisible?: boolean;
  readonly className?: string;
}

export const FieldLabel: React.FC<IFieldLabelProps> = (props: IFieldLabelProps): JSX.Element | null => {
  const { label, labelVisible, hidden, isRequired, column, className = '' } = props;
  const labelProps = typeof column == 'undefined' ? {} : { column: true, [column.mode]: column.size };
  const overlay = <Tooltip id={`${label}_required`}>Обязательное поле</Tooltip>;

  if (hidden === true || labelVisible === false || !label) {
    return null;
  }

  const tooltip = (
    <OverlayTrigger placement="top" overlay={overlay}>
      <i className="ion ion-ios-alert ml-1" />
    </OverlayTrigger>
  );

  return (
    <Form.Label className={className} {...labelProps}>
      {label}
      {isRequired && tooltip}
    </Form.Label>
  );
};
