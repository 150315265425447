import React, { createContext, memo, ReactNode, useContext } from 'react';
import { ticketsRootStore, TTicketsRootStore } from './TicketsRootStore';

export interface TicketsRootStoreProviderProps {
  children: ReactNode;
}

let store: TTicketsRootStore | null = null;

const TicketsRootStoreContext = createContext<TTicketsRootStore | null>(null);

export const useTicketsRootStore: () => TTicketsRootStore = () => useContext(TicketsRootStoreContext)!;

export const TicketsRootStoreProvider = memo<TicketsRootStoreProviderProps>(({ children }) => {
  if (!store) {
    store = ticketsRootStore;
  }

  return <TicketsRootStoreContext.Provider value={store}>{children}</TicketsRootStoreContext.Provider>;
});
