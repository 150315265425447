import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Accordion, Button, Card } from 'react-bootstrap';
import styles from './TicketsHistory.module.scss';
import TicketsListStyles from '../TicketsList/TicketsList.module.scss';
import classNames from 'classnames';
import { Icon } from 'src/libs/ui-kit';
import { useTicketsRootStore } from '../../store';
import { matchPath, useParams, useRouteMatch } from 'react-router-dom';
import { TicketContent } from '../TicketItem/components/TicketContent';
import { TicketTaskCard } from '../Task';
import ticketStyles from '../TicketItem/styles.module.scss';
import { SaleItem } from '../SaleItem';
import { Loader } from '../../../../components/Loader';

export const TicketsHistory = observer(() => {
  const { ticketsSystemStore } = useTicketsRootStore();
  const { userId: clientId, orderId } = useParams<{ userId: string; orderId: string }>();
  const [isOrderUrl, setIsOrderUrl] = useState<boolean>(false);
  const { path } = useRouteMatch();

  useEffect(() => {
    if (!clientId) {
      return;
    }

    ticketsSystemStore.getClientHistory(clientId, orderId);
    // Запрос тикетов нужен для того, чтобы понять, есть ли активные тикеты или задачи.
    // На эту кнопку завязана логика отображения кнопки "Завершить работу с клиентом".
    ticketsSystemStore.getTickets(clientId);

    return () => {
      ticketsSystemStore.ticketsHistory = [];
      ticketsSystemStore.salesHistory = [];
      ticketsSystemStore.resetTickets();
    };
  }, []);

  useEffect(() => {
    const ordersPaths: string[] = [
      '/users/:userId/orders/:orderId/',
      '/users/:userId/orders/:orderId/:ticketId',
      '/users/:userId/external-orders/:orderId/',
    ];
    const isOrderUrl = ordersPaths.some((pathItem) => !!matchPath(pathItem, { path, exact: true }));

    setIsOrderUrl(isOrderUrl);
  }, []);

  return (
    <div className={styles.ticketsHistoryContainer}>
      <section>
        <div className={classNames('d-flex justify-content-between font-semi-bold-base p-4', styles.cardHeader)}>
          <span>Все обращения</span>
          {ticketsSystemStore.loadingHistory && <Loader size="sm" />}
        </div>
        <div>
          <Accordion>
            {ticketsSystemStore.ticketsHistory.map((ticketHistoryItem) => (
              <div key={`ticket-${ticketHistoryItem.date}`}>
                <Accordion.Toggle
                  as={Card.Header}
                  className="border-0 pl-4 pr-4"
                  eventKey={`ticket-${ticketHistoryItem.date}`}>
                  <div className="d-flex justify-content-between">
                    <div className="font-semi-bold-base">{ticketHistoryItem.date}</div>
                    <div>
                      <Icon name="chevron-down" className={styles.chevron} />
                    </div>
                  </div>
                </Accordion.Toggle>
                {ticketHistoryItem.tickets.map((ticket) => (
                  <Accordion.Collapse
                    key={ticket.id}
                    eventKey={`ticket-${ticketHistoryItem.date}`}
                    className="border-0">
                    <Card.Body className="pt-0 pb-0 pl-4 pr-4 mb-4">
                      <TicketContent ticket={ticket} />
                      <div>
                        <div>
                          {!!ticket.tasksList.length && (
                            <div className={classNames('p-4', ticketStyles.cardContainer)}>
                              {ticket.tasksList.map((task) => (
                                <div className={ticketStyles.taskItem} key={task.id}>
                                  <TicketTaskCard task={task} hiddenToolbar />
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                ))}
              </div>
            ))}
          </Accordion>
        </div>
      </section>

      {!isOrderUrl && (
        <section className="mt-8">
          <div className={classNames('d-flex justify-content-between font-semi-bold-base p-4', styles.cardHeader)}>
            <span>Все продажи</span>
            {ticketsSystemStore.loadingHistory && <Loader size="sm" />}
          </div>
          <div>
            <Accordion>
              {ticketsSystemStore.salesHistory.map((salesHistoryItem) => (
                <div key={`sales-${salesHistoryItem.date}`}>
                  <Accordion.Toggle
                    as={Card.Header}
                    className="border-0 pl-4 pr-4"
                    eventKey={`sales-${salesHistoryItem.date}`}>
                    <div className="d-flex justify-content-between">
                      <div className="font-semi-bold-base">{salesHistoryItem.date}</div>
                      <div>
                        <Icon name="chevron-down" className={styles.chevron} />
                      </div>
                    </div>
                  </Accordion.Toggle>
                  {salesHistoryItem.sales.map((sale) => (
                    <Accordion.Collapse key={sale.id} eventKey={`sales-${salesHistoryItem.date}`} className="border-0">
                      <Card.Body className="pt-0 pb-0 pl-2 pr-2 mb-4">
                        <SaleItem sale={sale} />
                      </Card.Body>
                    </Accordion.Collapse>
                  ))}
                </div>
              ))}
            </Accordion>
          </div>
        </section>
      )}
      {ticketsSystemStore.hasActiveTickets && !ticketsSystemStore.hasActiveTasks && (
        <div
          className={classNames(
            'px-4 py-3 d-flex justify-content-center',
            TicketsListStyles.finishWorkButton,
            styles.finishWork,
          )}>
          <Button variant="default" onClick={ticketsSystemStore.completeWorkWithUser}>
            Завершить работу с пользователем
          </Button>
        </div>
      )}
    </div>
  );
});
