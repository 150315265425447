import { AppSubModule } from 'src/interfaces';
import { CATALOGS_PROVIDER_TOKENS } from '../constants';
import { specializationsServiceProvider } from './service-providers/SpecializationsService.provider';

class SpecializationsListModule extends AppSubModule {
  public providers = [
    { token: CATALOGS_PROVIDER_TOKENS.SPECIALIZATIONS_SERVICE, provider: specializationsServiceProvider },
  ];
}

export const specializationsListModule = new SpecializationsListModule();
