import { ADDRESS_TYPE, IAddressDetail, ICoordinates, IDadataObject, IMetro } from '../address.interfaces';
import AddressService from '../services/AddressService';
import { getCoordinates } from '../utils/utils';

export class ManualAddressModel {
  public value: string = '';
  public unrestrictedValue: string = '';
  public readonly object: string = JSON.stringify({});
  public readonly coordinates: ICoordinates | null = null;
  public readonly metro: IMetro[] = [];

  // eslint-disable-next-line max-params
  constructor(
    public readonly id: string,
    public readonly label: string,
    public readonly detail: IAddressDetail | null,
    public readonly type: ADDRESS_TYPE = ADDRESS_TYPE.DEFAULT,
    public readonly dadataObject: string | undefined,
    public readonly isReadonly: boolean = false,
  ) {
    const parsedDadata: IDadataObject | undefined = AddressService.parseDadataObject(dadataObject);

    if (dadataObject) {
      this.object = dadataObject;
      this.coordinates = getCoordinates(parsedDadata);
      this.metro = parsedDadata?.metro ?? [];
    }

    this.value = this.getAddressDetailsString();
    this.unrestrictedValue = parsedDadata
      ? `${parsedDadata.postal_code} ${this.getAddressDetailsString()}`
      : this.getAddressDetailsString();
  }

  private getAddressDetailsString(): string {
    const value: string[] = [];

    if (this.detail?.region) {
      value.push(this.detail.region);
    }

    if (this.detail?.city) {
      value.push(this.detail.city);
    }

    if (this.detail?.area) {
      value.push(this.detail.area);
    }

    if (this.detail?.settlement) {
      value.push(this.detail.settlement);
    }

    if (this.detail?.street) {
      value.push(this.detail.street);
    }

    if (this.detail?.house) {
      value.push(this.detail.house);
    }

    if (this.detail?.block) {
      value.push(this.detail.block);
    }

    if (this.detail?.flat) {
      value.push(this.detail.flat);
    }

    return value
      .map((detail) => detail.trim())
      .filter((value) => !!value)
      .join(', ');
  }
}
