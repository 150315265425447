import GrpcService, { PlatformAdminApi } from 'src/services/GrpcService';
import { IFindById, ISearch } from 'src/services/ServiceLocator/ServiceLocator.interface';
import { RequestController } from 'src/utils/decorators/request-controller';

class PriceElementServiceProvider implements IFindById, ISearch {
  @RequestController({ logger: true, retry: true, debounce: true })
  findById(params: PlatformAdminApi.IUuidRequest): Promise<PlatformAdminApi.PriceElementResponse> {
    return GrpcService.PriceElementService.FindById(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  search(params: PlatformAdminApi.ISearchPriceElementRequest): Promise<PlatformAdminApi.SearchPriceElementResponse> {
    return GrpcService.PriceElementService.Search(params);
  }
}

export const priceElementServiceProvider = new PriceElementServiceProvider();
export type TPriceElementServiceProvider = typeof priceElementServiceProvider;
