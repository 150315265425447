import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import PhoneNumber from 'awesome-phonenumber';

import { DateTimeService } from 'src/services/DateTimeService';

export const I18nInitPromise = i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    // debug: true,
    fallbackLng: 'ru',
    lng: 'ru',
    ns: [
      'common',
      'base',
      'profile',
      'short-entity',
      'promocodes',
      'contractor',
      'contract',
      'market-service',
      'orders',
      'tickets',
      'tasks',
      'channels',
    ],
    // backend: {
    //   loadPath: '/locales/{{lng}}/{{ns}}.json',
    // },

    // we do not use keys in form messages.welcome
    keySeparator: false,
    interpolation: {
      // react already safes from xss
      escapeValue: false,
      // eslint-disable-next-line max-params
      format: (value, format, lng) => {
        // console.log('format', value, format, lng);
        if (typeof value === 'string' && format === 'uppercase') {
          return value.toUpperCase();
        }

        if (value && (format === 'international' || format === 'rfc3966')) {
          return new PhoneNumber(value).getNumber(format);
        }

        if (value instanceof Date) {
          if (DateTimeService.handleGlobalLocale() !== lng) {
            DateTimeService.handleGlobalLocale(lng);
          }

          return value ? DateTimeService.format(value, format) : value;
        }

        return value;
      },
    },
  });

export default i18n;
