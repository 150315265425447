import type { IconButtonLabel } from 'src/libs/ui-kit/buttons/IconButton/IconButton';
import { ExecutionHistoryItemKindEnum, TaskStateEnum, TicketStateEnum } from '../../services/GraphQL';
import cn from 'classnames';
import TaskStyles from './components/Task/task.module.scss';

export const DEFAULT_TICKETS_REQUEST_PARAMS: { amount: number } = {
  amount: 4,
};

export const DEFAULT_TASKS_REQUEST_PARAMS: { amount: number } = {
  amount: 10,
};

export enum TicketsBarSection {
  Active,
  AdditionalTicketForm,
  New,
}

interface ButtonConfig {
  icon: IconButtonLabel;
  value: TicketsBarSection;
  disabled?: boolean;
}

export const BUTTONS_CONFIG: ReadonlyArray<ButtonConfig> = [
  {
    icon: 'check-double',
    value: TicketsBarSection.Active,
  },
  {
    icon: 'dollar-sign',
    value: TicketsBarSection.Active,
    disabled: true,
  },
  {
    icon: 'cart-shopping',
    value: TicketsBarSection.Active,
    disabled: true,
  },
];

export const TICKET_STATUS_MAP: Record<TicketStateEnum, string> = {
  [TicketStateEnum.OPENED]: 'primary',
  [TicketStateEnum.CLOSED]: 'primary',
  [TicketStateEnum.NEW]: 'primary',
  [TicketStateEnum.SOLVED]: 'success',
  [TicketStateEnum.HOLD]: 'warning',
};

export const TICKET_DATE_TIME_FORMAT = 'DD.MM.YY, HH:mm';

export const TICKET_TASK_STATUS_MAP: Record<TaskStateEnum, string> = {
  [TaskStateEnum.DEDUPLICATED]: 'primary',
  [TaskStateEnum.NEW]: 'primary',
  [TaskStateEnum.NO_COMMUNICATION]: 'warning',
  [TaskStateEnum.RESCHEDULED]: 'warning',
  [TaskStateEnum.POSTPONED]: 'warning',
  [TaskStateEnum.IN_WORK]: 'primary',
  [TaskStateEnum.PROCEED]: 'success',
  [TaskStateEnum.CANCELLED]: 'danger',
};

export const TASKS_STATES_TRANSLATIONS_STRING = {
  [TaskStateEnum.DEDUPLICATED]: 'tasks:state.deduplicated',
  [TaskStateEnum.NEW]: 'tasks:state.new',
  [TaskStateEnum.NO_COMMUNICATION]: 'tasks:state.no-communication',
  [TaskStateEnum.RESCHEDULED]: 'tasks:state.rescheduled',
  [TaskStateEnum.POSTPONED]: 'tasks:state.postponed',
  [TaskStateEnum.IN_WORK]: 'tasks:state.in-work',
  [TaskStateEnum.PROCEED]: 'tasks:state.proceed',
  [TaskStateEnum.CANCELLED]: 'tasks:state.cancelled',
};
export const HISTORY_KIND_TRANSLATIONS_STRING = {
  [ExecutionHistoryItemKindEnum.ADD_TASK_TO_TASK_LIST]: 'tasks:history.kind.add_task_to_list',
  [ExecutionHistoryItemKindEnum.CHANGE_STATE]: 'tasks:history.kind.change_status',
  [ExecutionHistoryItemKindEnum.CANCEL_COMMUNICATION]: 'tasks:history.kind.cancel_communication',
  [ExecutionHistoryItemKindEnum.CHANGE_ORDER]: 'tasks:history.kind.change_order',
  [ExecutionHistoryItemKindEnum.MANAGER_ONLINE]: 'tasks:history.kind.manager_online',
  [ExecutionHistoryItemKindEnum.CHANGE_TASK_OWNER]: 'tasks:history.kind.change_task_owner',
};

export const getTitleStyles = (taskState: TaskStateEnum): string => {
  const TITLES_MAP: Record<TaskStateEnum, string> = {
    [TaskStateEnum.PROCEED]: cn(TaskStyles.icon, TaskStyles.iconSuccess),
    [TaskStateEnum.NEW]: cn(TaskStyles.icon),
    [TaskStateEnum.IN_WORK]: cn(TaskStyles.icon),
    [TaskStateEnum.CANCELLED]: cn(TaskStyles.icon),
    [TaskStateEnum.DEDUPLICATED]: cn(TaskStyles.icon),
    [TaskStateEnum.POSTPONED]: cn(TaskStyles.icon, TaskStyles.iconAwaiting),
    [TaskStateEnum.NO_COMMUNICATION]: cn(TaskStyles.icon),
    [TaskStateEnum.RESCHEDULED]: cn(TaskStyles.icon, TaskStyles.iconAwaiting),
  };

  return TITLES_MAP[taskState];
};

export const COMMENTABLE_TASK_STATUSES: TaskStateEnum[] = [
  TaskStateEnum.IN_WORK,
  TaskStateEnum.NEW,
  TaskStateEnum.POSTPONED,
  TaskStateEnum.RESCHEDULED,
];
