import { makeAutoObservable } from 'mobx';
import { OmsOrderBySerialNumber, OmsProvider } from '../../services/providers/oms.provider';

export class OmsStore {
  private readonly provider: OmsProvider = new OmsProvider();

  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public async getOrderBySerialNumber(serialNumber: number): Promise<OmsOrderBySerialNumber[]> {
    return this.provider.searchOmsOrderBySerialNumber(serialNumber);
  }
}
