import React, { memo, useCallback, useMemo, useState } from 'react';
import cn from 'classnames';

import { Icon, IconName } from '../common';

// import { ITab } from './interfaces';
import { Tab } from './Tab';

import styles from './styles.module.scss';
import { TabsMenu } from './TabsMenu';

export type IconTabLabel = IconName;

export function TabsRender<T, AT = never>(props: {
  tabs: readonly T[];
  availableTabs?: readonly AT[];
  value: T | null | undefined;
  onTabClick(tab: T): void;
  onAddTab?(tab: AT): void;
  getId?(value: T): string;
  getLabel(value: T | AT): string;
  getIcon?(tab: T): undefined | null | IconTabLabel;
  canAddTabs?: boolean;
  disabledPlus?: boolean;
}): JSX.Element | null;

export function TabsRender<T, AT>(props: {
  tabs: readonly T[];
  availableTabs?: readonly AT[];
  value: T | null | undefined;
  getTabLink(tab: T): string;
  onAddTab?(tab: AT): void;
  getId?(value: T | AT): string;
  getLabel(value: T | AT): string;
  getIcon?(tab: T): undefined | null | IconTabLabel;
  canAddTabs?: boolean;
  disabledPlus?: boolean;
}): JSX.Element | null;

export function TabsRender<T, AT>({
  tabs,
  availableTabs,
  value,
  onTabClick,
  getTabLink,
  onAddTab,
  getLabel,
  getId = getLabel,
  getIcon,
  canAddTabs = true,
  disabledPlus = false,
}: {
  tabs: readonly T[];
  availableTabs?: readonly AT[];
  value: T | null | undefined;
  onTabClick?(tab: T): void;
  getTabLink?(tab: T): string;
  onAddTab?(tab: AT): void;
  getId?(value: T | AT): string;
  getLabel(value: T | AT): string;
  getIcon?(tab: T): undefined | null | IconTabLabel;
  canAddTabs?: boolean;
  disabledPlus?: boolean;
}): JSX.Element | null {
  const [displayed, setDisplayed] = useState<Readonly<Record<number, true>>>({});

  const onShow = useCallback((index: number) => setDisplayed((prev) => ({ ...prev, [index]: true })), []);

  const onHide = useCallback(
    (index: number) =>
      setDisplayed((prev) => {
        const next = { ...prev };
        delete next[index];
        return next;
      }),
    [],
  );

  const maxDisplayedIndex = useMemo(() => {
    const result = +(Object.keys(displayed).pop() || -1);
    if (result >= 0) return result;
  }, [displayed]);

  const hiddenTabs = useMemo(
    () => (typeof maxDisplayedIndex == 'number' ? tabs.slice(maxDisplayedIndex + 1) : null),
    [maxDisplayedIndex, tabs],
  );

  const activeTabId = value == null ? null : getId(value);

  return (
    <div className={styles.root}>
      {!!tabs.length && (
        <nav className={styles.tabs}>
          {tabs.map((tab, i) => {
            const id = getId(tab);
            const iconName = getIcon?.(tab);
            const icon = iconName && <Icon name={iconName} className={cn(styles.icon, 'mr-1')} />;

            return (
              <Tab
                key={id}
                tab={tab}
                active={activeTabId === id}
                onTabClick={onTabClick}
                getTabLink={getTabLink}
                getLabel={getLabel}
                index={i}
                onHide={onHide}
                onShow={onShow}
                icon={icon}
              />
            );
          })}
        </nav>
      )}

      {(!!hiddenTabs?.length || availableTabs) && canAddTabs && (
        <div className={styles.actions}>
          {!!hiddenTabs?.length && (
            <TabsMenu
              icon="ellipsis"
              tabs={hiddenTabs}
              value={value}
              getTabLink={getTabLink}
              onTabClick={onTabClick}
              getId={getId}
              getLabel={getLabel}
              disabledPlus={disabledPlus}
            />
          )}

          {availableTabs && (
            <TabsMenu
              icon="plus"
              tabs={availableTabs}
              onTabClick={onAddTab}
              getId={getId}
              getLabel={getLabel}
              disabledPlus={disabledPlus}
            />
          )}
        </div>
      )}
    </div>
  );
}

export const Tabs = memo(TabsRender) as unknown as typeof TabsRender;
