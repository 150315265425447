import { plainToClass } from 'class-transformer';
import { action, computed, observable, runInAction } from 'mobx';

import GrpcService, { PlatformAdminApi } from 'src/services/GrpcService';

import { TokenResponseDto } from './AuthorizeStore.dto';

import { logger } from '@qlean/front-logger';

export type GetAuthTokenValues = PlatformAdminApi.SendEmailAndPassRequest & { isRememberMe: boolean };

export const REFRESH_TOKEN_TIME = 'RefreshTokenTime';

export type TUserProfile = {
  id: string;
  email: string;
};

export default class AuthorizeStore {
  @observable accessToken?: string;
  @observable refreshToken?: string;
  private refreshPromise?: Promise<unknown> | null = null;

  constructor() {
    this.accessToken = localStorage.getItem('accessToken') || undefined;
    this.refreshToken = localStorage.getItem('refreshToken') || undefined;
    if (this.accessToken) {
      GrpcService.setMetadata({ token: this.accessToken });
    }

    window.addEventListener('storage', (e) => {
      if (e.key === 'refreshToken') {
        logger.debug('[refreshToken] get from another tab', e);
        if (!e.newValue) {
          window.location.href = '/login';
        }
        this.refreshToken = e.newValue ?? '';
      }
      if (e.key === 'accessToken') {
        this.accessToken = e.newValue ?? '';
        GrpcService.setMetadata({ token: String(localStorage.getItem('accessToken')) });
      }
    });
  }

  setTokens(accessToken = '', refreshToken = ''): void {
    this.accessToken = accessToken;
    this.refreshToken = refreshToken;
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
    GrpcService.setMetadata({ token: String(localStorage.getItem('accessToken')) });
  }

  getTokens() {
    return {
      accessToken: this.accessToken,
      refreshToken: this.refreshToken,
    };
  }

  @action getAuthToken = (values: GetAuthTokenValues): Promise<void> => {
    logger.info('-req-getAuthToken-', values);
    const { isRememberMe, ...credentials } = values;

    return GrpcService.AuthorizeService.SendEmailAndPass(credentials).then((res) => {
      logger.info('-res-getAuthToken-', res);
      const { accessToken, refreshToken } = plainToClass(TokenResponseDto, res);

      if (accessToken) {
        // TODO Тут если падает парсер - разваливается приложение
        const id = this.parseToken(accessToken)?.uid;

        if (isRememberMe) {
          runInAction(() => {
            this.setTokens(accessToken, refreshToken);
          });
          localStorage.setItem('user_email', credentials.email);
          localStorage.setItem('user_id', id);
        } else {
          runInAction(() => {
            this.setTokens(accessToken, refreshToken);
          });

          sessionStorage.setItem('user_email', credentials.email);
          sessionStorage.setItem('user_id', id);
        }
      }
    });
  };

  @action removeAuthToken = (): void => {
    localStorage.clear();
    sessionStorage.clear();
  };

  @computed get userProfile(): TUserProfile | null {
    try {
      const email = sessionStorage.getItem('user_email') || localStorage.getItem('user_email');
      const id = sessionStorage.getItem('user_id') || localStorage.getItem('user_id');

      if (email && id) {
        return { id, email };
      }

      return null;
    } catch (err) {
      this.removeAuthToken();

      return null;
    }
  }

  @action refresh = (): Promise<void | unknown> => {
    if (this.refreshPromise) {
      return this.refreshPromise;
    }

    if (!this.refreshToken) {
      logger.warn('-res-refreshToken- empty refresh token');
      this.setTokens();

      return Promise.reject(new Error('-res-refreshToken- RefreshToken empty'));
    }

    logger.info('-req-refreshToken- Start refresh');
    localStorage.setItem(REFRESH_TOKEN_TIME, Date.now().toString());

    this.refreshPromise = GrpcService.AuthorizeService.RefreshToken(
      {
        refreshToken: this.refreshToken,
      },
      { ignoreInterceptors: true },
    )
      .then(({ refreshToken, accessToken }) => {
        this.setTokens(accessToken, refreshToken);
        logger.info('-res-refreshToken- refreshed ok');
        localStorage.removeItem(REFRESH_TOKEN_TIME);
        this.refreshPromise = null;
      })
      .catch(() => localStorage.removeItem(REFRESH_TOKEN_TIME));

    return this.refreshPromise;
  };

  private parseToken = (token: string): { uid: string } => JSON.parse(window.atob(token.split('.')[1]));
}
