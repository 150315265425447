import axios, { AxiosInstance, AxiosRequestHeaders, AxiosResponse, AxiosRequestConfig } from 'axios';

class BaseApiService {
  private static _instance: AxiosInstance;

  /**
   * Конструктор нужен только для того, чтобы сделать его приватным.
   * Это позволит не допустить возможность создания нового экземпляра через new BaseApiService снаружи класса.
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  public static createHttpClient(baseUrl: string): AxiosInstance {
    const headers: AxiosRequestHeaders = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };

    if (!BaseApiService._instance) {
      BaseApiService._instance = axios.create({
        baseURL: baseUrl,
        headers,
      });
    }

    return BaseApiService._instance;
  }

  public post<T, R>(endpoint: string, body: T): Promise<AxiosResponse<Promise<R>, AxiosRequestConfig>> {
    return BaseApiService._instance.post<Promise<R>>(endpoint, body);
  }
}

export default BaseApiService;
