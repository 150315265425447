import GrpcService, { PlatformAdminApi } from 'src/services/GrpcService';

import {
  ICreate,
  ISearch,
  IUpdate,
  IRestore,
  IFindById,
  IRemove,
} from 'src/services/ServiceLocator/ServiceLocator.interface';
import { RequestController } from 'src/utils/decorators/request-controller';

class ManagerSkillsServiceProvider implements ISearch, ICreate, IUpdate, IRestore, IFindById, IRemove {
  @RequestController({ logger: true, retry: true, debounce: true })
  search(params: PlatformAdminApi.ISearchManagerSkillRequest): Promise<PlatformAdminApi.ISearchManagerSkillResponse> {
    return GrpcService.ManagementApiSkillsService.Search(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  create(params: PlatformAdminApi.IManagerSkill): Promise<PlatformAdminApi.ManagerSkillResponse> {
    return GrpcService.ManagementApiSkillsService.Create(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  update(params: PlatformAdminApi.IManagerSkill): Promise<PlatformAdminApi.ManagerSkillResponse> {
    return GrpcService.ManagementApiSkillsService.Update(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  restore(params: PlatformAdminApi.IUuidRequest): Promise<PlatformAdminApi.ManagerSkillResponse> {
    return GrpcService.ManagementApiSkillsService.Restore(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  remove(params: PlatformAdminApi.IUuidRequest): Promise<PlatformAdminApi.ManagerSkillResponse> {
    return GrpcService.ManagementApiSkillsService.Remove(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  findById(params: PlatformAdminApi.IUuidRequest): Promise<PlatformAdminApi.ManagerSkillResponse> {
    return GrpcService.ManagementApiSkillsService.FindById(params);
  }
}

export const managerSkillsServiceProvider = new ManagerSkillsServiceProvider();
export type TManagerSkillsServiceProvider = typeof managerSkillsServiceProvider;
