import { ADDRESS_STATUSES, IMetroStation, IPresenceRegion, TAddress, TAddressFeedback } from './address.interfaces';
import { FIELD_FEEDBACK_TYPES } from '../../../../common/FieldFeedback';
import { PlatformAdminApi } from '../../../../../../services/GrpcService';

export const PRESENCE_REGIONS: IPresenceRegion[] = [
  {
    id: 'region-1',
    title: 'Москва',
    name: 'msk',
  },
  {
    id: 'region-2',
    title: 'Санкт-Петербург',
    name: 'spb',
  },
  {
    id: 'region-3',
    title: 'Московская',
    name: 'msk_obl',
  },
  {
    id: 'region-4',
    title: 'Ленинградская',
    name: 'len_obl',
  },
];

export const TITLES = {
  address: 'Адрес',
  flat: 'Квартира',
  intercom: 'Домофон',
  floor: 'Этаж',
  entrance: 'Подъезд',
  comment: 'Комментарий',
  region: 'Регион',
  city: 'Город',
  settlement: 'Населенный пункт',
  area: 'Район',
  street: 'Улица',
  block: 'Корпус / Строение',
  building: 'Строение',
  house: 'Дом',
  label: 'Название адреса',
};

export const PLACEHOLDERS = {
  label: 'Дом, работа...',
};

export const ADDRESS_FEEDBACKS: TAddressFeedback = {
  [ADDRESS_STATUSES.DANGER]: { text: 'Недостаточно информации', type: FIELD_FEEDBACK_TYPES.DANGER },
  [ADDRESS_STATUSES.NEED_HOUSE]: { text: 'Укажите дом', type: FIELD_FEEDBACK_TYPES.DANGER },
  [ADDRESS_STATUSES.NEED_FLAT]: { text: 'Укажите квартиру', type: FIELD_FEEDBACK_TYPES.WARNING },
  [ADDRESS_STATUSES.SUCCESS]: { text: 'Отлично!', type: FIELD_FEEDBACK_TYPES.SUCCESS },
};

// https://metrofanat.ru/lines
export const METRO_LINE_COLORS: IMetroStation[] = [
  { label: '1', color: '#DA2128', line: 'Сокольническая' },
  { label: '2', color: '#48B85E', line: 'Замоскворецкая' },
  { label: '3', color: '#0079BF', line: 'Арбатско-Покровская' },
  { label: '4', color: '#00C2F3', line: 'Филёвская' },
  { label: '5', color: '#894E35', line: 'Кольцевая' },
  { label: '6', color: '#F58320', line: 'Калужско-Рижская' },
  { label: '7', color: '#8E479B', line: 'Таганско-Краснопресненская' },
  { label: '8', color: '#FFC61A', line: 'Калининская' },
  { label: '8A', color: '#FFC61A', line: 'Солнцевская' },
  { label: '9', color: '#A1A2A3', line: 'Серпуховско-Тимирязевская' },
  { label: '10', color: '#B5D445', line: 'Люблинско-Дмитровская' },
  { label: '11', color: '#6AC9C8', line: 'Большая кольцевая линия' },
  { label: '12', color: '#ACBFE3', line: 'Бутовская' },
  { label: '13', color: '#8378FF', line: 'Монорельс' },
  { label: '14', color: '#FFA8AF', line: 'МЦК' },
  { label: '15', color: '#E66AC0', line: 'Некрасовская' },
  { label: 'D1', color: '#FAA400', line: 'Белорусско-Савеловский' },
  { label: 'D2', color: '#EA4184', line: 'Некрасовская' },
  { label: '', color: '#D6083B', line: 'Кировско-Выборгская' },
  { label: '', color: '#0078C9', line: 'Московско-Петроградская' },
  { label: '', color: '#009A49', line: 'Невско-Василеостровская' },
  { label: '', color: '#EA7125', line: 'Лахтинско-Правобережная' },
  { label: '', color: '#EA7125', line: 'Правобережная' },
  { label: '', color: '#702785', line: 'Фрунзенско-Приморская' },
];

export const ADDRESS_TYPE_OPTIONS: PlatformAdminApi.ADDRESS_TYPE[] = [
  PlatformAdminApi.ADDRESS_TYPE.DEFAULT,
  PlatformAdminApi.ADDRESS_TYPE.RESIDENCE_ADDRESS,
];

export const ADDRESS_TYPE_TRANSLATION: Partial<Record<PlatformAdminApi.ADDRESS_TYPE, string>> = {
  [PlatformAdminApi.ADDRESS_TYPE.DEFAULT]: 'Создан пользователем',
  [PlatformAdminApi.ADDRESS_TYPE.RESIDENCE_ADDRESS]: 'Проживание',
};

export const ADDRESS_DETAILS_KEYS: (keyof NonNullable<TAddress['detail']>)[] = [
  'region',
  'city',
  'settlement',
  'area',
  'street',
  'block',
  'house',
  'flat',
  'floor',
  'comment',
  'intercom',
  'entrance',
];
