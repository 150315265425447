import { wmsSkusServiceProvider } from './service-providers/WmsSkusService.provider';
import { AppSubModule } from 'src/interfaces';
import { CATALOGS_PROVIDER_TOKENS } from '../constants';

class WmsSkusModule extends AppSubModule {
  public providers = [
    {
      token: CATALOGS_PROVIDER_TOKENS.WMS_SKUS_SERVICE,
      provider: wmsSkusServiceProvider,
    },
  ];
}

export const wmsSkusModule = new WmsSkusModule();
