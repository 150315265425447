import React, { useEffect, useMemo } from 'react';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { Breadcrumb } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import { routes } from 'src/routes';
import AppStore from 'src/store/AppStore';

import styles from './Breadcrumbs.styles.module.scss';

export const Breadcrumbs = (): JSX.Element => {
  const routesConfig = useMemo(() => routes(), []);
  const breadcrumbs = useBreadcrumbs(routesConfig);
  const current = breadcrumbs[breadcrumbs.length - 1];

  useEffect(() => {
    // @ts-expect-error todo
    const title = routesConfig.find((route) => route.path === current.match.path)?.title || '';
    AppStore.setPageMeta(title);
    // @ts-expect-error todo
  }, [current.match.path, routesConfig]);

  const history = useHistory();

  return (
    <Breadcrumb className={styles.breadcrumbs}>
      {breadcrumbs.map(({ match, breadcrumb }, index) => {
        // @ts-expect-error todo
        const route = routesConfig.find((route) => route.path === match.path);
        const isLastIndex = breadcrumbs.length - 1 === index;

        return (
          <Breadcrumb.Item
            className="font-regular-14"
            key={match.url}
            style={{ cursor: 'pointer' }}
            linkAs="span"
            onClick={() => {
              if (route?.pureComponent) {
                history.push(match.url);
              }
            }}
            active={isLastIndex}>
            {breadcrumb}
          </Breadcrumb.Item>
        );
      })}
    </Breadcrumb>
  );
};
