import { RequestController } from 'src/utils/decorators/request-controller';
import GrpcService, { PlatformAdminApi } from 'src/services/GrpcService';

class WmsPlacesServiceProvider {
  @RequestController({ logger: true, debounce: true })
  search(request: PlatformAdminApi.PlacesRequest): Promise<PlatformAdminApi.IWarehousesList> {
    return GrpcService.PlacesService.RetrievePlaces(request);
  }

  @RequestController({ logger: true })
  async findPlaceById({ id, warehouseId }: PlatformAdminApi.IPlaceRequest): Promise<PlatformAdminApi.IPlaceItem> {
    return GrpcService.PlacesService.FindPlaceById({ id, warehouseId });
  }
}

export const wmsPlacesServiceProvider = new WmsPlacesServiceProvider();
export type TWmsPlacesServiceProvider = typeof wmsPlacesServiceProvider;
