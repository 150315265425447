import 'reflect-metadata';
import React from 'react';
import * as Sentry from '@sentry/react';
import { observer } from 'mobx-react';
import { BrowserRouter, Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { Login } from '@qlean/admin-web-login';
import { ApolloProvider } from '@apollo/client';

import NotFound from 'src/components/NotFound';
import AppStore from 'src/store/AppStore';
import AuthorizeStore from 'src/store/AuthorizeStore';
import MenuStore from './store/MenuStore';

import { DefaultLayout, routes } from './routes';
import { menu } from './menu';
import { I18nInitPromise } from './i18n';
import { logger } from '@qlean/front-logger';

import '@qlean/admin-web-appwork/dist/css/bootstrap-qlean.css';
import '@qlean/admin-web-appwork/dist/css/appwork-qlean.css';
import '@qlean/admin-web-appwork/dist/css/theme-corporate-qlean.css';
import '@qlean/admin-web-appwork/dist/css/colors-qlean.css';
import '@qlean/admin-web-appwork/dist/css/uikit.css';

import '@qlean/admin-web-appwork/vendor/react/libs/react-bootstrap-typeahead/react-bootstrap-typeahead.scss';
import '@qlean/admin-web-appwork/vendor/react/libs/react-datepicker/react-datepicker.scss';
import '@qlean/admin-web-appwork/vendor/react/libs/react-select/react-select.scss';
import '@qlean/admin-web-appwork/vendor/react/libs/react-quill/editor.scss';
import '@qlean/admin-web-appwork/vendor/react/libs/react-image-lightbox/react-image-lightbox.scss';
import '@qlean/admin-web-appwork/vendor/react/libs/sweetalert2/sweetalert2.scss';
import '@qlean/admin-web-appwork/dist/css/pages/clients.css';
import '@qlean/admin-web-appwork/vendor/react/libs/rc-input-number/rc-input-number.scss';
import '@qlean/admin-web-appwork/vendor/fonts/ionicons.css';
import '@qlean/admin-web-appwork/vendor/fonts/fontawesome.css';

import './App.scss';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ServiceLocator from './services/ServiceLocator/ServiceLocator';
import { serviceProviders } from './serviceProviders';
import { BaseFetchStore } from './store/BaseFetch.store';
import { AsyncCacheStorage } from './services/CacheStorage';
import { ErrorFallback } from './components/ErrorFallback/ErrorFallback';
import { ConfirmDialog } from './libs/ui-kit/confirm-dialog/ConfirmDialog';
import { apolloClient } from './services/GraphQL';

declare const REACT_APP_BASENAME: string;

function RedirectToLogin() {
  const location = useLocation();
  const { defaultRoute, loginRoute } = AppStore;
  const loginRedirectTo = {
    pathname: loginRoute,
    state: { from: location.pathname === loginRoute ? defaultRoute : location.pathname },
  };

  return <Redirect to={loginRedirectTo} />;
}

@observer
export default class App extends React.Component {
  routes = this.fillRoutes();

  constructor(props: Record<string, unknown>) {
    super(props);
    // Set app loading class
    MenuStore.setMenu(menu);
    AsyncCacheStorage.createAsyncCache();
    this.registerServiceProviders();
    this.updateDocumentTitle();
    this.updateLoadingClass();
    this.updateSplashScreen();
    this.checkAuthorization();
    this.configureSentry();
    // Дожидаемся подгрузки локализации
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    I18nInitPromise.then(() => {
      AppStore.offLoading();
    });
  }

  setTitle(title: string): void {
    logger.warn('Method deprecated. Use: AppStore.setTitle(this.title); OR this.props.appStore.setTitle(this.title);');
    AppStore.setPageMeta(title);
  }

  private updateSplashScreen() {
    const { isLoading } = AppStore;
    const splashScreen = document.querySelector('.app-splash-screen');

    if (!splashScreen) {
      return;
    }

    if (!isLoading) {
      // @ts-expect-error TODO
      splashScreen.style.opacity = 0;

      setTimeout(() => {
        if (splashScreen && splashScreen.parentNode) {
          splashScreen.parentNode.removeChild(splashScreen);
        }
      }, 300);
    }
  }

  private updateLoadingClass() {
    const { isLoading } = AppStore;
    const { classList } = document.documentElement;

    if (isLoading) classList.add('app-loading');
    else classList.remove('app-loading');
  }

  private updateDocumentTitle() {
    document.title = AppStore.documentTitle;
  }

  private checkAuthorization(): void {
    AppStore.isAuthorized = AppStore.isLogged;
  }

  private configureSentry(): void {
    const { userProfile } = AuthorizeStore;

    if (!userProfile) {
      return;
    }

    Sentry.setUser({
      email: userProfile.email,
      id: userProfile.id,
    });
  }

  private registerServiceProviders(): void {
    const serviceLocator = new ServiceLocator();
    serviceProviders.forEach(({ token, provider }) => {
      serviceLocator.register(token, provider);
    });
    BaseFetchStore.serviceLocator = serviceLocator;
  }

  private fillRoutes() {
    return routes().map((route) => {
      const Layout = route.layout || DefaultLayout;
      const exact = typeof route.exact === 'undefined' ? true : route.exact;

      return (
        <Route
          path={route.path}
          exact={exact}
          render={(props) => {
            // On small screens collapse sidenav
            // @ts-expect-error TODO
            if (window.layoutHelpers && window.layoutHelpers.isSmallScreen()) {
              // @ts-expect-error TODO
              window.layoutHelpers.setCollapsed(true, false);
            }

            return (
              <Layout {...props}>
                <route.pureComponent appStore={AppStore} menuStore={MenuStore} {...props} />
              </Layout>
            );
          }}
          key={route.path}
        />
      );
    });
  }

  componentWillUnmount() {
    AsyncCacheStorage.destroy();
  }

  render() {
    this.updateDocumentTitle();
    this.updateLoadingClass();
    this.updateSplashScreen();
    const { signIn, defaultRoute, loginRoute, isAuthorized } = AppStore;

    return (
      <Sentry.ErrorBoundary fallback={ErrorFallback}>
        <ApolloProvider client={apolloClient}>
          <BrowserRouter basename={REACT_APP_BASENAME}>
            <Switch>
              {/* Редирект с корня на роут по умолчанию */}
              {defaultRoute !== '/' && <Redirect from="/" to={defaultRoute} exact />}

              {/* Добавляем маршрут со страницей логина если используем авторизацию */}
              <Route
                path={loginRoute}
                exact
                render={(props) => (
                  <Login
                    setTitle={this.setTitle}
                    {...props}
                    onSubmit={signIn}
                    path={{ linkSubmit: defaultRoute, linkTo: loginRoute }}
                  />
                )}
              />

              {/* Редирект на логин или рендерим внутренние страницы */}
              {isAuthorized ? this.routes : <RedirectToLogin />}

              {/* Заглушка для отсутствующих роутов */}
              <Route
                path="*"
                exact
                render={(props) => (
                  <DefaultLayout {...props}>
                    <NotFound {...props} />
                  </DefaultLayout>
                )}
              />
            </Switch>
            <ToastContainer />
            <ConfirmDialog />
          </BrowserRouter>
        </ApolloProvider>
      </Sentry.ErrorBoundary>
    );
  }
}
