import { AppSubModule } from 'src/interfaces';
import { CATALOGS_PROVIDER_TOKENS } from '../constants';
import { appServiceProvider } from './service-providers/AppService.provider';

class AppListModule extends AppSubModule {
  public providers = [
    {
      token: CATALOGS_PROVIDER_TOKENS.APP_SERVICE,
      provider: appServiceProvider,
    },
  ];
}

export const appListModule = new AppListModule();
