import { IRoute } from 'src/routes';
import { lazy } from 'src/utils';

export const ordersRoutes = (fallback: JSX.Element): IRoute[] => [
  // {
  //   path: '/users/orders/new/',
  //   pureComponent: lazy(() => import('./pages/order-view'), fallback),
  //   exact: true,
  //   title: 'Создание заказа',
  // },
  {
    path: '/users/orders',
    exact: true,
  },
  {
    path: '/users/:userId/orders/:orderId/',
    pureComponent: lazy(() => import('./pages/order-view'), fallback),
    exact: true,
    title: 'Просмотр заказа',
  },
  {
    path: '/users/external-orders',
    exact: true,
  },
  {
    path: '/users/:userId/external-orders/:orderId/',
    pureComponent: lazy(() => import('./pages/order-view'), fallback),
    exact: true,
    title: 'Просмотр заказа CRM',
  },
  {
    path: '/users/orders/new/:ticketId',
    pureComponent: lazy(() => import('./pages/order-view'), fallback),
    exact: true,
    title: 'Создание заказа',
  },
  {
    path: '/users/orders/:id/:ticketId',
    pureComponent: lazy(() => import('./pages/order-view'), fallback),
    exact: true,
    title: 'Просмотр заказа',
  },
];
