import React, { memo } from 'react';
import { Button } from 'react-bootstrap';
import { Form, Formik } from 'formik';

import { StringField } from 'src/libs/ui-kit';

import { ShortcutConfig, TimeShortcutFields } from '../../TimeShortcutFields';

export interface TicketTaskPlanForm {
  readonly label: string;
  readonly shortcuts: readonly ShortcutConfig[];
  onSubmit(value: TicketTaskPlanFormValues): void;
  onClose?: () => void;
}

export const TicketTaskPlanForm = memo<TicketTaskPlanForm>(({ label, onClose, onSubmit, shortcuts }) => (
  <Formik initialValues={FORM_VALUES} onSubmit={onSubmit}>
    <Form className="p-3">
      <div className="font-semi-bold-sm mb-2">{label}</div>

      <TimeShortcutFields name="date" shortcuts={shortcuts} />
      <StringField name="comment" variant="textarea" label="Комментарий" />

      <div>
        <Button type="submit" className="mr-2">
          Подтвердить
        </Button>

        <Button type="button" variant="default" onClick={onClose}>
          Отменить
        </Button>
      </div>
    </Form>
  </Formik>
));

export interface TicketTaskPlanFormValues {
  readonly date: null | Date;
  readonly comment: string;
}

const FORM_VALUES: TicketTaskPlanFormValues = {
  date: null,
  comment: '',
};
