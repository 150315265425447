import { AppSubModule } from 'src/interfaces';
import { PLT_CORE_PROVIDER_TOKENS } from 'src/modules/plt-core/constants';
import { unitServiceProvider } from 'src/modules/plt-core/service-providers';

class UnitsListModule extends AppSubModule {
  public providers = [
    {
      token: PLT_CORE_PROVIDER_TOKENS.UNIT_SERVICE,
      provider: unitServiceProvider,
    },
  ];
}

export const unitsListModule = new UnitsListModule();
