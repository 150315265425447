import { useField } from 'formik';
import React, { ChangeEvent, FC } from 'react';
import { Form } from 'react-bootstrap';
import { FieldLabel } from 'src/libs/ui-kit';
import Image from 'react-bootstrap/Image';
import { getDataUrl } from 'src/utils';
import { logger } from '@qlean/front-logger';
import styles from './ImageField.module.scss';
import classNames from 'classnames';

interface IImageFieldProps {
  name: string;
  label: string;
  accept?: string;
  isEditable?: boolean;
}

interface IImageInformation {
  base64: string;
  name?: string;
}

export const ImageField: FC<IImageFieldProps> = ({ name, label, accept, isEditable = true }) => {
  const defaultImageFormats: string = '.jpg, .jpeg, .png';
  const [{ value }, _, { setValue }] = useField<IImageInformation>(name);

  const handleFiles = async (event: ChangeEvent<HTMLInputElement>) => {
    const [file]: File[] = [...(event.target.files || [])];

    try {
      const base64: string = await getDataUrl(file);
      setValue({ base64, name: file.name });
    } catch (error) {
      logger.error(error);
    }
  };

  const CustomField = () => (
    <div className="mb-8">
      <label className="btn btn-secondary btn-file">
        {value.base64 ? 'Обновить изображение' : 'Загрузить'}
        <input
          type="file"
          name={name}
          accept={accept || defaultImageFormats}
          onChange={handleFiles}
          style={{ display: 'none' }}
        />
      </label>
    </div>
  );

  const EmptyImage: FC = () => (
    <div>
      <FieldLabel label={label} />
      <div className={classNames(styles.emptyImageContainer, 'p-4 mb-3')}>
        <div className="font-semi-bold-sm text-muted">Чтобы добавить изображение, нажмите кнопку "Загрузить"</div>
      </div>
    </div>
  );

  return (
    <div>
      {value.base64 ? (
        <div className="mb-3">
          <FieldLabel label={label} />
          <div>
            <Image className="mw-100" src={value.base64} />
          </div>
        </div>
      ) : (
        <EmptyImage />
      )}
      <div>
        {isEditable && (
          <Form.Group controlId="sku-image">
            <Form.File as={CustomField} custom />
          </Form.Group>
        )}
      </div>
    </div>
  );
};
