import GrpcService, { PlatformAdminApi } from 'src/services/GrpcService';

import {
  ICreate,
  ISearch,
  IUpdate,
  IRestore,
  IFindById,
  IRemove,
} from 'src/services/ServiceLocator/ServiceLocator.interface';
import { RequestController } from 'src/utils/decorators/request-controller';

class NotificationServiceProvider implements ISearch, ICreate, IUpdate, IRestore, IFindById, IRemove {
  @RequestController({ logger: true, retry: true, debounce: true })
  search(params: PlatformAdminApi.ISearchNotificationRequest): Promise<PlatformAdminApi.ISearchNotificationResponse> {
    return GrpcService.NotificationService.Search(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  create(params: PlatformAdminApi.INotificationRequest): Promise<PlatformAdminApi.INotification> {
    return GrpcService.NotificationService.Upsert(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  update(params: PlatformAdminApi.INotificationRequest): Promise<PlatformAdminApi.INotification> {
    return GrpcService.NotificationService.Upsert(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  restore(params: PlatformAdminApi.IUuidRequest): Promise<PlatformAdminApi.INotification> {
    return GrpcService.NotificationService.Restore(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  remove(params: PlatformAdminApi.IUuidRequest): Promise<PlatformAdminApi.INotification> {
    return GrpcService.NotificationService.Remove(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  findById(params: PlatformAdminApi.IUuidRequest): Promise<PlatformAdminApi.INotification> {
    return GrpcService.NotificationService.FindById(params);
  }
}

export const notificationServiceProvider = new NotificationServiceProvider();
export type TNotificationServiceProvider = typeof notificationServiceProvider;
