import {
  PresenterTaskItem,
  PresenterTicketItem,
  RequestedTaskItem,
  RequestedTicketItem,
  TRedirectionLinks,
} from '../../../interfaces';
import i18n from '../../../../../i18n';
import { TicketTranslations } from '../../../enums';
import { COMMENTABLE_TASK_STATUSES, TICKET_DATE_TIME_FORMAT, TICKET_STATUS_MAP } from '../../../constants';
import { logger } from '@qlean/front-logger';
import { DateTimeService } from '../../../../../services/DateTimeService';
import { AdapterFunction } from '../interfaces';
import { CategoryTypeEnum, CommonOrder } from 'src/services/GraphQL';
import { taskExecutionHistoryAdapter } from './TaskExecutionHistoryAdapter.helper';

interface IProfileInfo {
  id: string;
  firstName: string;
  lastName: string;
  middleName: string;
}

export const ticketAdapter: AdapterFunction<RequestedTicketItem, PresenterTicketItem, string> = (
  ticket: RequestedTicketItem,
  serialNumber?: string,
): PresenterTicketItem => ({
  id: ticket.id,
  number: ticket.number,
  serialNumber: `${serialNumber}` || '001',
  badgeText: badgeText(ticket.state),
  badgeType: badgeType(ticket.state),
  createdAt: createdAtString(ticket.createdAt),
  tasksList: tasksList(ticket),
  state: ticket.state,
  clientSsoId: ticket.clientSsoId,
  redirectionLink: generateTaskRedirectionLink(ticket),
  client: getProfileInfo(ticket.clientSsoProfile as IProfileInfo),
  assingee: getProfileInfo(ticket.assigneeSsoProfile as IProfileInfo),
  creatorSsoId: ticket.creatorSsoId,
});

const badgeText = (ticketState: string) => i18n.t(TicketTranslations[ticketState]);
const badgeType = (ticketState: string) => {
  const type = TICKET_STATUS_MAP[ticketState];

  if (!type) {
    logger.error(i18n.t(TicketTranslations.TICKET_STATUS_ERROR));
  }

  return type;
};

const createdAtString = (createdAt: string) => DateTimeService.format(createdAt, TICKET_DATE_TIME_FORMAT);
const tasksList = (ticket: RequestedTicketItem): PresenterTaskItem[] =>
  [...ticket.tasks.edges]
    .sort((task1, task2) => DateTimeService.difference(task2.node.createdAt, task1.node.createdAt))
    .map((requestedTask: RequestedTaskItem): PresenterTaskItem => {
      const {
        node: { id, title, createdAt, state, entity, executionHistory },
      } = requestedTask;

      return {
        id,
        title,
        createdAt: createdAtString(createdAt),
        state,
        entity,
        redirectionLink: generateTaskRedirectionLink(ticket),
        executionHistory: taskExecutionHistoryAdapter(executionHistory),
        // Регулярка разбивает строку в массив по лайн брейкам ('/n')
        comment: requestedTask.node.comment?.match(/[^\r\n]+/gu) ?? [],
        canComment: COMMENTABLE_TASK_STATUSES.includes(state),
        hasComments: Boolean(requestedTask.node.comment),
      };
    });

const generateTaskRedirectionLink = (ticket: RequestedTicketItem): string | null => {
  const links: CommonOrder[] = (ticket.links || []).filter(
    (link) => link.__typename === 'CommonOrder',
  ) as CommonOrder[];

  const redirectionLinksMap: TRedirectionLinks = {
    [CategoryTypeEnum.PROFILE]: `/users/customers/${ticket.clientSsoId}`,
    [CategoryTypeEnum.ORDER]: `/users/${ticket.clientSsoId}/orders/${links[0]?.serialNumber}`,
    EXTERNAL_LINK: `/users/${ticket.clientSsoId}/external-orders/${links[0]?.serialNumber}`,
    [CategoryTypeEnum.CONSULTATION]: null,
    [CategoryTypeEnum.ORDER_PROBLEM]: null,
    [CategoryTypeEnum.BUG]: null,
    [CategoryTypeEnum.ORDER_PROBLEM]: null,
  };

  if (ticket.category.type === CategoryTypeEnum.ORDER && (ticket.links?.[0] as CommonOrder)?.isExternal) {
    return redirectionLinksMap.EXTERNAL_LINK;
  }

  return redirectionLinksMap[ticket.category.type];
};

const getProfileInfo = (profile: IProfileInfo): PresenterTicketItem['client'] => {
  const fullName: string = [profile.lastName, profile.firstName, profile.middleName].filter(Boolean).join(' ');

  return {
    id: profile.id,
    fullName,
  };
};
