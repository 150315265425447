import { IAdapter, IAddressDetail, ICoordinates, ISuggestion, TAddress } from '../address.interfaces';
import { AddressDetailModel, AddressRelModel, CoordinatesModel } from '../models';

interface IAddressData {
  value: TAddress;
  address: ISuggestion;
}

class DadataAddressAdapter implements IAdapter<IAddressData, TAddress> {
  adapt({ value, address }: IAddressData): TAddress {
    return new AddressRelModel(
      value?.id,
      value?.label,
      address?.value,
      address?.unrestricted_value,
      JSON.stringify(address.data),
      DadataAddressAdapter.adaptDetails({ value, address }),
      DadataAddressAdapter.adaptCoordinates({ lat: Number(address.data.geo_lat), lng: Number(address.data.geo_lon) }),
      address.data.metro || [],
      value?.type,
    );
  }

  private static adaptCoordinates({ lat, lng }: ICoordinates): CoordinatesModel | null {
    const hasCoordinates: boolean = !!lat && !!lng;
    const coordinates: ICoordinates = new CoordinatesModel(lat, lng);

    return hasCoordinates ? coordinates : null;
  }

  private static adaptDetails({ value, address }: IAddressData): IAddressDetail {
    return new AddressDetailModel(
      address.data.region_with_type || '',
      address.data.city_with_type,
      address.data.settlement_with_type || '',
      address.data.area_with_type || '',
      address.data.street_with_type || '',
      ` ${address.data.block_type || ''} ${address.data.block || ''}`,
      ` ${address.data.house_type || ''} ${address.data.house || ''}`,
      value?.detail?.floor || '',
      ` ${address.data.flat_type || ''} ${address.data.flat || ''}`,
      value?.detail?.comment || '',
      value?.detail?.intercom || '',
      value?.detail?.entrance || '',
    );
  }
}

export default new DadataAddressAdapter();
