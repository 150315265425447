import { AppModule, Menu, MENU_TYPES } from 'src/interfaces';
import { IRoute } from 'src/routes';
import { IProviderRecord } from 'src/services/ServiceLocator/ServiceLocator.interface';

import { CONTRACTORS_PROVIDER_TOKENS } from './constants';
import { contractorsRoutes } from './contractors.routes';
import { contractorProvider } from './service-providers';

class ContractorsModule extends AppModule {
  routes = (fallback: JSX.Element): IRoute[] => contractorsRoutes(fallback);

  menu = (): Menu[] => [
    {
      type: MENU_TYPES.ROUTER,
      text: 'Исполнители',
      link: '/users/contractors/',
    },
  ];

  providers: IProviderRecord[] = [
    {
      token: CONTRACTORS_PROVIDER_TOKENS.CONTRACTOR_PROVIDER,
      provider: contractorProvider,
    },
  ];
}

export const contractorsModule = new ContractorsModule();
