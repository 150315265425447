import { PresenterTicketConnection } from 'src/features/Tickets/interfaces';
import { TicketEdge, TicketsConnection } from 'src/services/GraphQL';
import { AdapterFunction } from '../interfaces';
import { ticketAdapter } from './AdaptTicket.helper';

export const ticketConnectionAdapter: AdapterFunction<TicketsConnection | null, PresenterTicketConnection> = (
  requestedTicketConnection,
) => {
  const ticketsEdges: ReadonlyArray<TicketEdge> = requestedTicketConnection?.edges ?? [];
  const ticketsLength: number = ticketsEdges.length;
  const currentCursor: string = ticketsEdges[ticketsLength - 1]?.cursor ?? '';

  return {
    tickets: (requestedTicketConnection?.edges ?? []).map((ticket) => ticketAdapter(ticket.node)),
    hasNextPage: requestedTicketConnection?.pageInfo?.hasNextPage ?? false,
    currentCursor,
  };
};
