import GrpcService from 'src/services/GrpcService';
import { RequestController } from '../../../../utils/decorators/request-controller';
import {
  ICreate,
  IFindById,
  IRemove,
  IRestore,
  ISearch,
  IUpdate,
} from '../../../../services/ServiceLocator/ServiceLocator.interface';
import {
  TWageCreatePage,
  TWageEditPage,
  TWageResponse,
  TWageSearchRequest,
  TWagesList,
  TWageSuggestOptionsRequest,
  TWageSuggestOptionsResponse,
  TWageSuggestPositionsRequest,
  TWageSuggestPositionsResponse,
  TWageSuggestRegionsRequest,
  TWageSuggestRegionsResponse,
  TWageSuggestRegulationsRequest,
  TWageSuggestRegulationsResponse,
  TWageSuggestSpecializationsRequest,
  TWageSuggestSpecializationsResponse,
  TWageViewResponse,
} from '../Wages.interfaces';
import { TUuidRequest } from '../../../../interfaces';

class WagesProvider implements ISearch, IFindById, ICreate, IUpdate, IRestore, IRemove {
  @RequestController({ logger: true, debounce: true, retry: true })
  public search(params: TWageSearchRequest): Promise<TWagesList> {
    return GrpcService.WageService.Search(params);
  }

  @RequestController({ logger: true, debounce: true, retry: true })
  public async findById(params: TUuidRequest): Promise<TWageViewResponse> {
    return GrpcService.WageService.FindById(params);
  }

  @RequestController({ logger: true, debounce: true, retry: true })
  public create(params: TWageCreatePage): Promise<TWageResponse> {
    return GrpcService.WageService.Create(params);
  }

  @RequestController({ logger: true, debounce: true, retry: true })
  public update(params: TWageEditPage): Promise<TWageResponse> {
    return GrpcService.WageService.Update(params);
  }

  @RequestController({ logger: true, debounce: true, retry: true })
  public remove(params: TUuidRequest): Promise<TWageViewResponse> {
    return GrpcService.WageService.Remove(params);
  }

  @RequestController({ logger: true, debounce: true, retry: true })
  public restore(params: TUuidRequest): Promise<TWageViewResponse> {
    return GrpcService.WageService.Restore(params);
  }

  @RequestController({ logger: true, debounce: true, retry: true })
  public retrieveEditWage(params: TUuidRequest): Promise<TWageEditPage> {
    return GrpcService.WageService.RetrieveEditWage(params);
  }

  @RequestController({ logger: true, debounce: true, retry: true })
  public suggestRegulations(params: TWageSuggestRegulationsRequest): Promise<TWageSuggestRegulationsResponse> {
    return GrpcService.WageService.SuggestRegulations(params);
  }

  @RequestController({ logger: true, debounce: true, retry: true })
  public suggestSpecializations(
    params: TWageSuggestSpecializationsRequest,
  ): Promise<TWageSuggestSpecializationsResponse> {
    return GrpcService.WageService.SuggestSpecializations(params);
  }

  @RequestController({ logger: true, debounce: true, retry: true })
  public suggestPositions(params: TWageSuggestPositionsRequest): Promise<TWageSuggestPositionsResponse> {
    return GrpcService.WageService.SuggestPositions(params);
  }

  @RequestController({ logger: true, debounce: true, retry: true })
  public suggestRegions(params: TWageSuggestRegionsRequest): Promise<TWageSuggestRegionsResponse> {
    return GrpcService.WageService.SuggestRegions(params);
  }

  @RequestController({ logger: true, debounce: true, retry: true })
  public suggestOptions(params: TWageSuggestOptionsRequest): Promise<TWageSuggestOptionsResponse> {
    return GrpcService.WageService.SuggestOptions(params);
  }
}

export const wagesProvider = new WagesProvider();
export type TWagesProvider = typeof wagesProvider;
