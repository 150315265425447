import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/ru';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import { IDifferenceOptions } from './interfaces';

export const DEFAULT_DATE_FORMAT = 'DD.MM.YYYY HH:mm';
export const DEFAULT_TIME_ZONE = 'Europe/Moscow';

dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

export const DAYS_OF_WEEK = ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'];

export const MONTH_GENITIVE_NAMES = [
  'января',
  'февраля',
  'марта',
  'апреля',
  'мая',
  'июня',
  'июля',
  'августа',
  'сентября',
  'октября',
  'ноября',
  'декабря',
];

export class DateTimeService {
  public static format(date: Date | string | Dayjs, format?: string): string {
    if (!date) return '';

    return dayjs(date).format(format || DEFAULT_DATE_FORMAT);
  }

  public static formatTz(params: {
    date?: Date | string | null;
    format?: string;
    timeZone?: string | null;
  }): string | null {
    if (!params.date) {
      return null;
    }
    let date = dayjs(params.date);
    if (params.timeZone) {
      date = date.tz(params.timeZone);
    }
    return date.format(params.format || DEFAULT_DATE_FORMAT);
  }

  public static toISOString(date: string): string {
    if (!date) return '';

    return dayjs(date).toISOString();
  }

  // eslint-disable-next-line max-params
  public static parseCustomFormatDateToISOString(date: string, parseFormat: string, timeZone: string) {
    return dayjs.utc(date, parseFormat).tz(timeZone, true).toISOString();
  }

  public static getDateDescription(rawDate: string | null | undefined, timeZone: string | null): string {
    if (!rawDate || !timeZone) {
      return '';
    }
    const parsedDate = DateTimeService.parseInTimeZone(rawDate, timeZone);

    const dayOfWeek = DAYS_OF_WEEK[parsedDate.day()];
    const dayOfMonth = parsedDate.date();
    const month = MONTH_GENITIVE_NAMES[parsedDate.month()];
    const hoursAndMinutes = DateTimeService.format(parsedDate, 'HH:mm');

    return `${dayOfWeek} ${dayOfMonth} ${month}, ${hoursAndMinutes}`;
  }

  public static parseInTimeZone(rawDate: string, timeZone: string): Dayjs {
    return dayjs(rawDate).tz(timeZone);
  }

  public static handleGlobalLocale(lng?: string): string {
    return dayjs.locale(lng);
  }

  static addTime(value: number, unit: DateTimeService.Unit): Date {
    return dayjs().add(value, unit).toDate();
  }

  public static today(): string {
    const today: string = dayjs().format();

    return DateTimeService.toISOString(today);
  }

  public static getTimeInMs(date: Date | string): number {
    if (!date) return 0;

    return dayjs(date).valueOf();
  }

  public static toLocalISO(date: Date | string): string | null {
    if (!dayjs(date).isValid()) {
      return null;
    }

    const offset: number = dayjs(date).utcOffset();

    return dayjs(date).add(offset, 'minutes').toISOString();
  }

  public static now(): string {
    const offset: number = dayjs().utcOffset();

    return dayjs().add(offset, 'minutes').toISOString();
  }

  public static getJsDate(date: Date | string): Date | null {
    if (!dayjs(date).isValid()) {
      return null;
    }

    return dayjs(date).toDate();
  }

  // eslint-disable-next-line max-params
  public static difference(date1: string, date2: string, options?: IDifferenceOptions): number {
    return dayjs(date1).diff(date2, options?.unit, options?.float);
  }

  public static formatUnix(date: number, format?: string) {
    const unixDate = dayjs.unix(date);
    return this.format(unixDate, format);
  }
}

export namespace DateTimeService {
  export enum Unit {
    Years = 'years',
    Months = 'months',
    Days = 'days',
    Hours = 'hours',
    Minutes = 'minutes',
    Seconds = 'seconds',
  }

  export const getTimezoneOffset = () => new Date().getTimezoneOffset();

  export function getTimezoneOffsetString() {
    const offset = getTimezoneOffset();

    const hours = offset / MINUTES_IN_HOUR;
    const partOfHour = hours % 1;
    const minutes = MINUTES_IN_HOUR * partOfHour;

    const hoursString = hours.toLocaleString('ru', {
      minimumIntegerDigits: 2,
      signDisplay: 'always',
    });

    const minutesString = minutes.toLocaleString('ru', {
      minimumIntegerDigits: 2,
      signDisplay: 'never',
    });

    return `${hoursString}${minutesString}`;
  }

  const MINUTES_IN_HOUR = 60;

  export class DateModel {
    static of(date?: null | string | Date | DateModel | dayjs.Dayjs) {
      return new DateModel(date);
    }

    private readonly date: dayjs.Dayjs;

    constructor(date?: null | string | Date | DateModel | dayjs.Dayjs) {
      this.date = date instanceof DateModel ? date.date : dayjs(date || new Date());
    }

    add(value: number, unit: DateTimeService.Unit) {
      return new DateModel(this.date.add(value, unit));
    }

    format(template?: string | null) {
      return this.date.format(template || undefined);
    }

    toDate() {
      return this.date.toDate();
    }
  }
}
