import { RequestController } from 'src/utils/decorators/request-controller';
import { ISkuItem } from '../interfaces';
import GrpcService, { PlatformAdminApi } from 'src/services/GrpcService';

class WmsSkusServiceProvider {
  @RequestController({ logger: true, debounce: true })
  search(request: PlatformAdminApi.SkusRequest): Promise<PlatformAdminApi.SkusList> {
    return GrpcService.SkuService.RetrieveSkus(request);
  }

  @RequestController({ logger: true })
  updateImage(image: PlatformAdminApi.ChangeImageRequest): Promise<PlatformAdminApi.SkuViewItem> {
    return GrpcService.SkuService.UpdateImage(image);
  }

  @RequestController({ logger: true })
  async findById(id: number): Promise<ISkuItem | undefined> {
    return GrpcService.SkuService.FindSkuById({ id });
  }

  @RequestController({ logger: true })
  async updateSkuItem(data: PlatformAdminApi.IUpdateSkuRequest) {
    return GrpcService.SkuService.UpdateSku(data);
  }
}

export const wmsSkusServiceProvider = new WmsSkusServiceProvider();
export type TWmsSkusServiceProvider = typeof wmsSkusServiceProvider;
