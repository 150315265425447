import React, { FC } from 'react';
import { ExecutionHistoryItem, PresenterSalesItem } from '../../interfaces';
import {
  getTitleStyles,
  TASKS_STATES_TRANSLATIONS_STRING,
  TICKET_DATE_TIME_FORMAT,
  TICKET_TASK_STATUS_MAP,
} from '../../constants';
import { useTranslationHook } from 'src/hooks';
import { Badge } from 'react-bootstrap';
import classNames from 'classnames';
import styles from './SaleItem.module.scss';
import { TaskHistoryItem } from '../Task/components/TaskHistoryItem';
import { ExecutionHistoryItemKindEnum, TaskStateEnum } from '../../../../services/GraphQL';
import { DateTimeService } from '../../../../services/DateTimeService';

interface ISaleItemProps {
  sale: PresenterSalesItem;
}

export const SaleItem: FC<ISaleItemProps> = ({ sale }: ISaleItemProps) => {
  // Задача переходит в статус New после отмены коммуникации. Добавляем в историю запись о создании задачи, если ее нет
  const createdHistoryItem: ExecutionHistoryItem | null =
    sale?.executionHistory?.[0]?.taskStateValue === TaskStateEnum.NEW
      ? null
      : {
          ...sale.executionHistory[0],
          id: sale.id,
          createdAt: DateTimeService.format(sale.createdAt, TICKET_DATE_TIME_FORMAT),
          kind: ExecutionHistoryItemKindEnum.CHANGE_STATE,
          taskStateValue: TaskStateEnum.NEW,
        };

  return (
    <div className={styles.salesItemContainer}>
      <div className={classNames(styles.saleHeader, 'p-3')}>
        <div className={classNames('d-flex', 'align-items-center')}>
          <span className={classNames('flex-grow-1', 'font-semi-bold-14', getTitleStyles(sale.state))}>
            {sale.title}
          </span>
          <Badge variant={TICKET_TASK_STATUS_MAP[sale.state] ?? 'primary'}>
            {useTranslationHook(TASKS_STATES_TRANSLATIONS_STRING[sale.state])}
          </Badge>
        </div>
        <div className={classNames(styles.saleSkill, 'pt-4')}>{sale.skill}</div>
      </div>
      <div className={classNames(styles.historyItemBorder, 'p-3')}>
        {createdHistoryItem && <TaskHistoryItem item={createdHistoryItem} />}
        {sale.executionHistory.map((historyItem) => (
          <TaskHistoryItem key={historyItem.id} item={historyItem} />
        ))}
      </div>
      {!!sale.orderInformation && (
        <div className={classNames(styles.orderInformation, 'p-3')}>
          <div className="d-flex mb-1">
            <div className="mr-3">
              <span className="mr-1 text-muted">Скидка:</span>
              <span>{sale.orderInformation.totalDiscount}</span>
            </div>
            <div className="mr-3">
              <span className="mr-1 text-muted">Бонусы:</span>
              <span>{sale.orderInformation.usedBonuses}</span>
            </div>
            <div>
              <span className="mr-1 text-muted">Время:</span>
              <span>{sale.orderInformation.totalDuration}</span>
            </div>
          </div>
          <div className="d-flex">
            <div className="mr-3">
              <span className="mr-1 text-muted">Без скидок:</span>
              <span>{sale.orderInformation.subTotalPrice}</span>
            </div>
            <div>
              <span className="mr-1 text-muted">Итог:</span>
              <span>{sale.orderInformation.totalPrice}</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
