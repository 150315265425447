export enum FIELD_FEEDBACK_TYPES {
  WARNING = 'warning',
  SUCCESS = 'success',
  DANGER = 'danger',
  DEFAULT = 'default',
}

export interface IFieldFeedbackProps {
  type: FIELD_FEEDBACK_TYPES;
  text: string | null | undefined | JSX.Element;
}
