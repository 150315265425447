import React from 'react';
import { useHistory } from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';

import { routes } from 'src/routes';
import { IBreadcrumbRedirectProps } from './interfaces';

export const BreadcrumbRedirect = ({ redirectLink = '', text }: IBreadcrumbRedirectProps): JSX.Element => {
  const history = useHistory();
  const routesConfig = routes();
  const breadcrumbs = useBreadcrumbs(routesConfig);
  // @ts-expect-error todo
  const pathForRedirection = breadcrumbs.find((breadcrumb) => breadcrumb.match.path === redirectLink)?.match.url;

  const onClick = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e.stopPropagation();
    history.push(pathForRedirection || redirectLink);
  };

  return <span onClick={onClick}>{text}</span>;
};
