import { captureGrpcException } from '@qlean/front-sentry';
import { GrpcService } from './GrpcService';
import ToastifyService from '../ToastifyService';
import AuthorizeStore from '../../store/AuthorizeStore';
import { REFRESH_TOKEN_TIME } from 'src/store/AuthorizeStore/AuthorizeStore';
import { BaseException, ExceptionBuilder, InvalidArgumentException } from '@qlean/front-exceptions';

export * from './codegen/app_pb';

declare const REACT_APP_API: string;

const REFRESH_TIMEOUT = 1500;

const grpcService = new GrpcService(REACT_APP_API);

function getErrorMessage(error: Error): string {
  const apiException = ExceptionBuilder.buildFromRpcException(error);

  if (apiException instanceof InvalidArgumentException) {
    if (apiException.validationErrors.length) {
      return apiException.validationErrors.map(({ message }) => message).join('\n');
    }
  }

  if (apiException instanceof BaseException) {
    return apiException.message;
  }

  return String(error);
}

grpcService.interceptors.errors.push((error): Promise<void | unknown> => {
  const errorMessage = getErrorMessage(error);

  if (error.code === 419) {
    const lastRefreshTokenTime = localStorage.getItem(REFRESH_TOKEN_TIME);
    const lockExpirationTime = parseInt(lastRefreshTokenTime ?? '0', 10) + REFRESH_TIMEOUT;

    if (lastRefreshTokenTime && lockExpirationTime > Date.now()) {
      // eslint-disable-next-line no-promise-executor-return
      return new Promise((resolve) => setTimeout(resolve, REFRESH_TIMEOUT + 500));
    }

    return AuthorizeStore.refresh().catch(() => {
      AuthorizeStore.removeAuthToken();
      window.location.href = '/login';
    });
  }

  if (error.code === 401) {
    AuthorizeStore.removeAuthToken();
    window.location.href = '/login';
  }

  ToastifyService.toast(errorMessage, { type: 'error', autoClose: 2000 });

  // if error.code in 500...599
  if (error.code >= 500 && error.code < 600) {
    captureGrpcException(error);
  }

  return Promise.resolve();
});

export default grpcService;
