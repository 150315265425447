import { PlatformAdminApi } from 'src/services/GrpcService';

export class AddressDetailModel implements PlatformAdminApi.IAddressDetail {
  // eslint-disable-next-line max-params
  constructor(
    public readonly region: string = '',
    public readonly city: string = '',
    public readonly settlement: string = '',
    public readonly area: string = '',
    public readonly street: string = '',
    public readonly block: string = '',
    public readonly house: string = '',
    public readonly floor: string = '',
    public readonly flat: string = '',
    public readonly comment: string = '',
    public readonly intercom: string = '',
    public readonly entrance: string = '',
  ) {
    if (region === city) {
      this.region = '';
    }

    Object.keys(this).forEach((key) => (this[key] = this[key]?.trim()));
  }
}
