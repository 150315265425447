import React, { FC } from 'react';
import { observer } from 'mobx-react';

import { IconButton } from 'src/libs/ui-kit';

import { useRightSidebarStore } from '../store';

export const ButtonsList: FC = observer(() => {
  const { buttonsList, setSelected } = useRightSidebarStore();

  return (
    <div>
      {buttonsList.map((button) => (
        <div className="mb-2" key={button.id}>
          {button.isVisible && (
            <IconButton
              variant={button.state}
              icon={button.icon}
              disabled={button.disabled}
              clickHandler={() => setSelected(button)}
            />
          )}
        </div>
      ))}
    </div>
  );
});
