import GrpcService, { PlatformAdminApi } from 'src/services/GrpcService';

import {
  ICreate,
  ISearch,
  IUpdate,
  IRestore,
  IFindById,
  IRemove,
} from 'src/services/ServiceLocator/ServiceLocator.interface';
import { RequestController } from 'src/utils/decorators/request-controller';

class ApiServiceProvider implements ISearch, ICreate, IUpdate, IRestore, IFindById, IRemove {
  @RequestController({ logger: true, retry: true, debounce: true })
  search(params: PlatformAdminApi.IApiSearchRequest): Promise<PlatformAdminApi.ApiSearchResponse> {
    return GrpcService.ApiService.Search(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  create(params: PlatformAdminApi.IApiUpsertRequest): Promise<PlatformAdminApi.ApiResponse> {
    return GrpcService.ApiService.Upsert(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  update(params: PlatformAdminApi.IApiUpsertRequest): Promise<PlatformAdminApi.ApiResponse> {
    return GrpcService.ApiService.Upsert(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  restore(params: PlatformAdminApi.IUuidRequest): Promise<PlatformAdminApi.ApiResponse> {
    return GrpcService.ApiService.Restore(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  remove(params: PlatformAdminApi.IUuidRequest): Promise<PlatformAdminApi.ApiResponse> {
    return GrpcService.ApiService.Remove(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  findById(params: PlatformAdminApi.IUuidRequest): Promise<PlatformAdminApi.ApiResponse> {
    return GrpcService.ApiService.FindById(params);
  }
}

export const apiServiceProvider = new ApiServiceProvider();
export type TApiServiceProvider = typeof apiServiceProvider;
