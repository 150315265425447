import GrpcService, { PlatformAdminApi } from 'src/services/GrpcService';

import {
  ICreate,
  ISearch,
  IUpdate,
  IRestore,
  IFindById,
  IRemove,
} from 'src/services/ServiceLocator/ServiceLocator.interface';
import { RequestController } from 'src/utils/decorators/request-controller';

class PositionServiceProvider implements ISearch, ICreate, IUpdate, IRestore, IFindById, IRemove {
  @RequestController({ logger: true, retry: true, debounce: true })
  search(params: PlatformAdminApi.IPositionSearchRequest): Promise<PlatformAdminApi.PositionSearchResponse> {
    return GrpcService.PositionService.Search(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  create(params: PlatformAdminApi.IPositionCreateRequest): Promise<PlatformAdminApi.PositionResponse> {
    return GrpcService.PositionService.Create(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  update(params: PlatformAdminApi.IPositionUpdateRequest): Promise<PlatformAdminApi.PositionResponse> {
    return GrpcService.PositionService.Update(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  restore(params: PlatformAdminApi.IUuidRequest): Promise<PlatformAdminApi.PositionResponse> {
    return GrpcService.PositionService.Restore(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  remove(params: PlatformAdminApi.IUuidRequest): Promise<PlatformAdminApi.PositionResponse> {
    return GrpcService.PositionService.Remove(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  findById(params: PlatformAdminApi.IUuidRequest): Promise<PlatformAdminApi.PositionResponse> {
    return GrpcService.PositionService.FindById(params);
  }
}

export const positionServiceProvider = new PositionServiceProvider();
export type TPositionServiceProvider = typeof positionServiceProvider;
