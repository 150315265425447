import { AppSubModule } from 'src/interfaces';
import { CATALOGS_PROVIDER_TOKENS } from '../constants';
import { tenantServiceProvider } from './service-providers/TenantService.provider';

class TenantListModule extends AppSubModule {
  public providers = [
    {
      token: CATALOGS_PROVIDER_TOKENS.TENANT_SERVICE,
      provider: tenantServiceProvider,
    },
  ];
}

export const tenantListModule = new TenantListModule();
