import * as debounceFn from 'debounce-promise';

import { IDebounceOptions, TDecorator } from '../interfaces';

const DEFAULT_DEBOUNCE_TIME = 400;

export function AsyncDebounce(debounceOptions: Partial<IDebounceOptions> = {}): TDecorator {
  const { debounceTime = DEFAULT_DEBOUNCE_TIME } = debounceOptions;

  // eslint-disable-next-line max-params
  return (target: unknown, propertyKey: string, descriptor: PropertyDescriptor): void => {
    const originalMethod = descriptor.value;
    const debouncedRequest = debounceFn(originalMethod, debounceTime);

    descriptor.value = function (...args) {
      return debouncedRequest.apply(this, args);
    };
  };
}
