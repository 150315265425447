import { IAdapter, ICoordinates, IMetro, ITotalBlockInfo, TAddress } from '../address.interfaces';
import { TotalInfoBlockModel } from '../models';
import { METRO_LINE_COLORS } from '../address-constants';

class TotalInfoBlockAdapter implements IAdapter<TAddress, ITotalBlockInfo> {
  adapt(address: TAddress): ITotalBlockInfo {
    return new TotalInfoBlockModel(
      address.value,
      TotalInfoBlockAdapter.adaptAddressLink(address.coordinates),
      TotalInfoBlockAdapter.adaptMetro(address.metro),
      address.detail?.intercom,
    );
  }

  private static adaptAddressLink(coordinates: ICoordinates | null): string {
    if (!coordinates) return '';

    return `https://yandex.ru/maps/?pt=${coordinates.lng},${coordinates.lat}&z=15`;
  }

  private static adaptMetro(metro: IMetro[]): IMetro[] {
    const metroColors = METRO_LINE_COLORS.reduce((acc, metro) => ({ ...acc, [metro.line]: metro.color }), {});

    return metro.reduce((acc: IMetro[], metro) => {
      acc.push({ ...metro, color: metroColors[metro.line] });

      return acc;
    }, []);
  }
}

export default new TotalInfoBlockAdapter();
