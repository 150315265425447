import { PlatformAdminApi } from 'src/services/GrpcService';
import { SweetAlertOptions } from 'sweetalert2';
import { IStatusFilter } from './Contracts.interfaces';

export enum CONTRACTS_PROVIDER_TOKENS {
  CONTRACTS_SERVICE = 'contractsService',
}

export enum EContractStatuses {
  'BLOCKED' = 'BLOCKED',
  'ACTIVE' = 'ACTIVE',
}

export const BASE_MODAL_CONFIG: SweetAlertOptions = {
  showCancelButton: true,
  confirmButtonText: 'Да',
  cancelButtonText: 'Отмена',
  buttonsStyling: false,
  customClass: {
    confirmButton: 'btn btn-warning btn-lg',
    cancelButton: 'btn btn-default btn-lg',
    actions: 'text-center',
  },
};

export const MODAL_REMOVE_CONFIG: SweetAlertOptions = {
  ...BASE_MODAL_CONFIG,
  title: 'Удалить?',
  text: 'Вы планируете удалить выбранную сущность.',
  icon: 'error',
  customClass: {
    ...BASE_MODAL_CONFIG.customClass,
    confirmButton: 'btn btn-danger btn-lg',
  },
};

export const MODAL_RESTORE_CONFIG: SweetAlertOptions = {
  ...BASE_MODAL_CONFIG,
  title: 'Восстановить?',
  text: 'Вы планируете восстановить выбранную сущность.',
  icon: 'info',
  customClass: {
    ...BASE_MODAL_CONFIG.customClass,
    confirmButton: 'btn btn-info btn-lg',
  },
};

export const MODAL_BLOCK_CONFIG: SweetAlertOptions = {
  ...BASE_MODAL_CONFIG,
  title: 'Блокировка контракта',
  customClass: {
    ...BASE_MODAL_CONFIG.customClass,
    title: 'contract-title',
  },
};

export enum EStatusFilters {
  ALL = 'Все контракты',
  ACTIVE = 'Активные',
  BLOCKED = 'Заблокированные',
}

const STATUS_FILTERS_WHERE_FIELDS = {
  [EStatusFilters.ALL]: {},
  [EStatusFilters.ACTIVE]: { like: 'ACTIVE' },
  [EStatusFilters.BLOCKED]: { like: 'BLOCKED' },
};

export const STATUS_FILTERS: IStatusFilter[] = [
  { title: EStatusFilters.ALL, where: undefined },
  { title: EStatusFilters.ACTIVE, where: STATUS_FILTERS_WHERE_FIELDS[EStatusFilters.ACTIVE] },
  { title: EStatusFilters.BLOCKED, where: STATUS_FILTERS_WHERE_FIELDS[EStatusFilters.BLOCKED] },
];

export const DEFAULT_PRODUCT: PlatformAdminApi.IProductFilterOptionsListItem = {
  id: 'all',
  name: 'Все продукты',
  title: 'Все продукты',
};

export const DEFAULT_SPECIALIZATION: PlatformAdminApi.ISpecializationFilterOptionsListItem = {
  id: 'all',
  title: 'Все специализации',
};
