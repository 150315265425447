import React from 'react';
import { BreadcrumbRedirect } from 'src/components/Breadcrumbs';
import { IRoute } from 'src/routes';
import { IProviderRecord } from 'src/services/ServiceLocator/ServiceLocator.interface';
import { AppModule, Menu, MENU_TYPES } from '../../interfaces';
import { contractorsModule } from './contractors';
import { customersModule } from './customers/customers.module';
import { ordersRoutes } from './orders/orders.routes';

class UsersModule extends AppModule {
  public routes = (fallback: JSX.Element): IRoute[] => [
    {
      path: '/users/',
      exact: true,
      breadcrumb: () => <BreadcrumbRedirect redirectLink="/users/customers/" text="Пользователи" />,
      title: 'CRM',
    },
    ...customersModule.routes(fallback),
    ...contractorsModule.routes(fallback),
    ...ordersRoutes(fallback),
  ];
  public menu = (): Menu[] => [
    {
      type: MENU_TYPES.ITEMS,
      text: 'Пользователи',
      link: '/users',
      children: [...customersModule.menu(), ...contractorsModule.menu()],
    },
  ];

  public providers: IProviderRecord[] = [...customersModule.providers, ...contractorsModule.providers];
}

export const usersModule = new UsersModule();
