import { lazy } from '../../../utils';

import { IRoute } from '../../../routes';

export default (fallback: JSX.Element): IRoute[] => [
  {
    path: '/hrm/wages/',
    pureComponent: lazy(() => import('./pages/wages-list'), fallback),
    exact: true,
    breadcrumb: 'Тарифы',
    title: 'Тарифы',
  },
  {
    path: '/hrm/wages/new',
    pureComponent: lazy(() => import('./pages/wage-edit'), fallback),
    exact: true,
    breadcrumb: 'Новый тариф',
    title: 'Новый тариф',
  },
  {
    path: '/hrm/wages/:id',
    pureComponent: lazy(() => import('./pages/wage-view'), fallback),
    exact: true,
    breadcrumb: 'Карточка тарифа',
    title: 'Тариф',
  },
  {
    path: '/hrm/wages/:id/edit',
    pureComponent: lazy(() => import('./pages/wage-edit'), fallback),
    exact: true,
    breadcrumb: 'Редактирование тарифа',
    title: 'Редактирование тарифа',
  },
];
