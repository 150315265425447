import React from 'react';
import cn from 'classnames';
import TaskStyles from '../task.module.scss';
import { ExecutionHistoryItem } from '../../../interfaces';
import { useTranslationHook } from 'src/hooks';
import { ExecutionHistoryItemKindEnum } from 'src/services/GraphQL';
import { Badge } from 'react-bootstrap';
import {
  HISTORY_KIND_TRANSLATIONS_STRING,
  TASKS_STATES_TRANSLATIONS_STRING,
  TICKET_TASK_STATUS_MAP,
} from '../../../constants';

export interface TicketHistoryItemProps {
  item: ExecutionHistoryItem;
}

export const TaskHistoryItem = ({ item }: TicketHistoryItemProps) => (
  <div className={cn('mx-3', 'py-2')}>
    <div className={cn(TaskStyles.statusRow, 'mb-1')}>
      <div className={TaskStyles.statusCell}>
        {item.kind === ExecutionHistoryItemKindEnum.ADD_TASK_TO_TASK_LIST && (
          <Badge variant="primary">
            {/* eslint-disable-next-line react-hooks/rules-of-hooks */}
            {useTranslationHook(HISTORY_KIND_TRANSLATIONS_STRING[ExecutionHistoryItemKindEnum.ADD_TASK_TO_TASK_LIST])}
          </Badge>
        )}

        {item.kind === ExecutionHistoryItemKindEnum.CHANGE_STATE && item.taskStateValue != null && (
          <Badge variant={TICKET_TASK_STATUS_MAP[item.taskStateValue]}>
            {/* eslint-disable-next-line react-hooks/rules-of-hooks */}
            {useTranslationHook(TASKS_STATES_TRANSLATIONS_STRING[item.taskStateValue])}
          </Badge>
        )}
      </div>

      <div className={cn(TaskStyles.createdAt, 'font-regular-sm')}>{item.createdAt}</div>
    </div>

    <div>
      <span className={cn(TaskStyles.managerName, 'font-regular-sm')}>{item.managerName}</span>
    </div>
  </div>
);
