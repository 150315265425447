import { IRoute } from 'src/routes';
import { lazy } from 'src/utils';

export default (fallback: JSX.Element): IRoute[] => [
  {
    path: '/users/customers/',
    pureComponent: lazy(() => import('./pages/customer-list/CustomersList.page'), fallback),
    exact: true,
    breadcrumb: 'Клиенты',
    title: 'Клиенты',
  },
  {
    path: '/users/customers/:userId/',
    pureComponent: lazy(() => import('./pages/customer-view/CustomerView.page'), fallback),
    exact: true,
    breadcrumb: 'Карточка клиента',
    title: 'Карточка клиента',
  },
  {
    path: '/users/customers/:userId/:ticketId',
    pureComponent: lazy(() => import('./pages/customer-view/CustomerView.page'), fallback),
    exact: true,
    breadcrumb: 'Карточка клиента',
    title: 'Карточка клиента',
  },
  // {
  //   path: '/users/customers/new/',
  //   pureComponent: lazy(() => import('./pages/сontract-сreate/CustomerCreate.page'), fallback),
  //   exact: true,
  //   breadcrumb: 'Новый клиент',
  //   title: 'Новый клиент',
  // },
  // {
  //   path: '/users/customers/:id/',
  //   pureComponent: lazy(() => import('./pages/customer-view/CustomerView.page'), fallback),
  //   exact: true,
  //   breadcrumb: 'Карточка клиента',
  //   title: 'Карточка клиента',
  // },
  // {
  //   path: '/users/customers/:id/edit/',
  //   pureComponent: lazy(() => import('./pages/сontract-edit/CustomerEdit.page'), fallback),
  //   exact: true,
  //   breadcrumb: 'Редактирование клиента',
  //   title: 'Редактирование клиента',
  // },
];
