import { ApplyTask, EntityTypeEnum } from 'src/services/GraphQL';
import { IApplyTaskPresenter } from '../../../interfaces';

export const ApplyTaskAdapterHelper = (task: ApplyTask['applyTask']): IApplyTaskPresenter => {
  let entityLink: string = '';

  if (!task?.entity) {
    return {
      clientId: `/users/orders/${task.clientId}`,
      entityLink: '',
      entityId: '',
    };
  }

  if (task.entity?.entityType === EntityTypeEnum.ORDER) {
    entityLink = `/users/orders/${task.clientId}/${task.entity.entityId}`;
  } else {
    entityLink = `/users/customers/${task.clientId}/${task.entity.entityId}`;
  }

  return { clientId: task.clientId, entityLink, entityId: task.entity.entityId };
};
