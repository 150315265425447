import React from 'react';
import { Dropdown, Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

interface IProp {
  isRTL: boolean;
  login: string;
  profileRoute?: string;
  onLogout?: () => void;
}

export class UserMenu extends React.Component<IProp> {
  render() {
    const { login, profileRoute, isRTL, onLogout } = this.props;

    return (
      <Dropdown as={Nav.Item} className="demo-navbar-user" alignRight={!isRTL}>
        <Dropdown.Toggle id="demo-user-dropdown" as={Nav.Link}>
          <span className="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
            <span className="px-1 mr-lg-2 ml-2 ml-lg-0">{login}</span>
          </span>
        </Dropdown.Toggle>

        {(profileRoute || onLogout) && (
          <Dropdown.Menu>
            {profileRoute && (
              <Dropdown.Item as={NavLink} to={profileRoute}>
                <i className="ion ion-ios-person text-primary" /> &nbsp; Мой профиль
              </Dropdown.Item>
            )}

            {onLogout && (
              <Dropdown.Item onClick={onLogout}>
                <i className="ion ion-ios-log-out text-danger" /> &nbsp; Выйти
              </Dropdown.Item>
            )}
          </Dropdown.Menu>
        )}
      </Dropdown>
    );
  }
}
