import { RequestController } from 'src/utils/decorators/request-controller';
import { ITemplatesChildItem, ITemplatesParentItem } from '../interfaces';
import GrpcService, { PlatformAdminApi } from 'src/services/GrpcService';

class WmsTemplatesServiceProvider {
  @RequestController({ logger: true })
  search(request: PlatformAdminApi.TemplatesRequest): Promise<PlatformAdminApi.ITemplatesList> {
    return GrpcService.TemplatesService.RetrieveTemplates(request);
  }

  @RequestController({ logger: true })
  findParentTemplateById(id: number, ruleId: number): Promise<ITemplatesParentItem | undefined> {
    return GrpcService.TemplatesService.FindParentTemplateById({ id, ruleId });
  }

  @RequestController({ logger: true })
  findChildTemplateById({ id, parentId }: { id: number; parentId: number }): Promise<ITemplatesChildItem | undefined> {
    return GrpcService.TemplatesService.FindChildTemplateById({ id, parentId });
  }
}

export const wmsTemplatesServiceProvider = new WmsTemplatesServiceProvider();
export type TWmsTemplatesServiceProvider = typeof wmsTemplatesServiceProvider;
