import PureCache from 'pure-cache';
import { Logger } from '../LoggerService';

/**
 *
 * defaultCacheExpiryIn: 60000ms.
 * expiryCheckInterval: 100ms.
 *
 * Настройки по умолчанию можно посмотреть здесь:
 * @link https://github.com/ganapativs/pure-cache/blob/master/src/constants/pureCacheDefaultConfig.js
 */
export interface IAsyncCacheOptions {
  expiryCheckInterval?: number;
  expiryIn?: number;
}

export interface IAsyncCacheData {
  addedAt: number;
  expiryAt: number;
  value: unknown;
}

const DEFAULT_CACHE_EXPIRE_IN = 60000;

export class AsyncCacheStorage {
  private static instance: PureCache;

  /**
   * Конструктор нужен только для того, чтобы сделать его приватным.
   * Это позволит не допустить возможность создания нового экземпляра через new CacheStorage снаружи класса.
   * @private
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  public static createAsyncCache(options?: IAsyncCacheOptions): PureCache {
    if (!AsyncCacheStorage.instance) {
      AsyncCacheStorage.instance = new PureCache(options);
    }

    return AsyncCacheStorage.instance;
  }

  // eslint-disable-next-line max-params
  public static setItem(key: string, data: unknown, expireIn: number = DEFAULT_CACHE_EXPIRE_IN): void {
    const itemExists = Boolean(AsyncCacheStorage.getItem(key));

    if (itemExists) {
      Logger.error(`The key '${key}' already exists in the cache storage. Please choose another one.`);

      return;
    }

    AsyncCacheStorage.instance.put(key, data, expireIn);
  }

  public static getItem(token: string): IAsyncCacheData {
    return AsyncCacheStorage.instance.get(token);
  }

  public static removeItem(key: string): void {
    const itemExists = Boolean(AsyncCacheStorage.getItem(key));

    if (!itemExists) {
      Logger.error(`The key '${key}' doesn't exist in the cache storage. Please check if its correct.`);

      return;
    }

    AsyncCacheStorage.instance.removeItem(key);
  }

  public static destroy(): void {
    AsyncCacheStorage.instance.dispose();
  }
}
