import { AppSubModule } from 'src/interfaces';
import { CATALOGS_PROVIDER_TOKENS } from '../constants';
import { regulationsServiceProvider } from './service-providers/RegulationsService.provider';

class RegulationsListModule extends AppSubModule {
  public providers = [
    {
      token: CATALOGS_PROVIDER_TOKENS.REGULATIONS_SERVICE,
      provider: regulationsServiceProvider,
    },
  ];
}

export const regulationsListModule = new RegulationsListModule();
