import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Col, Form, Row } from 'react-bootstrap';
import { FieldLabel, IFieldLabelProps, FieldError } from '../../common';
import { IStringFieldProps } from './StringField.interface';
import { useField, useFormikContext } from 'formik';

export const StringField: FC<IStringFieldProps> = observer((props: IStringFieldProps): JSX.Element => {
  const {
    className,
    mode = 'vertical',
    variant = 'default',
    name = '',
    label,
    value = '',
    onChange,
    validate,
    style,
    ...rest
  } = props;
  const isHorizontal = mode === 'horizontal';
  const fieldProps = isHorizontal ? { as: Row } : {};
  const labelProps: IFieldLabelProps = isHorizontal ? { column: { mode: 'sm', size: 3 } } : {};
  // eslint-disable-next-line react/jsx-no-useless-fragment
  const wrapper = (children) => (isHorizontal ? <Col sm={9}>{children}</Col> : <>{children}</>);
  const [inputProps, { error, touched }, { setValue, setTouched }] = useField({ name, defaultValue: value, validate });
  const { validateField } = useFormikContext();

  const changeHandler = (event) => {
    setValue(event.target.value);
    setTouched(true);

    if (onChange) {
      onChange(event);
    }
  };

  useEffect(() => {
    if (validate) {
      validateField(inputProps.name);
    }
  }, [inputProps.name, validate, validateField]);

  return (
    <Form.Group id={name} className={className} style={style} {...fieldProps}>
      <FieldLabel label={label} {...labelProps} />
      {wrapper(
        <>
          {variant === 'default' && (
            <Form.Control {...rest} name={name} value={inputProps.value || ''} onChange={changeHandler} />
          )}
          {variant === 'textarea' && (
            <Form.Control as="textarea" {...rest} name={name} value={inputProps.value} onChange={changeHandler} />
          )}
        </>,
      )}
      {touched && <FieldError error={error || ''} />}
    </Form.Group>
  );
});
