import { AppSubModule } from 'src/interfaces';
import { PLT_CORE_PROVIDER_TOKENS } from 'src/modules/plt-core/constants';
import { tagsServiceProvider } from 'src/modules/plt-core/service-providers';

class TagsListModule extends AppSubModule {
  public providers = [
    {
      token: PLT_CORE_PROVIDER_TOKENS.TAGS_SERVICE,
      provider: tagsServiceProvider,
    },
  ];
}

export const tagsListModule = new TagsListModule();
