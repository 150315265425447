import { AppSubModule } from 'src/interfaces';
import { CATALOGS_PROVIDER_TOKENS } from '../constants';
import { tokenServiceProvider } from './service-providers/TokenService.provider';

class TokensListModule extends AppSubModule {
  public providers = [
    {
      token: CATALOGS_PROVIDER_TOKENS.TOKEN_SERVICE,
      provider: tokenServiceProvider,
    },
  ];
}

export const tokensListModule = new TokensListModule();
