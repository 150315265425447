import React, { memo } from 'react';
import { Navbar } from 'react-bootstrap';

import styles from './LayoutSubHeader.module.scss';
import cn from 'classnames';
import { SubHeaderPortal } from '../../../portals';

export const LayoutSubHeader = memo(() => {
  const isVisible = SubHeaderPortal.useBusy();

  return isVisible ? (
    <Navbar
      expand="lg"
      className={cn({
        'layout-navbar': true,
        [styles.subNavBar]: true,
        [styles.subNavBarHidden]: !isVisible,
      })}>
      <SubHeaderPortal.Target className={styles.portal} />
    </Navbar>
  ) : null;
});
