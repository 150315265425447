import React, { FC, useState } from 'react';
import { LoaderButton } from 'src/libs/ui-kit/buttons/LoaderButton';
import { PresenterTaskItem } from 'src/features/Tickets/interfaces';
import { useTicketsRootStore } from 'src/features/Tickets/store/tickets-root-store/TicketsStoreProvider';

interface ITakeToWorkToolbarProps {
  task: PresenterTaskItem;
}

export const TakeToWorkToolbar: FC<ITakeToWorkToolbarProps> = ({ task }) => {
  const {
    ticketsSystemStore: { applyTaskInTicket, getManagerSsoId },
  } = useTicketsRootStore();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onApplyTask = async () => {
    const managerSsoId: string = getManagerSsoId();

    setIsLoading(true);
    await applyTaskInTicket({ assigneeSsoId: managerSsoId, task });
    setIsLoading(false);
  };

  return (
    <LoaderButton
      variant="secondary"
      className="w-100"
      isLoading={isLoading}
      onClick={onApplyTask}
      disabled={isLoading}>
      Взять в работу
    </LoaderButton>
  );
};
