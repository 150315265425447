import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Badge, Button, Card } from 'react-bootstrap';
import cn from 'classnames';
import TaskStyles from './task.module.scss';
import { PresenterTaskItem } from '../../interfaces';
import { useTranslationHook } from 'src/hooks';
import { getTitleStyles, TASKS_STATES_TRANSLATIONS_STRING, TICKET_TASK_STATUS_MAP } from '../../constants';
import { TaskFormsContainer, TaskToolbar } from './components';
import { CancellationReasonEnum, PostponeTaskVariables, TaskStateEnum } from 'src/services/GraphQL';
import { useTicketsRootStore } from '../../store';
import { Icon, StringField } from 'src/libs/ui-kit';
import { Form, Formik } from 'formik';

export interface TicketTaskCardProps {
  task: PresenterTaskItem;
  hiddenToolbar?: boolean;
}

export const TicketTaskCard = observer(({ task, hiddenToolbar }: TicketTaskCardProps) => {
  const {
    ticketsSystemStore: { postponeTask, proceedTask, updateTask, hasActiveTickets },
  } = useTicketsRootStore();
  const statusVariant = TICKET_TASK_STATUS_MAP[task.state] ?? 'primary';
  const [currentTaskAction, setCurrentTaskAction] = useState<TaskStateEnum | undefined>(undefined);
  const [toolbarIsVisible, setToolbarIsVisible] = useState<boolean>(true);
  const [isCommentEditable, setIsCommentEditable] = useState<boolean>(false);
  const closeFormCallback = useCallback(() => setCurrentTaskAction(undefined), [setCurrentTaskAction]);

  useEffect(() => {
    const hiddenToolbarStates: TaskStateEnum[] = [TaskStateEnum.PROCEED, TaskStateEnum.CANCELLED];
    const toolbarIsHidden: boolean = hiddenToolbarStates.includes(task.state);
    setToolbarIsVisible(!toolbarIsHidden && !hiddenToolbar);
  }, [task]);

  const onAction = (taskAction: TaskStateEnum): void => {
    if (taskAction === TaskStateEnum.POSTPONED) {
      postponeTaskAction();
      return;
    }

    if (taskAction === TaskStateEnum.PROCEED) {
      proceedTask(task.id);
    }

    setCurrentTaskAction(taskAction);
  };

  const postponeTaskAction = (): void => {
    const defaultCancellationReason: CancellationReasonEnum = CancellationReasonEnum.NO_PICKUP;
    const requestBody: PostponeTaskVariables = {
      id: task.id,
      cancellationReason: defaultCancellationReason,
    };

    setCurrentTaskAction(undefined);
    postponeTask(requestBody);
  };

  const onCommentSubmit = ({ comment }: { comment?: string }) => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    updateTask({ id: task.id, ticketId: task.entity!.entityId, comment });
    setIsCommentEditable(false);
  };

  return (
    <Card key={task.id}>
      <div className={cn('p-3', 'd-flex', 'align-items-center', 'border-bottom')}>
        <span className={cn('flex-grow-1', 'font-semi-bold-14', getTitleStyles(task.state))}>{task.title}</span>
        <Badge variant={statusVariant}>{useTranslationHook(TASKS_STATES_TRANSLATIONS_STRING[task.state])}</Badge>
      </div>

      <div className={TaskStyles.historyItemBorder}>
        {task.executionHistory.map((historyItem) => (
          <div key={historyItem.id} className={cn('mx-3', 'py-2')}>
            <div className={cn(TaskStyles.statusRow, 'mb-1')}>
              <div className={TaskStyles.statusCell}>
                {historyItem.taskStateValue != null && (
                  <Badge variant={TICKET_TASK_STATUS_MAP[historyItem.taskStateValue]}>
                    {useTranslationHook(TASKS_STATES_TRANSLATIONS_STRING[historyItem.taskStateValue])}
                  </Badge>
                )}
              </div>

              <div className={cn(TaskStyles.createdAt, 'font-regular-sm')}>{historyItem.createdAt}</div>
            </div>

            <div>
              <span className={cn(TaskStyles.managerName, 'font-regular-sm')}>{historyItem.managerName}</span>
            </div>
          </div>
        ))}
      </div>

      <div className="p-3 taskComment">
        {task.hasComments && <div className="mb-3">Комментарии:</div>}
        {task.comment &&
          task.comment.map((item, i) => (
            <div key={`comment-${i}`} className="mb-2">
              <Icon name="comment" className={cn('mr-1', TaskStyles.comment)} />
              {item}
            </div>
          ))}
        {task.canComment && (
          <div className="mb-2 mt-4">
            <div>
              <div onClick={() => setIsCommentEditable(true)} className={TaskStyles.addCommentLink}>
                <Icon name="plus" className="mr-2" />
                Добавить комментарий к задаче
              </div>
            </div>
          </div>
        )}
        <div>
          {isCommentEditable && (
            <Formik initialValues={{ comment: '' }} onSubmit={onCommentSubmit}>
              <Form>
                <StringField name="comment" variant="textarea" />
                <Button type="submit">Сохранить</Button>
                <Button variant="secondary" className="ml-2" onClick={() => setIsCommentEditable(false)}>
                  Отменить
                </Button>
              </Form>
            </Formik>
          )}
        </div>
      </div>

      {toolbarIsVisible && (
        <div className="px-3 py-2 border-top">
          <TaskToolbar task={task} onAction={onAction} isDisabled={!hasActiveTickets} />
        </div>
      )}

      <div>
        <TaskFormsContainer currentTaskState={currentTaskAction} taskId={task.id} onAction={closeFormCallback} />
      </div>
    </Card>
  );
});
